const columns = {
  name: { id: "name", name: "Name", sortable: true, deselectable: false },
  no_of_lessons: { id: "no_of_lessons", name: "# Lesson", sortable: false, deselectable: true },
  local_id: { id: "local_id", name: "MIS ID", sortable: false, deselectable: true },
  data_ref: { id: "data_ref", name: "Xporter ID", sortable: false, deselectable: true },
  is_sync: { id: "is_sync", name: "Type", sortable: false, deselectable: true },
  actions: { id: "actions", name: "Actions", sortable: false, deselectable: false },
}

const downloadOptions = [{ key: "xls", name: "Download All XLS" }]

const defaultColumns = ["name", "no_of_lessons", "local_id", "data_ref", "is_sync", "actions"]

const filters = {
  custom: { id: "custom", name: "Custom" },
  sync: { id: "sync", name: "Sync" },
}

export { columns, defaultColumns, downloadOptions, filters }
