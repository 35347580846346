import { useEffect, useState } from "react"
import { AutoComplete } from "@components";
import { GET_STAFF_AUTO_COMPLETES } from "@graphql"
import { getInitialsFromParts, staffFullname, stringToColor } from '@helpers'
import { useQuery } from "@apollo/client"
import PropTypes from 'prop-types';

const StaffAutoComplete = ({ clientId, inputRef, onChange, value }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage] = useState(50)
  const [searchTerm, setSearchTerm] = useState("")
  const [skip, setSkip] = useState(true)
  const [querySearchTerm, setQuerySearchTerm] = useState("")
  const [staff, setStaff] = useState([])

  useEffect(() => {
    setSearchTerm(value ? staffFullname(value) : "")
  }, [value])

  useEffect(() => {
    const debounce = setTimeout(() => setQuerySearchTerm(searchTerm), 300)
    return () => clearTimeout(debounce)
  }, [searchTerm])

  const {loading, data } = useQuery(GET_STAFF_AUTO_COMPLETES, {
    variables: { 
      clientId, 
      searchTerm: querySearchTerm, 
      page: currentPage, 
      perPage, 
      orderBy: [{ "column": "FORENAME", "order": "ASC" }, { "column": "SURNAME", "order": "ASC" }] 
    },
    fetchPolicy: "cache-first",
    skip: skip || querySearchTerm.length === 0,
    onCompleted: (data) => {
      currentPage === 1 
        ? setStaff(data.client.staff.data) 
        : setStaff((prevStaff) => ([...prevStaff, ...data.client.staff.data]))
    }
  })

  const getOptions = () => {
    if (querySearchTerm.length === 0) return []

    return staff.map((teacher) => {
      const fullname = staffFullname(teacher)
      const initials = getInitialsFromParts(teacher.forename, teacher.surname)

      return {
        id: teacher.id,
        title: fullname,
        teacher: {...teacher},
        icon: 
        <div className="flex items-center justify-center flex-none w-10 h-10 rounded-lg" style={{ backgroundColor: stringToColor(initials || "", "teacher") }}>
          <h1 className="text-lg font-semibold text-white">{initials}</h1>
        </div>,
        description: `${teacher.data_ref || ""}${teacher.data_ref && teacher.local_id ? " / " : ""}${teacher.local_id || ""}`
      }
    })
  }

  const handleSelectTeacher = (teacher) => {
    onChange(teacher.teacher)
    setSkip(true)
    setSearchTerm(teacher.title)
  }

  const handleSearchChange = (searchTerm) => {
    onChange(null)
    setSkip(false)
    setSearchTerm(searchTerm)
    setCurrentPage(1)
  }

  const handleSearchClear = () => {
    setSearchTerm("")
    onChange(null)
    setSkip(true)
  }

  const options = getOptions()

  return ( 
    <AutoComplete 
      handleChange={(searchTerm) => handleSearchChange(searchTerm)}
      handleClear={handleSearchClear}
      handleLoadMore={() => setCurrentPage(currentPage + 1)}
      handleOptionSelected={(selectedOption) => handleSelectTeacher(selectedOption)}
      hasMore={data?.client.staff.paginatorInfo.hasMorePages}
      id="staff-auto-complete"
      inputRef={inputRef}
      isLoading={loading || (searchTerm !== querySearchTerm && searchTerm !== "")}
      label="Select a member of staff"
      noData={{ title: "No teachers found", message: "Sorry no teachers found. Try adjusting or clearing your filter." }}
      placeholder="Teacher forename or surname..."
      searchTerm={searchTerm} 
      isSelected={value !== null}
      options={options}
    />      
  )
}

StaffAutoComplete.propTypes = {
  clientId: PropTypes.string.isRequired,
  inputRef: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object
}

export default StaffAutoComplete