import { gql } from "@apollo/client"

const GET_INVITATION_STUDENT_GUARDIANS = gql`  
  query studentGuardians(
    $student_id: ID!, 
    $invitedGuardiansByStudentInput: InvitedGuardiansByStudentInput!
  ) {
    invitedGuardiansByStudent(input: $invitedGuardiansByStudentInput) {
      id
    }
    
    student(id: $student_id) {
      id
      forename
      surname
      guardians {
        id 
        salutation
        forename
        surname
        user {
          id
          email
        }
        pivot {
          contact_sequence
          legal_guardian
          lives_with
        }
      }
    }
  }
`

export { GET_INVITATION_STUDENT_GUARDIANS }