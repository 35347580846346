import { gql } from "@apollo/client"

const GET_SESSION_GROUPS_XLS_DOWNLOAD_LINK = gql`
  query sessionGroupsXlsDownload($input: SessionGroupsXlsDownloadInput) {
    sessionGroupsXlsDownload(input: $input) {
	    filename
		  url
		  type
		  queued
    }
  }
`

export { GET_SESSION_GROUPS_XLS_DOWNLOAD_LINK }