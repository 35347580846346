import React, { useContext, useEffect, useState } from "react"
import { cache } from "@services/graphql/client"
import { Chip } from "@nextui-org/react"
import { CircleStackIcon } from "@heroicons/react/24/outline"
import { useLazyQuery, useQuery } from "@apollo/client"

import {
  DataTable,
  EllipsisDropdown,
  IntegrationTypeBadge,
  MisBadge,
  MisCountBadge,
  NoDataTablePlaceholder,
  Page,
  PageHeader,
  StudentGuardiansList,
  StudentLessonsList,
} from "@components"

import { ContextualSidebarContext, UserContext } from "@context"
import { GET_STUDENTS, GET_SITE_DATA_STUDENTS_SUMMARY, GET_SITE_DATA_STUDENTS_XLS_DOWNLOAD_LINK } from "@graphql"
import { clearCacheWithRegex, downloadFile, lastImportDuration, mySqlToLocal, studentFullname } from "@helpers"
import { useSetActiveSidebarLink } from "@hooks"
import { columns, defaultColumns, downloadOptions, filters } from "./data"
import { Stats } from "./Stats"

const initialSelectedColumns = JSON.parse(window.localStorage.getItem("data.students.columns")) ?? defaultColumns

const Students = () => {
  const { user } = useContext(UserContext)
  const { setContextualSidebar } = useContext(ContextualSidebarContext)
  const [isDownloading, setIsDownloading] = useState(false)
  const [refetching, setRefetching] = useState(false)
  const [selectedColumns, setSelectedColumns] = useState(initialSelectedColumns)
  const [selectedFilters, setSelectedFilters] = useState(Object.keys(filters))
  const [searchTerm, setSearchTerm] = useState("")
  const [sortDescriptor, setSortDescriptor] = useState({ column: "surname", direction: "ascending" })
  const [page, setPage] = useState(1)
  const [perPage] = useState(50)

  useSetActiveSidebarLink("SITE_DATA_STUDENTS", "SITE_DATA")

  useEffect(() => {
    window.localStorage.setItem("data.students.columns", JSON.stringify(selectedColumns))
  }, [selectedColumns])

  const summaryData = useQuery(GET_SITE_DATA_STUDENTS_SUMMARY, {
    variables: {
      clientId: user.client.id,
    },
    fetchPolicy: "cache-first",
    pollInterval: 900000,
  })

  const { loading, error, data, refetch } = useQuery(GET_STUDENTS, {
    variables: {
      searchTerm,
      page,
      perPage,
      clientId: user.client.id,
      orderBy: [
        {
          column: sortDescriptor.column.toUpperCase(),
          order: sortDescriptor.direction === "ascending" ? "ASC" : "DESC",
        },
      ],
      where: selectedFilters.length < 2 ? { column: "IS_SYNC", value: selectedFilters.includes("sync") } : null,
    },
    fetchPolicy: "cache-first",
    pollInterval: 900000,
  })

  const [getSiteDataStudentsXlsDownloadLink] = useLazyQuery(GET_SITE_DATA_STUDENTS_XLS_DOWNLOAD_LINK, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      downloadFile(data.dataStudentsXlsDownload.filename, data.dataStudentsXlsDownload.url)
      setIsDownloading(false)
    },
  })

  const refetchData = () => {
    setRefetching(true)
    refetch().then(() => setRefetching(false))
  }

  const handleDownload = type => {
    if (type === "xls") {
      setIsDownloading(true)
      getSiteDataStudentsXlsDownloadLink()
    }
  }

  const handleSearchClear = () => {
    setSearchTerm("")
    setPage(1)
  }

  const handleRefetchData = () => {
    const cacheId = cache.identify({ __typename: "Client", id: user.client.id })
    clearCacheWithRegex(cacheId, /^students\(.*\)$/g)
    refetchData()
  }

  const handleSearchChange = searchTerm => {
    setSearchTerm(searchTerm)
    setPage(1)
  }

  const handleShowGuardians = student =>
    setContextualSidebar(prevState => ({
      ...prevState,
      open: true,
      title: studentFullname(student),
      subTitle: `${studentFullname(student)} guardians are listed below.`,
      content: <StudentGuardiansList student={student} />,
      size: "lg",
    }))

  const handleShowLessons = student =>
    setContextualSidebar(prevState => ({
      ...prevState,
      open: true,
      title: studentFullname(student),
      subTitle: `${studentFullname(student)} lessons are listed below.`,
      content: <StudentLessonsList student={student} />,
      size: "lg",
    }))

  const handleSortChange = sortDescriptor => {
    setSortDescriptor(sortDescriptor)
    setPage(1)
  }

  const handleToggleColumn = selectedColumns => {
    setSelectedColumns(Object.keys(columns).filter(column => selectedColumns.has(column)))
  }

  const handleToggleFilter = selectedFilters => {
    setSelectedFilters(Object.keys(filters).filter(filter => selectedFilters.has(filter)))
  }

  const renderCell = (student, column) => {
    if (column === "actions") {
      const items = [
        { id: 1, handleClick: () => handleShowGuardians(student), caption: "View Guardians" },
        { id: 2, handleClick: () => handleShowLessons(student), caption: "View Lessons" },
      ]

      return <EllipsisDropdown items={items} />
    }

    if (["created_at", "updated_at"].includes(column)) {
      return mySqlToLocal(student[column]).format("YYYY-MM-DD HH:mm")
    }

    if (column === "is_sync" && student.is_sync) {
      return (
        <Chip classNames={{ base: "bg-sb-sync", content: "text-white" }} size="sm" variant="flat">
          Sync
        </Chip>
      )
    }

    if (column === "is_sync" && !student.is_sync) {
      return (
        <Chip classNames={{ base: "bg-sb-sync-custom", content: "text-white" }} size="sm" variant="flat">
          Custom
        </Chip>
      )
    }

    return student[column]
  }

  if (error) return `Error! ${error.message}`

  return (
    <Page>
      <PageHeader
        title="Student Data"
        subTitle="Imported student data from your MIS / CSV file."
        icon={<CircleStackIcon className="page-header-icon" />}
        contentBottom={
          <div className="flex gap-2 flex-wrap">
            <IntegrationTypeBadge name={user?.client?.importType?.name || "unknown"} />
            <MisBadge name={user?.client?.mis || "n/a"} />
            <MisCountBadge name={`${summaryData?.data?.dataStudentsSummary?.total || 0} Students`} />
          </div>
        }
      />

      <Stats
        summary={{
          dataAge: summaryData?.data?.dataStudentsSummary?.data_age,
          lastImport: lastImportDuration(summaryData?.data?.dataStudentsSummary?.imported_at),
          total: summaryData?.data?.dataStudentsSummary?.total,
          syncCount: summaryData?.data?.dataStudentsSummary?.sync_count,
          customCount: summaryData?.data?.dataStudentsSummary?.custom_count,
          missingLessonCount: summaryData?.data?.dataStudentsSummary?.missing_lesson_count,
          missingGuardianCount: summaryData?.data?.dataStudentsSummary?.missing_guardian_count,
        }}
      />

      <DataTable
        columns={columns}
        currentPage={page}
        currentSortOrder={sortDescriptor}
        data={data?.client?.students?.data}
        downloadOptions={downloadOptions}
        filters={filters}
        handleDownload={type => handleDownload(type)}
        handlePageChange={page => setPage(page)}
        handleRefetch={() => handleRefetchData()}
        handleRenderCell={(item, key) => renderCell(item, key)}
        handleSearchChange={searchTerm => handleSearchChange(searchTerm)}
        handleSearchClear={handleSearchClear}
        handleSortChange={sortDescriptor => handleSortChange(sortDescriptor)}
        handleToggleColumn={selectedColumns => handleToggleColumn(selectedColumns)}
        handleToggleFilter={selectedFilters => handleToggleFilter(selectedFilters)}
        isDownloading={isDownloading}
        loading={loading || refetching}
        isRefetching={refetching}
        noDataMessage={<NoDataTablePlaceholder title="No Students Found" message="No Students found! Try clearing any filters." />}
        paginatorInfo={data?.client?.students?.paginatorInfo}
        searchInputPlaceHolder="Search by forename / surname"
        searchTerm={searchTerm}
        selectedColumns={selectedColumns}
        selectedFilters={selectedFilters}
        showDownloadButton={true}
        showRefetchButton={true}
      />
    </Page>
  )
}

export default Students
