import { SBLogo } from '@components';
import PropTypes from 'prop-types';

const ErrorPage = ({ errorCode, title, subTitle, children = null }) => (
  <div className="h-screen bg-gradient-to-r from-blue-800 to-indigo-900 gap-y-3 text-white text-center flex flex-col items-center justify-center">
    <SBLogo className="h-40 w-auto mb-3" />
    <h1 className="text-8xl font-bold opacity-70">{errorCode}</h1>
    <h2 className="text-4xl font-semibold opacity-70">{title}</h2>
    <p className="text-xl">{subTitle}</p>
    {children && children}
  </div>
)

ErrorPage.propTypes = {
  children: PropTypes.node,
  errorCode: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
}

export default ErrorPage