import { gql } from "@apollo/client"

const GET_REPORT_TEACHER_ITINERARY = gql`
  query ($event_id: ID!, $group_ids: [ID!], $page: Int!, $perPage: Int!, $searchTerm: String) {
    reportTeacherItinerary(
      event_id: $event_id
      group_ids: $group_ids
      page: $page
      first: $perPage
      searchTerm: $searchTerm
    ) {
      data {
        id
        group
        session
        parent
        starts_at
        ends_at
        students
        lesson
        subject
        location
        type
      }

      paginatorInfo {
        total
        currentPage
        hasMorePages
        lastPage
        perPage
      }
    }
  }
`

export default GET_REPORT_TEACHER_ITINERARY
