import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Pagination,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react"

import { ColumnSelector, DownloadButton, FilterSelector, PaginatorInfo, RefreshButton, SearchInput } from "@components"

import PropTypes from "prop-types"

const DataTable = ({
  actions = null,
  columns = null,
  currentPage,
  currentSortOrder,
  data,
  disabledKeys = [],
  downloadOptions = null,
  filters = null,
  filtersSelectionMode = "multiple",
  handleDownload = () => {},
  handleRenderCell,
  handleRefetch = () => {},
  handlePageChange = () => {},
  handleSearchChange,
  handleSearchClear,
  handleSelectionChange = () => {},
  handleSortChange = () => {},
  handleToggleColumn = () => {},
  handleToggleFilter,
  handleToggleSessionFilter,
  header = null,
  isDownloading = false,
  isRefetching = false,
  loading,
  paginatorInfo,
  noDataMessage = "No data found",
  searchInputPlaceHolder = "Search by term",
  searchTerm,
  selectedColumns,
  selectedFilters = null,
  selectedKeys = new Set([]),
  selectedSessions = null,
  selectionMode = "none",
  sessionFilters,
  showDownloadButton = false,
  showColumnsButton = true,
  showPagination = true,
  showRefetchButton = false,
  showSearch = true,
}) => (
  <Card fullWidth className="shadow-lg">
    {header && (
      <CardHeader className="bg-indigo-500 text-white">
        {header}
      </CardHeader>
    )}
    
    <CardHeader className="flex gap-3 items-center">
      {showSearch && (
        <SearchInput
          debounceMilliSeconds={300}
          placeholder={searchInputPlaceHolder}
          handleChange={handleSearchChange}
          handleClear={handleSearchClear}
          value={searchTerm}
        />
      )}

      {showColumnsButton && (
        <ColumnSelector
          columns={columns}
          selectedColumns={selectedColumns}
          toggleColumn={selectedColumns => handleToggleColumn(selectedColumns)}
        />
      )}

      {filters && (
        <FilterSelector
          closeOnSelect={filtersSelectionMode === "single" ? true : false}
          filters={filters}
          selectionMode={filtersSelectionMode}
          selectedFilters={selectedFilters}
          toggleFilter={selectedFilters => handleToggleFilter(selectedFilters)}
        />
      )}

      {sessionFilters && Object.keys(sessionFilters).length > 1 && (
        <FilterSelector
          caption="Sessions"
          filters={sessionFilters}
          selectedFilters={selectedSessions}
          toggleFilter={selectedSession => handleToggleSessionFilter(selectedSession)}
        />
      )}

      {showDownloadButton && (
        <DownloadButton
          handleClick={type => handleDownload(type)}
          isLoading={isDownloading}
          options={downloadOptions}
        />
      )}

      {showRefetchButton && <RefreshButton handleClick={() => handleRefetch()} isLoading={isRefetching} />}

      {actions && actions}
    </CardHeader>

    <Divider />
    
    <CardBody>
      <Table
        aria-label="Current Students"
        classNames={{
          loadingWrapper: "bg-white opacity-40",
          thead: "bg-round",
          wrapper: "rounded shadow-none p-0 overflow-y-hidden",
        }}
        disabledKeys={disabledKeys}
        isStriped
        selectionMode={selectionMode}
        selectedKeys={selectedKeys}
        sortDescriptor={currentSortOrder}
        onSelectionChange={selectedItems => handleSelectionChange(selectedItems)}
        onSortChange={sortDescriptor => handleSortChange(sortDescriptor)}
        isCompact
        variants={["outline-none"]}
      >
        <TableHeader>
          {selectedColumns &&
            selectedColumns.map(key => {
              const column = columns[key]
              const alignment = column?.align || "text-start"
              const classes = column?.classes ? `${column.classes} ${alignment}` : `${alignment}`

              return (
                <TableColumn key={column.id} allowsSorting={column.sortable} className={classes}>
                  {column.name}
                </TableColumn>
              )
            })}
        </TableHeader>

        <TableBody emptyContent={!loading ? noDataMessage : null} isLoading={loading} loadingContent={<Spinner />}>
          {data &&
            data.map(item => (
              <TableRow key={item.id}>
                {selectedColumns.map(key => {
                  const column = columns[key]                  
                  const alignment = column?.align || "start"
                  const classes = column?.classes ? `${column.classes} ${alignment}` : `${alignment}`

                  return (
                    <TableCell key={key} className={classes}>
                      {handleRenderCell(item, key)}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </CardBody>

    <Divider />
    
    {showPagination && (  
      <CardFooter>
        {paginatorInfo?.lastPage > 0 && (
          <div className="flex w-full justify-between items-center">
            <PaginatorInfo paginatorInfo={paginatorInfo}></PaginatorInfo>

            <Pagination
              isCompact
              showControls
              showShadow
              color="secondary"
              page={currentPage}
              total={paginatorInfo?.lastPage}
              onChange={page => handlePageChange(page)}
            />
          </div>
        )}
      </CardFooter>
    )}
  </Card>
)

DataTable.propTypes = {
  actions: PropTypes.node,
  columns: PropTypes.object,
  currentPage: PropTypes.number,
  currentSortOrder: PropTypes.object,
  data: PropTypes.array,
  disabledKeys: PropTypes.array,
  downloadOptions: PropTypes.array,
  filters: PropTypes.object,
  filtersSelectionMode: PropTypes.string,
  handleDownload: PropTypes.func,
  handleRenderCell: PropTypes.func.isRequired,
  handleRefetch: PropTypes.func,
  handlePageChange: PropTypes.func,
  handleSearchChange: PropTypes.func.isRequired,
  handleSearchClear: PropTypes.func.isRequired,
  handleSelectionChange: PropTypes.func,
  handleSortChange: PropTypes.func,
  handleToggleColumn: PropTypes.func,
  handleToggleFilter: PropTypes.func,
  handleToggleSessionFilter: PropTypes.func,
  header: PropTypes.node,
  isDownloading: PropTypes.bool,
  isRefetching: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  paginatorInfo: PropTypes.object,
  noDataMessage: PropTypes.node,
  searchInputPlaceHolder: PropTypes.string,
  searchTerm: PropTypes.string,
  selectedColumns: PropTypes.array,
  selectedFilters: PropTypes.array,
  selectionMode: PropTypes.string,
  selectedSessions: PropTypes.array,
  sessionFilters: PropTypes.object,
  showDownloadButton: PropTypes.bool,
  showPagination: PropTypes.bool,
  showRefetchButton: PropTypes.bool,
  showSearch: PropTypes.bool,
}

export default DataTable
