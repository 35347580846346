import { ErrorPlaceholder, SuccessPlaceholder, WizardFooter } from "@components"
import { meetingTypes } from "@config"
import { Button, Divider } from "@nextui-org/react"
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid"
import { Link } from "react-router-dom"
import dayjs from "dayjs"
import { PropTypes } from "prop-types"

const advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

const Step5 = ({ 
  errors, 
  event = null, 
  formData, 
  handleCloseModal, 
  handlePrevious, 
  handleSubmit, 
  isSubmitting = false, 
  status, 
  venues = [] 
}) => {
  const venue = formData.selected_meeting_type_ids.includes(1) ? venues.find(venue => venue.id === formData.venue_id) : null

  if (status === "Errors") {
    return (
      <div>
        <ErrorPlaceholder
          title="Submission Error"
          message="There was an error creating your event. Please click the previous button and correct any errors and try again."
          errors={errors}
        />

        <WizardFooter currentStep={5} totalSteps={5} previousCaption="Add Sessions" nextCaption="Create Event" handlePrevious={() => handlePrevious()} handleNext={() => handleSubmit()} />
      </div>

    )
  }

  if (status === "Success") {
    return (
      <SuccessPlaceholder title="Event Created" message="Your event has been created. Click the button below to manage your event.">
        <Button className="mt-4" size="lg" color="secondary" onClick={() => handleCloseModal()}>
          <Link to={`/event/${event.id}/manage`}>Manage Event</Link>
        </Button>
      </SuccessPlaceholder>
    )
  }

  return (
    <div>
      <div>
        <h1 className="text-md mb-1 font-semibold">Basic Info</h1>
        <p className="text-md">Title: <span className="font-semibold text-blue-700">{formData.title}</span></p>
        <p className="text-md">Description: <span className="font-semibold text-blue-700">{formData.description}</span></p>
      </div>

      <Divider className="my-4" />

      <div>
        <h1 className="text-md mb-1 font-semibold">Meeting Types</h1>

        {Object.keys(meetingTypes).map((type) => {
          const meetingType = meetingTypes[type]
          const icon = formData.selected_meeting_type_ids.includes(meetingType.id) ? <CheckCircleIcon className="h-6 w-6 my-1 mr-2 text-green-600" /> : <XCircleIcon className="h-6 w-6 my-1 mr-2 text-red-600" />

          return (
            <p key={meetingType.id} className="text-md flex items-center">{icon} {meetingType.label}</p>
          )
        })}
      </div>

      <Divider className="my-4" />

      <div>
        <h1 className="text-md mb-1 font-semibold">Settings</h1>

        {formData.selected_meeting_type_ids.includes(3) && (
          <p className="text-md">Auto disconnect: <span className="font-semibold text-blue-700">{formData.vc_auto_disconnect ? "Yes" : "No"}</span></p>
        )}

        <p className="text-md">Max bookings: <span className="font-semibold text-blue-700">{formData.max_bookings_per_student}</span></p>

        {formData.selected_meeting_type_ids.includes(1) && (
          <p className="text-md">Venue: <span className="font-semibold text-blue-700">{venue.name}</span></p>
        )}
      </div>

      <Divider className="my-4" />

      <div>
        <h1 className="text-md mb-1 font-semibold">Sessions</h1>

        {formData.sessions.map((session) => (
          <p key={session.id} className="text-md"><span className="font-semibold text-blue-700">{session.title}</span> - {dayjs(session.start_date).format("ddd Do MMM")} {session.start_time.format("HH:mm")} - {session.end_time.format("HH:mm")}</p>
        ))}
      </div>

      <WizardFooter currentStep={5} totalSteps={5} previousCaption="Add Sessions" nextCaption="Create Event" handlePrevious={() => handlePrevious()} handleNext={() => handleSubmit()} isSubmitting={isSubmitting} />
    </div>
  )
}

Step5.propTypes = {
  event: PropTypes.object,
  handleCloseModal: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool,
  venues: PropTypes.array,
}

export default Step5