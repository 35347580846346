import { CheckCircleIcon } from "@heroicons/react/24/solid"
import PropTypes from 'prop-types'

const ImageRadioGroup = ({ classNames = "", handleChange, options, selectedOptionIds = [] }) => {
  const getOptionIdsArray = (optionId) => {
    return selectedOptionIds.includes(optionId)
      ? selectedOptionIds.filter((id) => id !== optionId)
      : [...selectedOptionIds, optionId]
  }

  return (
    <div className="flex gap-3">
      {Object.keys(options).map(key => {
        const option = options[key]
        const isSelected = selectedOptionIds.includes(option.id)

        return (
          <div
            key={option.id}
            className={`${classNames} grow hover:cursor-pointer border-8 flex items-center justify-center flex-col relative rounded-lg p-6 transition-all duration-300 ${isSelected ? 'border-green-200 bg-green-500' : 'border-gray-200 bg-gray-400 border-4'}`}
            onClick={() => handleChange(getOptionIdsArray(option.id))}
          >
            {isSelected && <CheckCircleIcon className="text-green-200 absolute h-16 -top-6 -right-5" />}

            <div className="text-white opacity-70">{option.image}</div>

            <h1 className='text-xl font-semibold text-center text-white mt-3'>
              {option.label}
            </h1>

            <p className="text-white text-sm text-center">{option.description}</p>
          </div>
        )
      })}
    </div>
  )
}

ImageRadioGroup.propTypes = {
  classNames: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  selectedOptionIds: PropTypes.array,
}

export default ImageRadioGroup
