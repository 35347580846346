import { CalculatorIcon, UserGroupIcon, UsersIcon } from "@heroicons/react/24/outline"
import { StatCardCompact } from "@components"
import PropTypes from "prop-types"

const Stats = ({ summary = {} }) => {
  const {
    total = 0,
    lastImport = null,
    dataAge = null,
    syncCount = 0,
    missingGuardianCount = 0,
    missingLessonCount = 0,
  } = summary

  return (
    <div className="my-5 grid grid-cols-1 gap-5 sm:grid-cols-3 2xl:grid-cols-6">
      <StatCardCompact
        title="No of Students"
        stat={total}
        icon={<CalculatorIcon className="h-10 w-10 text-white" aria-hidden="true" />}
      />

      <StatCardCompact
        title="Last Import"
        stat={lastImport ? lastImport : "N/A"}
        icon={<UsersIcon className="h-10 w-10 text-white" aria-hidden="true" />}
      />

      <StatCardCompact
        title="Data Age"
        stat={dataAge ? (dataAge === 1 ? `${dataAge} Day` : `${dataAge} Days`) : "N/A"}
        icon={<UserGroupIcon className="h-10 w-10 text-white" aria-hidden="true" />}
      />

      <StatCardCompact
        title="Sync"
        stat={syncCount}
        icon={<CalculatorIcon className="h-10 w-10 text-white" aria-hidden="true" />}
      />

      <StatCardCompact
        title="Missing Lessons"
        stat={missingLessonCount}
        icon={<CalculatorIcon className="h-10 w-10 text-white" aria-hidden="true" />}
      />

      <StatCardCompact
        title="Missing Guardians"
        stat={missingGuardianCount}
        icon={<CalculatorIcon className="h-10 w-10 text-white" aria-hidden="true" />}
      />
    </div>
  )
}

Stats.propTypes = {
  summary: PropTypes.object,
}

export default Stats
