import { gql } from "@apollo/client"

const GET_SITE_DATA_GUARDIANS_XLS_DOWNLOAD_LINK = gql`
  {
    dataGuardiansXlsDownload {
      filename
      type
      url
      queued
    }
  }
`

export { GET_SITE_DATA_GUARDIANS_XLS_DOWNLOAD_LINK }
