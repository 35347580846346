import { gql } from "@apollo/client"

const CREATE_FEEDBACK_MUTATION = gql`
  mutation($input: FeedbackInput!) {
    createFeedback(input: $input) {
      client_id
      name
      email
      description
    }
  }
`

export default CREATE_FEEDBACK_MUTATION
