import { ReactComponent as DataSvg } from "@svgs/data.svg"
import PropTypes from "prop-types"

const NoDataPlaceholder = ({ actions = null, className = "", title, message }) => (
  <div className={`${className} flex items-center flex-col rounded-lg border-2 border-dashed border-gray-300 p-8 text-center`}>
    <DataSvg className="max-w-[150px] mb-2" />

    <div className="max-w-lg">
      <h1 className="text-xl font-semibold my-1 text-blue-400">{title}</h1>
      <p className="text-base leading-7">{message}</p>
    </div>

    {actions && <div>{actions}</div>}
  </div>
)

NoDataPlaceholder.propTypes = {
  actions: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
}

export { NoDataPlaceholder }