import { gql } from "@apollo/client"

const GET_GROUP_LESSON_STUDENTS_DIFF = gql`
  query($groupId: ID!, $lessonId: ID!) {
    group(id: $groupId) {
      id
      lessons(lesson_id: $lessonId) {
        id
        name
                
        groupStudentsDiff {
          id
          forename
          surname
          diff_status
          booking_slot_count
        }
      }
    }
  }
`

export { GET_GROUP_LESSON_STUDENTS_DIFF }
