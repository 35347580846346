import { gql } from "@apollo/client"

const GET_AUDIT_LOG_SUMMARY_AND_TYPES = gql`
  query auditLogSummary($eventId: ID!) {
    event(id: $eventId) {
			id
			
			auditLogSummary {
				total
				type_quantities {
          id
					code
					description
					qty
				}
			}
		}
    
    auditLogTypes {
      id
      code
      description
    }
	}
`

export { GET_AUDIT_LOG_SUMMARY_AND_TYPES }