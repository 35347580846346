import { memo } from "react"
import { SidebarLink } from "@components"
import classnames from "classnames"
import PropTypes from "prop-types"

const SidebarSubmenu = ({ links, isExpanded }) => (
  <div className='overflow-hidden'>
    {links && (
      <ul
        className={classnames("px-2 transition-max-height duration-300 max-h-0", { "mt-1 max-h-screen": isExpanded })}
      >
        {links.map(link => (
          <li key={link.id}>
            <SidebarLink
              href={link.href}
              icon={link.icon}
              id={link.id}
              subMenu={link.subMenu}
              type={link.type}
              expandable={link.expandable}
            >
              {link.caption}
            </SidebarLink>
          </li>
        ))}
      </ul>
    )}
  </div>
)

SidebarSubmenu.propTypes = {
  isExpanded: PropTypes.bool,
  links: PropTypes.array,
}

export default memo(SidebarSubmenu)
