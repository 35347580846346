import { apolloClient } from "@services"

const authService = {
  clearToken: () => window.localStorage.removeItem("adminToken"),

  getToken: () => window.localStorage.getItem("adminToken"),

  isAuthenticated: () => window.localStorage.getItem("adminToken") !== null,

  login: token => authService.setToken(token),

  logout: (client, redirect = "/") => {
    authService.clearToken()
    apolloClient.clearStore()
    window.location.replace(redirect)
  },

  setToken: token => window.localStorage.setItem("adminToken", token),
}

export default authService
