import { gql } from "@apollo/client"

const GET_REPORT_EVENT_ATTENDANCE_SUMMARY = gql`
  query ($event_id: ID!) {
    reportEventAttendanceSummary(event_id: $event_id) {
      absent
      attended
      attendance_ratio
      no_of_parents
      total_bookings
      unknown
    }
  }
`

export { GET_REPORT_EVENT_ATTENDANCE_SUMMARY }
