import { gql } from "@apollo/client"

const GET_MANAGE_BOOKINGS_SUMMARY = gql`
  query manageBookingsSummary($eventId: ID!) {
    manageBookingsSummary(event_id: $eventId) {
			no_of_booked_slots
		  no_of_lessons
		  no_of_groups
		  no_of_sessions
		  no_of_students
		  no_of_non_attending
		}
	}
`

export { GET_MANAGE_BOOKINGS_SUMMARY }