import { gql } from "@apollo/client"

const UPDATE_GROUP_LESSON_NOTE = gql`
  mutation updateGroupLessonNote($input: UpdateGroupLessonNoteInput!) {
    updateGroupLessonNote(input: $input) {
			id
			group_id
			lesson_id
			note
	  }
  }
`

export { UPDATE_GROUP_LESSON_NOTE }
