import { useQuery } from "@apollo/client"
import { Chip } from "@nextui-org/react"
import { CircleStackIcon } from "@heroicons/react/24/outline"
import { LineLoader, SimplePlaceholder } from "@components"
import { GET_SUBJECT_LESSONS } from "@graphql"
import PropTypes from "prop-types"

const SubjectLessonsList = ({ subject }) => {
  const { loading, error, data } = useQuery(GET_SUBJECT_LESSONS, {
    variables: {
      fetchPolicy: "cache-first",
      id: subject.id,
    },
  })

  if (loading) return <LineLoader message="Loading lessons..." classNames="mt-8" />

  if (error) return "error"

  if (data.subject.lessons.length === 0) {
    return (
      <SimplePlaceholder icon={<CircleStackIcon className="h-20 w-auto" />} title="No lessons found for this subject">
        <p className="mt-1 text-sm text-gray-500">
          If you believe this subject should have associated lessons then please check your MIS and Xporter integration.
        </p>
      </SimplePlaceholder>
    )
  }

  return (
    <div>
      <Chip className="float-right" color="primary">
        {data.subject.lessons.length} lessons
      </Chip>

      <ul className="clear-right divide-y divide-gray-100">
        {data.subject.lessons.map(lesson => (
          <li key={lesson.id} className="flex justify-between py-2">
            {lesson.name}
          </li>
        ))}
      </ul>
    </div>
  )
}

SubjectLessonsList.propTypes = {
  subject: PropTypes.object.isRequired,
}

export default SubjectLessonsList
