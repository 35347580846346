import { ChevronRightIcon } from "@heroicons/react/24/outline"
import classnames from "classnames"
import PropTypes from "prop-types"

const ArrowToggle = ({ className = "", isRotated }) => (
  <ChevronRightIcon
    className={classnames(`${className} ml-auto shrink-0 transform duration-300`, { "rotate-90": isRotated })}
  />
)

ArrowToggle.propTypes = {
  className: PropTypes.string,
  isRotated: PropTypes.bool.isRequired,
}

export default ArrowToggle
