import { Required, WizardFooter } from "@components"
import { Divider, Select, SelectItem, Slider, Switch } from "@nextui-org/react"
import { Controller, useForm } from "react-hook-form";
import { PropTypes } from "prop-types"

const EventSettings = ({ 
  vcAutoDisconnect = false, 
  maxBookingsPerStudent = 0, 
  showWizardFooter = true, 
  venueId = null, 
  isVcEvent, 
  isVenueEvent, 
  handlePrevious = () => {}, 
  handleSubmit, 
  venues = []
}) => {
  const { control, handleSubmit: handleValidateForm, formState: { errors }, getValues } = useForm({
    defaultValues: {
      vc_auto_disconnect: vcAutoDisconnect,
      max_bookings_per_student: maxBookingsPerStudent,
      venue_id: venueId,
    }
  })

  return (
    <form id="event-settings-form" className="flex flex-col gap-y-4" noValidate>
      {isVcEvent && (
        <div>
          <h5 className="text-base mb-1 font-semibold">Video Call Options</h5>
          <p className="text-sm mb-3">Automatically disconnect calls once slot time has expired.</p>

          <Controller
            control={control}
            name="vc_auto_disconnect"
            render={({ field: { value, onChange } }) => (
              <Switch defaultSelected={value} onChange={onChange}>Auto Disconnect</Switch>
            )}
          />

          <Divider className="mt-4" />
        </div>
      )}

      {isVenueEvent && (
        <div>
          <h5 className="text-base mb-1 font-semibold">Venue Options<Required /></h5>
          <p id="venue-label" className="text-sm mb-0">Select where the event will take place.</p>

          <Controller
            control={control}
            rules={{ required: "You must supply a venue for your event" }}
            name="venue_id"
            render={({ field: { value, onChange } }) => (
              <Select
                classNames={{ "label": "mt-2", "mainWrapper": "mt-3" }}
                isRequired
                placeholder="Select a venue"
                disallowEmptySelection={true}
                onChange={onChange}
                selectionMode="single"
                defaultSelectedKeys={[value]}
                isInvalid={errors.hasOwnProperty("venue_id")}
                aria-labelledby="venue-label"
              >
                {venues.map((venue) => (
                  <SelectItem key={venue.id} value={venue.id}>
                    {venue.name}
                  </SelectItem>
                ))}
              </Select>
            )}
          />

          <Divider className="mt-4" />
        </div>
      )}

      <div>
        <h5 className="text-base mb-1 font-semibold">Other Options</h5>
        <p className="text-sm mb-3">Select max bookings per student. If 0 then no limit is set.</p>

        <Controller
          control={control}
          name="max_bookings_per_student"
          render={({ field: { value, onChange } }) => (
            <Slider
              defaultValue={value}
              label="Max Bookings (0 - 100)"
              size="md"
              step={1}
              maxValue={100}
              minValue={0}
              onChange={onChange}
            />
          )}
        />
      </div>

      {showWizardFooter && (
        <WizardFooter currentStep={1} totalSteps={3} previousCaption="Meeting Types" nextCaption="Sessions" handlePrevious={() => handlePrevious(getValues())} handleNext={handleValidateForm((data) => handleSubmit(data))} />
      )}
    </form>
  )
}

EventSettings.propTypes = {
  vcAutoDisconnect: PropTypes.bool,
  isVcEvent: PropTypes.bool.isRequired,
  isVenueEvent: PropTypes.bool.isRequired,
  handlePrevious: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  maxBookingsPerStudent: PropTypes.number,
  showWizardFooter: PropTypes.bool,
  venueId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  venues: PropTypes.array
}

export default EventSettings