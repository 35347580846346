import {
  CheckBadgeIcon,
  ExclamationCircleIcon,
  ReceiptPercentIcon,
  RectangleGroupIcon,
  Square3Stack3DIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline"

import { StatCardCompact } from "@components"
import PropTypes from "prop-types"

const Stats = ({ summary = {} }) => {
  const {
    booked_percentage = 0,
    disabled_percentage = 0,
    no_of_free_capacity_groups = 0,
    no_of_fully_booked_groups = 0,
    no_of_groups = 0,
    no_of_oversubscribed_groups = 0,
  } = summary?.data?.reportTeacherBookingsSummary || {}

  return (
    <div className="my-5 grid auto-rows-fr grid-cols-1 gap-5 sm:grid-cols-3 2xl:grid-cols-6">
      <StatCardCompact
        title="Groups"
        stat={no_of_groups}
        icon={<RectangleGroupIcon className="h-10 w-10 text-white" aria-hidden="true" />}
      />

      <StatCardCompact
        title="Free Capacity"
        stat={no_of_free_capacity_groups}
        icon={<CheckBadgeIcon className="h-10 w-10 text-white" aria-hidden="true" />}
      />

      <StatCardCompact
        title="Fully Booked"
        stat={no_of_fully_booked_groups}
        icon={<ExclamationCircleIcon className="h-10 w-10 text-white" aria-hidden="true" />}
      />

      <StatCardCompact
        title="Over Subscribed"
        stat={no_of_oversubscribed_groups}
        icon={<Square3Stack3DIcon className="h-10 w-10 text-white" aria-hidden="true" />}
      />

      <StatCardCompact
        title="Disabled %"
        stat={`${disabled_percentage}%`}
        icon={<XCircleIcon className="h-10 w-10 text-white" aria-hidden="true" />}
      />

      <StatCardCompact
        title="Booked %"
        stat={`${booked_percentage}%`}
        icon={<ReceiptPercentIcon className="h-10 w-10 text-white" aria-hidden="true" />}
      />
    </div>
  )
}

Stats.propTypes = {
  summary: PropTypes.object,
}

export default Stats
