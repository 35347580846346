import { gql } from "@apollo/client"

const GET_STAFF = gql`
  query (
    $searchTerm: String
    $page: Int!
    $perPage: Int!
    $clientId: ID!
    $orderBy: [ClientStaffOrderByOrderByClause!]
    $where: ClientStaffWhereWhereConditions
  ) {
    client(id: $clientId) {
      id

      staff(searchTerm: $searchTerm, page: $page, first: $perPage, orderBy: $orderBy, where: $where) {
        data {
          id
          salutation
          forename
          surname
          local_id
          data_ref
          is_sync
          no_of_lessons
          created_at
          updated_at
        }

        paginatorInfo {
          total
          currentPage
          hasMorePages
          lastPage
          perPage
        }
      }
    }
  }
`

const GET_STAFF_LESSONS = gql`
  query ($id: ID!) {
    staffMember(id: $id) {
      id

      lessons(orderBy: [{ column: NAME, order: ASC }]) {
        id
        name
      }
    }
  }
`

export { GET_STAFF, GET_STAFF_LESSONS }
