import { ChartPlaceholder } from "@components"
import Chart from "react-apexcharts"
import PropTypes from "prop-types"
import { donutOptions } from "../Options"

const AvailabilityDonut = ({ 
  noOfAvailableSlots = 0, 
  noOfBookedSlots = 0, 
  noOfDisabledSlots = 0, 
  noOfTotalSlots = 0 
}) => {
  const graph = {
    options: {
      ...donutOptions,
      colors: ["#89D641", "#FDB629", "#BCBCBD"],
      chart: { ...donutOptions.chart, id: "availability - donut" },
      labels: ["Available", "Booked", "Disabled"],
    },

    series: [noOfAvailableSlots, noOfBookedSlots, noOfDisabledSlots],
  }

  if (noOfTotalSlots === 0) {
    return (
      <ChartPlaceholder
        className="h-48"
        caption="Availability stats will be displayed when data is available."
        type="donut"
      />
    )
  }

  return <Chart options={graph.options} series={graph.series} type="donut" height={350} />
}

AvailabilityDonut.propTypes = {
  noOfAvailableSlots: PropTypes.number,
  noOfBookedSlots: PropTypes.number,
  noOfDisabledSlots: PropTypes.number,
  noOfTotalSlots: PropTypes.number,
}

export default AvailabilityDonut
