import { memo, useContext } from "react"
import { Link } from "react-router-dom"
import classnames from "classnames"
import { ArrowToggle, SidebarSubmenu } from "@components"
import { SidebarContext } from "@context"
import PropTypes from "prop-types"

const SidebarLink = ({ 
  children, 
  id, 
  expandable, 
  handleClick = null, 
  subMenu, 
  href, 
  target = "_self", 
  icon, 
  type, 
  updateActiveLinkOnClick = true 
}) => {
  const { sidebar, setSidebar } = useContext(SidebarContext)
  const isActive = sidebar.activeLink === id
  const isExpanded = expandable && sidebar.expandedLinks[id] !== undefined && sidebar.expandedLinks[id] === true
  const linkClasses = classnames("items-center group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold", {
    "bg-sidebar-link-active text-white": isActive,
    "text-white opacity-80 hover:text-white hover:bg-sidebar-link-hover": !isActive,
    "py-1 mb-1 mt-0": type === "SUB_MENU_LINK",
  })

  const handleLinkClicked = () => {
    const newSidebarState = { ...sidebar, activeLink: updateActiveLinkOnClick ? id : sidebar.activeLink, show: expandable }

    if (expandable) {
      newSidebarState.expandedLinks[id] = !isExpanded
    }

    if (handleClick) {
      handleClick()
    }

    setSidebar(newSidebarState)
  }

  return (
    <div>
      <Link to={href} target={target} className={linkClasses} onClick={() => handleLinkClicked()}>
        <div className="h-6 w-6 shrink-0">{icon}</div>
        {children}
        {expandable && <ArrowToggle className="h-5 w-5" isRotated={isExpanded} />}
      </Link>

      <SidebarSubmenu links={subMenu} isExpanded={isExpanded} />
    </div>
  )
}

SidebarLink.propTypes = {
  children: PropTypes.node.isRequired,
  expandable: PropTypes.bool.isRequired,
  handleClick: PropTypes.func,
  href: PropTypes.string.isRequired,
  icon: PropTypes.node,
  id: PropTypes.string.isRequired,
  subMenu: PropTypes.array,
  target: PropTypes.string,
  type: PropTypes.string.isRequired,
  updateActiveLinkOnClick: PropTypes.bool,
}

export default memo(SidebarLink)
