import { Tooltip } from "@nextui-org/react"
import { BuildingOffice2Icon, DevicePhoneMobileIcon, VideoCameraIcon } from "@heroicons/react/24/outline"

import { 
  BuildingOffice2Icon as BuildingOffice2IconSolid, 
  DevicePhoneMobileIcon as DevicePhoneMobileIconSolid, 
  VideoCameraIcon as VideoCameraIconSolid 
} from "@heroicons/react/24/solid"

import PropTypes from "prop-types"

const MeetingTypeDetails = ({ phone, vc, venue }) => 
{
  return (
    <div className="flex items-center space-x-2">
      {venue && <Tooltip content="Venue"><BuildingOffice2IconSolid className="text-center text-green-400 h-7 w-7" /></Tooltip>}
      {!venue && <Tooltip content="Venue"><BuildingOffice2Icon className="text-center text-gray-400 h-7 w-7" /></Tooltip>}
      {phone && <Tooltip content="Phone"><DevicePhoneMobileIconSolid className="text-center text-green-400 h-7 w-7" /></Tooltip>}
      {!phone && <Tooltip content="Phone"><DevicePhoneMobileIcon className="text-center text-gray-400 h-7 w-7" /></Tooltip>}
      {vc && <Tooltip content="Video Conference"><VideoCameraIconSolid className="text-center text-green-400 h-7 w-7" /></Tooltip>}
      {!vc && <Tooltip content="Video Conference"><VideoCameraIcon className="text-center text-gray-400 h-7 w-7" /></Tooltip>}
    </div>
  )
}

MeetingTypeDetails.propTypes = {
  phone: PropTypes.bool.isRequired,
  venue: PropTypes.bool.isRequired,
  vc: PropTypes.bool.isRequired,
}

export default MeetingTypeDetails