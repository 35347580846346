import PropTypes from "prop-types"

const Header = ({ 
  actions = null, 
  bgColor = "bg-indigo-900", 
  contentBottom = null, 
  icon = null, 
  subTitle = null, 
  title 
}) => (
  <div className={`${bgColor} rounded-2xl p-6 mt-4`}>
    <div className="flex items-center gap-1">
      {icon && <div className="hidden md:block mr-3">{icon}</div>}
      <div>
        <h2 className="my-1 text-xl text-white opacity-80 font-semibold leading-7 sm:truncate sm:text-2xl sm:tracking-tight">
          {title}
        </h2>

        {subTitle && <h3 className="text-base text-white">{subTitle}</h3>}

        <div className="mt-3">
          {contentBottom && contentBottom}
        </div>
      </div>

      <div className="ml-auto self-baseline">
        {actions && actions}
      </div>
    </div>
  </div>
)

Header.propTypes = {
  actions: PropTypes.node,
  bgColor: PropTypes.string,
  contentBottom: PropTypes.node,
  icon: PropTypes.node,
  subTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default Header
