import { gql } from "@apollo/client"

const GET_REPORT_TEACHER_BOOKINGS = gql`
  query (
    $event_id: ID!
    $searchTerm: String!
    $page: Int!
    $perPage: Int!
    $orderBy: [QueryReportTeacherBookingsOrderByOrderByClause!]
    $filters: [String!]
    $sessions: [ID!]
  ) {
    reportTeacherBookings(
      event_id: $event_id
      page: $page
      first: $perPage
      orderBy: $orderBy
      searchTerm: $searchTerm
      filters: $filters
      sessions: $sessions
    ) {
      data {
        id
        group
        session
        session_id
        location
        available_slots
        disabled_slots
        remaining_slots
        total_slots
        subscription
        no_of_invitation_slots
        no_of_booked_slots
        capacity
        status
        booked_percentage
      }

      paginatorInfo {
        total
        currentPage
        hasMorePages
        lastPage
        perPage
      }
    }
  }
`

export { GET_REPORT_TEACHER_BOOKINGS }
