import { useContext } from "react"
import { DashboardCard, Page, PageHeader, SuccessPlaceholder } from "@components";
import { Button, Divider, Input, Textarea } from "@nextui-org/react";
import { UserContext } from "@context"
import { getValidationErrors } from "@helpers";
import { useSetActiveSidebarLink } from "@hooks";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client"
import { HandThumbUpIcon } from "@heroicons/react/24/outline"
import { CREATE_FEEDBACK_MUTATION } from "@graphql"

const Feedback = () => {
  useSetActiveSidebarLink("SUPPORT_FEEDBACK")

  const { user } = useContext(UserContext)
  const { register, handleSubmit: handleValidateForm, formState: { errors }, setError } = useForm()
  const [createFeedback, { data, loading }] = useMutation(CREATE_FEEDBACK_MUTATION, {
    onError: error => {
      const errors = getValidationErrors(error)
      Object.keys(errors).map(key => setError(key, { type: "server", message: errors[key] }))
    }
  })

  const handleSubmit = (data) => {
    createFeedback({ variables: { input: { client_id: user?.client?.id, name: data.name, email: data.email, description: data.description } } })
  }

  const successMessage = <SuccessPlaceholder title="Feedback Received" message="Thank you for your feedback. We'll take your views onboard." />

  const form =
    <form className="flex flex-col gap-y-4" onSubmit={handleValidateForm((data) => handleSubmit(data))} noValidate>
      <p className="my-3">
        We're interested to know what you think of the new administration portal.  Your feedback helps us decide which features to build, and what improvements should be made to our platform.
      </p>

      <Input
        {...register("email", {
          required: "You must supply a valid email address",
          pattern: {
            value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
            message: 'You must supply a valid email address',
          }
        }
        )}
        isRequired
        type="email"
        label="Email"
        placeholder="Enter your email"
        disabled={loading}
        labelPlacement="outside"
        isInvalid={errors.hasOwnProperty("email")}
        errorMessage={errors?.email?.message}
      />

      <Divider />

      <Input
        {...register("name", { required: "You must supply a contact name", minLength: { value: 3, message: "Your name must be at least 3 characters" } })}
        isRequired
        type="name"
        label="Name"
        placeholder="Enter your name"
        disabled={loading}
        labelPlacement="outside"
        isInvalid={errors.hasOwnProperty("name")}
        errorMessage={errors?.name?.message}
      />

      <Divider />

      <Textarea
        {...register("description", { required: "You must supply a short description", minLength: { value: 50, message: "Description must be at least 50 characters" } })}
        isRequired
        label="Description"
        labelPlacement="outside"
        minRows={20}
        disabled={loading}
        placeholder="Enter your description"
        isInvalid={errors.hasOwnProperty("description")}
        errorMessage={errors?.description?.message}
      />

      <Button className="self-end max-w-[250px]" type="submit" color="secondary" isDisabled={loading} isLoading={loading}>Submit Feedback</Button>
    </form>

  return (
    <div>
      <Page>
        <PageHeader
          title="Your Feedback"
          subTitle="We'd love to hear your thoughts on the new administration portal."
          icon={<HandThumbUpIcon className="page-header-icon" />}
        />

        <DashboardCard
          title="Your Feedback"
          className="mt-4"
          body={data ? successMessage : form}
        />
      </Page>
    </div>
  );
}

export default Feedback