import { gql } from "@apollo/client"

const MANAGE_GROUPS_SESSION_FRAGMENT = gql`
  fragment SessionFragment on Session {
    id
    title
    starts_at
    ends_at
      
    groupLessonBookingCounts {
      id
      group_id
      lesson_id
      no_of_bookings
    }
  
    groupLessonStudentCounts {
      id
      group_id
      lesson_id
      no_of_students
    }

    groups {
      id
      title
      user_id

      groupLessonNotes {
        id
        lesson_id
        note
      }
      
      lessons {
        id
        name
        studentCount

        subject {
          id
          name
        }
      }
    }
  }
`

export default MANAGE_GROUPS_SESSION_FRAGMENT