const columns = {
  select: { id: "select", name: "", sortable: false, deselectable: false },
  class: { id: "name", name: "Class", sortable: true, deselectable: false },
  teacher: { id: "teacher", name: "Teacher", sortable: false, deselectable: false },
  subject: { id: "subject", name: "Subject", sortable: false, deselectable: true },
  type: { id: "type", name: "Type", sortable: false, deselectable: true },
  students: { id: "studentCount", name: "#Students", sortable: false, deselectable: true, align: "text-end" },
}

const defaultColumns = [
  "select",
  "class",
  "teacher",
  "subject",
  "type",
  "students",
]

const filters = {
  all: { id: "all", name: "All" },
  selected: { id: "selected", name: "Newly Selected Only" },
  sync: { id: "sync", name: "Sync Only" },
  custom: { id: "custom", name: "Custom Only" },
}

export { columns, defaultColumns, filters }
