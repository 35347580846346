import React, { useState } from 'react'
import { CustomModal } from '@components'
import { Button, Checkbox, ModalContent, ModalHeader, ModalBody, ModalFooter, Textarea } from '@nextui-org/react';
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline"
import PropTypes from 'prop-types';

const DeleteBookingSlotsConfirmationModal = ({
  description,
  handleClose, 
  handleConfirm, 
  handlePreviewEmail, 
  isProcessing = false, 
  isLoadingPreview = false, 
  show = false,
  title
}) => {
  const [sendEmail, setSendEmail] = useState(true)
  const [intro, setIntro] = useState("We regret to inform you that some of your booking slots have been cancelled.  We apologize for any inconvenience this may have caused you. Please see the detail of cancellations below.");
  const isValid = sendEmail ? intro.length >= 150 && intro.length <= 1000 : true

  return (
    <CustomModal
      isOpen={show}
      size="2xl"
      handleClose={() => handleClose()}
      scrollBehavior="outside"
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">Delete Appointments</ModalHeader>

            <ModalBody>
              <div>
                <div className="flex items-center gap-2">
                  <QuestionMarkCircleIcon className="shrink-0 w-16 h-16 text-warning" />
                
                  <div>
                    <h1 className="text-xl font-semibold">{title}</h1>
                    <p>{description}</p>
                  </div>
                </div>

                <div className="p-4 bg-gray-100 rounded-lg mt-3">
                  <div className="flex w-full items-center justify-between">
                    <div>
                      <Checkbox color="secondary" isSelected={sendEmail} onValueChange={() => setSendEmail(!sendEmail)}>Send Cancellation Email</Checkbox>
                    </div>

                    {sendEmail && 
                      <Button size="sm" variant="flat" color="secondary" onClick={() => handlePreviewEmail(intro)} isLoading={isLoadingPreview}>
                        Preview Email
                      </Button>
                    }
                  </div>

                    {sendEmail && (
                      <div className="mt-4">
                        <Textarea
                          classNames={{
                            "inputWrapper": "bg-white border-0",
                            "input": "bg-white",
                          }}
                          label="Introductory text"
                          minRows={8}
                          isInvalid={!isValid}
                          placeholder="Enter your description"
                          value={intro}
                          variant="bordered"
                          onValueChange={setIntro}
                          errorMessage="The message should be between 150 and 1000 characters long."
                        />

                      </div>
                    )}
                </div>
              </div>
            </ModalBody>

            <ModalFooter>
              <div className="flex w-full justify-between">
                <Button onClick={() => handleClose()} tabIndex={-1}>Cancel</Button>
                <Button color="danger" onClick={() => handleConfirm(sendEmail, intro)} tabIndex={-1} isDisabled={!isValid} isLoading={isProcessing}>Continue</Button>
              </div>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </CustomModal>
  )
}

DeleteBookingSlotsConfirmationModal.propTypes = {
  description: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handlePreviewEmail: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool,
  isLoadingPreview: PropTypes.bool,
  show: PropTypes.bool,
  title: PropTypes.string.isRequired,
}

export default DeleteBookingSlotsConfirmationModal