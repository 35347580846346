import { gql } from "@apollo/client"

const GET_DASHBOARD_EVENT_VIEWS_STATS = gql`
  query ($input: ViewsInput!) {
    views(input: $input) {
      total
      total_live
      total_staff
      total_parents
      total_parents_live
      total_staff_live
      total_last_3_days
      total_today
      by_browser_family {
        name
        qty
      }

      by_platform_family {
        name
        qty
      }

      by_device_type {
        name
        qty
      }
    }
  }
`

export { GET_DASHBOARD_EVENT_VIEWS_STATS }
