import { mySqlToLocal } from '@helpers'

const getEventDateRange = (sessions) => sessions.reduce((dateRange, session) => {
  const starts = mySqlToLocal(session.starts_at)
  const ends = mySqlToLocal(session.ends_at)

  if (!dateRange["starts"] || dateRange["starts"] > starts) {
    dateRange["starts"] = starts
  }

  if (!dateRange["ends"] || dateRange["ends"] < ends) {
    dateRange["ends"] = ends
  }

  return dateRange
}, {'starts': null, 'ends': null})


export default getEventDateRange