const columns = {
  name: { id: "name", name: "Name", sortable: true, deselectable: false },
  teacher: { id: "teacher", name: "Teacher", sortable: false, deselectable: true },
  subject: { id: "subject", name: "Subject", sortable: false, deselectable: true },
  studentCount: { id: "studentCount", name: "# Students", sortable: false, deselectable: true },
  local_id: { id: "local_id", name: "MIS ID", sortable: false, deselectable: true },
  data_ref: { id: "data_ref", name: "Xporter ID", sortable: false, deselectable: true },
  is_sync: { id: "is_sync", name: "Type", sortable: false, deselectable: true },
  created_at: { id: "created_at", name: "Created", sortable: false, deselectable: true },
  updated_at: { id: "updated_at", name: "Updated", sortable: false, deselectable: true },
  actions: { id: "actions", name: "Actions", sortable: false, deselectable: false },
}

const defaultColumns = [
  "name",
  "teacher",
  "subject",
  "studentCount",
  "local_id",
  "data_ref",
  "is_sync",
  "created_at",
  "updated_at",
  "actions",
]

const downloadOptions = [{ key: "xls", name: "Download All XLS" }]

const filters = {
  custom: { id: "custom", name: "Custom" },
  sync: { id: "sync", name: "Sync" },
}

export { columns, defaultColumns, downloadOptions, filters }
