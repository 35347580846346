import { useContext } from "react"
import { UserContext } from "@context"
import { AdminDashboard, StaffDashboard } from "@components"
import { hasOrganiserRights } from "@helpers"

const Dashboard = () => {
    const { user } = useContext(UserContext)

    return (
        hasOrganiserRights(user) ? <AdminDashboard /> : <StaffDashboard />
    )
}

export default Dashboard
