import "./App.css"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { NextUIProvider } from "@nextui-org/react"

import {
  AttendanceReport,
  AuditLog,
  AutoLogin,
  CommentsReport,
  ComingSoon,
  Dashboard,
  Feedback,
  Guardians,
  Home,
  Imports,
  Lessons,
  ManageBookings,
  ManageEvent,
  ManageEventCommunications,
  ManageGroups,
  ManageInvitations,
  PageNotFound,
  ParentBookingsReport,
  ProtectedRoutes,
  SessionExpired,
  SiteSettings,
  Staff,
  Students,
  Subjects,
  TawkWidget,
  TeacherBookingsReport,
} from "@components"

import {
  ContextualSidebarContextProvider,
  ModalContextProvider,
  NotificationContextProvider,
  SidebarContextProvider,
  UserContextProvider,
} from "@context"

function App() {
  return (
    <NextUIProvider>
      <UserContextProvider>
        <TawkWidget />
        <ContextualSidebarContextProvider>
          <NotificationContextProvider>
            <Router>
              <SidebarContextProvider>
                <ModalContextProvider>
                  <Routes>
                    <Route element={<ProtectedRoutes />}>
                      <Route path="/" element={<Home />} />
                      <Route path="/coming-soon" element={<ComingSoon />} />
                      <Route path="/feedback" element={<Feedback />} />
                      <Route path="/data/guardians" element={<Guardians />} />
                      <Route path="/data/lessons" element={<Lessons />} />
                      <Route path="/settings" element={<SiteSettings />} />
                      <Route path="/data/staff" element={<Staff />} />
                      <Route path="/data/students" element={<Students />} />
                      <Route path="/data/subjects" element={<Subjects />} />
                      <Route path="/data/imports" element={<Imports />} />
                      <Route path="/event/:event/audit-log" element={<AuditLog />} />
                      <Route path="/event/:event/dashboard" element={<Dashboard />} />
                      <Route path="/event/:event/reports/attendance" element={<AttendanceReport />} />
                      <Route path="/event/:event/reports/comments" element={<CommentsReport />} />
                      <Route path="/event/:event/manage" element={<ManageEvent />} />
                      <Route path="/event/:event/bookings" element={<ManageBookings />} />
                      <Route path="/event/:event/groups" element={<ManageGroups />} />
                      <Route path="/event/:event/invitations" element={<ManageInvitations />} />
                      <Route path="/event/:event/communications" element={<ManageEventCommunications />} />
                      <Route path="/event/:event/reports/parent-bookings" element={<ParentBookingsReport />} />
                      <Route path="/event/:event/reports/teacher-bookings" element={<TeacherBookingsReport />} />
                    </Route>

                    <Route path="/auth/:details" element={<AutoLogin />} />
                    <Route path="/session-expired" element={<SessionExpired />} />
                    <Route path="/404" element={<PageNotFound />} />
                    <Route path="*" element={<PageNotFound />} />
                  </Routes>
                </ModalContextProvider>
              </SidebarContextProvider>
            </Router>
          </NotificationContextProvider>
        </ContextualSidebarContextProvider>
      </UserContextProvider>
    </NextUIProvider>
  )
}

export default App
