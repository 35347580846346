import { gql } from "@apollo/client"

const GET_BOOKING_SLOTS_XLS_DOWNLOAD_LINK = gql`
  query bookingsXlsDownload($eventId: ID!) {
    bookingsXlsDownload(event_id: $eventId) {
      filename
      url
      type
      queued
    }
	}
`

export { GET_BOOKING_SLOTS_XLS_DOWNLOAD_LINK }
   