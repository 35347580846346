import { ChartPlaceholder } from "@components"
import Chart from "react-apexcharts"
import { mySqlToLocal } from "@helpers"
import PropTypes from "prop-types"

const BookingsByDateLineGraph = ({ data = [] }) => {
  const getAxis = () => data.map(booking => mySqlToLocal(booking.date).format("DD/MM"))

  const getValues = () => [
    {
      name: "All Bookings",
      data: data.map(booking => booking.no_of_booked_slots),
    },
  ]

  const graph = {
    options: {
      chart: {
        id: "Bookings By Date",
        fontFamily: "Poppins, Helvetica, Arial, sans-serif",
        toolbar: {
          show: false,
        },
      },

      dataLabels: {
        enabled: false,
      },

      markers: {
        size: [6],
      },

      stroke: {
        curve: "smooth",
      },

      xaxis: {
        categories: getAxis(),
      },
    },

    series: getValues(),
  }

  if (data.length === 0) {
    return (
      <ChartPlaceholder
        className="h-48"
        caption="Booking stats will be displayed when data is available."
        type="line"
      />
    )
  }

  return <Chart options={graph.options} series={graph.series} type="area" height="300px" />
}

BookingsByDateLineGraph.propTypes = {
  data: PropTypes.array,
}

export default BookingsByDateLineGraph
