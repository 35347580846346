import React, { useContext } from "react"
import { Avatar, Dropdown, DropdownItem, DropdownMenu, DropdownSection, DropdownTrigger } from "@nextui-org/react"
import { BurgerMenu } from "@components"
import { UserCircleIcon, UserIcon } from "@heroicons/react/24/outline"
import { useApolloClient } from "@apollo/client"
import { authService } from "@services"
import { UserContext } from "@context"
import { getInitials, getSbUrl, hasRight } from "@helpers"

const NavbarButton = ({ active, caption, link }) => {
  if (active) return <a href={link} className="bg-navbar-active text-white rounded-md px-3 py-2 text-sm font-medium">{caption}</a>

  return <a href={link} className="text-gray-300 hover:bg-navbar-hover hover:text-white rounded-md px-3 py-2 text-sm font-medium" aria-current="page">{caption}</a>
}

const TopNavbar = () => {
  const { user } = useContext(UserContext)
  const client = useApolloClient()
  const clientName = user.client.menu_display_name || user.client.name
  const initials = getInitials(user?.me?.name || "")

  return (
    <nav className="bg-sb-blue fixed w-full z-40">
      <div className="mx-auto px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="flex flex-1 items-center justify-between sm:items-stretch">
            <div className="flex flex-shrink-0 items-center text-white font-semibold">
              <BurgerMenu className="mr-2 xl:hidden" /><span className="lg:max-w-[320px] xl:max-w-none whitespace-nowrap overflow-hidden">{clientName}</span>
            </div>

            <div className="hidden lg:ml-6 lg:block">
              <div className="flex space-x-4">
                {hasRight("bookings") && <NavbarButton link={getSbUrl("/apps/bookings")} caption="Bookings" />}
                {hasRight("lettings") && <NavbarButton link={getSbUrl("/apps/let")} caption="Lettings" />}
                <NavbarButton link="#" active caption="Parents" />
                {hasRight("reports") && <NavbarButton link={getSbUrl("/apps/reports")} caption="Reports" />}
                {hasRight("admin") && <NavbarButton link={getSbUrl("/apps/administration")} caption="Administration" />}
              </div>
            </div>
          </div>

          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <div className="relative ml-3">
              <Dropdown placement="bottom-end">
                <DropdownTrigger>
                  <Avatar
                    as="button"
                    className="transition-transform"
                    classNames={{
                      base: "bg-indigo-700",
                      name: "text-white text-base font-semibold",
                    }}
                    icon={<UserIcon className="w-6 h-6 text-white" />}
                    name={initials}
                  />
                </DropdownTrigger>

                <DropdownMenu aria-label="Menu" variant="flat">
                  <DropdownSection showDivider>
                    <DropdownItem key="my-profile" startContent={<UserCircleIcon className="w-6 h-6" />} onClick={() => window.location.href = getSbUrl("/apps/bookings/myprofile.php")}>
                      My Profile
                    </DropdownItem>
                  </DropdownSection>

                  <DropdownSection showDivider>
                    {hasRight("bookings") && <DropdownItem key="bookings" onClick={() => window.location.href = getSbUrl("/apps/bookings")}>Bookings</DropdownItem>}
                    {hasRight("lettings") && <DropdownItem key="lettings" onClick={() => window.location.href = getSbUrl("/apps/let")}>Lettings</DropdownItem>}
                    <DropdownItem key="parents">Parents</DropdownItem>
                    {hasRight("admin") && <DropdownItem key="administration" onClick={() => window.location.href = getSbUrl("/apps/administration")}>Administration</DropdownItem>}
                    {hasRight("reports") && <DropdownItem key="reports" onClick={() => window.location.href = getSbUrl("/apps/reports")}>Reports</DropdownItem>}
                  </DropdownSection>

                  {hasRight("admin") && (
                    <DropdownSection showDivider>
                      <DropdownItem key="support" onClick={() => window.location.href = getSbUrl("/apps/administration/support.php")}>Support</DropdownItem>
                      <DropdownItem key="licence" onClick={() => window.location.href = getSbUrl("/apps/administration/licence/licence.php")}>Licence</DropdownItem>
                    </DropdownSection>
                  )}

                  <DropdownSection>
                    <DropdownItem key="sign-out" color="danger" onClick={() => authService.logout(client, getSbUrl("/apps/shared/signout.php"))}>Sign out</DropdownItem>
                  </DropdownSection>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default TopNavbar
