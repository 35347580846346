import { gql } from "@apollo/client"

const DUPLICATE_GROUP_LESSONS_MUTATION = gql`
  mutation duplicateGroup($input: DuplicateGroupInput!) {
    duplicateGroup(input: $input) {
			id
			title
			
      lessons {
				id
				name
			}
	  }
  }
`

export { DUPLICATE_GROUP_LESSONS_MUTATION }