import React, { useEffect } from "react"
import { useMutation } from "@apollo/client"
import { EVENT_VIEWS_MUTATION } from "@graphql"
import PropTypes from "prop-types"

const EventViewsTracker = ({ children, eventId = null }) => {
  const [createEventView] = useMutation(EVENT_VIEWS_MUTATION)

  useEffect(() => {
    if (eventId) {
      createEventView({ variables: { input: { event_id: eventId, type: "STAFF" } } })

      const eventViewInterval = setInterval(() => {
        createEventView({ variables: { input: { event_id: eventId, type: "STAFF" } } })
      }, 120000) // runs every 2 minutes

      return () => clearInterval(eventViewInterval)
    }
  }, [createEventView, eventId])

  return <React.Fragment>{children}</React.Fragment>
}

EventViewsTracker.props = {
  eventId: PropTypes.number
}

export default EventViewsTracker