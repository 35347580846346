import React from "react"
import { CardStat, Divider } from "@components"
import { Progress } from "@nextui-org/react"
import { CalendarDaysIcon, CalendarIcon, SignalIcon, UserCircleIcon } from "@heroicons/react/24/outline"
import PropTypes from "prop-types"

const ViewStats = ({
  noOfTotalViews = 0,
  noOfParentViews = 0,
  noOfStaffViews = 0,
  noOfStaffLiveViews = 0,
  noOfParentLiveViews = 0,
  noOfTotalLiveViews = 0,
  noOfTotalViewsToday = 0,
  noOfTotalViewsLast3Days = 0,
  handleClick = () => {},
}) => (
  <React.Fragment>
    <CardStat
      title="Total Live Views"
      icon={SignalIcon}
      stat={noOfTotalLiveViews}
      meta={`${noOfParentLiveViews} Parents | ${noOfStaffLiveViews} Staff`}
      handleClick={() => handleClick("LIVE")}
    />

    <Divider />

    <CardStat
      title="Total Unique Views"
      icon={UserCircleIcon}
      stat={noOfTotalViews}
      meta={`${noOfParentViews} Parents | ${noOfStaffViews} Staff`}
      handleClick={() => handleClick("ALL")}
    />

    <Divider />

    <CardStat
      title="Unique Views Today"
      icon={CalendarIcon}
      stat={noOfTotalViewsToday}
      meta="Views today vs total views"
      handleClick={() => handleClick("TODAY")}
    >
      <Progress color="primary" value={noOfTotalViewsToday} aria-label="Unique Views Today" />
    </CardStat>

    <Divider />

    <CardStat
      title="Unique Views Last 3 Days"
      icon={CalendarDaysIcon}
      stat={noOfTotalViewsLast3Days}
      meta="Views last 3 days vs total"
      handleClick={() => handleClick("LAST_3_DAYS")}
    >
      <Progress color="primary" value={noOfTotalViewsLast3Days} aria-label="Unique Views Last 3 Days" />
    </CardStat>
  </React.Fragment>
)

ViewStats.propTypes = {
  handleClick: PropTypes.func,
  noOfParentViews: PropTypes.number,
  noOfStaffViews: PropTypes.number,
  noOfTotalViews: PropTypes.number,
  noOfParentLiveViews: PropTypes.number,
  noOfStaffLiveViews: PropTypes.number,
  noOfTotalLiveViews: PropTypes.number,
  noOfTotalViewsLast3Days: PropTypes.number,
  noOfTotalViewsToday: PropTypes.number,
}

export default ViewStats
