import { gql } from "@apollo/client"

const REASSIGN_GROUP_LESSONS_MUTATION = gql`
  mutation reassignGroupLessons($input: ReassignGroupLessonsInput!) {
    reassignGroupLessons(input: $input) {
			id
			title
			
      lessons {
				id
				name
			}
	  }
  }
`

export { REASSIGN_GROUP_LESSONS_MUTATION }