import { Chip, Tooltip } from "@nextui-org/react"
import { HomeIcon, ShieldCheckIcon } from "@heroicons/react/24/outline"
import { HomeIcon as HomeIconSolid, ShieldCheckIcon as ShieldIconSolid } from "@heroicons/react/24/solid"
import classNames from "classnames"
import PropTypes from "prop-types"

const GuardianStudentDetails = ({ contactSequence, livesWith, legalGuardian }) => 
{
  const contactSequenceClasses = classNames("text-center", "min-w-7", "h-7", "text-white", { "bg-green-400": contactSequence === 1 }, { "bg-gray-400": contactSequence !== 1})

  return (
    <div className="flex items-center space-x-2">
      <Tooltip content="Contact Sequence"><Chip className={contactSequenceClasses} size="sm">{contactSequence || "?"}</Chip></Tooltip>
      {livesWith && <Tooltip content="Lives with"><HomeIconSolid className="text-center h-7 w-7 text-green-400" /></Tooltip>}
      {!livesWith && <Tooltip content="Does not live with"><HomeIcon className="text-center h-7 w-7 text-gray-400" /></Tooltip>}
      {legalGuardian && <Tooltip content="Legal Guardian"><ShieldIconSolid className="text-center h-7 w-7 text-green-400" /></Tooltip>}
      {!legalGuardian && <Tooltip content="Legal Guardian"><ShieldCheckIcon className="text-center h-7 w-7 text-gray-400" /></Tooltip>}      
    </div>
  )
}

GuardianStudentDetails.propTypes = {
  contactSequence: PropTypes.number.isRequired,
  livesWith: PropTypes.bool.isRequired,
  legalGuardian: PropTypes.bool.isRequired,
}

export default GuardianStudentDetails