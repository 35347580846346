import { gql } from "@apollo/client"

const GET_AUDIT_LOG_DOWNLOAD = gql`
  query auditLogXlsDownload($eventId: ID!) {
    event(id: $eventId) {
			id 
			
			auditLogXlsDownload {
				filename
				url
				type
				queued
			}
		}
	}
`

export { GET_AUDIT_LOG_DOWNLOAD }