import { gql } from "@apollo/client"

const GET_DASHBOARD_TEACHER_SCHEDULE = gql`
  query ($event_id: ID!, $first: Int!) {
    teacherEventSchedule(event_id: $event_id, first: $first) {
      data {
        id
        lesson
        starts_at
        ends_at
        session
        student
        parent
        status
      }
    }
  }
`

export { GET_DASHBOARD_TEACHER_SCHEDULE }
