import { SquareLoader } from '@components';
import PropTypes from 'prop-types';

const LoadingPage = ({ message }) => {
  return (
    <div className="flex items-center flex-col justify-center h-screen bg-gradient-to-r from-blue-900 to-[#172554]">
      <SquareLoader />

      <div className="text-center mt-3">
        <h1 className="text-white text-2xl font-bold mb-1">Please wait loading...</h1>
        <p className="text-white text-sm">{message}</p>
      </div>
    </div>
  );
}

LoadingPage.propTypes = {
  message: PropTypes.string.isRequired
};

export default LoadingPage;