const columns = {
  student: { id: "student", name: "Student", sortable: false, deselectable: false },
  guardian: { id: "guardian", name: "Guardian", sortable: false, deselectable: false },
  email: { id: "email", name: "Account", sortable: false, deselectable: true },
  status: { id: "status", name: "Status", sortable: false, deselectable: false },
  emailed_date: { id: "emailed_date", name: "Emailed Date", sortable: false, deselectable: true },
  meeting_type: { id: "meeting_type", name: "Type", sortable: false, deselectable: true },
  contact_details: { id: "contact_details", name: "Contact Details", sortable: false, deselectable: true },
  actions: { id: "actions", name: "Actions", sortable: false, deselectable: false, align: "text-end" },
}

const defaultColumns = [
  "student",
  "guardian",
  "email",
  "status",
  "emailed_date",
  "contact_details",
  "actions",
]

const downloadOptions = [{ key: "xls", name: "Download All XLS" }]

const filters = {
  all: { id: "all", name: "All", filter: null },
  matched: { id: "matched", name: "Matched", filter: { column: "STATUS", operator: "EQ", value: "MATCHED" } },
  unmatched: { id: "unmatched", name: "Unmatched", filter: { column: "STATUS", operator: "EQ", value: "UNMATCHED" } },
  emailed: { id: "emailed", name: "Email Sent", filter: { column: "LAST_SENT_AT", operator: "IS_NOT_NULL" } },
  not_emailed: { id: "not_emailed", name: "Email Not Sent", filter: { column: "LAST_SENT_AT", operator: "IS_NULL" } },
}

export { columns, defaultColumns, downloadOptions, filters }