const columns = {
  comment: { id: "comment", name: "Comment", sortable: false, deselectable: false },
  commenter: { id: "commenter", name: "Commenter", sortable: true, deselectable: false },
  student: { id: "student", name: "Student", sortable: true, deselectable: true },
  teacher: { id: "teacher", name: "Teacher", sortable: true, deselectable: true },
  lesson: { id: "lesson", name: "Lesson", sortable: true, deselectable: true },
  session: { id: "session", name: "Session", sortable: true, deselectable: true },
  read: { id: "read", name: "Read", sortable: true, deselectable: true },
  type: { id: "type", name: "Type", sortable: true, deselectable: true, align: "text-center" },
  starts_at: { id: "starts_at", name: "Starts", sortable: true, deselectable: true },
  ends_at: { id: "ends_at", name: "Ends", sortable: true, deselectable: true },
  created_at: { id: "created_at", name: "Created", sortable: true, deselectable: true },
  actions: { id: "actions", name: "Actions", sortable: false, deselectable: false },
}

const defaultColumns = [
  "comment",
  "commenter",
  "student",
  "teacher",
  "lesson",
  "session",
  "read",
  "type",
  "starts_at",
  "ends_at",
  "created_at",
  "actions",
]

const downloadOptions = [
  { key: "pdf", name: "Download All PDF" },
  { key: "xls", name: "Download All XLS" },
]

const filters = {
  parent: { id: "parent", name: "Parent" },
  read: { id: "read", name: "Read" },
  staff: { id: "staff", name: "Staff" },
  unread: { id: "unread", name: "Unread" },
}

export { columns, defaultColumns, downloadOptions, filters }
