import { Tooltip } from "@nextui-org/react"
import { BuildingOffice2Icon, PhoneArrowDownLeftIcon, VideoCameraIcon } from "@heroicons/react/24/outline"
import PropTypes from "prop-types"

const EventTypeIcon = ({ isPhone, isVc, isVenue, size = "md" }) => {
  const iconSize = size === "sm" ? "h-5" : size === "md" ? "h-7" : "h-9"

  return (
    <div>
      {isVenue && (
        <Tooltip showArrow={true} color="secondary" content="Venue" className="capitalize">
          <BuildingOffice2Icon className={`mr-3 ${iconSize} w-auto text-blue-500`} />
        </Tooltip>
      )}

      {isPhone && (
        <Tooltip showArrow={true} color="secondary" content="Phone" className="capitalize">
          <PhoneArrowDownLeftIcon className={`mr-3 ${iconSize} w-auto text-blue-500`} />
        </Tooltip>
      )}

      {isVc && (
        <Tooltip showArrow={true} color="secondary" content="Video conference" className="capitalize">
          <VideoCameraIcon className={`mr-3 ${iconSize} w-auto text-blue-500`} />
        </Tooltip>
      )}
    </div>
  )
}

EventTypeIcon.propTypes = {
  isPhone: PropTypes.bool,
  isVc: PropTypes.bool,
  isVenue: PropTypes.bool,
  size: PropTypes.string,
}


export default EventTypeIcon
