import { useQuery } from "@apollo/client"
import { Chip } from "@nextui-org/react"

import {
  ArrowDownCircleIcon,
  CheckCircleIcon,
  DevicePhoneMobileIcon,
  LockClosedIcon,
  PaperAirplaneIcon,
  ShieldCheckIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid"

import { CircleStackIcon } from "@heroicons/react/24/outline"
import { LineLoader, SimplePlaceholder } from "@components"
import { GET_STUDENT_GUARDIANS } from "@graphql"
import { guardianFullname, humanReadableDuration, mySqlToLocal } from "@helpers"
import PropTypes from "prop-types"

const StudentGuardiansList = ({ student }) => {
  const { loading, error, data } = useQuery(GET_STUDENT_GUARDIANS, {
    variables: {
      fetchPolicy: "cache-first",
      id: student.id,
    },
  })

  if (loading) return <LineLoader message="Loading guardians..." classNames="mt-8" />

  if (error) return "error"

  if (data.student.guardians.length === 0) {
    return (
      <SimplePlaceholder icon={<CircleStackIcon className="h-20 w-auto" />} title="No guardians found for this student">
        <p className="mt-1 text-sm text-gray-500">
          If you believe this student should have associated guardians then please check your MIS and Xporter
          integration.
        </p>
      </SimplePlaceholder>
    )
  }

  return (
    <ul className="divide-y divide-gray-100">
      {data.student.guardians.map(guardian => {
        const { contact_sequence, legal_guardian, lives_with } = guardian.pivot

        const lastSeen = guardian?.user?.last_active
          ? humanReadableDuration(mySqlToLocal(guardian.user.last_active))
          : null

        const activatedDate = guardian?.user?.activated_at
          ? mySqlToLocal(guardian?.user?.activated_at).format("DD/MM/YYYY")
          : "N/A"

        const lastLoginDate = guardian?.user?.last_active
          ? mySqlToLocal(guardian?.user?.last_active).format("DD/MM/YYYY")
          : "N/A"

        return (
          <li key={guardian.id}>
            <div className="flex justify-between gap-x-6 pt-3">
              <div className="flex min-w-0 gap-x-4">
                <div className="min-w-0 flex-auto">
                  <h1 className="text-lg font-semibold leading-6 text-gray-900 mb-4">{guardianFullname(guardian)}</h1>
                </div>
              </div>

              <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                {lastSeen && (
                  <Chip
                    startContent={<CheckCircleIcon className="w-6 h-auto text-white" />}
                    color="primary"
                    classNames={{ content: "text-sm font-semibold leading-5" }}
                  >
                    Last seen {lastSeen} ago
                  </Chip>
                )}

                {!lastSeen && (
                  <Chip
                    startContent={<XCircleIcon className="w-6 h-auto text-white" />}
                    color="warning"
                    classNames={{ content: "text-sm font-semibold text-white leading-5" }}
                  >
                    Never Logged In
                  </Chip>
                )}
              </div>
            </div>

            <div>
              <ul>
                <li className="mt-1 truncate text-md leading-5 text-gray-500 flex items-center mb-2">
                  <PaperAirplaneIcon className="w-6 h-auto mr-2 text-indigo-400" /> {guardian?.user?.email || "N/A"}
                </li>

                <li className="mt-1 truncate text-md leading-5 text-gray-500 flex items-center mb-2">
                  <DevicePhoneMobileIcon className="w-6 h-auto mr-2 text-indigo-400" />{" "}
                  {guardian?.user?.mobile || "N/A"}
                </li>

                <li className="mt-1 truncate text-md leading-5 text-gray-500 flex items-center mb-2">
                  <ShieldCheckIcon className="w-6 h-auto mr-2 text-indigo-400" /> Account Activated: {activatedDate}
                </li>

                <li className="mt-1 truncate text-md leading-5 text-gray-500 flex items-center mb-2">
                  <LockClosedIcon className="w-6 h-auto mr-2 text-indigo-400" /> Last Login: {lastLoginDate}
                </li>
              </ul>
            </div>

            <div className="flex gap-x-3 mt-4 mb-6">
              <Chip startContent={<ArrowDownCircleIcon className="w-6 h-auto" />} color="primary">
                {contact_sequence} # Contact
              </Chip>

              {legal_guardian && (
                <Chip
                  startContent={<CheckCircleIcon className="w-6 h-auto text-white" />}
                  classNames={{ content: "text-white" }}
                  color="success"
                >
                  Legal Guardian
                </Chip>
              )}

              {!legal_guardian && (
                <Chip
                  startContent={<XCircleIcon className="w-6 h-auto text-white" />}
                  classNames={{ content: "text-white" }}
                  color="warning"
                >
                  Legal Guardian
                </Chip>
              )}

              {lives_with && (
                <Chip
                  startContent={<CheckCircleIcon className="w-6 h-auto text-white" />}
                  classNames={{ content: "text-white" }}
                  color="success"
                >
                  Lives With
                </Chip>
              )}

              {!lives_with && (
                <Chip
                  startContent={<XCircleIcon className="w-6 h-auto text-white" />}
                  classNames={{ content: "text-white" }}
                  color="warning"
                >
                  Lives With
                </Chip>
              )}
            </div>
          </li>
        )
      })}
    </ul>
  )
}

StudentGuardiansList.propTypes = {
  student: PropTypes.object.isRequired,
}

export default StudentGuardiansList
