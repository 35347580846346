import PropTypes from "prop-types"
import "./LineLoader.css"

const LineLoader = ({ classNames = "", message = null }) => (
  <div className={`${classNames} margin-auto text-center`}>
    <div className="lineloader-spinner">
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>

    {message && <p>{message}</p>}
  </div>
)

LineLoader.propTypes = {
  classNames: PropTypes.string,
  message: PropTypes.string,
}

export default LineLoader
