import React, { createContext, useState } from "react"
import { CustomModal } from "@components"
import { ModalContent, ModalHeader, ModalBody, ModalFooter } from "@nextui-org/react";

const ModalContext = createContext()

const ModalContextProvider = ({ children }) => {
  const [modal, setModal] = useState({
    isOpen: false,
    title: "",
    content: "",
    size: "3xl",
    showFooter: false,
    footer: null,
    hideCloseButton: true,
  })

  return (
    <ModalContext.Provider value={{ setModal }}>
      <CustomModal
        show={modal.isOpen}
        size={modal.size}
        handleClose={() => setModal(prevState => ({ ...prevState, isOpen: false }))}
        isDismissable={false}
        hideCloseButton={modal.hideCloseButton}
        isKeyboardDismissDisabled={false}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">{modal.title}</ModalHeader>

              <ModalBody>
                {modal.content}
              </ModalBody>

              {modal.showFooter && (
                <ModalFooter>
                  {modal.footer}
                </ModalFooter>
              )}
            </>
          )}
        </ModalContent>
      </CustomModal>

      {children}
    </ModalContext.Provider>
  )
}

export { ModalContext, ModalContextProvider }
