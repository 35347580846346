import { gql } from "@apollo/client"

const GET_REPORT_EVENT_ATTENDANCE = gql`
  query (
    $event_id: ID!
    $searchTerm: String
    $page: Int!
    $perPage: Int!
    $filters: [String!]
    $sessions: [ID!]
    $orderBy: [QueryReportEventAttendanceOrderByOrderByClause!]
  ) {
    reportEventAttendance(
      event_id: $event_id
      page: $page
      first: $perPage
      searchTerm: $searchTerm
      filters: $filters
      sessions: $sessions
      orderBy: $orderBy
    ) {
      data {
        id
        starts_at
        ends_at
        parent
        group
        lesson
        session
        student
        subject
        attendance
      }

      paginatorInfo {
        total
        currentPage
        hasMorePages
        lastPage
        perPage
      }
    }
  }
`

export { GET_REPORT_EVENT_ATTENDANCE }
