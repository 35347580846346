import { ChartPlaceholder } from "@components"
import Chart from "react-apexcharts"
import PropTypes from "prop-types"
import { donutOptions } from "../Options"

const AttendanceDonut = ({ noOfAbsentSlots = 0, noOfAttendedSlots = 0, noOfUnknownSlots = 0 }) => {
  const totalBookedSlots = noOfAbsentSlots + noOfAttendedSlots + noOfUnknownSlots

  const graph = {
    options: {
      ...donutOptions,
      colors: ["#EC86F6", "#55A9FA", "#FDB629"],
      chart: { ...donutOptions.chart, id: "attendance-donut" },
      labels: ["Absent", "Attended", "Unknown"],
    },

    series: [noOfAbsentSlots, noOfAttendedSlots, noOfUnknownSlots],
  }

  if (totalBookedSlots === 0) {
    return (
      <ChartPlaceholder
        className="h-48"
        caption="Attendance stats will be displayed when data is available."
        type="donut"
      />
    )
  }

  return <Chart options={graph.options} series={graph.series} type="donut" height={350} />
}

AttendanceDonut.propTypes = {
  noOfAbsentSlots: PropTypes.number,
  noOfAttendedSlots: PropTypes.number,
  noOfUnknownSlots: PropTypes.number,
}

export default AttendanceDonut
