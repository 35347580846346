import { hasFullLiveAccess, hasOrganiserRights } from "@helpers"

import {
  ArrowPathIcon,
  CalendarDaysIcon,
  ChartPieIcon,
  CircleStackIcon,
  Cog6ToothIcon,
  CursorArrowRippleIcon,
  DocumentDuplicateIcon,
  HandThumbUpIcon,
  PaperAirplaneIcon,
  PresentationChartLineIcon,
  SquaresPlusIcon,
  TableCellsIcon,
} from "@heroicons/react/24/outline"

const getEventLinks = (eventId, user) => {
  const links = [
    {
      caption: "Create New Event",
      expandable: false,
      href: hasFullLiveAccess() ? "#" : "/coming-soon",
      target: "_self",
      icon: <SquaresPlusIcon />,
      id: "CREATE_EVENT",
      subMenu: null,
      type: "TOP_LEVEL_LINK",
      isAdmin: true,
      updateActiveLinkOnClick: true,
    }
  ]

  if (user?.me?.defaultEvent !== null) {
    links.push({
      caption: "Switch Event",
      expandable: false,
      href: "#",
      target: "_self",
      icon: <ArrowPathIcon />,
      id: "SWITCH_EVENT",
      subMenu: null,
      type: "TOP_LEVEL_LINK",
      isAdmin: false,
      updateActiveLinkOnClick: false,
    })
  }

  return links
}

const getCurrentEventLinks = eventId => [
  {
    caption: "Event Dashboard",
    expandable: false,
    href: `/event/${eventId}/dashboard`,
    target: "_self",
    icon: <PresentationChartLineIcon />,
    id: "EVENT_DASHBOARD",
    subMenu: null,
    type: "TOP_LEVEL_LINK",
    isAdmin: false,
    updateActiveLinkOnClick: true,
  },
  {
    caption: "Event Reports",
    expandable: true,
    href: "#",
    icon: <ChartPieIcon />,
    id: "EVENT_REPORTS",
    subMenu: [
      {
        caption: "Event Attendance",
        expandable: false,
        href: `/event/${eventId}/reports/attendance`,
        target: "_self",
        id: "EVENT_REPORTS_ATTENDANCE",
        icon: null,
        subMenu: null,
        type: "SUB_MENU_LINK",
      },
      {
        caption: "Event Comments",
        expandable: false,
        href: `/event/${eventId}/reports/comments`,
        target: "_self",
        id: "EVENT_REPORTS_COMMENTS",
        icon: null,
        subMenu: null,
        type: "SUB_MENU_LINK",
      },
      {
        caption: "Parent Bookings & Itinerary",
        expandable: false,
        href: `/event/${eventId}/reports/parent-bookings`,
        target: "_self",
        id: "EVENT_REPORTS_PARENT_BOOKINGS",
        icon: null,
        subMenu: null,
        type: "SUB_MENU_LINK",
      },
      {
        caption: "Teacher Bookings & Itinerary",
        expandable: false,
        href: `/event/${eventId}/reports/teacher-bookings`,
        target: "_self",
        id: "EVENT_REPORTS_TEACHER_BOOKINGS",
        icon: null,
        subMenu: null,
        type: "SUB_MENU_LINK",
      },
    ],
    type: "TOP_LEVEL_LINK",
    isAdmin: false,
    updateActiveLinkOnClick: false,
  },
  {
    caption: "Manage Event",
    expandable: true,
    href: `/event/${eventId}/manage`,
    target: "_self",
    icon: <CalendarDaysIcon />,
    id: "EVENT_MANAGE",
    type: "TOP_LEVEL_LINK",
    isAdmin: false,
    subMenu: [
      {
        caption: "Audit Log",
        expandable: false,
        href: `/event/${eventId}/audit-log`,
        target: "_self",
        id: "EVENT_AUDIT_LOG",
        icon: null,
        subMenu: null,
        type: "SUB_MENU_LINK",
      },
      {
        caption: "Manage Bookings",
        expandable: false,
        href: hasFullLiveAccess() ? `/event/${eventId}/bookings` : "/coming-soon",
        target: "_self",
        id: "EVENT_MANAGE_BOOKINGS",
        icon: null,
        subMenu: null,
        type: "SUB_MENU_LINK",
      },
      {
        caption: "Manage Groups",
        expandable: false,
        href: hasFullLiveAccess() ? `/event/${eventId}/groups` : "/coming-soon",
        target: "_self",
        id: "EVENT_MANAGE_GROUPS",
        icon: null,
        subMenu: null,
        type: "SUB_MENU_LINK",
      },
      {
        caption: "Manage Invitations",
        expandable: false,
        href: hasFullLiveAccess() ? `/event/${eventId}/invitations` : "/coming-soon",
        target: "_self",
        id: "EVENT_MANAGE_INVITATIONS",
        icon: null,
        subMenu: null,
        type: "SUB_MENU_LINK",
      },
    ]
  },
  {
    caption: "Manage Event Emails",
    expandable: false,
    href:  hasFullLiveAccess() ? `/event/${eventId}/communications` : "/coming-soon",
    target: "_self",
    icon: <PaperAirplaneIcon />,
    id: "EVENT_MANAGE_EMAILS",
    subMenu: null,
    type: "TOP_LEVEL_LINK",
    isAdmin: true,
  },
]

const getSiteLinks = () => [
  {
    caption: "Data Imports",
    expandable: false,
    href: `/data/imports`,
    target: "_self",
    icon: <TableCellsIcon />,
    id: "SITE_DATA_IMPORTS",
    subMenu: null,
    type: "TOP_LEVEL_LINK",
    isAdmin: true,
  },
  {
    caption: "Site Data",
    expandable: true,
    href: "#",
    icon: <CircleStackIcon />,
    id: "SITE_DATA",
    subMenu: [
      {
        caption: "Guardians",
        expandable: false,
        href: "/data/guardians",
        target: "_self",
        id: "SITE_DATA_GUARDIANS",
        icon: null,
        subMenu: null,
        type: "SUB_MENU_LINK",
      },
      {
        caption: "Lessons",
        expandable: false,
        href: "/data/lessons",
        target: "_self",
        id: "SITE_DATA_LESSONS",
        icon: null,
        subMenu: null,
        type: "SUB_MENU_LINK",
      },
      {
        caption: "Staff",
        expandable: false,
        href: "/data/staff",
        target: "_self",
        id: "SITE_DATA_STAFF",
        icon: null,
        subMenu: null,
        type: "SUB_MENU_LINK",
      },
      {
        caption: "Students",
        expandable: false,
        href: "/data/students",
        target: "_self",
        id: "SITE_DATA_STUDENTS",
        icon: null,
        subMenu: null,
        type: "SUB_MENU_LINK",
      },
      {
        caption: "Subjects",
        expandable: false,
        href: "/data/subjects",
        target: "_self",
        id: "SITE_DATA_SUBJECTS",
        icon: null,
        subMenu: null,
        type: "SUB_MENU_LINK",
      },
    ],
    type: "TOP_LEVEL_LINK",
    isAdmin: true,
    updateActiveLinkOnClick: false,
  },
  {
    caption: "Site Settings",
    expandable: false,
    href: `/settings`,
    target: "_self",
    icon: <Cog6ToothIcon />,
    id: "SITE_SETTINGS",
    subMenu: null,
    type: "TOP_LEVEL_LINK",
    isAdmin: true,
  },
]

const getSupportLinks = () => [
  {
    caption: "Your Feedback",
    expandable: false,
    href: "/feedback",
    target: "_self",
    icon: <HandThumbUpIcon />,
    id: "SUPPORT_FEEDBACK",
    subMenu: null,
    type: "TOP_LEVEL_LINK",
    isAdmin: false,
  },
  {
    caption: "Change Request",
    expandable: false,
    href: "https://forms.office.com/r/q46Dn6uVK4",
    target: "_blank",
    icon: <DocumentDuplicateIcon />,
    id: "SUPPORT_CHANGE_REQUEST",
    subMenu: null,
    type: "TOP_LEVEL_LINK",
    isAdmin: false,
  },
  {
    caption: "Product Tour",
    expandable: false,
    href: "https://www.guidejar.com/embed/9cc67aae-f4d3-4119-9398-cb6b4833f031?type=1&controls=on",
    target: "_blank",
    icon: <CursorArrowRippleIcon />,
    id: "PRODUCT_TOUR",
    subMenu: null,
    type: "TOP_LEVEL_LINK",
    isAdmin: true,
  }
]

const getLinks = (eventId = null, user) => {
  const links = []

  if (hasOrganiserRights(user) || user?.me?.defaultEvent !== null) {
    links.push({ id: "EVENT_OPTIONS", caption: "Event Options", links: getEventLinks(eventId, user), isAdminOnly: false })
  }

  if (user?.me?.defaultEvent !== null) {
    links.push({ id: "CURRENT_EVENT_OPTIONS", caption: "Current Event Options", links: getCurrentEventLinks(eventId), isAdminOnly: false })
  }

  links.push(
    { id: "SITE_OPTIONS", caption: "Site Options", links: getSiteLinks(), isAdminOnly: true },
    { id: "SUPPORT_OPTIONS", caption: "Support", links: getSupportLinks(), isAdminOnly: false },
  )

  return links
}

export default getLinks
