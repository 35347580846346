import { gql } from "@apollo/client"

const GET_AUDIT_LOG = gql`
  query (
    $eventId: ID!
    $perPage: Int!
    $page: Int!
    $orderBy: [EventAuditLogOrderByOrderByClause!]
    $searchTerm: String
    $types: [ID!]
  ) {
    event(id: $eventId) {
      id
      
      auditLog(
        first: $perPage
        page: $page
        orderBy: $orderBy
        searchTerm: $searchTerm
        types: $types
      ) {
        paginatorInfo {
          total
          currentPage
          hasMorePages
          perPage
          lastPage
        }
  
        data {
          id
          details
          event_id
          summary
          
          type {
            code
            description
          }
  
          user {
            name
          }
  
          created_at
        }
      }
    }
  }
`

export { GET_AUDIT_LOG }