import { Chip } from "@nextui-org/react"
import { Squares2X2Icon } from "@heroicons/react/24/outline"
import { badgeStylesBySize } from "@helpers"
import PropTypes from "prop-types"

const MisBadge = ({ size = "md", name }) => {
  const badgeStyles = badgeStylesBySize(size)

  return (
    <Chip size={size} classNames={{ base: "w-100 max-w-none bg-blue-400" }}>
      <span className={`flex items-center justify-center text-white px-2 font-semibold ${badgeStyles.font} `}>
        <Squares2X2Icon className={`text-white mr-1  ${badgeStyles.icon}`} />
        {name}
      </span>
    </Chip>
  )

}

MisBadge.propTypes = {
  size: PropTypes.string,
  name: PropTypes.string.isRequired,
}

export default MisBadge
