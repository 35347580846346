import { gql } from "@apollo/client"

const GET_STAFF_AUTO_COMPLETES = gql`
  query client($clientId: ID!, $orderBy: [ClientStaffOrderByOrderByClause!], $searchTerm: String!, $perPage: Int!, $page: Int!) {
    client(id: $clientId) {
      id
			
			staff(first: $perPage, page: $page, searchTerm: $searchTerm, orderBy: $orderBy) {
				data {
					id
					salutation
					forename
					surname
          data_ref
          local_id
					user_id
				}
			
				paginatorInfo {
					currentPage
					lastPage
					perPage
					total
					hasMorePages
				}
			}
		}
	}`

export { GET_STAFF_AUTO_COMPLETES }
