const columns = {
  select: { id: "select", name: "", sortable: true, deselectable: false },
  group: { id: "group", name: "Group", sortable: true, deselectable: false },
  lesson: { id: "lesson", name: "Lesson", sortable: true, deselectable: false },
  student: { id: "student", name: "Student", sortable: true, deselectable: false },
  parent: { id: "parent", name: "User", sortable: true, deselectable: false },
  email: { id: "email", name: "Email", sortable: true, deselectable: true },
  booked_by_user: { id: "booked_by_user", name: "Booked By", sortable: true, deselectable: true },
  booked_by_user_type: { id: "booked_by_user_type", name: "Booked By Type", sortable: true, deselectable: true },
  starts_at: { id: "starts_at", name: "Starts", sortable: true, deselectable: false },
  ends_at: { id: "ends_at", name: "Ends", sortable: true, deselectable: false },
  booked_at: { id: "booked_at", name: "Booked At", sortable: true, deselectable: true },
  session: { id: "session", name: "Session", sortable: true, deselectable: true },
  attendance_status: { id: "attendance_status", name: "Attendance", sortable: false, deselectable: true },
}

const defaultColumns = [
  "select",
  "group",
  "lesson",
  "student",
  "parent",
  "starts_at",
  "ends_at",
  "session",
  "attendance_status",
]

const filters = {
  attending: { id: "attending", name: "Attending" },
  cancelled: { id: "cancelled", name: "Cancelled" },
}

const downloadOptions = [{ key: "xls", name: "Download All XLS" }]

export { columns, defaultColumns, downloadOptions, filters }
