import { Modal } from '@nextui-org/react';
import PropTypes from 'prop-types';

const CustomModal = ({ children, handleClose = () => {}, show = false, ...props }) => (
  <Modal
    isOpen={show}
    onClose={() => handleClose()}
    placement="center"
    isDismissable={false}
    isKeyboardDismissDisabled={true}
    radius="lg"
    className="max-h-[800px]"
    classNames={{
      header: "bg-indigo-800 rounded-t-xl text-white",
      wrapper: "md:items-center",
      closeButton: "hover:bg-white/5 text-white mt-2 mr-2",
    }}
    {...props}
  >
    {children}
  </Modal>
)

CustomModal.propTypes = {
  children: PropTypes.node.isRequired,
  handleClose: PropTypes.func,
  show: PropTypes.bool,
}

export default CustomModal