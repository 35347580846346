const styles = {
  sm: {
    font: "text-xs",
    icon: "h-4",
  },

  md: {
    font: "text-sm",
    icon: "h-5",
  },

  lg: {
    font: "text-md",
    icon: "h-6",
  },
}

const badgeStylesBySize = size => styles[size]

export default badgeStylesBySize
