import React, { useContext, useEffect, useState } from "react"

import { 
  DataTable,
  DeleteBookingSlotsConfirmationModal,
  EmailPreviewModal, 
  EventPageHeader, 
  EventViewsTracker, 
  NoDataTablePlaceholder, 
  Page 
} from "@components"

import { UserContext } from "@context"

import { 
  DELETE_BOOKING_SLOTS, 
  GET_BOOKING_SLOTS_XLS_DOWNLOAD_LINK, 
  GET_DELETE_BOOKING_SLOTS_EMAIL_PREVIEW, 
  GET_MANAGE_BOOKINGS, 
  GET_MANAGE_BOOKINGS_SUMMARY 
} from "@graphql"

import { downloadFile, mySqlToLocal } from "@helpers"
import { useSetCurrentEvent } from "@hooks"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { CalendarDaysIcon } from "@heroicons/react/24/outline"
import { Button, Checkbox, Chip } from "@nextui-org/react"
import { cache } from "@services/graphql/client"
import { useParams } from "react-router-dom"
import { columns, defaultColumns, downloadOptions, filters } from "./data"
import { Stats } from "./Stats"

const initialSelectedColumns = JSON.parse(window.localStorage.getItem("event.manage.bookings.columns")) ?? defaultColumns

const ManageBookings = () => {
  const { event: eventId } = useParams()
  const { user } = useContext(UserContext)
  const [isDownloading, setIsDownloading] = useState(false)
  const [refetching, setRefetching] = useState(false)
  const [selectedColumns, setSelectedColumns] = useState(initialSelectedColumns)
  const [selectedIds, setSelectedIds] = useState(new Set([]))
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedFilters, setSelectedFilters] = useState(Object.keys(filters))
  const [showDeleteBookingSlotsConfirmationModal, setShowDeleteBookingSlotsConfirmationModal] = useState(false)
  const [showEmailPreviewModal, setShowEmailPreviewModal] = useState(false)
  const [sortDescriptor, setSortDescriptor] = useState({ column: "starts_at", direction: "ascending" })
  const [page, setPage] = useState(1)
  const [perPage] = useState(50)
  
  useSetCurrentEvent(eventId)

  useEffect(() => {
    window.localStorage.setItem("event.manage.bookings.columns", JSON.stringify(selectedColumns))
  }, [selectedColumns])

  const buildWhereClause = () => {
    const where = {"OR": []}
    
    if (selectedFilters.includes("attending")) {
      where.OR.push({ "column": "NOT_ATTENDING", "value": false })
    }

    if (selectedFilters.includes("cancelled")) {
      where.OR.push({ "column": "NOT_ATTENDING", "value": true })
    }

    return where
  }

  const [ getBookingsXlsDownloadLink ] = useLazyQuery(GET_BOOKING_SLOTS_XLS_DOWNLOAD_LINK, {
    variables: { eventId },
    fetchPolicy: "network-only",
    onCompleted: data => {
      downloadFile(data.bookingsXlsDownload.filename, data.bookingsXlsDownload.url)
      setIsDownloading(false)
    },
  })

  const [ getEmailPreview, { data: emailPreview, loading: isLoadingEmailPreview }] = useLazyQuery(GET_DELETE_BOOKING_SLOTS_EMAIL_PREVIEW)

  const { data: summary, refetch: refetchSummary } = useQuery(GET_MANAGE_BOOKINGS_SUMMARY, { 
    fetchPolicy: "cache-first",
    variables: { eventId }
  })
  
  const [deleteBookingSlots, { loading: isDeleting }] = useMutation(DELETE_BOOKING_SLOTS, {
    onCompleted: () => {
      refetch()
      refetchSummary()
      setSelectedIds(new Set([]))
      setShowDeleteBookingSlotsConfirmationModal(false)
    }
  })

  const {data, loading, refetch} = useQuery(GET_MANAGE_BOOKINGS, {
    variables: {
      eventId,
      page,
      perPage,
      orderBy: [
        {
          column: sortDescriptor.column.toUpperCase(),
          order: sortDescriptor.direction === "ascending" ? "ASC" : "DESC",
        },
      ],
      searchTerm,
      where: buildWhereClause(),
      bookingSlotIds: selectedFilters.includes("selected") ? [...selectedIds] : null
    },
    fetchPolicy: "cache-first",
    pollInterval: 1500000,
  })

  const handleDeleteBookingSlots = (sendCancellationEmails, intro) => {
    deleteBookingSlots({ 
      variables: {
        input: {
          event_id: eventId,
          booking_slot_ids: [...selectedIds],
          cancellation_email_intro: intro,
          send_cancellation_emails: sendCancellationEmails
        }
      }
    })
  }

  const handleDownload = type => {
    if (type === "xls") {
      setIsDownloading(true)
      getBookingsXlsDownloadLink()
    }
  }

  const handleRefetchData = () => {
    const cacheData = cache.extract();

    Object.keys(cacheData).forEach(key => {
      const entry = cacheData[key];

      if (entry.__typename === "ManageBooking" && entry.event_id === eventId) {
        cache.evict({ id: key });
      }
    });

    cache.gc();

    refetchData()
  }

  const handlePreviewEmail = (intro) => {
    getEmailPreview({
      variables: {
        input: {
          event_id: eventId,
          booking_slot_ids: [...selectedIds],
          intro: intro
        }
      }
    }).then(() => {
      setShowEmailPreviewModal(true)
    })
  }

  const handleSearchChange = searchTerm => {
    setPage(1)
    setSearchTerm(searchTerm)
  }

  const handleSearchClear = () => {
    setSearchTerm("")
    setPage(1)
  }

  const handleRowSelection = id => {
    if (selectedIds.has(id)) {
      setSelectedIds(previousIds => new Set([...previousIds].filter(_id => _id !== id)))
    } else {
      setSelectedIds(previousIds => new Set([...previousIds, id]))
    }
  }

  const handleSortChange = sortDescriptor => {
    setSortDescriptor(sortDescriptor)
    setPage(1)
  }

  const handleToggleColumn = selectedColumns => {
    setSelectedColumns(Object.keys(columns).filter(column => selectedColumns.has(column)))
  }

  const handleToggleFilter = selectedFilters => {
    setSelectedFilters(Object.keys(filters).filter(filter => selectedFilters.has(filter)))
  }
  
  const refetchData = () => {
    setRefetching(true)
    refetch().then(() => setRefetching(false))
  }

  const renderCell = (item, column) => {
    if (column === "select") {
      return (
        <Checkbox color="secondary" isSelected={selectedIds.has(item.id)} onChange={() => handleRowSelection(item.id)} />
      )
    }

    if (column === "attendance_status") {
      return item.attendance_status === "Cancelled" 
        ? <Chip color="danger" size="sm">Cancelled</Chip> 
        : <Chip color="success" size="sm" classNames={{ content: "text-white" }}>Attending</Chip>
    }

    if (column === "starts_at" || column === "ends_at") {
      return mySqlToLocal(item[column]).format("YYYY-MM-DD HH:mm:ss")
    }

    return item[column]
  }

  const deleteButtonCaption = selectedIds.size === 0 ? "Delete Bookings" : `Delete ${selectedIds.size} Bookings`

  const hasSelection = selectedIds.size > 0

  return (
    <EventViewsTracker eventId={eventId}>
      <Page>
        <EventPageHeader
          title="Manage Bookings"
          icon={<CalendarDaysIcon className="page-header-icon" />}
          event={user?.currentEvent}
        />

        <Stats 
          noOfBookedSlots={summary?.manageBookingsSummary?.no_of_booked_slots}
          noOfLessons={summary?.manageBookingsSummary?.no_of_lessons}
          noOfGroups={summary?.manageBookingsSummary?.no_of_groups}
          noOfSessions={summary?.manageBookingsSummary?.no_of_sessions}
          noOfStudents={summary?.manageBookingsSummary?.no_of_students}
          noOfNonAttending={summary?.manageBookingsSummary?.no_of_non_attending}
        />

        <DataTable
          actions={<Button color="secondary" className="shrink-0" isDisabled={!hasSelection} onClick={() => setShowDeleteBookingSlotsConfirmationModal(true)}>{deleteButtonCaption}</Button>}
          columns={columns}
          currentPage={page}
          currentSortOrder={sortDescriptor}
          data={data?.manageBookings?.data}
          downloadOptions={downloadOptions}
          filters={filters}
          filtersSelectionMode="multiple"
          handleDownload={type => handleDownload(type)}
          handlePageChange={page => setPage(page)}
          handleRefetch={() => handleRefetchData()}
          handleRenderCell={(item, key) => renderCell(item, key)}
          handleSearchChange={searchTerm => handleSearchChange(searchTerm)}
          handleSearchClear={handleSearchClear}
          handleSortChange={sortDescriptor => handleSortChange(sortDescriptor)}
          handleToggleColumn={selectedColumns => handleToggleColumn(selectedColumns)}
          handleToggleFilter={selectedFilters => handleToggleFilter(selectedFilters)}
          loading={loading}
          isDownloading={isDownloading}
          isRefetching={refetching}
          noDataMessage={<NoDataTablePlaceholder title="No Bookings" message="No bookings found! Try clearing any filters." />}
          paginatorInfo={data?.manageBookings?.paginatorInfo}
          searchInputPlaceHolder="Search by parent / lesson / group / student / email"
          searchTerm={searchTerm}
          selectedColumns={selectedColumns}
          selectedFilters={selectedFilters}
          showDownloadButton={true}
          showRefetchButton={true}
        />

        <DeleteBookingSlotsConfirmationModal
          description="This action is irreversible. Are you sure you want to continue?"
          handleClose={() => setShowDeleteBookingSlotsConfirmationModal(false)}
          handleConfirm={(sendCancellationEmails, intro) => handleDeleteBookingSlots(sendCancellationEmails, intro)}
          handlePreviewEmail={(intro) => handlePreviewEmail(intro)}
          isProcessing={isDeleting}
          isLoadingPreview={isLoadingEmailPreview}
          show={showDeleteBookingSlotsConfirmationModal}
          title={`Are you sure you want to delete ${selectedIds.size} appointments?`}
        />

        <EmailPreviewModal 
          handleClose={() => setShowEmailPreviewModal(false)}
          html={emailPreview?.deleteBookingSlotsPreview?.html} 
          isLoading={isLoadingEmailPreview} 
          show={showEmailPreviewModal}
          title="Delete Appointments Email Preview"
        />
      </Page>
    </EventViewsTracker>
  )
}

export default ManageBookings