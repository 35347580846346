import { gql } from "@apollo/client"

const GET_SITE_DATA_SUBJECTS_XLS_DOWNLOAD_LINK = gql`
  {
    dataSubjectsXlsDownload {
      filename
      type
      url
      queued
    }
  }
`

export { GET_SITE_DATA_SUBJECTS_XLS_DOWNLOAD_LINK }
