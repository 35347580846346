import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"
import relativeTime from "dayjs/plugin/relativeTime"

dayjs.extend(duration)
dayjs.extend(relativeTime)

const humanReadableDuration = (date1, date2 = null) => {
  if (date2 === null) date2 = dayjs()

  const duration = date2.diff(date1)

  return dayjs.duration(duration).humanize()
}

export default humanReadableDuration
