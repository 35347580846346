import PropTypes from "prop-types"
import "./SquareLoader.css"

const Loader = ({ classNames = "", message = null, size = 200 }) => (
  <div className={`${classNames} text-center`} style={{ width: size, height: size }}>
    <div className="square-loader">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>

    {message && <p>{message}</p>}
  </div>
)

Loader.propTypes = {
  classNames: PropTypes.string,
  message: PropTypes.node,
  size: PropTypes.number,
}

export default Loader
