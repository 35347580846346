import { Fragment, memo, useContext } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { Scrollbars } from 'react-custom-scrollbars-2';
import { XMarkIcon } from "@heroicons/react/24/outline"
import { SidebarLinks } from "@components"
import { SidebarContext } from "@context"
import PropTypes from "prop-types"

const MobileSidebar = ({ className = "" }) => {
  const { sidebar, toogleSidebar } = useContext(SidebarContext)

  return (
    <Transition.Root show={sidebar.show} as={Fragment}>
      <Dialog as="div" className={`${className} relative z-30`} onClose={() => toogleSidebar()}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900/80" />
        </Transition.Child>

        <div className="fixed inset-0 flex">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button type="button" className="-m-2.5 p-2.5 outline-0" onClick={toogleSidebar}>
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>

              <div className="flex grow flex-col gap-y-5 bg-sidebar pt-24 pb-10">
                <Scrollbars>
                  <div className="px-6 pb-4">
                    <SidebarLinks />
                  </div>
                </Scrollbars>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

MobileSidebar.propTypes = {
  className: PropTypes.string,
}

export default memo(MobileSidebar)
