import { Button } from "@nextui-org/react"
import { ErrorPage2Column } from "@components"
import { getSbUrl } from "@helpers"
import { ReactComponent as AlarmSvg } from "@svgs/graphs/alarm.svg"

const SessionExpired = () => (
  <ErrorPage2Column
    errorCode={401}
    title="Session Expired"
    subTitle="Sorry, your session has expired. Please log in again."
    image={<AlarmSvg className="h-2/6 xl:h-3/6 w-auto max-w-[600px]" />}
  >
    <Button size="lg" variant="flat" className="bg-blue-500 mt-2 text-white" onClick={() => window.location.replace(getSbUrl("/apps/shared/signout.php"))}>
      Login
    </Button>
  </ErrorPage2Column>
)

export default SessionExpired
