import { useContext, useRef, useState } from "react"
import { useQuery } from "@apollo/client"
import { Button, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "@nextui-org/react"
import { EventTypeIcon, LineLoader, SimplePlaceholder, SearchInput } from "@components"
import { UserContext } from "@context"
import { mySqlToLocal } from "@helpers"
import { CircleStackIcon } from "@heroicons/react/24/outline"
import { GET_REPORT_TEACHER_ITINERARY } from "@graphql"
import PropTypes from "prop-types"

const TeacherItineraryList = ({ eventId, groupId }) => {
  const { user } = useContext(UserContext)
  const [searchTerm, setSearchTerm] = useState("")
  const searchInputRef = useRef(null)

  const { loading, error, data } = useQuery(GET_REPORT_TEACHER_ITINERARY, {
    variables: {
      group_ids: [groupId],
      event_id: eventId,
      fetchPolicy: "cache-first",
      searchTerm,
      clientId: user.client.id,
      page: 1,
      perPage: 5000,
    },
  })

  const handleClearSearch = () => {
    searchInputRef.current.value = ""
    setSearchTerm("")
  }

  if (error) return "error"

  return (
    <div>
      <SearchInput
        innerRef={searchInputRef}
        classNames="mb-3"
        debounceMilliSeconds={300}
        placeholder="Search by parent / student / class"
        handleChange={searchTerm => setSearchTerm(searchTerm)}
        handleClear={() => setSearchTerm("")}
      />

      {loading && <LineLoader message="Loading itinerary..." classNames="mt-8" />}

      {!loading && data.reportTeacherItinerary.data.length === 0 && (
        <SimplePlaceholder icon={<CircleStackIcon className="h-20 w-auto" />} title="No appointments found">
          <p className="mt-1 text-sm text-gray-500">
            You currently have no appointments.{" "}
            {searchTerm.length > 0 && (
              <span>
                Try a different search term.{" "}
                <Button className="mt-4" color="primary" fullWidth onClick={() => handleClearSearch()}>
                  Clear Search
                </Button>
              </span>
            )}
          </p>
        </SimplePlaceholder>
      )}

      {!loading && data.reportTeacherItinerary.data.length > 0 && (
        <Table isStriped isCompact removeWrapper aria-label="Teacher Itinerary">
          <TableHeader>
            <TableColumn></TableColumn>
            <TableColumn>Starts</TableColumn>
            <TableColumn>Ends</TableColumn>
            <TableColumn>Parent</TableColumn>
            <TableColumn>Students</TableColumn>
            <TableColumn>Class</TableColumn>
          </TableHeader>

          <TableBody>
            {data.reportTeacherItinerary.data.map(item => (
              <TableRow key={item.id}>
                <TableCell>
                  <EventTypeIcon
                    isVenue={item.type === "Venue"}
                    isPhone={item.type === "Telephone"}
                    isVC={item.type === "Video Call"}
                    size="sm"
                  />
                </TableCell>

                <TableCell>{mySqlToLocal(item.starts_at).format("HH:mm")}</TableCell>
                <TableCell>{mySqlToLocal(item.ends_at).format("HH:mm")}</TableCell>

                <TableCell>{item.parent}</TableCell>
                <TableCell>{item.students}</TableCell>
                <TableCell>{item.lesson}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  )
}

TeacherItineraryList.propTypes = {
  eventId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  groupId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

export default TeacherItineraryList
