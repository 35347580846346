import { CheckCircleIcon } from '@heroicons/react/24/outline'
import PropTypes from 'prop-types'

const SuccessPlaceholder = ({ children = null, title, message }) => (
  <div className="flex flex-col items-center text-center pt-6 pb-10">
    <CheckCircleIcon className="h-40 w-40 text-indigo-800" />
    <h1 className="text-2xl mt-1 font-semibold mb-2">{title}</h1>
    <p className="text-lg">{message}</p>

    <div>
      {children && children}
    </div>
  </div>
)

SuccessPlaceholder.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  children: PropTypes.node
}

export default SuccessPlaceholder