import React from "react"
import { Divider, SimplePlaceholder } from "@components"
import { AcademicCapIcon, CalendarDaysIcon, PresentationChartBarIcon, UserIcon } from "@heroicons/react/24/outline"
import { CheckCircleIcon, CubeTransparentIcon, QuestionMarkCircleIcon, XCircleIcon } from "@heroicons/react/24/solid"
import { mySqlToLocal } from "@helpers"
import dayjs from "dayjs"
import PropTypes from "prop-types"

const statusProps = {
  absent: {
    color: "bg-absent",
    icon: <XCircleIcon className="h-8 w-8 mr-1 text-absent" />,
  },

  attended: {
    color: "bg-attended",
    icon: <CheckCircleIcon className="h-8 w-8 mr-1 text-attended" />,
  },

  pending: {
    color: "bg-pending",
    icon: <CubeTransparentIcon className="h-8 w-8 mr-1 text-pending" />,
  },

  unknown: {
    color: "bg-unknown",
    icon: <QuestionMarkCircleIcon className="h-8 w-8 mr-1 text-unknown" />,
  },
}

const Schedule = ({ schedule }) => {
  const getStatusProps = status => {
    return statusProps[status.toLowerCase()]
  }

  const getNextAppointments = noOfAppointmentsToShow => {
    const now = dayjs()

    if (schedule.length < noOfAppointmentsToShow) {
      return schedule
    }

    let pendingAppointments = schedule.filter(item => mySqlToLocal(item.ends_at).isAfter(now))

    if (pendingAppointments.length < noOfAppointmentsToShow) {
      return schedule.slice(-noOfAppointmentsToShow)
    }

    return pendingAppointments
  }

  const appointments = getNextAppointments(5)

  if (appointments.length === 0) {
    return (
      <SimplePlaceholder icon={<CalendarDaysIcon className="h-40 w-auto text-indigo-500" />} title="No Bookings">
        <p className="mt-1 text-sm text-gray-500">
          Your next five appointments will appear here once they have been booked.
        </p>
      </SimplePlaceholder>
    )
  }

  return (
    <div>
      {appointments.map((item, index) => {
        const statusProps = getStatusProps(item.status)

        return (
          <React.Fragment key={item.id}>
            <div className="px-4 py-1">
              <div className="flex items-center mb-1 justify-between">
                <p className="text-blue-700 flex items-center">
                  {mySqlToLocal(item.starts_at).format("H:mm")} - {mySqlToLocal(item.ends_at).format("H:mm")} -{" "}
                  {mySqlToLocal(item.starts_at).format("ddd D MMM")}
                </p>
                {statusProps.icon}
              </div>

              <div className="grid grid-cols-24 items-center gap-3">
                <div className="col-span-6 items-baseline">
                  <div className="flex items-center">
                    <PresentationChartBarIcon className="h-8 w-8 min-w-max mr-1 text-blue-500" />
                    <p className="text-sm text-gray-500">{item.lesson}</p>
                  </div>
                </div>

                <div className="col-span-9">
                  <div className="flex items-center">
                    <AcademicCapIcon className="ml-2 h-8 w-8 min-w-max mr-1 text-blue-500" />
                    <p className="text-sm text-gray-500">{item.student}</p>
                  </div>
                </div>

                <div className="col-span-9">
                  <div className="flex items-center">
                    <UserIcon className="ml-2 h-8 w-8 min-w-max mr-1 text-blue-500" />
                    <p className="text-sm text-gray-500">{item.parent}</p>
                  </div>
                </div>
              </div>
            </div>

            {index < appointments.length - 1 && <Divider />}
          </React.Fragment>
        )
      })}
    </div>
  )
}

Schedule.propTypes = {
  schedule: PropTypes.array.isRequired,
}

export default Schedule
