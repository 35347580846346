import { useSetActiveSidebarLink } from "@hooks"
import { ComingSoon } from "@components"

const ManageEventCommunications = () => {
  useSetActiveSidebarLink("EVENT_MANAGE_EMAILS")

  return <ComingSoon />
}

export default ManageEventCommunications
