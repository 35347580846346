import { memo } from "react"
import { SidebarLinks } from "@components"
import PropTypes from "prop-types"

const DesktopSidebar = ({ className = "" }) => (
  <div className={`${className} min-h-screen xl:inset-y-0 xl:z-30 xl:w-80 xl:block h-full`}>
    <div className="py-12 flex grow -mb-4 flex-col gap-y-5 overflow-y-auto bg-sidebar px-6 pb-4">
      <SidebarLinks />
    </div>
  </div>
)

DesktopSidebar.propTypes = {
  className: PropTypes.string,
}

export default memo(DesktopSidebar)
