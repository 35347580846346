import { XCircleIcon } from '@heroicons/react/24/outline'
import PropTypes from 'prop-types'

const ErrorPlaceholder = ({ title, message, errors }) => (
  <div className="flex flex-col items-center pt-6 pb-10">
    <XCircleIcon className="h-40 w-40 text-indigo-800" />

    <div className="text-center">
      <h1 className="text-2xl mt-1 font-semibold mb-2">{title}</h1>
      <p className="text-lg">{message}</p>
    </div>

    <ul className="mt-3 w-full text-center">
      {Object.keys(errors).map((key) => {
        const error = errors[key]

        return (
          <li className="text-danger font-semibold" key={key}>
            {error}
          </li>
        )
      })}
    </ul>
  </div>
)

ErrorPlaceholder.propTypes = {
  errors: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
}

export default ErrorPlaceholder