import { gql } from "@apollo/client"

const GET_REPORT_COMMENTS_PDF_DOWNLOAD = gql`
  query ($event_id: ID!) {
    reportCommentsPdfs(event_id: $event_id) {
      filename
      type
      url
      queued
    }
  }
`

export { GET_REPORT_COMMENTS_PDF_DOWNLOAD }
