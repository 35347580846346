import React, { useContext, useEffect, useState } from "react"

import {
  DataTable,
  EllipsisDropdown,
  EventPageHeader,
  GuardianContactsList,
  NoDataTablePlaceholder,
  Page,
  ParentItineraryList,
} from "@components"

import { Chip, Progress } from "@nextui-org/react"
import { useLazyQuery, useQuery } from "@apollo/client"

import {
  GET_REPORT_PARENT_BOOKINGS,
  GET_REPORT_PARENT_BOOKINGS_SUMMARY,
  GET_REPORT_PARENT_ITINERARY_PDF_DOWNLOAD,
  GET_REPORT_PARENT_BOOKINGS_XLS_DOWNLOAD_LINK,
} from "@graphql"

import { clearCache, downloadFile, humanReadableDuration, mySqlToLocal } from "@helpers"
import { useSetActiveSidebarLink, useSetCurrentEvent } from "@hooks"
import { CalendarDaysIcon } from "@heroicons/react/24/outline"
import { ContextualSidebarContext, NotificationContext, UserContext } from "@context"
import { useParams } from "react-router-dom"
import dayjs from "dayjs"
import { columns, defaultColumns, downloadOptions, filters } from "./data"
import { Stats } from "./Stats"

const initialSelectedColumns =
  JSON.parse(window.localStorage.getItem("reports.event.parentBookings.columns")) ?? defaultColumns

const ParentBookingsReport = () => {
  const { event: eventId } = useParams()
  const { user } = useContext(UserContext)
  const { setContextualSidebar } = useContext(ContextualSidebarContext)
  const { setNotification } = useContext(NotificationContext)
  const [isDownloading, setIsDownloading] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedColumns, setSelectedColumns] = useState(initialSelectedColumns)
  const [selectedFilters, setSelectedFilters] = useState(["all"])
  const [sortDescriptor, setSortDescriptor] = useState({ column: "guardian", direction: "ascending" })
  const [refetching, setRefetching] = useState(false)
  const [page, setPage] = useState(1)
  const [perPage] = useState(50)

  useSetActiveSidebarLink("EVENT_REPORTS_PARENT_BOOKINGS", "EVENT_REPORTS")
  useSetCurrentEvent(eventId)

  useEffect(() => {
    window.localStorage.setItem("reports.event.parentBookings.columns", JSON.stringify(selectedColumns))
  }, [selectedColumns])

  const summaryData = useQuery(GET_REPORT_PARENT_BOOKINGS_SUMMARY, {
    variables: {
      event_id: eventId,
    },
    fetchPolicy: "cache-first",
    pollInterval: 900000,
  })

  const [getParentItineraryPdfsDownload] = useLazyQuery(GET_REPORT_PARENT_ITINERARY_PDF_DOWNLOAD, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data.reportParentItineraryPdfs.queued) {
        setNotification({
          title: "PDF Download Queued",
          content:
            "Your download has been queued. We'll send an email to the address you logged in with containing your download. This should take no longer than 5 minutes.",
          type: "success",
          open: true,
        })
      }

      if (!data.reportParentItineraryPdfs.queued) {
        downloadFile(data.reportParentItineraryPdfs.filename, data.reportParentItineraryPdfs.url)
      }

      setIsDownloading(false)
    },

    onError: ({ graphQLErrors }) => {
      if (graphQLErrors) {
        setNotification({ title: "Error", type: "danger", content: graphQLErrors[0].message, open: true })
        setIsDownloading(false)
      }
    },
  })

  const [getParentBookingsXlsDownloadLink] = useLazyQuery(GET_REPORT_PARENT_BOOKINGS_XLS_DOWNLOAD_LINK, {
    variables: { event_id: eventId },
    fetchPolicy: "network-only",
    onCompleted: data => {
      downloadFile(data.reportParentBookingsXls.filename, data.reportParentBookingsXls.url)
      setIsDownloading(false)
    },
  })

  const { error, data, loading, refetch } = useQuery(GET_REPORT_PARENT_BOOKINGS, {
    variables: {
      event_id: eventId,
      searchTerm,
      filters: selectedFilters,
      page,
      perPage,
      orderBy: [
        {
          column: sortDescriptor.column.toUpperCase(),
          order: sortDescriptor.direction === "ascending" ? "ASC" : "DESC",
        },
      ],
    },
    fetchPolicy: "cache-first",
    pollInterval: 900000,
  })

  const handleDownload = type => {
    if (type === "xls") {
      setIsDownloading(true)
      getParentBookingsXlsDownloadLink()
    }

    if (type === "pdf_all") {
      setIsDownloading(true)
      getParentItineraryPdfsDownload({ variables: { event_id: eventId, user_ids: null } })
    }
  }

  const handleShowGuardianDetails = item => {
    setContextualSidebar(prevState => ({
      ...prevState,
      open: true,
      title: item.guardian,
      subTitle: `Contact details are listed below.`,
      content: <GuardianContactsList mobile={item.mobile} misPhone={item.mis_phone} email={item.email} />,
      size: "lg",
    }))
  }

  const handleShowItinerary = item => {
    setContextualSidebar(prevState => ({
      ...prevState,
      open: true,
      title: `${item.guardian}`,
      subTitle: `${item.students}`,
      content: (
        <ParentItineraryList eventId={eventId} userIds={item.id} groupId={item.id} isVenue={item.type === "Venue"} />
      ),
      size: "3xl",
    }))
  }

  const refetchData = () => {
    setRefetching(true)
    summaryData.refetch()
    refetch().then(() => setRefetching(false))
  }

  const handleDownloadItinerary = item => {
    setIsDownloading(true)
    getParentItineraryPdfsDownload({ variables: { event_id: eventId, user_ids: [item.id] } })
  }

  const handleSearchClear = () => {
    setSearchTerm("")
    setPage(1)
  }

  const handleSearchChange = searchTerm => {
    setSearchTerm(searchTerm)
    setPage(1)
  }

  const handleSortChange = sortDescriptor => {
    setSortDescriptor(sortDescriptor)
    setPage(1)
  }

  const handleToggleColumn = selectedColumns => {
    setSelectedColumns(Object.keys(columns).filter(column => selectedColumns.has(column)))
  }

  const handleToggleFilter = selectedFilters => {
    setSelectedFilters([...selectedFilters])
  }

  const handleRenderCell = (item, column) => {
    const chipClasses = {
      base: "max-w-full w-full text-center",
      content: "w-full text-white font-semibold text-xs",
    }

    if (column === "actions") {
      let items = [{ id: 1, handleClick: () => handleShowGuardianDetails(item), caption: "View Contact Details" }]

      if (item.no_of_booked_slots > 0) {
        items = [
          ...items,
          { id: 2, handleClick: () => handleShowItinerary(item), caption: "View Itinerary" },
          { id: 3, handleClick: () => handleDownloadItinerary(item), caption: "Download Itinerary" },
        ]
      }

      return <EllipsisDropdown items={items} />
    }

    if (column === "last_active") {
      if (item.last_active === null) {
        return (
          <Chip color="danger" size="sm" classNames={chipClasses}>
            Never
          </Chip>
        )
      }

      const today = dayjs()
      const lastActive = mySqlToLocal(item.last_active)
      const diffInDays = today.diff(lastActive, "day")
      const color = diffInDays < 30 ? "success" : "warning"

      return (
        <Chip color={color} size="sm" classNames={chipClasses}>
          {humanReadableDuration(lastActive, today) + " ago"}
        </Chip>
      )
    }

    if (column === "status") {
      const color = item.status === "Attending" ? "success" : "danger"

      if (item.status === "-") return "-"

      return (
        <Chip color={color} size="sm" classNames={chipClasses}>
          {item.status}
        </Chip>
      )
    }

    if (column === "booked_slots_ratio") {
      const color = item.booked_slots_ratio < 50 ? "danger" : item.booked_slots_ratio <= 75 ? "warning" : "success"

      return (
        <Progress value={item.booked_slots_ratio} color={color} className="max-w-md" aria-label="Booked Percentage" />
      )
    }

    return item[column]
  }

  const handleRefetchData = () => {
    clearCache("reportParentBookings", { $event_id: eventId })
    refetchData()
  }

  if (error) return `Error! ${error.message}`

  return (
    <Page>
      <EventPageHeader
        title="Parent Bookings Report"
        icon={<CalendarDaysIcon className="page-header-icon" />}
        event={user?.currentEvent}
      />

      <Stats summary={summaryData} />

      <DataTable
        columns={columns}
        currentPage={page}
        currentSortOrder={sortDescriptor}
        data={data?.reportParentBookings?.data}
        downloadOptions={downloadOptions}
        filters={filters}
        filtersSelectionMode="single"
        handleDownload={type => handleDownload(type)}
        handlePageChange={page => setPage(page)}
        handleRenderCell={(item, key) => handleRenderCell(item, key)}
        handleRefetch={() => handleRefetchData()}
        handleSearchChange={searchTerm => handleSearchChange(searchTerm)}
        handleSearchClear={handleSearchClear}
        handleSortChange={sortDescriptor => handleSortChange(sortDescriptor)}
        handleToggleColumn={selectedColumns => handleToggleColumn(selectedColumns)}
        handleToggleFilter={selectedFilters => handleToggleFilter(selectedFilters)}
        loading={loading || refetching}
        isDownloading={isDownloading}
        isRefetching={refetching}
        noDataMessage={<NoDataTablePlaceholder title="No Data Found" message="No Data found! Try clearing any filters." />}
        paginatorInfo={data?.reportParentBookings?.paginatorInfo}
        searchInputPlaceHolder="Search by email / guardian / student"
        searchTerm={searchTerm}
        selectedColumns={selectedColumns}
        selectedFilters={selectedFilters}
        showDownloadButton={true}
        showRefetchButton={true}
      />
    </Page>
  )
}

export default ParentBookingsReport
