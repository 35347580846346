import { gql } from "@apollo/client"

const GET_REPORT_PARENT_ITINERARY_PDF_DOWNLOAD = gql`
  query ($event_id: ID!, $user_ids: [ID!]) {
    reportParentItineraryPdfs(event_id: $event_id, user_ids: $user_ids) {
      filename
      type
      url
      queued
    }
  }
`

export { GET_REPORT_PARENT_ITINERARY_PDF_DOWNLOAD }
