import { gql } from "@apollo/client"

const GET_REPORT_TEACHER_BOOKINGS_XLS_DOWNLOAD_LINK = gql`
  query ($event_id: ID!) {
    reportTeacherBookingsXls(event_id: $event_id) {
      filename
      type
      url
    }
  }
`

export { GET_REPORT_TEACHER_BOOKINGS_XLS_DOWNLOAD_LINK }
