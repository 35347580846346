import { gql } from "@apollo/client"
import { EVENT_FRAGMENT } from "@graphql"

const GET_EVENT = gql`
  ${EVENT_FRAGMENT}

  query ($event_id: ID!) {
    event(id: $event_id) {
      ...EventFragment
    }
  }
`

export { GET_EVENT }
