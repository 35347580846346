import { Button } from "@nextui-org/react"
import { ErrorPage2Column } from "@components"
import { getSbUrl } from "@helpers"
import { ReactComponent as AuthSvg } from "@svgs/graphs/auth.svg"

const AuthError = () => (
  <ErrorPage2Column
    errorCode={401}
    title="Authentication Error"
    subTitle="Sorry, we couldn't verify you. Please log in again."
    image={<AuthSvg className="h-2/6 xl:h-3/6 w-auto max-w-[600px]" />}
  >
    <Button size="lg" variant="flat" className="bg-blue-500 mt-2 text-white" onClick={() => window.location.replace(getSbUrl("/apps/shared/signout.php"))}>
      Login
    </Button>
  </ErrorPage2Column>
)

export default AuthError
