import { gql } from "@apollo/client"
import { EVENT_FRAGMENT } from "@graphql"

const GET_ME = gql`
  ${EVENT_FRAGMENT}

  query ($clientId: ID!) {
    me {
      id
      name
      isSuperUser
      defaultEvent {
        ...EventFragment
      }
    }

    client(id: $clientId) {
      id
      name
      menu_display_name
      data_age
      imported_at
      mis
      
      importType {
        id
        name
        code
      }
      
      unassignedStaffMember {
			  id
			  forename
			  surname
        user_id
		  }
    }

    clientUserRoles(client_id: $clientId) {
      id
      admin
      public
      guardian
      staff
      organiser
    }
  }
`

export default GET_ME
