import { Button } from "@nextui-org/react"
import PropTypes from "prop-types"

const Footer = ({ 
  currentStep, 
  handlePrevious = () => {}, 
  handleNext = () => {}, 
  isSubmitting = false, 
  nextCaption,
  previousCaption, 
  totalSteps 
}) => {
  const isFinalStep = currentStep === totalSteps
  return (
    <div className="flex items-center justify-between mt-2 mb-2">
      <Button className="mt-4" color="default" size="large" tabIndex="-1" onClick={(e) => handlePrevious()}>
        Previous Step: {previousCaption}
      </Button>

      <Button className="mt-4" color="secondary" size="large" onClick={() => handleNext()} isLoading={isSubmitting}>
        {!isFinalStep ? <span>Next Step: {nextCaption}</span> : <span>{nextCaption}</span>}
      </Button>
    </div>
  )
}

Footer.propTypes = {
  currentStep: PropTypes.number.isRequired,
  previousCaption: PropTypes.string.isRequired,
  nextCaption: PropTypes.string.isRequired,
  handlePrevious: PropTypes.func,
  handleNext: PropTypes.func,
  isSubmitting: PropTypes.bool,
  totalSteps: PropTypes.number.isRequired
}

export default Footer

