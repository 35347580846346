import { useEffect, useState } from "react";
import { Button } from "@nextui-org/react";
import { EventSessionForm } from "@components"
import { PropTypes } from "prop-types"
import ViewSessions from "./ViewSessions"

const Step4 = ({ handlePrevious, handleShowSteps, handleSubmitStage, formData }) => {
  const [mode, setMode] = useState("view")
  const [sessions, setSessions] = useState(formData.sessions)
  const [currentSession, setCurrentSession] = useState(null)

  useEffect(() => {
    handleShowSteps(mode === "view")
  }, [mode, handleShowSteps])

  const handleDeleteSession = (id) => {
    setSessions(sessions.filter((session) => session.id !== id))
  }

  const handleSubmit = () => {
    handleSubmitStage(sessions)
  }

  const handleUpdateSession = (data, mode) => {
    if (mode === "new") {
      setSessions((previousSessions) => ([...previousSessions, { ...data, id: new Date().valueOf() }]))
    }

    if (mode === "edit") {
      const session = { ...currentSession, ...data }
      const index = sessions.findIndex((session) => session.id === data.id)
      const newSessions = [...sessions]
      newSessions[index] = session
      setSessions(newSessions)
    }

    setMode("view")
  }

  if (["new", "edit"].includes(mode)) {
    return (
      <EventSessionForm
        handleCancel={() => setMode("view")} mode={mode}
        handleDeleteSession={(id) => handleDeleteSession(id)}
        handleSubmit={(data) => handleUpdateSession(data, mode)}
        session={mode === "edit" ? currentSession : undefined}
      >
        <div className="flex items-center justify-between mt-2 mb-2">
          <Button className="mt-4" color="default" size="large" onClick={() => setMode("view")} tabindex="-1">
            Cancel
          </Button>

          <Button className="mt-4" color="secondary" size="large" type="submit">
            {mode === "edit" ? "Save" : "Create Session"}
          </Button>
        </div>
      </EventSessionForm >
    )
  }

  return <ViewSessions
    handleDeleteSession={handleDeleteSession}
    handleSetMode={(mode) => setMode(mode)}
    handleSetCurrentSession={(session) => setCurrentSession(session)}
    handlePrevious={() => handlePrevious(sessions)}
    handleNext={() => handleSubmit()}
    sessions={sessions}
    showWizardFooter={true}
  />
}

Step4.propTypes = {
  handlePrevious: PropTypes.func.isRequired,
  handleShowSteps: PropTypes.func.isRequired,
  handleSubmitStage: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
}

export default Step4