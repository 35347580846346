import { ChartPlaceholder } from "@components"
import Chart from "react-apexcharts"
import PropTypes from "prop-types"

const BookingsByTypeRadial = ({
  bookingCountsByMeetingTypes,
  noOfParentBookings = 0,
  noOfStaffBookings = 0,
  noOfTotalBookedSlots,
}) => {
  const getPercentage = noOfSlots =>
    Math.round(noOfTotalBookedSlots === 0 ? 0 : (noOfSlots / noOfTotalBookedSlots) * 100, 0)

  const graph = {
    options: {
      legend: {
        position: "bottom",
        show: true,
      },

      chart: {
        id: "bookings-by-type-radial",
        toolbar: {
          show: false,
        },
      },

      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: "22px",
              offsetY: -10,
            },

            value: {
              fontSize: "28px",
              fontWeight: "bold",
              offsetY: 5,
            },

            total: {
              show: true,
              label: "Total",
              formatter: function (w) {
                return noOfTotalBookedSlots
              },
            },
          },
        },
      },

      labels: ["Parents", "Staff", ...bookingCountsByMeetingTypes.map(meetingType => meetingType.type)],

      dataLabels: {
        enabled: false,
      },
    },

    series: [
      getPercentage(noOfParentBookings),
      getPercentage(noOfStaffBookings),
      ...bookingCountsByMeetingTypes.map(meetingType => getPercentage(meetingType.no_of_booked_slots)),
    ],
  }

  if (noOfTotalBookedSlots === 0) {
    return (
      <ChartPlaceholder
        className="h-48"
        caption="Bookings by type will be displayed when data is available."
        type="radial"
      />
    )
  }

  return <Chart options={graph.options} series={graph.series} type="radialBar" height={350} />
}

BookingsByTypeRadial.propTypes = {
  bookingCountsByMeetingTypes: PropTypes.array.isRequired,
  noOfParentBookings: PropTypes.number,
  noOfStaffBookings: PropTypes.number,
  noOfTotalBookedSlots: PropTypes.number.isRequired,
}

export default BookingsByTypeRadial
