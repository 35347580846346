import React, { useContext, useState } from "react"
import { useQuery } from "@apollo/client"

import {
  AttendanceDonut,
  AvailabilityDonut,
  BookingsByDateLineGraph,
  BookingsByTypeRadial,
  CommunicationStats,
  DashboardCard,
  EventCheckList,
  EventPageHeader,
  EventViewsByBrowserDonut,
  EventViewsByDeviceDonut,
  EventViewsList,
  EventViewsByPlatformDonut,
  EventViewsByTypeDonut,
  HealthStats,
  Page,
  EventSettings,
  EventViewsTracker,
  RefreshButton,
  StatCardCompact,
  TopBookingTimes,
  TopTeachers,
  ViewStats,
} from "@components"

import {
  AcademicCapIcon,
  CalendarDaysIcon,
  DocumentCheckIcon,
  PresentationChartBarIcon,
  PresentationChartLineIcon,
  RectangleGroupIcon,
  Square3Stack3DIcon,
} from "@heroicons/react/24/outline"

import { ContextualSidebarContext, UserContext } from "@context"
import { GET_DASHBOARD_EVENT_STATS, GET_DASHBOARD_EVENT_VIEWS_STATS } from "@graphql"
import { useSetActiveSidebarLink, useSetCurrentEvent } from "@hooks"
import { useParams } from "react-router-dom"

const AdminDashboard = () => {
  const { event: eventId } = useParams()
  const { user } = useContext(UserContext)
  const { setContextualSidebar } = useContext(ContextualSidebarContext)
  const [isRefetching, setRefetching] = useState(false)

  useSetActiveSidebarLink("EVENT_DASHBOARD")
  useSetCurrentEvent(eventId)

  const eventViews = useQuery(GET_DASHBOARD_EVENT_VIEWS_STATS, {
    variables: { input: { event_id: eventId } },
    pollInterval: 120000,
    fetchPolicy: "network-first",
  })

  const eventStats = useQuery(GET_DASHBOARD_EVENT_STATS, {
    variables: { eventId },
    pollInterval: 900000,
    fetchPolicy: "network-first",
  })

  const getEventViewsSubTitle = filter => {
    if (filter === "TODAY") return "Unique user views seen today"
    if (filter === "LAST_3_DAYS") return "Unique user views seen in the last 3 days"
    if (filter === "LIVE") return "Live unique user views seen in the last 3 minutes"

    return "All unique user views"
  }

  const handleRefetch = () => {
    setRefetching(true)
    Promise.all([eventStats.refetch(), eventViews.refetch()]).then(() =>
      setRefetching(false),
    )
  }

  const handleShowEventViews = filter => {
    setContextualSidebar(prevState => ({
      ...prevState,
      open: true,
      title: "Event Views",
      subTitle: getEventViewsSubTitle(filter),
      content: <EventViewsList eventId={eventId} filter={filter} />,
      size: "lg",
    }))
  }

  const getBookingTypeStats = booking_counts => {
    const initialCounts = { no_of_parent_booked_slots: 0, no_of_staff_booked_slots: 0 }

    if (!booking_counts) return initialCounts

    return booking_counts.counts_by_date.reduce((totals, bookingCounts) => {
      return {
        no_of_parent_booked_slots: totals.no_of_parent_booked_slots + bookingCounts.no_of_parent_booked_slots,
        no_of_staff_booked_slots: totals.no_of_staff_booked_slots + bookingCounts.no_of_staff_booked_slots,
      }
    }, initialCounts)
  }

  const {
    booking_counts = null,
    communications = null,
    health = null,
    slots = null,
    summary = null,
    top_booking_times = null,
    top_teachers = [],
  } = eventStats?.data?.eventStats || {}

  const { views = null } = eventViews?.data || {}
  const { currentEvent } = user
  const bookingTypeStats = getBookingTypeStats(booking_counts)
  const loading = eventStats.loading || eventViews.loading

  return (
    <EventViewsTracker eventId={eventId}>
      <Page>
        <EventPageHeader
          title="Event Dashboard"
          icon={<PresentationChartLineIcon className="page-header-icon" />}
          event={user?.currentEvent}
          actions={<RefreshButton isLoading={isRefetching} handleClick={() => handleRefetch()} theme="dark" />}
        />

        <div className="my-5 grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 3xl:grid-cols-6">
          <StatCardCompact
            title="No of Bookings"
            stat={summary?.no_of_bookings || 0}
            icon={<CalendarDaysIcon className="h-10 w-10 text-white" aria-hidden="true" />}
          />

          <StatCardCompact
            title="No of Groups"
            stat={summary?.no_of_groups || 0}
            icon={<RectangleGroupIcon className="h-10 w-10 text-white" aria-hidden="true" />}
          />

          <StatCardCompact
            title="No of Invitations"
            stat={summary?.no_of_invitations || 0}
            icon={<DocumentCheckIcon className="h-10 w-10 text-white" aria-hidden="true" />}
          />

          <StatCardCompact
            title="No of Lessons"
            stat={summary?.no_of_lessons || 0}
            icon={<PresentationChartBarIcon className="h-10 w-10 text-white" aria-hidden="true" />}
          />

          <StatCardCompact
            title="No of Sessions"
            stat={summary?.no_of_sessions || 0}
            icon={<Square3Stack3DIcon className="h-10 w-10 text-white" aria-hidden="true" />}
          />

          <StatCardCompact
            title="No of Students"
            stat={summary?.no_of_students || 0}
            icon={<AcademicCapIcon className="h-10 w-10 text-white" aria-hidden="true" />}
          />
        </div>

        <div className="my-5 grid grid-cols-24 gap-y-5 sm:gap-5">
          <DashboardCard
            title="Event Settings"
            className="col-span-full md:col-span-12 lg:col-span-12 xl:col-span-12 3xl:col-span-8"
            loading={loading}
            body={
              <EventSettings
                startsAt={currentEvent?.start_date}
                endsAt={currentEvent?.end_date}
                maxBookingsPerStudent={currentEvent?.max_bookings_per_student}
                meetingTypes={currentEvent?.meetingTypes}
                noOfSessions={currentEvent?.sessions.length}
                sessions={currentEvent?.sessions}
                vcAutoDisconnect={currentEvent?.vc_auto_disconnect}
              />
            }
          />

          <DashboardCard
            title="Event Views"
            className="col-span-full md:col-span-12 lg:col-span-12 xl:col-span-12 3xl:col-span-8"
            loading={loading}
            body={
              <ViewStats
                noOfTotalViews={views?.total}
                noOfParentViews={views?.total_parents}
                noOfStaffViews={views?.total_staff}
                noOfTotalLiveViews={views?.total_live}
                noOfParentLiveViews={views?.total_parents_live}
                noOfStaffLiveViews={views?.total_staff_live}
                noOfTotalViewsToday={views?.total_today}
                noOfTotalViewsLast3Days={views?.total_last_3_days}
                handleClick={filter => handleShowEventViews(filter)}
              />
            }
          />

          <DashboardCard
            title="Communication Stats"
            className="col-span-full md:col-span-24 lg:col-span-12 xl:col-span-12 3xl:col-span-8"
            loading={loading}
            body={
              <CommunicationStats
                noOfBookingConfirmationsPending={communications?.no_of_booking_confirmations_pending}
                noOfBookingConfirmationsSent={communications?.no_of_booking_confirmations_sent}
                noOfInvitationsPending={communications?.no_of_invitations_pending}
                noOfInvitationsSent={communications?.no_of_invitations_sent}
                noOfComments={communications?.no_of_comments}
                noOfNotifications={communications?.no_of_notifications}
              />
            }
          />

          <DashboardCard
            title="Event Health"
            className="col-span-full md:col-span-24 lg:col-span-12 xl:col-span-12 3xl:col-span-8"
            loading={loading}
            body={
              <HealthStats
                activeAccountsRatio={health?.no_of_active_accounts_ratio}
                noOfDisabledSlots={slots?.no_of_disabled_slots}
                noOfActiveAccounts={health?.no_of_active_accounts}
                noOfOverSubscribedGroupsRatio={health?.no_of_over_subscribed_groups_ratio}
                noOfFullyBookedGroups={health?.no_of_fully_booked_groups}
                noOfFullyBookedGroupsRatio={health?.no_of_fully_booked_groups_ratio}
                noOfOverSubscribedGroups={health?.no_of_over_subscribed_groups}
                noOfUnactiveAccounts={health?.no_of_unactive_accounts}
                noOfUnassignedGroups={health?.no_of_unassigned_groups}
                noOfUnassignedGroupsRatio={health?.no_of_unassigned_groups_ratio}
                totalNoOfAccounts={summary?.no_of_invitations}
                totalNoOfGroups={summary?.no_of_groups}
                totalNoOfSlots={slots?.no_of_total_slots}
              />
            }
          />

          <DashboardCard
            title="Top 5 Fastest to 75% Booked"
            className="col-span-full md:col-span-24 lg:col-span-12 xl:col-span-12 3xl:col-span-8"
            loading={loading}
            body={<TopTeachers topTeachers={top_teachers} />}
         />

          <DashboardCard
            title="Top 6 Slot Times"
            className="col-span-full md:col-span-24 lg:col-span-12 xl:col-span-12 3xl:col-span-8"
            loading={loading}
            body={<TopBookingTimes topBookingTimes={top_booking_times} />}
          />

          <DashboardCard
            title="Event Checklist"
            className="col-span-full md:col-span-24 3xl:col-span-16"
            loading={loading}
            body={
              <EventCheckList
                eventCreated={summary?.no_of_invitations > 0}
                openToStaff={currentEvent?.open_staff}
                openToParents={currentEvent?.open_parents}
                noOfInvitationsSent={communications?.no_of_invitations_sent}
                noOfBookingConfirmationsSent={communications?.no_of_booking_confirmations_sent}
                noOfInvitationsPending={communications?.no_of_invitations_pending}
                noOfBookingConfirmationsPending={communications?.no_of_booking_confirmations_pending}
                noOfSessions={summary?.no_of_sessions}
                noOfStudents={summary?.no_of_students}
                bookingsDisabled={currentEvent?.disable_parent_bookings}
              />
            }
          />

          <DashboardCard
            title="Event Views By Type"
            className="col-span-full md:col-span-12 lg:col-span-12 xl:col-span-12 3xl:col-span-8"
            body={
              <EventViewsByTypeDonut
                noOfTotalViews={views?.total}
                noOfParentViews={views?.total_parents}
                noOfStaffViews={views?.total_staff}
              />
            }
          />

          <DashboardCard
            title="Event Views By Device"
            className="col-span-full md:col-span-12 lg:col-span-12 xl:col-span-12 3xl:col-span-8"
            body={
              <EventViewsByDeviceDonut
                labels={views?.by_device_type.map(device => device.name) || []}
                series={views?.by_device_type.map(device => device.qty) || []}
              />
            }
          />

          <DashboardCard
            title="Event Views By Browser"
            className="col-span-full md:col-span-12 lg:col-span-12 xl:col-span-12 3xl:col-span-8"
            body={
              <EventViewsByBrowserDonut
                labels={views?.by_browser_family.map(browser => browser.name) || []}
                series={views?.by_browser_family.map(browser => browser.qty) || []}
              />
            }
          />

          <DashboardCard
            title="Event Views By Platform"
            className="col-span-full md:col-span-12 lg:col-span-12 xl:col-span-12 3xl:col-span-8"
            body={
              <EventViewsByPlatformDonut
                labels={views?.by_platform_family.map(platform => platform.name) || []}
                series={views?.by_platform_family.map(platform => platform.qty) || []}
              />
            }
          />

          <DashboardCard
            title="Attendance Stats"
            className="col-span-full md:col-span-12 lg:col-span-12 xl:col-span-12 3xl:col-span-8"
            body={
              <AttendanceDonut
                noOfAbsentSlots={slots?.attendance?.no_of_absent_slots}
                noOfAttendedSlots={slots?.attendance?.no_of_attended_slots}
                noOfUnknownSlots={slots?.attendance?.no_of_unknown_slots}
                noOfTotalSlots={slots?.no_of_total_slots}
              />
            }
          />

          <DashboardCard
            title="Availability Stats"
            className="col-span-full md:col-span-12 lg:col-span-12 xl:col-span-12 3xl:col-span-8"
            body={
              <AvailabilityDonut
                noOfAvailableSlots={slots?.no_of_available_slots}
                noOfBookedSlots={slots?.no_of_booked_slots}
                noOfDisabledSlots={slots?.no_of_disabled_slots}
                noOfTotalSlots={slots?.no_of_total_slots}
              />
            }
          />

          <DashboardCard
            title="Bookings By Type"
            className="col-span-full md:col-span-10 lg:col-span-10 xl:col-span-10 2xl:col-span-10 3xl:col-span-8"
            body={
              <BookingsByTypeRadial
                bookingCountsByMeetingTypes={booking_counts?.counts_by_meeting_type || []}
                noOfParentBookings={bookingTypeStats?.no_of_parent_booked_slots || 0}
                noOfStaffBookings={bookingTypeStats?.no_of_staff_booked_slots || 0}
                noOfTotalBookedSlots={slots?.no_of_booked_slots || 0}
              />
            }
          />

          <DashboardCard
            className="col-span-full md:col-span-14 lg:col-span-14 xl:col-span-14 2xl:col-span-14 3xl:col-span-24"
            title="Bookings By Date"
            body={<BookingsByDateLineGraph data={booking_counts?.counts_by_date} />}
          />
        </div>
      </Page>
    </EventViewsTracker>
  )
}

export default AdminDashboard
