import { gql } from "@apollo/client"

const GET_DASHBOARD_EVENT_STATS = gql`
  query ($eventId: ID!) {
    eventStats(event_id: $eventId) {
      booking_counts {
        counts_by_meeting_type {
          type
          no_of_booked_slots
        }

        counts_by_date {
          date
          no_of_booked_slots
          no_of_parent_booked_slots
          no_of_staff_booked_slots
        }
      }

      communications {
        no_of_booking_confirmations_pending
        no_of_booking_confirmations_sent
        no_of_comments
        no_of_invitations_pending
        no_of_invitations_sent
        no_of_notifications
      }

      health {
        no_of_active_accounts
        no_of_unactive_accounts
        no_of_active_accounts_ratio
        no_of_over_subscribed_groups
        no_of_over_subscribed_groups_ratio
        no_of_fully_booked_groups
        no_of_fully_booked_groups_ratio
        no_of_unassigned_groups
        no_of_unassigned_groups_ratio
      }

      summary {
        no_of_bookings
        no_of_groups
        no_of_invitations
        no_of_lessons
        no_of_sessions
        no_of_students
      }

      top_booking_times {
        starts_at
        ends_at
        qty
      }

      top_teachers {
        book_time
        group_id
        no_of_booked_slots
        no_of_available_slots
        no_of_total_slots
        percentage_booked
        teacher
      }

      slots {
        attendance {
          attendance_ratio
          no_of_absent_slots
          no_of_attended_slots
          no_of_unknown_slots
        }

        no_of_total_slots
        no_of_available_slots
        no_of_booked_slots
        no_of_disabled_slots
        response_rate
      }
    }
  }
`

export { GET_DASHBOARD_EVENT_STATS }
