import PropTypes from "prop-types"

const PaginatorInfo = ({ paginatorInfo = { currentPage: 0, hasMorePages: false, lastPage: 0, perPage: 0, total: 0 } }) => {
  if (paginatorInfo.total === 0) return null

  const startRecord = paginatorInfo.currentPage * paginatorInfo.perPage - paginatorInfo.perPage + 1
  const endRecord = paginatorInfo.hasMorePages ? paginatorInfo.currentPage * paginatorInfo.perPage : paginatorInfo.total

  return (
    <div>
      <p className="text-sm text-gray-700">
        Showing <span className="font-medium">{startRecord}</span> to <span className="font-medium">{endRecord}</span>{" "}
        of <span className="font-medium">{paginatorInfo.total}</span> results
      </p>
    </div>
  )
}

PaginatorInfo.propTypes = {
  paginatorInfo: PropTypes.shape({
    currentPage: PropTypes.number,
    hasMorePages: PropTypes.bool,
    lastPage: PropTypes.number,
    perPage: PropTypes.number,
    total: PropTypes.number,
  }),
}

export default PaginatorInfo
