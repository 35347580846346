import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from "@nextui-org/react"
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline"
import PropTypes from "prop-types"

const EllipsisDropdown = ({ items }) => (
  <div className="relative flex justify-end items-center gap-2">
    <Dropdown>
      <DropdownTrigger>
        <Button isIconOnly size="sm" variant="light">
          <EllipsisVerticalIcon className="text-default-300" />
        </Button>
      </DropdownTrigger>

      <DropdownMenu aria-label="Actions">
        {items.map(item => (
          <DropdownItem key={item.id} isDisabled={item.isDisabled} onClick={() => item.handleClick()}>
            {item.caption}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  </div>
)

EllipsisDropdown.propTypes = {
  items: PropTypes.array,
}

export default EllipsisDropdown
