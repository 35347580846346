import { EventBasicInfoForm } from "@components";
import { PropTypes } from "prop-types";

const Step1 = ({ handlePrevious, handleSubmitStage, formData }) => (
  <EventBasicInfoForm
    title={formData.title}
    description={formData.description}
    handlePrevious={handlePrevious}
    handleSubmit={handleSubmitStage}
    showWizardFooter={true}
  />
)

Step1.propTypes = {
  handlePrevious: PropTypes.func.isRequired,
  handleSubmitStage: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
}

export default Step1
