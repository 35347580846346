import { SBLogo } from '@components';
import PropTypes from 'prop-types';

const ErrorPage2Column = ({ errorCode, image, title, subTitle, children = null }) => (
  <div className="h-screen bg-gradient-to-r from-blue-800 to-indigo-900 gap-y-3 text-white text-center">
    <div className="grid grid-cols-24 min-h-screen">
      <div className="col-span-24 px-8 md:col-span-12 h-full bg-indigo-900 bg-opacity-50 flex flex-col items-center justify-center gap-y-4">
        <SBLogo className="h-40 w-auto" />
        <h1 className="text-8xl font-bold opacity-70">{errorCode}</h1>
        <h2 className="text-4xl font-semibold opacity-70">{title}</h2>
        <p className="text-xl">{subTitle}</p>
        {children && children}
      </div>

      <div className="md:col-span-12 md:flex items-center justify-center hidden px-8">
        {image}
      </div>
    </div>
  </div>
)

ErrorPage2Column.propTypes = {
  children: PropTypes.node,
  errorCode: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.node.isRequired,
}

export default ErrorPage2Column