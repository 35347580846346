import { useRef, useState } from "react"
import { EventStatusBadge, SimplePlaceholder, SearchInput } from "@components"
import { formattedDateRange, mySqlToLocal } from "@helpers"
import { CircleStackIcon, Square3Stack3DIcon } from "@heroicons/react/24/outline"
import { Button } from "@nextui-org/react"
import PropTypes from "prop-types"

const SessionList = ({ handleChangeSession, sessions }) => {
  const [searchTerm, setSearchTerm] = useState("")
  const searchInputRef = useRef(null)

  const handleClearSearch = () => {
    searchInputRef.current.parentNode.querySelector('[data-slot="clear-button"]').click()
    setSearchTerm("")
  }

  const handleSearchChange = searchTerm => {
    setSearchTerm(searchTerm);  
  }

  const filteredSessions = sessions.filter(session => session.title.toLowerCase().includes(searchTerm.toLowerCase()))
  
  return (
    <div>
      <SearchInput
        innerRef={searchInputRef}
        classNames="mb-3"
        debounceMilliSeconds={0}
        placeholder="Search by title"
        handleChange={searchTerm => handleSearchChange(searchTerm)}
        handleClear={() => setSearchTerm("")}
      />

      {filteredSessions.length === 0 && (
        <SimplePlaceholder icon={<CircleStackIcon className="h-20 w-auto" />} title="No sessions found">
          <div>
            <p className="mt-1 text-sm text-gray-500">
              No sessions found. Try a different search term.
            </p>

            <Button className="mt-4" color="secondary" fullWidth onClick={() => handleClearSearch()}>
              Clear Search
            </Button>
          </div>
        </SimplePlaceholder>
      )}

      <ul className="divide-y divide-gray-100">
        {filteredSessions.map(session => {
          const startDate = mySqlToLocal(session.starts_at)
          const endDate = mySqlToLocal(session.ends_at)

          return (
            <li key={session.id} className="cursor-pointer items-center flex justify-between" onClick={() => handleChangeSession(session)}>
              <div className="flex items-center py-2">
                <Square3Stack3DIcon className="h-8 w-8 mr-2 text-indigo-600" />
                
                <div className="flex flex-col">
                  <h1 className="text-md font-semibold leading-6 text-gray-900">{session.title}</h1>
                  <p className="text-sm">{formattedDateRange(startDate, endDate)}</p>
                </div>
              </div>
              
              <EventStatusBadge 
                size="sm" 
                startDate={session.starts_at} 
                endDate={session.ends_at} 
              />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

SessionList.propTypes = {
  handleChangeSession: PropTypes.func.isRequired,
  sessions: PropTypes.array.isRequired,
}

export default SessionList
