import { Chip } from "@nextui-org/react"
import { badgeStylesBySize, eventStatus } from "@helpers"
import { ArchiveBoxArrowDownIcon, ArrowUpCircleIcon, CheckIcon, Cog8ToothIcon } from "@heroicons/react/24/outline"
import PropTypes from "prop-types"

const EventStatusBadge = ({ size = "md", startDate, endDate }) => {
  const status = eventStatus(startDate, endDate)
  const badgeStyles = badgeStylesBySize(size)

  const settings = {
    setup: {
      icon: <Cog8ToothIcon className={`text-white mr-1 ${badgeStyles.icon}`} />,
      color: "bg-red-400",
      caption: "In Setup",
    },

    current: {
      icon: <CheckIcon className={`text-white mr-1 ${badgeStyles.icon}`} />,
      color: "bg-green-400",
      caption: "Current",
    },

    expired: {
      icon: <ArchiveBoxArrowDownIcon className={`text-white mr-1 ${badgeStyles.icon}`} />,
      color: "bg-purple-400",
      caption: "Expired",
    },

    upcoming: {
      icon: <ArrowUpCircleIcon className={`text-white mr-1 ${badgeStyles.icon}`} />,
      color: "bg-orange-400",
      caption: "Upcoming",
    },
  }

  const styles = settings[status.toLowerCase()]

  return (
    <Chip size={size} classNames={{ base: `w-100 max-w-none ${styles.color}` }}>
      <span className={`flex items-center justify-center text-white px-2 ${badgeStyles.font} font-semibold`}>
        {styles.icon}
        {styles.caption}
      </span>
    </Chip>
  )
}

EventStatusBadge.propTypes = {
  size: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
}

export default EventStatusBadge
