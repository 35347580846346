import { Divider, Skeleton } from '@nextui-org/react'

const DashboardSkeleton = () => {
  return (
    Array(5).fill(null).map((e, i) => (

      <div key={i}>
        <div className="flex items-center" radius="lg">
          <div>
            <Skeleton className="flex rounded-full w-12 h-12" />
          </div>

          <div className="w-full p-4 space-y-3">
            <Skeleton className="rounded-lg">
              <div className="h-5 rounded-lg bg-default-300"></div>
            </Skeleton>

            <Skeleton className="rounded-lg">
              <div className="h-3 rounded-lg bg-default-200"></div>
            </Skeleton>
          </div>
        </div>

        {(i !== 4) && <Divider />}
      </div>
    )))
}

export default DashboardSkeleton