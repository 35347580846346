import { gql } from "@apollo/client"
import { EVENT_FRAGMENT } from "../../fragments"

const CREATE_EVENT_MUTATION = gql`
  ${EVENT_FRAGMENT}
  
  mutation createEvent($input: CreateEventInput!) {
    createEvent(input: $input) {
      ...EventFragment
    }
  }
`

export default CREATE_EVENT_MUTATION