import { gql } from "@apollo/client"

const GET_EVENT_CREATION_WIZARD = gql`
  query client($id: ID) {
    meetingTypes {
      id
      name
      type
    }

    client(id: $id) {
      id
      
      venues {
        id
        name
      }
    }
  }
`

export { GET_EVENT_CREATION_WIZARD }
