import { ChartPlaceholder } from "@components"
import Chart from "react-apexcharts"
import PropTypes from "prop-types"
import { donutOptions } from "../Options"

const EventViewsByTypeDonut = ({ noOfTotalViews = 0, noOfParentViews = 0, noOfStaffViews = 0 }) => {
  const graph = {
    options: {
      ...donutOptions,
      chart: { ...donutOptions.chart, id: "event-views-by-type-donut" },
      labels: ["Parent", "Staff"],
    },

    series: [noOfParentViews, noOfStaffViews],
  }

  if (noOfTotalViews === 0) {
    return (
      <ChartPlaceholder
        className="h-48"
        caption="Event views by staff / parents will be displayed when data is available."
        type="donut"
      />
    )
  }

  return <Chart options={graph.options} series={graph.series} type="donut" height={350} />
}

EventViewsByTypeDonut.propTypes = {
  noOfParentViews: PropTypes.number,
  noOfStaffViews: PropTypes.number,
  noOfTotalViews: PropTypes.number,
}

export default EventViewsByTypeDonut
