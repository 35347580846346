import { gql } from "@apollo/client"

const GET_SESSION_GROUP_LESSON_BOOKING_COUNTS = gql`
  query ($sessionId: ID!) {
    session(id: $sessionId) {
      id
    
		  groupLessonBookingCounts {
        id
			  group_id
				lesson_id
				no_of_bookings
        booking_slot_ids
			}
    }
  }
`

export { GET_SESSION_GROUP_LESSON_BOOKING_COUNTS }