import dayjs from "dayjs"
import { mySqlToLocal } from "@helpers"
import isBetween from "dayjs/plugin/isBetween"

dayjs.extend(isBetween)

const eventStatus = (starts, ends) => {
  if (starts === null || ends === null) return "Setup"

  const startDate = mySqlToLocal(starts)
  const endDate = mySqlToLocal(ends)
  const today = dayjs()

  if (today.isBetween(startDate, endDate, "day", "[]")) return "Current"

  if (today.isBefore(startDate, "day")) return "Upcoming"

  if (today.isAfter(endDate, "day")) return "Expired"
}

export default eventStatus
