import { useContext, useEffect } from "react"
import { UserContext } from "@context"

const useSetCurrentEvent = (eventId) => {
  const { setCurrentEvent } = useContext(UserContext)

  useEffect(() => {
    setCurrentEvent(eventId, [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId])
}

export default useSetCurrentEvent