import { gql } from "@apollo/client"

// Current thinking is that this fragment shouldn't go any further in depth than session. 
// The manage event page will load session data separately to improve performance.
const EVENT_FRAGMENT = gql`
  fragment EventFragment on Event {
    id
    title
    description
    start_date
    end_date
    event_setup_completed
    max_bookings_per_student
    disable_parent_bookings
    vc_auto_disconnect
    open_parents
    open_staff

    meetingTypes {
      id
      name
      type
    }

    sessions {
      id
      title
      starts_at
      ends_at
      slot_duration
      slot_gap
    }
  }
`

export default EVENT_FRAGMENT