import React from "react"
import { ReactComponent as BarChartSvg } from "@svgs/graphs/bar.svg"
import { ReactComponent as DonutChartSvg } from "@svgs/graphs/donut.svg"
import { ReactComponent as LineChartSvg } from "@svgs/graphs/line.svg"
import { ReactComponent as PieChartSvg } from "@svgs/graphs/pie.svg"
import { ReactComponent as RadialChartSvg } from "@svgs/graphs/radial.svg"
import PropTypes from "prop-types"

const ChartPlaceholder = ({ caption = "", className = "", type }) => (
  <React.Fragment>
    {type === "bar" && <BarChartSvg className={`mb-4 ${className}`} />}
    {type === "donut" && <DonutChartSvg className={`mb-4 ${className}`} />}
    {type === "line" && <LineChartSvg className={`mb-4 ${className}`} />}
    {type === "pie" && <PieChartSvg className={`mb-4 ${className}`} />}
    {type === "radial" && <RadialChartSvg className={`mb-4 ${className}`} />}
    {caption && <p className="text-center text-md text-blue-700">{caption}</p>}
  </React.Fragment>
)

ChartPlaceholder.propTypes = {
  caption: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
}

export default ChartPlaceholder
