import { memo, useContext } from "react"
import { ContextualSidebarContext, ModalContext, SidebarContext, UserContext } from "@context"
import { EventSetupWizard, EventList, SidebarLink } from "@components"
import { hasFullLiveAccess } from "@helpers"
import getLinks from "./links"

const SidebarLinks = () => {
  const { user } = useContext(UserContext)
  const { setContextualSidebar } = useContext(ContextualSidebarContext)
  const { sidebar } = useContext(SidebarContext)
  const { setModal } = useContext(ModalContext)
  const links = getLinks(user?.currentEvent?.id, user)

  const handleCreateEvents = () => {
    setModal(prevState => ({ ...prevState, isOpen: true, title: "Event Setup Wizard", content: <EventSetupWizard previousLink={sidebar.activeLink} /> }))
  }

  const handleShowEvents = () => {
    setContextualSidebar(prevState => ({
      ...prevState,
      open: true,
      title: "Your Events",
      subTitle: `All your events are listed below.`,
      content: <EventList />,
      size: "lg",
    }))
  }

  const clickEvents = { "SWITCH_EVENT": handleShowEvents, "CREATE_EVENT": hasFullLiveAccess() ? handleCreateEvents : null }

  return (
    <nav className="flex flex-1 flex-col">
      <ul className="flex flex-1 flex-col gap-y-7">
        {links.map(section => {
          if (section.isAdminOnly && !user.clientUserRoles.admin) return null

          return (
            <li key={section.id}>
              <div className="mb-3 text-md font-semibold leading-6 text-white opacity-80">{section.caption}</div>

              <ul className="-mx-2 space-y-1">
                {section.links.map(link => {
                  if (link.isAdmin && !user.clientUserRoles.admin) return null

                  return (
                    <li key={link.id}>
                      <SidebarLink
                        expandable={link.expandable}
                        href={link.href}
                        target={link.target}
                        icon={link.icon}
                        id={link.id}
                        subMenu={link.subMenu}
                        type={link.type}
                        updateActiveLinkOnClick={link.updateActiveLinkOnClick}
                        handleClick={clickEvents[link.id] || null}
                      >
                        {link.caption}
                      </SidebarLink>
                    </li>
                  )
                })}
              </ul>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export default memo(SidebarLinks)
