import React from "react"
import { CardStat, Divider, SimplePlaceholder } from "@components"

import {
  CalendarDaysIcon,
  CheckBadgeIcon,
  CheckCircleIcon,
  RocketLaunchIcon,
  StarIcon,
  TrophyIcon,
} from "@heroicons/react/24/outline"

import { mySqlToLocal } from "@helpers"
import PropTypes from "prop-types"

const iconsByIndex = {
  0: TrophyIcon,
  1: StarIcon,
  2: RocketLaunchIcon,
  3: CheckBadgeIcon,
  4: CheckCircleIcon,
  5: CalendarDaysIcon,
}

const TopBookingTimes = ({ topBookingTimes = [] }) => {
  if (topBookingTimes.length === 0) {
    return (
      <SimplePlaceholder icon={<CalendarDaysIcon className="h-40 w-auto text-indigo-500" />} title="No Stats Collated">
        <p className="mt-1 text-sm text-gray-500">
          The top 6 most popular slot times will appear here once bookings are made.
        </p>
      </SimplePlaceholder>
    )
  }

  return (
    <div>
      {topBookingTimes.map((slot, index) => (
        <div key={index}>
          <CardStat
            title={mySqlToLocal(slot.starts_at).format("H:mm - dddd D MMMM")}
            icon={iconsByIndex[index]}
            stat={`${slot.qty}`}
            meta={`
              ${slot.qty} bookings - 
              Starts ${mySqlToLocal(slot.starts_at).format("H:mm")} | 
              Ends ${mySqlToLocal(slot.ends_at).format("H:mm")}
            `}
          ></CardStat>

          {index < topBookingTimes.length - 1 && <Divider />}
        </div>
      ))}
    </div>
  )
}

TopBookingTimes.propTypes = {
  topBookingTimes: PropTypes.array,
}

export default TopBookingTimes
