import { useQuery } from "@apollo/client"
import { Chip } from "@nextui-org/react"
import { CircleStackIcon } from "@heroicons/react/24/outline"
import { LineLoader, SimplePlaceholder } from "@components"
import { GET_STUDENT_LESSONS } from "@graphql"
import { staffFullname } from "@helpers"
import PropTypes from "prop-types"

const StudentLessonsList = ({ student }) => {
  const { loading, error, data } = useQuery(GET_STUDENT_LESSONS, {
    variables: {
      fetchPolicy: "cache-first",
      id: student.id,
    },
  })

  if (loading) return <LineLoader message="Loading lessons..." classNames="mt-8" />

  if (error) return "error"

  if (data.student.lessons.length === 0) {
    return (
      <SimplePlaceholder icon={<CircleStackIcon className="h-20 w-auto" />} title="No lessons found for this student">
        <p className="mt-1 text-sm text-gray-500">
          If you believe this student should have associated lessons then please check your MIS and Xporter integration.
        </p>
      </SimplePlaceholder>
    )
  }

  return (
    <ul className="divide-y divide-gray-100">
      {data.student.lessons.map(lesson => {
        return (
          <li key={lesson.id} className="flex justify-between py-2">
            {lesson.name} <Chip color="primary">{staffFullname(lesson.teacher)}</Chip>
          </li>
        )
      })}
    </ul>
  )
}

StudentLessonsList.propTypes = {
  student: PropTypes.object.isRequired,
}

export default StudentLessonsList
