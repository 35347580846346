import React, {useState} from "react"
import { CustomModal, GuardianStudentDetails, NoDataPlaceholder } from "@components"
import { guardianFullname, studentFullname } from "@helpers"
import { ReactComponent as DataSvg } from "@svgs/data.svg"
import { Button, Checkbox, ModalContent, ModalHeader, ModalBody, ModalFooter, Spinner } from "@nextui-org/react"
import PropTypes from "prop-types"

const StudentGuardiansSelectorModal = ({
  guardians = [],
  handleClose,
  handleSave,
  isLoading = false,
  isSaving = false,
  selectedGuardianIds = [],
  show = false,
  student,
  title = "Select the guardians you would like to add to the student"
}) => {
  const [selectedIds, setSelectedIds] = useState([])
  const isDirty = selectedIds.length > 0
 
  const handleToggleGuardian = (guardianId, isSelected) => {
    (isSelected)
      ? setSelectedIds([...selectedIds, guardianId])
      : setSelectedIds(selectedIds.filter(id => id !== guardianId))
  }
  
  return (
    <CustomModal  
      isOpen={show}
      size="3xl"
      handleClose={() => handleClose()}
      scrollBehavior="inside"
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader>Guardians for {studentFullname(student)}</ModalHeader>
          
            <ModalBody>
              {isLoading && (
                <Spinner className="p-5" color="primary" label="Please wait loading guardians..." />
              )}

              {guardians.length > 0 && <p className="mt-2 -mb-2">{title}</p>}
                
              {!isLoading && guardians.length === 0 && (
                <NoDataPlaceholder className="mt-3" title="No guardians found" message="There are no guardians available to add for this student. Please check your MIS.">
                  <DataSvg className="max-w-[150px] mb-2" />
                </NoDataPlaceholder>
              )}

              {guardians.length > 0 && (
                <div className="flex flex-col">
                  {guardians.map(guardian => {
                    const isSelectedPreviously = selectedGuardianIds.includes(guardian.id)
                    const isSelected = isSelectedPreviously || selectedIds.includes(guardian.id)

                    return (
                      <div className="flex justify-between py-3 border-b border-slate-200 align-center">
                        <div className="flex gap-x-2">
                          <Checkbox key={guardian.id} isSelected={isSelected} onValueChange={(isSelected) => handleToggleGuardian(guardian.id, isSelected)} isDisabled={isSelectedPreviously}></Checkbox>
                          
                          <div>
                            <p className="text-lg font-semibold">{guardianFullname(guardian)}</p>
                            <p className="text-gray-700">{guardian.user.email}</p>
                          </div>
                        </div>

                        <GuardianStudentDetails contactSequence={guardian.pivot.contact_sequence} livesWith={guardian.pivot.lives_with} legalGuardian={guardian.pivot.legal_guardian} />
                      </div>
                    )
                  })}
                </div>
              )}
            </ModalBody>

            <ModalFooter>
              <div className="flex justify-between w-full">
                <Button onClick={() => handleClose()} tabIndex={-1}>Cancel</Button>
                <Button color="secondary" onClick={() => handleSave(student.id, selectedIds)} tabIndex={-1} isDisabled={!isDirty} isLoading={isSaving}>Add Guardian(s)</Button>
              </div>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </CustomModal>
  )
}

PropTypes.StudentGuardiansSelectorModal = {
  guardians: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isSaving: PropTypes.bool,
  selectedGuardianIds: PropTypes.array,
  show: PropTypes.bool,
  student: PropTypes.object.isRequired,
  title: PropTypes.string
}

export default StudentGuardiansSelectorModal