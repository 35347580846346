import React from "react"
import { CardStat, Divider } from "@components"
import { BellAlertIcon, CalendarDaysIcon, ChatBubbleLeftRightIcon } from "@heroicons/react/24/outline"
import { truncate } from "lodash"
import PropTypes from "prop-types"

const EventSummary = ({
  description,
  firstAppointmentDateTime = "N/A",
  lastAppointmentDateTime = "N/A",
  noOfComments = 0,
  noOfCommentsRead = 0,
  noOfCommentsUnread = 0,
  noOfNotifications = 0,
  noOfNotificationsRead = 0,
  noOfNotificationsUnread = 0,
}) => {
  return (
    <React.Fragment>
      <h3 className="text-md mb-1 px-4 text-blue-700">Description</h3>
      <p className="px-4">{truncate(description, { length: 250 })}</p>
      <Divider />

      <div className="flex items-center justify-between flex-wrap gap-y-3">
        <CardStat icon={CalendarDaysIcon} title="First Appointment" stat={firstAppointmentDateTime} />
        <CardStat icon={CalendarDaysIcon} title="Last Appointment" stat={lastAppointmentDateTime} />
      </div>

      <Divider />

      <CardStat
        title="Comments"
        icon={ChatBubbleLeftRightIcon}
        stat={`${noOfComments}`}
        meta={`${noOfCommentsRead} read | ${noOfCommentsUnread} unread`}
      />

      <Divider />

      <CardStat
        title="Notifications"
        icon={BellAlertIcon}
        stat={`${noOfNotifications}`}
        meta={`${noOfNotificationsRead} read | ${noOfNotificationsUnread} unread`}
      />
    </React.Fragment>
  )
}

EventSummary.propTypes = {
  description: PropTypes.string.isRequired,
  firstAppointmentDateTime: PropTypes.string,
  lastAppointmentDateTime: PropTypes.string,
  noOfComments: PropTypes.number,
  noOfCommentsRead: PropTypes.number,
  noOfCommentsUnread: PropTypes.number,
  noOfNotifications: PropTypes.number,
  noOfNotificationsRead: PropTypes.number,
  noOfNotificationsUnread: PropTypes.number,
}

export default EventSummary
