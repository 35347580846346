import { gql } from "@apollo/client"

const GET_DELETE_BOOKING_SLOTS_EMAIL_PREVIEW = gql`
  query deleteBookingSlotsPreview($input: DeleteBookingSlotsPreviewInput!) {
    deleteBookingSlotsPreview(input: $input) {
			html
		  text
		}
	}
`

export { GET_DELETE_BOOKING_SLOTS_EMAIL_PREVIEW }