import { WizardFooter } from "@components";
import { useForm } from "react-hook-form";
import { Divider, Input, Textarea } from "@nextui-org/react";
import PropTypes from "prop-types";

const EventBasicInfo = ({ title = "", description = "", handlePrevious = () => {}, handleSubmit = () => {}, showWizardFooter = false }) => {
  const { register, handleSubmit: handleValidateForm, formState: { errors }, getValues } = useForm({
    defaultValues: {
      title: title,
      description: description
    }
  })

  return (
    <form id="basic-info-form" className="flex flex-col gap-y-4" noValidate>
      <Input
        {...register("title", { required: "You must supply a title for your event", minLength: { value: 3, message: "Title must be at least 3 characters" } })}
        autoFocus
        isRequired
        type="text"
        label="Event Title"
        placeholder="Enter a title for your event"
        labelPlacement="outside"
        defaultValue={title}
        isInvalid={errors.hasOwnProperty("title")}
        errorMessage={errors?.title?.message}
      />

      <Divider />

      <Textarea
        {...register("description", { required: "You must supply a short description for your event", minLength: { value: 25, message: "Description must be at least 25 characters" } })}
        isRequired
        label="Description"
        labelPlacement="outside"
        minRows={20}
        placeholder="Enter your description"
        defaultValue={description}
        isInvalid={errors.hasOwnProperty("description")}
        errorMessage={errors?.description?.message}
      />

      {showWizardFooter && (
        <WizardFooter currentStep={1} totalSteps={5} previousCaption="Cancel Setup" nextCaption="Meeting Types" handlePrevious={() => handlePrevious(getValues())} handleNext={handleValidateForm((data) => handleSubmit(data))} />
      )}

    </form>
  )
}

EventBasicInfo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  handlePrevious: PropTypes.func,
  handleSubmit: PropTypes.func
}

export default EventBasicInfo