import { gql } from "@apollo/client"

const GET_DASHBOARD_EVENT_VIEWS = gql`
  query ($input: EventUserViewsInput!, $page: Int!, $perPage: Int!) {
    eventUserViews(input: $input, page: $page, first: $perPage) {
      data {
        user_id
        name
        email
        type
        platform_family
        device_type
        browser_family
        last_viewed_at
      }

      paginatorInfo {
        total
        currentPage
        hasMorePages
        lastPage
        perPage
      }
    }
  }
`

export { GET_DASHBOARD_EVENT_VIEWS }
