import { gql } from "@apollo/client"

const GET_REPORT_PARENT_BOOKINGS_SUMMARY = gql`
  query ($event_id: ID!) {
    reportParentBookingsSummary(event_id: $event_id) {
      total_invites
      total_bookings
      active_accounts
      cancelled
      not_active_accounts
      response_rate
    }
  }
`

export { GET_REPORT_PARENT_BOOKINGS_SUMMARY }
