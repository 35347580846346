import { Divider, Input } from "@nextui-org/react";
import { Controller, useForm } from "react-hook-form";
import { CustomTimePicker, ErrorMessage, Required } from "@components"
import { DayPicker } from 'react-day-picker';
import { PropTypes } from "prop-types"
import 'react-day-picker/dist/style.css';

const EventSessionForm = ({ 
  children, 
  handleSubmit, 
  session = {
    id: null,
    title: "",
    start_date: null,
    start_time: null,
    end_time: null,
    slot_duration: 5,
    slot_gap: 0
  }
}) => {
  const { control, getValues, handleSubmit: handleValidateForm, formState: { errors }, register } = useForm({
    defaultValues: {
      id: session.id,
      title: session.title,
      start_date: session.start_date,
      start_time: session.start_time,
      end_time: session.end_time,
      slot_duration: session.slot_duration,
      slot_gap: session.slot_gap
    }
  })

  const validateTimes = (values) => {
    if (values.end_time && values.start_time && values.end_time.isSameOrBefore(values.start_time)) {
      return "The end date must be greater than the start date."
    }

    return true
  }

  return (
    <form id="session-form" className="flex flex-col gap-y-4" onSubmit={handleValidateForm((data) => handleSubmit(data))} noValidate>
      <Input
        {...register("title", { required: "You must supply a name for your session", minLength: { value: 3, message: "The session name must be at least 3 characters" } })}
        name="title"
        autoFocus
        isRequired
        type="text"
        label="Session Name"
        placeholder="Enter a title for your session"
        labelPlacement="outside"
        defaultValue={session.title}
        isInvalid={errors.hasOwnProperty("title")}
        errorMessage={errors?.title?.message}
      />

      <Divider />

      <div>
        <h5 className="text-base mb-1 font-semibold">Session Date &amp; Time</h5>
        <p className="text-sm mb-3">Select the session day and start / end times.</p>

        <div className="flex justify-between my-4">
          <Controller
            control={control}
            rules={{ required: "You must select a start date for your event" }}
            name="start_date"
            render={({ field: { value, onChange } }) => (
              <div>
                <DayPicker
                  selected={value}
                  onSelect={onChange}
                  mode="single"
                  fromMonth={new Date()}
                  disabled={{ before: new Date() }}
                />
                {errors.hasOwnProperty("start_date") && <ErrorMessage>{errors?.start_date?.message}</ErrorMessage>}
              </div>
            )}
          />

          <Divider orientation="vertical" className="mx-4" />

          <div>
            <div className="mb-4">
              <p className="text-sm mb-1">Start Time<Required /></p>

              <Controller
                control={control}
                rules={{ required: "Select a start time for your event" }}
                name="start_time"
                render={({ field: { value, onChange } }) => (
                  <div>
                    <CustomTimePicker showSecond={false} minuteStep={5} allowEmpty={false} onChange={onChange} value={value} />
                    {errors.hasOwnProperty("start_time") && <ErrorMessage>{errors?.start_time?.message}</ErrorMessage>}
                  </div>
                )}
              />
            </div>

            <div>
              <p className="text-sm mb-1">End Time<Required /></p>

              <Controller
                control={control}
                rules={{ validate: () => validateTimes(getValues()) }}
                name="end_time"
                render={({ field: { value, onChange } }) => (
                  <div>
                    <CustomTimePicker showSecond={false} minuteStep={5} allowEmpty={false} onChange={onChange} value={value} />
                    {errors.hasOwnProperty("end_time") && <ErrorMessage>{errors?.end_time?.message}</ErrorMessage>}
                  </div>
                )}
              />
            </div>
          </div>
        </div>

        <Divider />
      </div>

      <div>
        <h5 className="text-base mb-1 font-semibold">Slots & Break Durations</h5>
        <p className="text-sm mb-5">Select the slot length and break durations.</p>

        <div className="flex gap-x-4">
          <Input
            {...register("slot_duration", { required: "You must supply a slot length for your session", min: { value: 3, message: "The slot length must be at least 3 minutes" }, max: { value: 120, message: "The slot length must be less than 120 minutes" } })}
            isRequired
            name="slot_duration"
            type="number"
            min="3"
            label="Slot Length (min 3 mins)"
            placeholder="The length of a slot"
            labelPlacement="outside"
            defaultValue={session.slot_duration}
            isInvalid={errors.hasOwnProperty("slot_duration")}
            errorMessage={errors?.slot_duration?.message}
          />

          <Input
            {...register("slot_gap", { required: "You must supply a break length for your session", max: { value: 120, message: "The break length must be less than 120 minutes" } })}
            isRequired
            type="number"
            min="0"
            label="Break Length"
            placeholder="The time between slots"
            labelPlacement="outside"
            defaultValue={session.slot_gap}
            isInvalid={errors.hasOwnProperty("slot_gap")}
            errorMessage={errors?.slot_gap?.message}
          />
        </div>
      </div>

      {children}
    </form>
  )
}

EventSessionForm.propTypes = {
  children: PropTypes.node,
  handleSubmit: PropTypes.func.isRequired,
  session: PropTypes.object,
}

export default EventSessionForm