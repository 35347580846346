import { Chip } from "@nextui-org/react"
import { RectangleStackIcon } from "@heroicons/react/24/outline"
import { badgeStylesBySize } from "@helpers"
import PropTypes from "prop-types"

const EventSessionCountBadge = ({ size = "md", count }) => {
  const badgeStyles = badgeStylesBySize(size)

  return (
    <Chip size={size} classNames={{ base: "w-100 max-w-none bg-indigo-400" }}>
      <span className={`flex items-center justify-center text-white px-2 ${badgeStyles.font} font-semibold`}>
        <RectangleStackIcon className={`text-white mr-1 ${badgeStyles.icon}`} />
        {count} {count === 1 ? "Session" : "Sessions"}
      </span>
    </Chip>
  )
}

EventSessionCountBadge.propTypes = {
  size: PropTypes.string,
  count: PropTypes.number,
}

export default EventSessionCountBadge
