import { CustomModal } from '@components'
import { Button, Chip, ModalContent, ModalHeader, ModalBody, ModalFooter, Divider } from '@nextui-org/react';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import PropTypes from 'prop-types';

const DetailsModal = ({ details, handleClose, show = false, typeStyles = [] }) => (
  <CustomModal
    isOpen={show}
    size="2xl"
    handleClose={() => handleClose()}
    scrollBehavior="outside"
  >
    <ModalContent>
      {(onClose) => (
        <>
          <ModalHeader className="flex flex-col gap-1">Event Log Details</ModalHeader>

          <ModalBody>
            <div className="flex flex-col gap-y-2">
              <div className="flex items-center">
                <p className="w-20 text-sm">Action</p>
                <Chip classNames={{ base: `${typeStyles[details.type.code]}`, content: "text-white font-semibold px-3" }}>{details.type.description}</Chip>
              </div>

              <div className="flex items-center">
                <p className="w-20 text-sm">User</p>
                <Chip classNames={{ base: "bg-blue-400", content: "text-white font-semibold px-3" }}>{details.user.name}</Chip>
              </div>

              <div className="flex items-center">
                <p className="w-20 text-sm">Time</p> 
                <Chip classNames={{ base: "bg-indigo-400", content: "text-white font-semibold px-3" }}>{details.created_at}</Chip>
              </div>

              <div className="flex items-center">
                <p className="w-20 text-sm">Summary</p> 
                <Chip classNames={{ base: "bg-green-400", content: "text-white font-semibold px-3" }}>{details.summary}</Chip>
              </div>
            </div>

            <Divider className="my-2" />

            <p className="-mb-1 font-semibold text-md">Details</p>
            
            <JSONPretty id="json-pretty" data={details.details} mainStyle="padding: 1.25em; border-radius: 8px;" />
          </ModalBody>

          <ModalFooter>
            <div className="flex justify-end w-full">
              <Button onClick={() => handleClose()} tabIndex={-1}>Close</Button>
            </div>
          </ModalFooter>
        </>
      )}
    </ModalContent>
  </CustomModal>
)

DetailsModal.propTypes = {
  details: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  typeStyles: PropTypes.object,
}

export default DetailsModal