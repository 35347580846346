import { gql } from "@apollo/client"

const GET_GROUP_BOOKINGS_XLS_DOWNLOAD_LINK = gql`
  query groupBookingsXlsDownload($input: GroupBookingXlsDownloadInput!) {
    groupBookingsXlsDownload(input: $input) {
      filename
      url
      type
      queued
    }
  }
`

export { GET_GROUP_BOOKINGS_XLS_DOWNLOAD_LINK }