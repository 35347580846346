import { AcademicCapIcon, ArchiveBoxXMarkIcon, CalculatorIcon, CalendarIcon, RectangleGroupIcon } from "@heroicons/react/24/outline"
import { StatCardCompact } from "@components"
import { orderBy, sumBy } from "lodash"
import PropTypes from "prop-types"

const iconMap = {
  1: CalendarIcon,
  2: ArchiveBoxXMarkIcon,
  3: ArchiveBoxXMarkIcon,
  4: AcademicCapIcon,
  5: AcademicCapIcon,
}

const Stats = ({ summary = {}, typeStyles = [] }) => {
  const typesOrderedByQty = orderBy(summary?.type_quantities, type => type.qty, ['desc']) || []
  const top4Types = typesOrderedByQty.slice(0, 4)
  const otherTypesTotal = typesOrderedByQty.length > 4 ? sumBy(typesOrderedByQty.slice(4), 'qty') : 0

  return (
    <div className="my-5 grid grid-cols-1 gap-5 sm:grid-cols-3 2xl:grid-cols-6">
      <StatCardCompact
        title="Total Entries"
        stat={summary.total}
        icon={<CalculatorIcon className="h-10 w-10 text-white" aria-hidden="true" />}
      />

      {top4Types && top4Types.map((stat) => {
        const Icon = iconMap[stat.id] || CalculatorIcon

        return (
          <StatCardCompact
            key={stat.id}
            title={stat.description}
            stat={stat.qty}
            icon={<Icon className="h-10 w-10 text-white" aria-hidden="true" />}
            bgColor={typeStyles[stat.code]}
          />
        )
      })}

      <StatCardCompact
        key="other"
        title="Other"
        stat={otherTypesTotal}
        icon={<RectangleGroupIcon className="h-10 w-10 text-white" aria-hidden="true" />}
        bgColor={typeStyles['OTHER']}
      />
    </div>
  )
}


Stats.propTypes = {
  summary: PropTypes.object,
  typeStyles: PropTypes.object,
}

export default Stats
