import { Chip } from "@nextui-org/react"
import { badgeStylesBySize, formattedDateRange } from "@helpers"
import { CalendarDaysIcon } from "@heroicons/react/24/outline"
import PropTypes from "prop-types"

const EventDateRangeBadge = ({ size = "md", startDate, endDate }) => {
  const badgeStyles = badgeStylesBySize(size)

  return (
    <Chip size={size} classNames={{ base: "w-100 max-w-none bg-blue-400" }}>
      <span className={`flex items-center justify-center text-white px-2 ${badgeStyles.font} font-semibold`}>
        <CalendarDaysIcon className="mr-1 h-5 w-auto" />
        {formattedDateRange(startDate, endDate, true, "ddd D", "MMM", "")}
      </span>
    </Chip>
  )
}

EventDateRangeBadge.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
}

export default EventDateRangeBadge
