import { Input } from "@nextui-org/react"
import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/outline"
import { debounce } from "lodash"
import PropTypes from "prop-types"

const SearchInput = ({ 
  classNames = "", 
  debounceMilliSeconds = 0, 
  handleClear, 
  handleChange, 
  innerRef = null, 
  placeholder, 
}) => (
  <Input
    isClearable
    className={`w-full ${classNames}`}
    ref={innerRef}
    placeholder={placeholder}
    startContent={<MagnifyingGlassCircleIcon className="h-8 w-auto" />}
    onClear={() => handleClear()}
    onValueChange={debounce(handleChange, debounceMilliSeconds)}
  />
)

SearchInput.propTypes = {
  classNames: PropTypes.string,
  debounceMilliSeconds: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  placeholder: PropTypes.string.isRequired,
}

export default SearchInput
