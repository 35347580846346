const getSessionFilters = sessions => {
  const sessionFilters = sessions.reduce((sessionFilters, session) => {
    sessionFilters[session.id] = { id: session.id, name: session.title }

    return sessionFilters
  }, {})

  return sessionFilters
}

export default getSessionFilters
