import { gql } from "@apollo/client"

const GET_REPORT_TEACHER_BOOKINGS_SUMMARY = gql`
  query ($event_id: ID!) {
    reportTeacherBookingsSummary(event_id: $event_id) {
      booked_percentage
      disabled_percentage
      no_of_free_capacity_groups
      no_of_fully_booked_groups
      no_of_groups
      no_of_oversubscribed_groups
    }
  }
`

export { GET_REPORT_TEACHER_BOOKINGS_SUMMARY }
