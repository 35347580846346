import { Button } from "@nextui-org/react"
import { ArrowPathIcon } from "@heroicons/react/20/solid"
import PropTypes from "prop-types"

const RefreshButton = ({ color = "default", handleClick, isLoading = false, theme = "light", variant = "flat" }) => (
  <Button onClick={() => handleClick()} color={color} variant={variant} isDisabled={isLoading} isIconOnly>
    <ArrowPathIcon className={`h-6 w-6 ${isLoading && "animate-spin"} ${theme === "dark" && "text-white opacity-60"}`} />
  </Button>
)

RefreshButton.propTypes = {
  color: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  theme: PropTypes.string,
  variant: PropTypes.string,
}

export default RefreshButton
