import React, { useState } from "react"
import { ColumnSelector, CustomModal, FilterSelector, NoDataTablePlaceholder, SearchInput } from "@components"
import { useQuery } from "@apollo/client"
import { GET_LESSON_SELECTOR } from "@graphql"
import { stringToColor, staffFullname } from "@helpers"
import { Button, Checkbox, Chip, ModalBody, ModalContent, ModalFooter, ModalHeader, Pagination, Spinner, Table, TableColumn, TableBody, TableHeader, TableRow, TableCell } from "@nextui-org/react"
import { columns, defaultColumns, filters } from "./data"
import PropTypes from "prop-types"

const initialSelectedColumns = JSON.parse(window.localStorage.getItem("lessons.selector")) ?? defaultColumns

const LessonSelectorModal = ({ 
  clientId, 
  handleClose, 
  handleSubmit, 
  isSubmitting, 
  selectedLessonsByUser, 
  show = false 
}) => {
  const [disabledLessonIds, setDisabledLessonIds] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedColumns, setSelectedColumns] = useState(initialSelectedColumns)
  const [selectedFilters, setSelectedFilters] = useState(["all"])
  const [selectedLessonIds, setSelectedLessonIds] = useState(new Set())
  const [sortDescriptor, setSortDescriptor] = useState({ column: "name", direction: "ascending" })
  const [page, setPage] = useState(1)
  const [perPage] = useState(50)
  const [paginatorInfo, setPaginatorInfo] = useState(null)

  const { data, loading } = useQuery(GET_LESSON_SELECTOR, {
    variables: { 
      page,
      perPage,
      clientId: clientId,
      
      orderBy: [
        {
          column: sortDescriptor.column.toUpperCase(),
          order: sortDescriptor.direction === "ascending" ? "ASC" : "DESC",
        },
      ],

      where: selectedFilters.includes("sync") 
        ? { column: "IS_SYNC", value: true } 
        : selectedFilters.includes("custom") 
        ? { column: "IS_SYNC", value: false } 
        : null,

      filterByIds: selectedFilters.includes("selected") ? [...selectedLessonIds, -1] : [],
	    searchTerm
    },

    fetchPolicy: "network-first",

    onCompleted: data => {
      const lessonUserIds = selectedLessonsByUser.map(lessonUser => `${lessonUser.lesson_id}-${lessonUser.user_id}`)

      const lessonIds = data.client.lessons.data
        .filter(lesson => lessonUserIds.includes(`${lesson.id}-${lesson?.teacher?.user_id}`))
        .map(lesson => lesson.id)

      setPaginatorInfo(data.client.lessons.paginatorInfo)
      setDisabledLessonIds(lessonIds)
    }
  })
  
  const getSelectedLessons = () => {
    return data?.client?.lessons?.data.filter(lesson => selectedLessonIds.has(lesson.id))
  }

  const getSubjectStyles = subject => {
    if (subject === null) return { backgroundColor: "#c0bcc2", color: "white" }

    return {
      backgroundColor: stringToColor(subject, "subject"),
      color: "white",
    }
  }
  
  const handleRenderCell = (lesson, column, isDisabled, isSelected) => {
    if (column === "select") return <Checkbox color="secondary" isSelected={isSelected} isDisabled={isDisabled} onChange={() => handleRowSelection(lesson.id)} />

    if (column === "class") return lesson.name || "N/A"
    
    if (column === "teacher") {
      return staffFullname(lesson?.teacher) || "N/A"
    }

    if (column === "subject") {
      return (
        <Chip size="sm" radius="sm" style={getSubjectStyles(lesson?.subject?.name)}>
          {lesson?.subject?.name || "N/A"}
        </Chip>
      )
    }

    if (column === "type" && lesson.is_sync) {
      return <Chip classNames={{ base: "bg-sb-sync", content: "text-white" }} size="sm" variant="flat">Sync</Chip>
    }

    if (column === "type" && !lesson.is_sync) {
      return <Chip classNames={{ base: "bg-sb-sync-custom", content: "text-white" }} size="sm" variant="flat">Custom</Chip>
    }

    if (column === "students") {
      return lesson.studentCount || 0
    }
  }

  const handleRowSelection = id => {
    selectedLessonIds.has(id)
      ? setSelectedLessonIds(previousLessonIds => new Set([...previousLessonIds].filter(previousLessonId => previousLessonId !== id)))
      : setSelectedLessonIds(previousLessonIds => new Set([...previousLessonIds, id]))
  }
  
  const handleSearchChange = searchTerm => {
    setSearchTerm(searchTerm)
    setPage(1)
  }

  const handleSearchClear = () => {
    setSearchTerm("")
    setPage(1)
  }

  const handleSortChange = sortDescriptor => {
    setSortDescriptor(sortDescriptor)
    setPage(1)
  }

  const handleToggleColumn = selectedColumns => {
    setSelectedColumns(Object.keys(columns).filter(column => selectedColumns.has(column)))
  }

  const handleToggleFilter = selectedFilters => {
    setSelectedFilters([...selectedFilters])
    setPage(1)
  }
  
  const addLessonsCaption = selectedLessonIds.size > 0 ? `Add ${selectedLessonIds.size} Lessons` : "Add Lessons"
  const lessons = data?.client?.lessons?.data || []
  
  return (
    <CustomModal 
      isOpen={show}
      classNames={{ 
        base: "h-[700px]",
        header: "bg-indigo-800 rounded-t-xl text-white",
        wrapper: "md:items-center",
        closeButton: "hover:bg-white/5 text-white mt-2 mr-2",
      }}         
      size="5xl"
      onClose={() => handleClose()}
      isDismissable={false}
      isKeyboardDismissDisabled={false}
      scrollBehavior="inside"  
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">MIS Lesson Selector</ModalHeader>

            <ModalBody>
              <div className="flex items-center gap-2">
                <SearchInput
                  debounceMilliSeconds={300}
                  placeholder="Search by name / teacher / subject"
                  handleChange={(searchTerm) => handleSearchChange(searchTerm)}
                  handleClear={handleSearchClear}
                  value={searchTerm}
                />

                <ColumnSelector
                  columns={columns}
                  selectedColumns={selectedColumns}
                  toggleColumn={selectedColumns => handleToggleColumn(selectedColumns)}
                />

                <FilterSelector
                  filters={filters}
                  selectionMode="single"
                  closeOnSelect={true}
                  selectedFilters={selectedFilters}
                  toggleFilter={selectedFilters => handleToggleFilter(selectedFilters)}
                />
              </div>

              <Table 
                removeWrapper 
                disabledKeys={disabledLessonIds} 
                isStriped
                classNames={{ wrapper: "min-h-full" }}
                sortDescriptor={sortDescriptor}
                onSortChange={sortDescriptor => handleSortChange(sortDescriptor)}
              >
                <TableHeader>
                  {selectedColumns.map(columnKey => {
                    const column = columns[columnKey]
                    return <TableColumn key={column.id} allowsSorting={column.sortable}>{column.name}</TableColumn>
                  })}
                </TableHeader>

                <TableBody 
                  isLoading={loading} 
                  loadingContent={<Spinner classNames={{ base: "absolute top-[230px]" }} label="Loading lessons..." />}
                  emptyContent={<NoDataTablePlaceholder className="absolute top-[130px] w-full" title="No Lessons Found" message="No Lessons found! Try clearing any filters." />}
                >
                  {lessons.map(lesson => {
                    const isDisabled = disabledLessonIds.includes(lesson.id)
                    const isSelected = selectedLessonIds.has(lesson.id) || disabledLessonIds.includes(lesson.id)
                    
                    return (
                      <TableRow key={lesson.id}>
                        {selectedColumns.map(columnKey => (
                          <TableCell key={columnKey}>
                            {handleRenderCell(lesson, columnKey, isDisabled, isSelected)}
                          </TableCell>
                        ))}
                      </TableRow>                        
                    )
                  })}
                </TableBody>
              </Table>
            </ModalBody>
            
            <ModalFooter>
              <div className="flex items-center justify-between w-full">
                {paginatorInfo?.lastPage > 1 && (
                  <Pagination
                    isCompact
                    showControls
                    showShadow
                    color="secondary"
                    page={page}
                    total={paginatorInfo?.lastPage}
                    onChange={page => setPage(page)}
                  />
                )}

                <Button className="ml-auto"	color="secondary" onClick={() => handleSubmit(getSelectedLessons())} isDisabled={selectedLessonIds.size === 0} isLoading={isSubmitting}>
                  {addLessonsCaption}
                </Button>
              </div>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </CustomModal>
  )
}

LessonSelectorModal.propTypes = {
  clientId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  selectedLessonsByUser: PropTypes.array,
  show: PropTypes.bool,
}

export default LessonSelectorModal
