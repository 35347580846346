const columns = {
  attendance_icon: { id: "attendance_icon", name: "", sortable: false, deselectable: false },
  starts_at: { id: "starts_at", name: "Starts", sortable: true, deselectable: false },
  ends_at: { id: "ends_at", name: "Ends", sortable: true, deselectable: false },
  parent: { id: "parent", name: "Parent", sortable: true, deselectable: false },
  session: { id: "session", name: "Session", sortable: true, deselectable: true },
  group: { id: "group", name: "Group", sortable: true, deselectable: true },
  lesson: { id: "lesson", name: "Lesson", sortable: true, deselectable: true },
  student: { id: "student", name: "Student", sortable: true, deselectable: true },
  subject: { id: "subject", name: "Subject", sortable: true, deselectable: true },
  attendance: { id: "attendance", name: "Attendance", sortable: true, deselectable: true },
}

const defaultColumns = [
  "attendance_icon",
  "starts_at",
  "ends_at",
  "session",
  "parent",
  "group",
  "lesson",
  "student",
  "subject",
  "attendance",
]

const downloadOptions = [
  { key: "pdf", name: "Download All PDF" },
  { key: "xls", name: "Download All XLS" },
]

const filters = {
  absent: { id: "absent", name: "Absent" },
  attended: { id: "attended", name: "Attended" },
  unknown: { id: "unknown", name: "Unknown" },
}

export { columns, defaultColumns, downloadOptions, filters }
