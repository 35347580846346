import PropTypes from "prop-types"

const SBLogo = ({ className = "" }) => (
  <svg viewBox="-41.735 -49.67 586.459 612.81" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g id="g10" transform="matrix(1.333333, 0, 0, -1.333333, -23.546319, 548.368302)">
      <g id="g12" transform="scale(0.1)">
        <path
          d="M 3715.22,2203.6 C 3909.3,1111.4 3092.99,109.879 1874.39,292.691 1279.42,377.02 751.754,723.738 421.887,1096.52 87.4727,1477.82 -49.1641,1885.12 33.5352,2287.43 116.738,2690.1 386.27,3074.34 716.754,3426.1 c 334.506,343.33 729.916,654.21 1157.636,714.99 829.94,133.32 1648.97,-844.58 1840.83,-1937.49 z"
          style={{
            fill: "none",
            stroke: "rgb(30, 166, 230)",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
            strokeOpacity: 1,
            strokeWidth: "150px",
          }}
          id="path40"
        />

        <path
          d="M 3163.54,880.16 C 2493.74,1.58984 1227.02,-125.109 560.652,831.93 231.734,1295.82 138.484,1893.04 194.047,2372.9 c 58.812,488.84 266.433,860.34 614.297,1078.62 348.516,218.25 805.836,296.65 1279.466,311.27 470.14,6.13 956.56,-51.57 1281.64,-302.09 642.02,-475.73 465.8,-1703.01 -205.91,-2580.54 z"
          style={{
            fill: "none",
            stroke: "rgb(241, 80, 41)",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
            strokeOpacity: 1,
            strokeWidth: "150px",
          }}
          id="path42"
        />

        <path
          d="M 383.316,2678.82 C 554.281,3901.68 1676.62,4701.33 2799.95,4112.86 3349.95,3830.79 3748.33,3291.79 3946,2789.12 4145.3,2275.96 4143.98,1799.1 3931.23,1398.61 3717.91,997.809 3329.7,676.898 2893,410.219 2455.17,153.789 1968.84,-48.418 1533.46,25.3789 683.605,152.301 210.051,1455.99 383.316,2678.82 Z"
          style={{
            fill: "none",
            stroke: "rgb(181, 220, 23)",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
            strokeOpacity: 1,
            strokeWidth: "150px",
          }}
          id="path44"
        />

        <path
          d="M 1663.686 2416.178 C 1599.105 2470.023 1516.484 2505.856 1430.358 2505.856 C 1365.761 2505.856 1279.628 2468.181 1279.628 2392.832 C 1279.628 2313.862 1374.713 2283.395 1435.76 2263.638 L 1525.534 2236.73 C 1713.941 2181.078 1859.364 2085.969 1859.364 1867.005 C 1859.364 1732.379 1827.027 1594.226 1719.37 1502.648 C 1613.462 1412.97 1471.66 1375.263 1335.237 1375.263 C 1164.804 1375.263 997.874 1432.696 859.682 1529.583 L 1010.433 1813.192 C 1098.365 1735.938 1202.46 1673.197 1322.701 1673.197 C 1405.237 1673.197 1493.198 1714.402 1493.198 1809.54 C 1493.198 1908.268 1354.986 1942.416 1279.628 1963.893 C 1058.897 2026.755 913.527 2084.159 913.527 2346.168 C 913.527 2620.755 1109.161 2800.263 1380.129 2800.263 C 1516.484 2800.263 1683.414 2757.188 1803.65 2689.018 L 1663.686 2416.178"
          style={{ fill: "#1ea6e6", fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
          id="path-1"
        />

        <path
          d="M 2486.715 1681.331 L 2531.481 1681.331 C 2635.339 1681.331 2810.824 1676.035 2810.824 1824.583 C 2810.824 1987.456 2637.13 1971.447 2522.487 1971.447 L 2486.715 1971.447 Z M 2486.715 2232.79 L 2526.124 2232.79 C 2619.208 2232.79 2715.903 2248.769 2715.903 2363.493 C 2715.903 2487.033 2606.664 2494.257 2511.787 2494.257 L 2486.715 2494.257 Z M 2135.792 1412.763 L 2135.792 2762.763 L 2637.13 2762.763 C 2877.062 2762.763 3038.221 2669.772 3038.221 2411.826 C 3038.221 2290.062 2998.827 2191.712 2889.612 2136.157 L 2889.612 2132.606 C 3083.002 2107.476 3165.373 1976.742 3165.373 1788.739 C 3165.373 1505.815 2923.619 1412.763 2676.518 1412.763 L 2135.792 1412.763"
          style={{ fill: "#1ea6e6", fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
          id="path-2"
        />
      </g>
    </g>
  </svg>
)

SBLogo.propTypes = {
  className: PropTypes.string,
}

export default SBLogo
