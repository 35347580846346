import { Chip } from "@nextui-org/react"

import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  QuestionMarkCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline"

import PropTypes from "prop-types"

const CheckListItem = ({ icon, required = false, status, title, meta = null }) => (
  <div className="flex items-center">
    <div className="mr-1 hidden sm:block">{icon}</div>

    <div>
      <h3 className="text-md text-blue-700">{title}</h3>
      {meta && <p className="text-sm text-gray-500">{meta}</p>}
    </div>

    <div className="flex items-center ml-auto">
      <Chip
        classNames={{
          base: "mx-3 px-3",
          content: "text-sm text-white font-semibold",
        }}
        color={status}
        size="sm"
      >
        {required ? "Required" : "Optional"}
      </Chip>

      {status === "danger" && <XCircleIcon className="h-10 w-10 mr-2 text-danger" aria-hidden="true" />}
      {status === "success" && <CheckCircleIcon className="h-10 w-10 mr-2 text-success" aria-hidden="true" />}
      {status === "warning" && <QuestionMarkCircleIcon className="h-10 w-10 mr-2 text-warning" aria-hidden="true" />}
      {status === "outstanding" && <ExclamationCircleIcon className="h-10 w-10 mr-2 text-info" aria-hidden="true" />}
    </div>
  </div>
)

CheckListItem.propTypes = {
  icon: PropTypes.object.isRequired,
  meta: PropTypes.string,
  required: PropTypes.bool,
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default CheckListItem
