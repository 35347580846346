const columns = {
  guardian: { id: "guardian", name: "Guardian", sortable: true, deselectable: false },
  students: { id: "students", name: "Students", sortable: true, deselectable: false },
  email: { id: "email", name: "Email", sortable: true, deselectable: true },
  last_active: { id: "last_active", name: "Last Active", sortable: true, deselectable: true, align: "text-center" },
  no_of_slots: { id: "no_of_slots", name: "#Slots", sortable: false, deselectable: true, align: "text-right" },

  no_of_booked_slots: {
    id: "no_of_booked_slots",
    name: "#Booked",
    sortable: false,
    deselectable: true,
    align: "text-right",
  },

  booked_slots_ratio: {
    id: "booked_slots_ratio",
    name: "%Booked",
    sortable: true,
    deselectable: true,
    align: "text-center",
  },

  status: { id: "status", name: "Status", sortable: true, deselectable: true, align: "text-center" },
  type: { id: "type", name: "Type", sortable: true, deselectable: true },
  actions: { id: "actions", name: "Actions", sortable: false, deselectable: false },
}

const defaultColumns = [
  "guardian",
  "students",
  "email",
  "last_active",
  "no_of_slots",
  "no_of_booked_slots",
  "booked_slots_ratio",
  "status",
  "type",
  "actions",
]

const downloadOptions = [
  { key: "pdf_all", name: "Download All Itineraries PDF" },
  { key: "xls", name: "Download All XLS" },
]

const filters = {
  all: { id: "All", name: "All" },
  attending: { id: "fully_booked", name: "Attending" },
  not_attending: { id: "not_attending", name: "Not Attending" },
  has_bookings: { id: "has_bookings", name: "Has Bookings" },
  no_bookings: { id: "no_bookings", name: "No Bookings" },
  activated_accounts: { id: "activated_accounts", name: "Activated Accounts" },
  unactivated_accounts: { id: "unactivated_accounts", name: "Unactivated Accounts" },
}

export { columns, defaultColumns, downloadOptions, filters }
