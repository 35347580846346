import { Page } from "@components"
import { Button } from "@nextui-org/react"
import { ReactComponent as DataSvg } from "@svgs/data.svg"
import { useNavigate } from 'react-router-dom';

const NoData = () => {
  const navigate = useNavigate();

  return (
    <Page>
      <div className="-mt-16 min-h-screen flex items-center flex-col justify-center">
        <div className="flex items-center flex-col max-w-lg text-center">
          <DataSvg className="max-h-[500px] mb-4" />
          <h1 className="text-3xl font-semibold mb-2 text-blue-400">No Data Found</h1>
          <p className="text-lg leading-7 mb-3">No Data found! Click below to connect to a data source (MIS or CSV).</p>
          <Button radius="full" size="lg" color="secondary" onClick={() => navigate('/data/imports')}>Connect to a data source</Button>
        </div>
      </div>
    </Page>
  )
}

export default NoData