import { ErrorMessage, ImageRadioGroup, WizardFooter } from "@components"
import { Controller, useForm } from "react-hook-form";
import { meetingTypes } from "@config"
import { PropTypes } from "prop-types"

const EventMeetingTypes = ({ 
  handlePrevious = () => {}, 
  handleSubmit = () => {}, 
  selectedMeetingTypeIds = [], 
  showWizardFooter = false 
}) => {
  const { control, handleSubmit: handleValidateForm, formState: { errors }, getValues } = useForm({
    defaultValues: {
      selected_meeting_type_ids: selectedMeetingTypeIds
    }
  })

  return (
    <form id="event-meeting-types-form" className="flex flex-col" noValidate>
      <Controller
        control={control}
        rules={{ required: "You must supply a venue for your event" }}
        name="selected_meeting_type_ids"
        render={({ field: { value, onChange } }) => (
          <ImageRadioGroup classNames="min-w-52" options={meetingTypes} handleChange={(options) => { onChange(options) }} selectedOptionIds={value} />
        )}
      />

      {errors.hasOwnProperty("selected_meeting_type_ids") && <ErrorMessage>{errors?.selected_meeting_type_ids?.message}</ErrorMessage>}

      {showWizardFooter && (
        <WizardFooter currentStep={2} totalSteps={5} previousCaption="Basic Information" nextCaption="Settings" handlePrevious={() => handlePrevious(getValues("selected_meeting_type_ids"))} handleNext={handleValidateForm(({ selected_meeting_type_ids }) => handleSubmit(selected_meeting_type_ids))} />
      )}
    </form>
  )
}

EventMeetingTypes.propTypes = {
  handlePrevious: PropTypes.func,
  handleSubmit: PropTypes.func,
  selectedMeetingTypeIds: PropTypes.array.isRequired,
  showWizardFooter: PropTypes.bool
}

export default EventMeetingTypes