const columns = {
  group: { id: "group", name: "Group", sortable: true, deselectable: false },
  session: { id: "session", name: "Session", sortable: true, deselectable: false },
  total_slots: { id: "total_slots", name: "#Created Slots", sortable: true, deselectable: true, align: "text-right" },
  disabled_slots: { id: "disabled_slots", name: "#Disabled", sortable: true, deselectable: true, align: "text-right" },

  available_slots: {
    id: "available_slots",
    name: "#Total Available",
    sortable: true,
    deselectable: true,
    align: "text-right",
  },

  no_of_invitation_slots: {
    id: "no_of_invitation_slots",
    name: "#Parent Invites",
    sortable: true,
    deselectable: true,
    align: "text-right",
  },

  subscription: { id: "subscription", name: "#Subscription", sortable: true, deselectable: true, align: "text-right" },

  remaining_slots: {
    id: "remaining_slots",
    name: "#Remaining",
    sortable: true,
    deselectable: true,
    align: "text-right",
  },

  no_of_booked_slots: {
    id: "no_of_booked_slots",
    name: "#Booked",
    sortable: true,
    deselectable: true,
    align: "text-right",
  },

  capacity: { id: "capacity", name: "Capacity", sortable: true, deselectable: true },

  booked_percentage: {
    id: "booked_percentage",
    name: "%Booked",
    sortable: true,
    deselectable: true,
    align: "text-right",
  },

  actions: { id: "actions", name: "Actions", sortable: false, deselectable: false },
}

const defaultColumns = [
  "group",
  "session",
  "total_slots",
  "disabled_slots",
  "available_slots",
  "no_of_invitation_slots",
  "subscription",
  "remaining_slots",
  "no_of_booked_slots",
  "capacity",
  "booked_percentage",
  "actions",
]

const downloadOptions = [
  { key: "xls", name: "Download All Bookings XLS" },
  { key: "pdf", name: "Download All Itineraries PDF" },
]

const filters = {
  all: { id: "All", name: "All" },
  fully_booked: { id: "fully_booked", name: "FULLY BOOKED" },
  free_capacity: { id: "free_capacity", name: "FREE CAPACITY" },
  over_subscribed: { id: "over_subscribed", name: "OVER SUBSCRIBED" },
}

export { columns, defaultColumns, downloadOptions, filters }
