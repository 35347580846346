import { 
  AcademicCapIcon, 
  ArchiveBoxXMarkIcon, 
  CalculatorIcon, 
  Square3Stack3DIcon, 
  UserGroupIcon, 
  UsersIcon 
} from "@heroicons/react/24/outline"

import { StatCardCompact } from "@components"
import PropTypes from "prop-types"

const Stats = ({ 
  noOfBookedSlots = 0,
  noOfLessons = 0,
  noOfGroups = 0,
  noOfSessions = 0,
  noOfStudents = 0,
  noOfNonAttending = 0 
}) => (
  <div className="my-5 grid grid-cols-1 gap-5 sm:grid-cols-3 2xl:grid-cols-6">
    <StatCardCompact
      title="Slots Booked"
      stat={noOfBookedSlots}
      icon={<CalculatorIcon className="h-10 w-10 text-white" aria-hidden="true" />}
    />

    <StatCardCompact
      title="Lessons"
      stat={noOfLessons}
      icon={<UsersIcon className="h-10 w-10 text-white" aria-hidden="true" />}
    />

    <StatCardCompact
      title="Groups"
      stat={noOfGroups}
      icon={<UserGroupIcon className="h-10 w-10 text-white" aria-hidden="true" />}
    />

    <StatCardCompact
      title="Sessions"
      stat={noOfSessions}
      icon={<Square3Stack3DIcon className="h-10 w-10 text-white" aria-hidden="true" />}
    />

    <StatCardCompact
      title="Students"
      stat={noOfStudents}
      icon={<AcademicCapIcon className="h-10 w-10 text-white" aria-hidden="true" />}
    />

    <StatCardCompact
      title="Non Attending"
      stat={noOfNonAttending}
      icon={<ArchiveBoxXMarkIcon className="h-10 w-10 text-white" aria-hidden="true" />}
    />
  </div>
)

Stats.propTypes = {
  noOfBookedSlots: PropTypes.number,
  noOfLessons: PropTypes.number,
  noOfGroups: PropTypes.number,
  noOfSessions: PropTypes.number,
  noOfStudents: PropTypes.number,
  noOfNonAttending: PropTypes.number,
}

export default Stats
