import React from "react"
import { Progress } from "@nextui-org/react"
import { CardStat, Divider } from "@components"
import { BellAlertIcon, ChatBubbleLeftRightIcon, EnvelopeIcon, DocumentTextIcon } from "@heroicons/react/24/outline"
import PropTypes from "prop-types"

const CommunicationStats = ({
  noOfBookingConfirmationsPending = 0,
  noOfBookingConfirmationsSent = 0,
  noOfComments = 0,
  noOfInvitationsPending = 0,
  noOfInvitationsSent = 0,
  noOfNotifications = 0,
}) => {
  const totalInvitations = noOfInvitationsSent + noOfInvitationsPending
  const totalBookingConfirmations = noOfBookingConfirmationsSent + noOfBookingConfirmationsPending
  const invitationsSentPercentage = Math.round(
    totalInvitations === 0 ? 0 : (noOfInvitationsSent / totalInvitations) * 100,
    0,
  )
  const bookingConfirmationsSentPercentage = Math.round(
    totalBookingConfirmations === 0 ? 0 : (noOfBookingConfirmationsSent / totalBookingConfirmations) * 100,
    0,
  )

  const getProgressColor = percentage => {
    if (percentage < 50) return "danger"
    if (percentage < 100) return "warning"
    return "success"
  }

  return (
    <React.Fragment>
      <CardStat
        title="Invitations Sent"
        icon={EnvelopeIcon}
        stat={`${invitationsSentPercentage}%`}
        meta={`${noOfInvitationsPending} Pending | ${noOfInvitationsSent} Sent`}
      >
        <Progress
          color={getProgressColor(invitationsSentPercentage)}
          value={invitationsSentPercentage}
          aria-label="Invitations Sent"
        />
      </CardStat>

      <Divider />

      <CardStat
        title="Booking Confirmations"
        icon={DocumentTextIcon}
        stat={`${bookingConfirmationsSentPercentage}%`}
        meta={`${noOfBookingConfirmationsPending} Pending | ${noOfBookingConfirmationsSent} Sent`}
      >
        <Progress
          color={getProgressColor(bookingConfirmationsSentPercentage)}
          value={bookingConfirmationsSentPercentage}
          aria-label="Booking Confirmations"
        />
      </CardStat>

      <Divider />

      <CardStat
        title="Comments"
        icon={ChatBubbleLeftRightIcon}
        stat={`${noOfComments}`}
        meta={`Comments sent by parents and staff`}
      />

      <Divider />

      <CardStat
        title="Notifications"
        icon={BellAlertIcon}
        stat={`${noOfNotifications}`}
        meta={`Notifications sent to parents or staff`}
      />
    </React.Fragment>
  )
}

CommunicationStats.propTypes = {
  noOfBookingConfirmationsPending: PropTypes.number,
  noOfBookingConfirmationsSent: PropTypes.number,
  noOfComments: PropTypes.number,
  noOfInvitationsPending: PropTypes.number,
  noOfInvitationsSent: PropTypes.number,
  noOfNotifications: PropTypes.number,
}

export default CommunicationStats
