import { useSetActiveSidebarLink } from "@hooks"
import { ComingSoon } from "@components"

const SiteSettings = () => {
  useSetActiveSidebarLink("SITE_SETTINGS")

  return <ComingSoon />
}

export default SiteSettings
