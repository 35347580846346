import { createContext, useState } from "react"

const NotificationContext = createContext()

const NotificationContextProvider = ({ children }) => {
  const [notification, setNotification] = useState({
    icon: null,
    title: null,
    type: "info",
    content: null,
    open: false,
  })

  const setOpen = open => setNotification(prevState => ({ ...prevState, open }))

  return (
    <NotificationContext.Provider value={{ notification, setNotification, open: notification.open, setOpen }}>
      {children}
    </NotificationContext.Provider>
  )
}

export { NotificationContext, NotificationContextProvider }
