const columns = {
  forename: { id: "forename", name: "Forename", sortable: true, deselectable: false },
  surname: { id: "surname", name: "Surname", sortable: true, deselectable: false },
  local_id: { id: "local_id", name: "MIS ID", sortable: false, deselectable: true },
  data_ref: { id: "data_ref", name: "Xporter ID", sortable: false, deselectable: true },
  is_sync: { id: "is_sync", name: "Type", sortable: false, deselectable: true },
  no_of_guardians: { id: "no_of_guardians", name: "# Guardians", sortable: false, deselectable: true },
  no_of_lessons: { id: "no_of_lessons", name: "# Lessons", sortable: false, deselectable: true },
  created_at: { id: "created_at", name: "Created", sortable: false, deselectable: true },
  updated_at: { id: "updated_at", name: "Updated", sortable: false, deselectable: true },
  actions: { id: "actions", name: "Actions", sortable: false, deselectable: false },
}

const defaultColumns = [
  "forename",
  "surname",
  "local_id",
  "data_ref",
  "is_sync",
  "no_of_guardians",
  "no_of_lessons",
  "created_at",
  "updated_at",
  "actions",
]

const downloadOptions = [{ key: "xls", name: "Download All XLS" }]

const filters = {
  custom: { id: "custom", name: "Custom" },
  sync: { id: "sync", name: "Sync" },
}

export { columns, defaultColumns, downloadOptions, filters }
