import { gql } from "@apollo/client"

const DELETE_BOOKING_SLOTS = gql`
  mutation deleteBookingSlots($input: DeleteBookingSlotsInput!) {
    deleteBookingSlots(input: $input) {
		  booking_slot_ids
		}
	}
`

export default DELETE_BOOKING_SLOTS