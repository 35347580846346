import React, { useContext, useState } from "react"
import { useQuery } from "@apollo/client"

import {
  AttendanceDonut,
  AvailabilityDonut,
  DashboardCard,
  EventViewsList,
  EventViewsTracker,
  Page,
  EventPageHeader,
  EventSettings,
  RefreshButton,
  Schedule,
  StatCardCompact,
  Summary,
  ViewStats,
} from "@components"

import {
  AcademicCapIcon,
  CalendarDaysIcon,
  DocumentCheckIcon,
  PresentationChartBarIcon,
  PresentationChartLineIcon,
  RectangleGroupIcon,
  Square3Stack3DIcon,
} from "@heroicons/react/24/outline"

import { ContextualSidebarContext, UserContext } from "@context"

import {
  GET_DASHBOARD_TEACHER_EVENT_STATS,
  GET_DASHBOARD_TEACHER_SCHEDULE,
  GET_DASHBOARD_EVENT_VIEWS_STATS,
} from "@graphql"

import { mySqlToLocal } from "@helpers"
import { useSetActiveSidebarLink, useSetCurrentEvent } from "@hooks"
import { useParams } from "react-router-dom"

const StaffDashboard = () => {
  const { event: eventId } = useParams()
  const { user } = useContext(UserContext)
  const { setContextualSidebar } = useContext(ContextualSidebarContext)
  const [isRefetching, setRefetching] = useState(false)

  useSetActiveSidebarLink("EVENT_DASHBOARD")
  useSetCurrentEvent(eventId)

  const eventViews = useQuery(GET_DASHBOARD_EVENT_VIEWS_STATS, {
    variables: { input: { event_id: eventId, teacher_id: user.me.id } },
    pollInterval: 120000,
    fetchPolicy: "network-first",
  })

  const schedule = useQuery(GET_DASHBOARD_TEACHER_SCHEDULE, {
    variables: { event_id: eventId, first: 5 },
    pollInterval: 130000,
    fetchPolicy: "network-first",
  })

  const teacherEventStats = useQuery(GET_DASHBOARD_TEACHER_EVENT_STATS, {
    variables: { eventId },
    pollInterval: 900000,
    fetchPolicy: "network-first",
  })

  const getEventViewsSubTitle = filter => {
    if (filter === "TODAY") return "Unique user views seen today"
    if (filter === "LAST_3_DAYS") return "Unique user views seen in the last 3 days"
    if (filter === "LIVE") return "Live unique user views seen in the last 3 minutes"

    return "All unique user views"
  }

  const handleShowEventViews = filter => {
    setContextualSidebar(prevState => ({
      ...prevState,
      open: true,
      title: "Event Views",
      subTitle: getEventViewsSubTitle(filter),
      content: <EventViewsList eventId={eventId} filter={filter} teacherId={user.me.id} />,
      size: "lg",
    }))
  }

  const { communications = null, slots = null, summary = null } = teacherEventStats?.data?.teacherEventStats || {}
  const { views = null } = eventViews?.data || {}
  const { teacherEventSchedule = null } = schedule?.data || {}
  const { currentEvent } = user

  const loading = teacherEventStats.loading || eventViews.loading || schedule.loading

  const firstAppointmentDateTime = teacherEventSchedule?.data[0]
    ? mySqlToLocal(teacherEventSchedule.data[0].starts_at).format("D MMM HH:mm")
    : null

  const lastAppointmentDateTime = teacherEventSchedule?.data[teacherEventSchedule?.data.length - 1]
    ? mySqlToLocal(teacherEventSchedule.data[teacherEventSchedule?.data.length - 1].starts_at).format("D MMM HH:mm")
    : null

  const handleRefetch = () => {
    setRefetching(true)

    Promise.all([teacherEventStats.refetch(), eventViews.refetch(), schedule.refetch()]).then(() =>
      setRefetching(false),
    )
  }

  return (
    <EventViewsTracker eventId={eventId}>
      <Page>
        <EventPageHeader
          actions={<RefreshButton isLoading={isRefetching} handleClick={() => handleRefetch()} theme="dark" />}
          title="Event Dashboard"
          icon={<PresentationChartLineIcon className="page-header-icon" />}
          event={user?.currentEvent}
        />

        <div className="my-5 grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 3xl:grid-cols-6">
          <StatCardCompact
            title="No of Bookings"
            stat={summary?.no_of_bookings || 0}
            icon={<CalendarDaysIcon className="h-10 w-10 text-white" aria-hidden="true" />}
          />

          <StatCardCompact
            title="No of Groups"
            stat={summary?.no_of_groups || 0}
            icon={<RectangleGroupIcon className="h-10 w-10 text-white" aria-hidden="true" />}
          />

          <StatCardCompact
            title="No of Invitations"
            stat={summary?.no_of_invitations || 0}
            icon={<DocumentCheckIcon className="h-10 w-10 text-white" aria-hidden="true" />}
          />

          <StatCardCompact
            title="No of Lessons"
            stat={summary?.no_of_lessons || 0}
            icon={<PresentationChartBarIcon className="h-10 w-10 text-white" aria-hidden="true" />}
          />

          <StatCardCompact
            title="No of Sessions"
            stat={summary?.no_of_sessions || 0}
            icon={<Square3Stack3DIcon className="h-10 w-10 text-white" aria-hidden="true" />}
          />

          <StatCardCompact
            title="No of Students"
            stat={summary?.no_of_students || 0}
            icon={<AcademicCapIcon className="h-10 w-10 text-white" aria-hidden="true" />}
          />
        </div>

        <div className="my-5 grid grid-cols-24 gap-y-5 sm:gap-5">
          <DashboardCard
            title="Event Settings"
            className="col-span-full md:col-span-24 lg:col-span-10 xl:col-span-11 3xl:col-span-7"
            loading={loading}
            body={
              <EventSettings
                startsAt={currentEvent?.start_date}
                endsAt={currentEvent?.end_date}
                maxBookingsPerStudent={currentEvent?.max_bookings_per_student}
                meetingTypes={currentEvent?.meetingTypes}
                noOfSessions={currentEvent?.sessions.length}
                openToParents={currentEvent?.open_parents}
                sessions={currentEvent?.sessions}
                vcAutoDisconnect={currentEvent?.vc_auto_disconnect}
              />
            }
          />

          <DashboardCard
            title="Next 5 Appointments"
            className="col-span-full lg:col-span-14 xl:col-span-13 3xl:col-span-10"
            loading={loading}
            body={<Schedule schedule={teacherEventSchedule?.data} />}
          />

          <DashboardCard
            title="Event Views"
            className="col-span-full md:col-span-24 lg:col-span-12 xl:col-span-11 3xl:col-span-7"
            loading={loading}
            body={
              <ViewStats
                noOfTotalViews={views?.total}
                noOfParentViews={views?.total_parents}
                noOfStaffViews={views?.total_staff}
                noOfTotalLiveViews={views?.total_live}
                noOfParentLiveViews={views?.total_parents_live}
                noOfStaffLiveViews={views?.total_staff_live}
                noOfTotalViewsToday={views?.total_today}
                noOfTotalViewsLast3Days={views?.total_last_3_days}
                handleClick={filter => handleShowEventViews(filter)}
              />
            }
          />

          <DashboardCard
            title="Event Summary"
            className="col-span-full lg:col-span-12 xl:col-span-13 3xl:col-span-8"
            loading={loading}
            body={
              <Summary
                firstAppointmentDateTime={firstAppointmentDateTime}
                lastAppointmentDateTime={lastAppointmentDateTime}
                description={currentEvent?.description}
                openToParents={currentEvent?.open_parents}
                noOfComments={communications?.no_of_comments}
                noOfComments_read={communications?.no_of_comments_read}
                noOfComments_unread={communications?.no_of_comments_unread}
                noOfNotifications={communications?.no_of_notifications}
                noOfNotifications_read={communications?.no_of_notifications_read}
                noOfNotifications_unread={communications?.no_of_notifications_unread}
              />
            }
          />

          <DashboardCard
            title="Attendance Stats"
            className="col-span-full md:col-span-12 lg:col-span-12 xl:col-span-12 3xl:col-span-8"
            body={
              <AttendanceDonut
                noOfAbsentSlots={slots?.attendance?.no_of_absent_slots}
                noOfAttendedSlots={slots?.attendance?.no_of_attended_slots}
                noOfUnknownSlots={slots?.attendance?.no_of_unknown_slots}
                noOfTotalSlots={slots?.no_of_total_slots}
              />
            }
          />

          <DashboardCard
            title="Availability Stats"
            className="col-span-full md:col-span-12 lg:col-span-12 xl:col-span-12 3xl:col-span-8"
            body={
              <AvailabilityDonut
                noOfAvailableSlots={slots?.no_of_available_slots}
                noOfBookedSlots={slots?.no_of_booked_slots}
                noOfDisabledSlots={slots?.no_of_disabled_slots}
                noOfTotalSlots={slots?.no_of_total_slots}
              />
            }
          />
        </div>
      </Page>
    </EventViewsTracker>
  )
}

export default StaffDashboard
