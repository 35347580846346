import React, { useContext, useEffect, useState } from "react"
import { DataTable, EllipsisDropdown, EventPageHeader, NoDataTablePlaceholder, Page } from "@components"
import { Chip } from "@nextui-org/react"
import { useLazyQuery, useQuery } from "@apollo/client"

import {
  GET_REPORT_COMMENTS,
  GET_REPORT_COMMENTS_SUMMARY,
  GET_REPORT_COMMENTS_PDF_DOWNLOAD,
  GET_REPORT_COMMENTS_XLS_DOWNLOAD_LINK,
} from "@graphql"

import {
  clearCache,
  downloadFile,
  formattedDateRange,
  getSessionFilters,
  humanReadableDuration,
  mySqlToLocal,
} from "@helpers"

import { useSetActiveSidebarLink, useSetCurrentEvent } from "@hooks"
import { ChatBubbleBottomCenterTextIcon, CheckIcon, XMarkIcon } from "@heroicons/react/24/outline"
import { ContextualSidebarContext, NotificationContext, UserContext } from "@context"
import { useParams } from "react-router-dom"
import { columns, defaultColumns, downloadOptions, filters } from "./data"
import { truncate } from "lodash"
import { Stats } from "./Stats"

const initialSelectedColumns =
  JSON.parse(window.localStorage.getItem("reports.event.comments.columns")) ?? defaultColumns

const Comments = () => {
  const { event: eventId } = useParams()
  const { user } = useContext(UserContext)
  const { setNotification } = useContext(NotificationContext)
  const { setContextualSidebar } = useContext(ContextualSidebarContext)
  const [isDownloading, setIsDownloading] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedColumns, setSelectedColumns] = useState(initialSelectedColumns)
  const [selectedFilters, setSelectedFilters] = useState(["all"])
  const [selectedSessionIds, setSelectedSessionIds] = useState(null)
  const [sortDescriptor, setSortDescriptor] = useState({ column: "created_at", direction: "descending" })
  const [refetching, setRefetching] = useState(false)
  const [page, setPage] = useState(1)
  const [perPage] = useState(50)

  useSetActiveSidebarLink("EVENT_REPORTS_COMMENTS", "EVENT_REPORTS")
  useSetCurrentEvent(eventId)

  useEffect(() => {
    window.localStorage.setItem("reports.event.comments.columns", JSON.stringify(selectedColumns))
  }, [selectedColumns])

  const summaryData = useQuery(GET_REPORT_COMMENTS_SUMMARY, {
    variables: {
      event_id: eventId,
    },
    fetchPolicy: "cache-first",
    pollInterval: 900000,
  })

  const [getCommentsPdfsDownload] = useLazyQuery(GET_REPORT_COMMENTS_PDF_DOWNLOAD, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data.reportCommentsPdfs.queued) {
        setNotification({
          title: "PDF Download Queued",
          content:
            "Your download has been queued. We'll send an email to the address you logged in with containing your download. This should take no longer than 5 minutes.",
          type: "success",
          open: true,
        })
      }

      if (!data.reportCommentsPdfs.queued) {
        downloadFile(data.reportCommentsPdfs.filename, data.reportCommentsPdfs.url)
      }

      setIsDownloading(false)
    },

    onError: ({ graphQLErrors }) => {
      if (graphQLErrors) {
        setNotification({ title: "Error", type: "danger", content: graphQLErrors[0].message, open: true })
        setIsDownloading(false)
      }
    },
  })

  const [getCommentsXlsDownloadLink] = useLazyQuery(GET_REPORT_COMMENTS_XLS_DOWNLOAD_LINK, {
    variables: { event_id: eventId },
    fetchPolicy: "network-only",
    onCompleted: data => {
      downloadFile(data.reportCommentsXls.filename, data.reportCommentsXls.url)
      setIsDownloading(false)
    },
  })

  const { error, data, loading, refetch } = useQuery(GET_REPORT_COMMENTS, {
    variables: {
      event_id: eventId,
      filters: selectedFilters,
      searchTerm,
      sessions: selectedSessionIds,
      page,
      perPage,
      orderBy: [
        {
          column: sortDescriptor.column.toUpperCase(),
          order: sortDescriptor.direction === "ascending" ? "ASC" : "DESC",
        },
      ],
    },
    fetchPolicy: "cache-first",
    pollInterval: 900000,
  })

  const refetchData = () => {
    setRefetching(true)
    summaryData.refetch()
    refetch().then(() => setRefetching(false))
  }

  const handleDownload = type => {
    if (type === "pdf") {
      setIsDownloading(true)
      getCommentsPdfsDownload({ variables: { event_id: eventId } })
    }

    if (type === "xls") {
      setIsDownloading(true)
      getCommentsXlsDownloadLink()
    }
  }

  const handleShowComment = item => {
    setContextualSidebar(prevState => ({
      ...prevState,
      open: true,
      title: `${item.commenter} / ${item.student}`,
      subTitle: `${formattedDateRange(item.starts_at, item.ends_at)}`,
      content: item.comment,
      size: "xl",
    }))
  }

  const handleSearchClear = () => {
    setSearchTerm("")
    setPage(1)
  }

  const handleSearchChange = searchTerm => {
    setSearchTerm(searchTerm)
    setPage(1)
  }

  const handleSortChange = sortDescriptor => {
    setSortDescriptor(sortDescriptor)
    setPage(1)
  }

  const handleToggleColumn = selectedColumns => {
    setSelectedColumns(Object.keys(columns).filter(column => selectedColumns.has(column)))
  }

  const handleToggleFilter = selectedFilters => {
    setSelectedFilters([...selectedFilters])
  }

  const handleToggleSessionFilter = selectedSessionIds => setSelectedSessionIds([...selectedSessionIds])

  const handleRenderCell = (item, column) => {
    if (column === "actions") {
      const items = [{ id: 1, handleClick: () => handleShowComment(item), caption: "View Comment" }]

      return <EllipsisDropdown items={items} />
    }

    if (column === "created_at") {
      return humanReadableDuration(mySqlToLocal(item["created_at"]))
    }

    if (column === "read") {
      return item["read"] ? (
        <CheckIcon className="h-4 w-4 text-success" />
      ) : (
        <XMarkIcon className="h-4 w-4 text-danger" />
      )
    }

    if (column === "comment") {
      return truncate(item["comment"], { length: 30 })
    }

    if (column === "starts_at") {
      return mySqlToLocal(item["starts_at"]).format("HH:mm")
    }

    if (column === "ends_at") {
      return mySqlToLocal(item["ends_at"]).format("HH:mm")
    }

    if (column === "type") {
      const color = item["type"] === "Parent" ? "bg-indigo-600" : "bg-green-600"

      const chipClasses = {
        base: `max-w-full w-full text-center ${color}`,
        content: "w-full text-white font-semibold text-xs",
      }

      return <Chip classNames={chipClasses}>{item["type"]}</Chip>
    }

    return item[column]
  }

  const handleRefetchData = () => {
    clearCache("reportComments", { $event_id: eventId })
    refetchData()
  }

  if (error) return `Error! ${error.message}`

  const sessionFilters = user?.currentEvent?.sessions ? getSessionFilters(user?.currentEvent?.sessions) : null
  const defaultSelectedSessionIds = sessionFilters !== null ? Object.keys(sessionFilters) : null

  return (
    <Page>
      <EventPageHeader
        title="Event Comments"
        icon={<ChatBubbleBottomCenterTextIcon className="page-header-icon" />}
        event={user?.currentEvent}
      />

      <Stats summary={summaryData} />

      <DataTable
        columns={columns}
        currentPage={page}
        currentSortOrder={sortDescriptor}
        data={data?.reportComments?.data}
        downloadOptions={downloadOptions}
        filters={filters}
        handleDownload={type => handleDownload(type)}
        handlePageChange={page => setPage(page)}
        handleRefetch={() => handleRefetchData()}
        handleRenderCell={(item, key) => handleRenderCell(item, key)}
        handleSearchChange={searchTerm => handleSearchChange(searchTerm)}
        handleSearchClear={handleSearchClear}
        handleSortChange={sortDescriptor => handleSortChange(sortDescriptor)}
        handleToggleColumn={selectedColumns => handleToggleColumn(selectedColumns)}
        handleToggleFilter={selectedFilters => handleToggleFilter(selectedFilters)}
        handleToggleSessionFilter={selectedSession => handleToggleSessionFilter(selectedSession)}
        isDownloading={isDownloading}
        isRefetching={refetching}
        loading={loading || refetching}
        noDataMessage={<NoDataTablePlaceholder title="No Data Found" message="No Data found! Try clearing any filters." />}
        paginatorInfo={data?.reportComments?.paginatorInfo}
        searchInputPlaceHolder="Search by commenter / student / teacher / lesson"
        searchTerm={searchTerm}
        selectedColumns={selectedColumns}
        selectedFilters={selectedFilters}
        sessionFilters={sessionFilters}
        selectedSessions={selectedSessionIds ?? defaultSelectedSessionIds}
        showDownloadButton={true}
        showRefetchButton={true}
      />
    </Page>
  )
}

export default Comments
