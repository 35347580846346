import { createContext, useState } from "react"

const SidebarContext = createContext()

const SidebarContextProvider = ({ children }) => {
  const [sidebar, setSidebar] = useState({
    activeLink: "EVENT_DASHBOARD",
    expandedLinks: {
      EVENT_REPORTS: false,
      SITE_DATA: false,
    },
    show: false, // mobile only
  })

  const activateLink = (linkKey, expandParent = null) => {
    setSidebar(prevState => {
      const newState = { ...prevState }

      if (expandParent) {
        newState.expandedLinks[expandParent] = true
      }

      newState.activeLink = linkKey

      return newState
    })
  }

  const showSidebar = (isOpen) => setSidebar(prevState => ({ ...prevState, show: isOpen }))
  const toogleSidebar = () => setSidebar(prevState => ({ ...prevState, show: !prevState.show }))

  return (
    <SidebarContext.Provider value={{ activateLink, showSidebar, toogleSidebar, sidebar, setSidebar }}>
      {children}
    </SidebarContext.Provider>
  )
}

export { SidebarContext, SidebarContextProvider }
