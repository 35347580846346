const downloadFile = (filename, href) => {
  const fileType = filename.split(".").pop()
  const anchor = document.createElement("a")
  anchor.href = href

  if (fileType.toLowerCase() === "pdf") {
    anchor.target = "_blank"
  }

  anchor.download = filename
  document.body.appendChild(anchor)
  anchor.click()
  document.body.removeChild(anchor)
}

export default downloadFile
