import { gql } from "@apollo/client"

const GET_LESSONS = gql`
  query (
    $searchTerm: String
    $page: Int!
    $perPage: Int!
    $clientId: ID!
    $orderBy: [ClientLessonsOrderByOrderByClause!]
    $where: ClientLessonsWhereWhereConditions
  ) {
    client(id: $clientId) {
      id

      lessons(searchTerm: $searchTerm, page: $page, first: $perPage, orderBy: $orderBy, where: $where) {
        data {
          id
          name
          studentCount
          local_id
          data_ref
          is_sync
          created_at
          updated_at

          subject {
            id
            name
          }

          teacher {
            id
            salutation
            forename
            surname
          }
        }

        paginatorInfo {
          total
          currentPage
          hasMorePages
          lastPage
          perPage
        }
      }
    }
  }
`

const GET_LESSON_STUDENTS = gql`
  query ($id: ID!) {
    lesson(id: $id) {
      id
      name
      students(orderBy: [{ column: SURNAME, order: ASC }]) {
        id
        forename
        surname
      }
    }
  }
`

export { GET_LESSONS, GET_LESSON_STUDENTS }
