import React from "react"
import ReactDOM from "react-dom/client"
import * as Sentry from '@sentry/react'
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { ApolloProvider } from "@apollo/client"
import { apolloClient } from "@services"

Sentry.init({
  dsn: "https://2dcccf48295c241f2aef6dc745497155@o1146767.ingest.us.sentry.io/4506982790266880",
  environment: process.env.REACT_APP_ENV,
  integrations: [],
});

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
