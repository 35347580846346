import React from "react"
import { Progress } from "@nextui-org/react"
import { CardStat, Divider, SimplePlaceholder } from "@components"

import {
  CheckBadgeIcon,
  CheckCircleIcon,
  RocketLaunchIcon,
  StarIcon,
  TrophyIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline"

import { formatDuration } from "@helpers"
import PropTypes from "prop-types"

const TopTeachers = ({ topTeachers = [] }) => {
  const iconsByIndex = {
    0: TrophyIcon,
    1: StarIcon,
    2: RocketLaunchIcon,
    3: CheckBadgeIcon,
    4: CheckCircleIcon,
  }

  const getProgressColor = percentage => (percentage < 50 ? "danger" : percentage < 100 ? "warning" : "success")

  if (topTeachers.length === 0) {
    return (
      <SimplePlaceholder icon={<UserCircleIcon className="h-40 w-auto text-indigo-500" />} title="No Stats Collated">
        <p className="mt-1 text-sm text-gray-500">
          Top 5 fastest booked groups will appear here when 75% of group slots have been booked.
        </p>
      </SimplePlaceholder>
    )
  }

  return (
    <div>
      {topTeachers.map((teacher, index) => (
        <div key={teacher.group_id}>
          <CardStat
            title={teacher.teacher}
            icon={iconsByIndex[index]}
            stat={`${formatDuration(teacher.book_time)}`}
            meta={`${teacher.no_of_booked_slots} / ${teacher.no_of_available_slots}`}
          >
            <div className="flex items-center -my-2">
              <Progress
                value={teacher.percentage_booked}
                aria-label="Percentage Booked"
                color={getProgressColor(teacher.percentage_booked)}
              />
              <span className="ml-3 text-lg">{teacher.percentage_booked}%</span>
            </div>
          </CardStat>

          {index < topTeachers.length - 1 && <Divider />}
        </div>
      ))}
    </div>
  )
}

TopTeachers.propTypes = {
  topTeachers: PropTypes.array,
}

export default TopTeachers
