import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from "@nextui-org/react"
import { AdjustmentsHorizontalIcon, ChevronDownIcon } from "@heroicons/react/20/solid"
import PropTypes from "prop-types"

const FilterSelector = ({ caption = "Filters", closeOnSelect = false, filters, selectedFilters, selectionMode = "multiple", toggleFilter }) => (
  <Dropdown>
    <DropdownTrigger className="hidden sm:flex flex-shrink-0"> 
      <Button
        startContent={<AdjustmentsHorizontalIcon className="h-5 w-auto" />}
        endContent={<ChevronDownIcon className="h-5 w-auto" />}
        variant="flat"
      >
        {caption}
      </Button>
    </DropdownTrigger>

    <DropdownMenu
      disallowEmptySelection
      aria-label="Filters"
      closeOnSelect={closeOnSelect}
      selectedKeys={selectedFilters}
      selectionMode={selectionMode}
      onSelectionChange={toggleFilter}
    >
      {Object.keys(filters).map(key => {
        return (
          <DropdownItem key={key} className="capitalize">
            {filters[key].name}
          </DropdownItem>
        )
      })}
    </DropdownMenu>
  </Dropdown>
)

FilterSelector.propTypes = {
  caption: PropTypes.string,
  closeOnSelect: PropTypes.bool,
  filters: PropTypes.object.isRequired,
  selectedFilters: PropTypes.array.isRequired,
  selectionMode: PropTypes.string,
  toggleFilter: PropTypes.func.isRequired,
}

export default FilterSelector
