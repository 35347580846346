import { useState } from "react"
import { NoDataTablePlaceholder, SearchInput } from "@components"
import { MinusIcon, PlusIcon } from "@heroicons/react/20/solid";
import { Checkbox, Chip, Spinner } from '@nextui-org/react';
import { orderBy } from "lodash"
import PropTypes from 'prop-types';

const GroupLessonStudents = ({ 
  handleToggleStudent, 
  students, 
  selectedStudents, 
  isLoading = false,
  isReadOnly = false
}) => {
  const [searchTerm, setSearchTerm] = useState("")

  const filteredOrderedStudents = orderBy(Object.keys(students).filter((studentId) => {
    if (searchTerm === "") return true
    const student = students[studentId]
    const studentName = `${student.forename} ${student.surname}`.toLowerCase()
    return studentName.includes(searchTerm.toLowerCase())
  }).map((studentId) => students[studentId]), ["forename", "surname"], ["asc", "asc"])

  const getStatus = (student, isSelected) => student.diff_status === "Match" && !isSelected ? "Removed" : student.diff_status
  
  const getStatusColor = (status) => status === "Added" ? "bg-green-100" : status === "Removed" ? "bg-red-100" : "bg-gray-50"
  
  const handleClearSearch = () => setSearchTerm("")

  const isStudentSelected = (student) => selectedStudents[student.id] !== undefined ? true : false

  return (
    <div className="grow">
      <h3 className="text-md font-semibold mb-2">Selected Students ({Object.keys(selectedStudents).length})</h3>

      <SearchInput
        classNames="mb-3"
        debounceMilliSeconds={300}
        placeholder="Search for a student"
        handleChange={(searchTerm) => setSearchTerm(searchTerm)}
        handleClear={() => handleClearSearch()}
      />

      <div className="bg-gray-50 rounded-lg h-[400px]">
        {!isLoading && filteredOrderedStudents.length === 0 && (
          <NoDataTablePlaceholder className="flex items-center justify-center h-full !my-0" />
        )}

        {isLoading && (
          <div className="flex items-center justify-center h-full">
            <Spinner /><p className="ml-2">Please wait fetching students...</p>
          </div>
        )}

        <div className="overflow-y-auto rounded-lg h-full">
          {!isLoading && filteredOrderedStudents.map((student) => {
            const isSelected = isStudentSelected(student)
            const status = getStatus(student, isSelected)
            const statusColor = getStatusColor(status)

            return (
              <div key={student.id} className={`flex items-center justify-between gap-2 py-1 px-3 border-b-1 border-gray-300 border-dashed ${statusColor}`}>
                <div className="flex w-full">
                  <Checkbox onChange={() => handleToggleStudent(student)} isSelected={isSelected} isDisabled={isReadOnly} />
                    
                  <div className="flex">
                    <p className="text-md">{student.forename} {student.surname}</p>
                    
                    {student.booking_slot_count > 0 && (
                      <Chip classNames={{ "base": "ml-2 p-1.5", "content": "font-semibold text-white" }} color="warning" size="sm" radius="full">
                        {student.booking_slot_count}
                      </Chip>
                    )}
                  </div>
                </div>

                {status === "Added" && <PlusIcon className="h-5 w-5 text-green-500" />}
                {status === "Removed" && <MinusIcon className="h-5 w-5 text-red-500" />}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

GroupLessonStudents.propTypes = {
  handleToggleStudent: PropTypes.func.isRequired,
  selectedStudents: PropTypes.object.isRequired,
  students: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  isReadOnly: PropTypes.bool,
}

export default GroupLessonStudents