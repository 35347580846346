import { cache } from "@services/graphql/client"

const clearCacheWithRegex = (id, regex) => {
  const cacheKeys = cache.extract()[id]

  Object.keys(cacheKeys).forEach(key => {
    if (key.match(regex)) {
      cache.evict({ id, fieldName: key })
    }
  })

  cache.gc()
}

export default clearCacheWithRegex
