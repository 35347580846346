import { gql } from "@apollo/client"

const GET_LESSON_AUTO_COMPLETES = gql`
  query client($clientId: ID!, $searchTerm: String!, $where: ClientLessonsWhereWhereConditions, $orderBy: [ClientLessonsOrderByOrderByClause!], $perPage: Int!, $page: Int!) {
    client(id: $clientId) {
      id
		
		  lessons(first: $perPage, page: $page, searchTerm: $searchTerm, where: $where, orderBy: $orderBy) {
		    data {
				  id
				  name
					
				  subject {
					  id
					  name
				  }

				  teacher {
					  id
					  forename
					  surname
					}
				}

				paginatorInfo {
				  currentPage
				  lastPage
				  perPage
				  total
				  hasMorePages
			  }
			}
		}
	}`

export { GET_LESSON_AUTO_COMPLETES }
