const columns = {
  select: { id: "select", name: "", sortable: false, deselectable: false },
  group: { id: "group", name: "Group", sortable: true, deselectable: false },
  lesson: { id: "lesson", name: "Lesson", sortable: true, deselectable: false },
  subject: { id: "subject", name: "Subject", sortable: true, deselectable: false },
  studentCounts: { id: "studentCount", name: "Students", sortable: false, deselectable: true, align: "text-end" },
  bookingCounts: { id: "bookingCount", name: "Bookings", sortable: false, deselectable: true, align: "text-end" },
  actions: { id: "actions", name: "Actions", sortable: false, deselectable: false, align: "text-end" },
}

const defaultColumns = [
  "select",
  "group",
  "lesson",
  "subject",
  "studentCounts",
  "bookingCounts",
  "actions",
]

const downloadOptions = [{ key: "xls", name: "Download All XLS" }]

const filters = {
  all: { id: "all", name: "All" },
  has_bookings: { id: "has_bookings", name: "Has Bookings" },
  no_bookings: { id: "no_bookings", name: "No Bookings" },
  student_mismatch: { id: "student_mismatch", name: "Student Mismatch" },
  unassigned: { id: "unassigned", name: "Unassigned" },
}

export { columns, defaultColumns, downloadOptions, filters }
