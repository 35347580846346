import { gql } from "@apollo/client"

const ADD_GUARDIANS_TO_INVITATION = gql` 
  mutation includeGuardians($input: IncludeGuardiansInput!) {
    includeGuardians(input: $input) {
      id

      user {
        id
      }
      
      guardian {
        id
      }

      invitationGroups {
        id
        lesson_id
        student_id
        group_id
      }
    }
  }
`

export { ADD_GUARDIANS_TO_INVITATION }