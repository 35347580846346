import { memo } from "react"
import { SidebarDesktop, SidebarMobile } from "@components"

const Sidebar = () => (
  <div>
    <SidebarMobile className="xl:hidden" />
    <SidebarDesktop className="hidden xl:flex" />
  </div>
)

export default memo(Sidebar)
