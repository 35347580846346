import PropTypes from "prop-types"

const SimplePlaceholder = ({ children, icon, title }) => (
  <div className="relative flex items-center flex-col w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center">
    <span>{icon}</span>
    <span className="block text-lg font-semibold text-gray-900">{title}</span>
    {children && children}
  </div>
)

SimplePlaceholder.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
}

export default SimplePlaceholder
