import { ChartPlaceholder } from "@components"
import Chart from "react-apexcharts"
import PropTypes from "prop-types"
import { donutOptions } from "../Options"

const EventViewsByPlatformDonut = ({ series, labels }) => {
  const graph = {
    options: { ...donutOptions, chart: { ...donutOptions.chart, id: "event-views-by-platform-donut" }, labels },
    series,
  }

  if (series.length === 0) {
    return (
      <ChartPlaceholder
        className="h-48"
        caption="Event views by platform will be displayed when data is available."
        type="donut"
      />
    )
  }

  return <Chart options={graph.options} series={graph.series} type="donut" height={350} />
}

EventViewsByPlatformDonut.propTypes = {
  labels: PropTypes.array.isRequired,
  series: PropTypes.array.isRequired,
}

export default EventViewsByPlatformDonut
