import { gql } from "@apollo/client"

const GET_INVITATIONS_MATRIX_XLS_DOWNLOAD_LINK = gql`
  query invitationsMatrixXlsDownload($event_id: ID!) {
    invitationsMatrixXlsDownload(event_id: $event_id) {
      filename
      url
      type
      queued
    }
  }
`

export { GET_INVITATIONS_MATRIX_XLS_DOWNLOAD_LINK }