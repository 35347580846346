import { useContext } from "react"
import { SidebarContext } from "@context"
import PropTypes from "prop-types"

const BurgerMenu = ({ className = "" }) => {
  const { showSidebar } = useContext(SidebarContext)

  return (
    <button onClick={() => showSidebar(true)} className={`flex flex-col justify-center items-center ${className}`}>
      <span className="bg-white block transition-all duration-300 ease-out h-[3px] w-6 rounded-sm"></span>
      <span className="bg-white block transition-all duration-300 ease-out h-[3px] w-6 rounded-sm my-1"></span>
      <span className="bg-white block transition-all duration-300 ease-out h-[3px] w-6 rounded-sm"></span>
    </button>
  )
}

BurgerMenu.propTypes = {
  className: PropTypes.string,
}

export default BurgerMenu