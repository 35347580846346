import { mySqlToLocal } from "@helpers"

const formattedDateRange = (
  starts,
  ends,
  showTime = true,
  dayFormat = "ddd D",
  monthFormat = "MMM",
  yearFormat = "YYYY",
) => {
  const startDate = mySqlToLocal(starts)
  const endDate = mySqlToLocal(ends)

  if (startDate.isSame(ends, "day")) {
    if (showTime) {
      return `${startDate.format(`${dayFormat} ${monthFormat} ${yearFormat}`)} ${startDate.format(
        "H:mm",
      )} - ${endDate.format("H:mm")}`
    } else {
      return `${startDate.format(`${dayFormat} ${monthFormat} ${yearFormat}`)}`
    }
  }

  return `${startDate.format(`${dayFormat} ${monthFormat}`)} - ${endDate.format(
    `${dayFormat} ${monthFormat} ${yearFormat}`,
  )}`
}

export default formattedDateRange
