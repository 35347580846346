import { useQuery } from "@apollo/client"
import { Chip } from "@nextui-org/react"
import { LineLoader, SimplePlaceholder } from "@components"
import { CircleStackIcon } from "@heroicons/react/24/outline"
import { GET_LESSON_STUDENTS } from "@graphql"
import PropTypes from "prop-types"

const LessonStudentsList = ({ lesson }) => {
  const { loading, error, data } = useQuery(GET_LESSON_STUDENTS, {
    variables: {
      fetchPolicy: "cache-first",
      id: lesson.id,
    },
  })

  if (loading) return <LineLoader message="Loading lessons..." classNames="mt-8" />

  if (error) return "error"

  if (data.lesson.students.length === 0) {
    return (
      <SimplePlaceholder icon={<CircleStackIcon className="h-20 w-auto" />} title="No students found for this lesson">
        <p className="mt-1 text-sm text-gray-500">
          If you believe this lesson should have associated students then please check your MIS and Xporter integration.
        </p>
      </SimplePlaceholder>
    )
  }

  return (
    <div>
      <Chip className="float-right" color="primary">
        {data.lesson.students.length} students
      </Chip>

      <ul className="clear-right divide-y divide-gray-100">
        {data.lesson.students.map(student => (
          <li key={student.id} className="flex justify-between py-2">
            {student.surname}, {student.forename || ""}
          </li>
        ))}
      </ul>
    </div>
  )
}

LessonStudentsList.propTypes = {
  lesson: PropTypes.object.isRequired,
}

export default LessonStudentsList
