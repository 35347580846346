import { gql } from "@apollo/client"

const GET_REPORT_PARENT_BOOKINGS = gql`
  query (
    $event_id: ID!
    $searchTerm: String!
    $page: Int!
    $perPage: Int!
    $orderBy: [QueryReportParentBookingsOrderByOrderByClause!]
    $filters: [String!]
  ) {
    reportParentBookings(
      event_id: $event_id
      page: $page
      first: $perPage
      orderBy: $orderBy
      searchTerm: $searchTerm
      filters: $filters
    ) {
      data {
        id
        guardian
        email
        mobile
        mis_phone
        students
        last_active
        no_of_slots
        no_of_booked_slots
        booked_slots_ratio
        status
        type
      }

      paginatorInfo {
        total
        currentPage
        hasMorePages
        lastPage
        perPage
      }
    }
  }
`

export { GET_REPORT_PARENT_BOOKINGS }
