import { gql } from "@apollo/client"

const GET_INVITATIONS_MATRIX = gql`  
  query invitationsMatrix(
    $page: Int!, 
    $perPage: Int!, 
    $orderBy: [QueryInvitationsMatrixOrderByOrderByClause!]  
    $input: InvitationsMatrixInput!
    $where: QueryInvitationsMatrixWhereWhereConditions
  ) {
	  invitationsMatrix(first: $perPage, page: $page, input: $input, orderBy: $orderBy, where: $where) {
		  data {
			  id
        invitation_id
        student_id
        student
        guardian
        email
        status
        meeting_type
        last_sent_at
        contact_sequence
        legal_guardian 
        lives_with	
		  }
      
      paginatorInfo {
        total
        currentPage
        hasMorePages
        lastPage
        perPage
      }
	  }
  } 
`

export { GET_INVITATIONS_MATRIX }