import { useContext, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { Button, Chip } from "@nextui-org/react"
import { EventIconSummary, EventStatusBadge, LineLoader, SimplePlaceholder, SearchInput } from "@components"
import { UserContext } from "@context"
import { CalendarDaysIcon, CircleStackIcon } from "@heroicons/react/24/outline"
import { GET_EVENTS_LIST } from "@graphql"
import { hasMeetingType, mySqlToLocal } from "@helpers"
import { truncate } from "lodash"

const EventList = () => {
  const { user } = useContext(UserContext)
  const [searchTerm, setSearchTerm] = useState("")
  const searchInputRef = useRef(null)

  const { loading, error, data } = useQuery(GET_EVENTS_LIST, {
    variables: {
      fetchPolicy: "cache-first",
      searchTerm,
      clientId: user.client.id,
      orderBy: [{ sessions: { aggregate: "MIN", column: "STARTS_AT" }, order: "DESC" }],
      page: 1,
      perPage: 50,
    },
  })

  const handleClearSearch = () => {
    searchInputRef.current.value = ""
    setSearchTerm("")
  }

  const getEventStartDate = startDate => (startDate ? mySqlToLocal(startDate).format("DD/MM/YYYY") : "No Date Set")

  if (error) return "error"

  return (
    <div>
      <SearchInput
        innerRef={searchInputRef}
        classNames="mb-3"
        debounceMilliSeconds={300}
        placeholder="Search by title / description"
        handleChange={searchTerm => setSearchTerm(searchTerm)}
        handleClear={() => setSearchTerm("")}
      />

      {loading && <LineLoader message="Loading events..." classNames="mt-8" />}

      {!loading && data.client.events.data.length === 0 && (
        <SimplePlaceholder icon={<CircleStackIcon className="h-20 w-auto" />} title="No events found">
          <p className="mt-1 text-sm text-gray-500">
            You currently have no events.{" "}
            {searchTerm.length > 0 && (
              <span>
                Try a different search term.{" "}
                <Button className="mt-4" color="primary" fullWidth onClick={() => handleClearSearch()}>
                  Clear Search
                </Button>
              </span>
            )}
          </p>
        </SimplePlaceholder>
      )}

      {!loading && data.client.events.data.length > 0 && (
        <ul className="divide-y divide-gray-100">
          {data.client.events.data.map(event => {
            return (
              <li key={event.id}>
                <Link to={`/event/${event.id}/dashboard`}>
                  <div className="flex items-center justify-between py-2">
                    <div className="mr-3">
                      <h1 className="text-md font-semibold leading-6 text-gray-900">{event.title}</h1>

                      <p className="mt-1 text-sm leading-5 text-gray-500 mb-2">
                        {truncate(event.description, { length: 140 })}
                      </p>

                      <EventIconSummary
                        isVenue={hasMeetingType("VENUE", event.meetingTypes)}
                        isPhone={hasMeetingType("PHONE", event.meetingTypes)}
                        isVc={hasMeetingType("VC", event.meetingTypes)}
                        isVcTimer={event.vc_auto_disconnect}
                        isOpenToParents={event.open_parents}
                        isOpenToStaff={event.open_staff}
                      />
                    </div>

                    <div className="flex flex-col">
                      <Chip className="mb-1" size="sm" color="primary">
                        <span className="flex items-center px-2 text-xs font-semibold">
                          <CalendarDaysIcon className="mr-2 h-4" />
                          {getEventStartDate(event.start_date)}
                        </span>
                      </Chip>

                      <EventStatusBadge size="sm" startDate={event.start_date} endDate={event.end_date} />
                    </div>
                  </div>
                </Link>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default EventList
