import { Button } from "@nextui-org/react"
import { ErrorPage } from "@components"
import { Link } from "react-router-dom"
import { ReactComponent as PlugSvg } from "@svgs/graphs/plug.svg"

const PageNotFound = () => (
  <ErrorPage
    errorCode={404}
    title="Page Not Found"
    subTitle="Sorry, the page you are looking for does not exist."
  >
    <Button size="lg" variant="flat" className="bg-blue-500 mt-4 text-white">
      <Link to={`/`}>Take me home</Link>
    </Button>

    <PlugSvg />
  </ErrorPage>
)

export default PageNotFound
