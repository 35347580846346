import { memo } from "react"
import { Link } from "react-router-dom"
import { SBLogo } from "@components"
import { getSbUrl } from "@helpers"
import { authService } from "@services"
import { useApolloClient } from "@apollo/client"
import dayjs from "dayjs"

const Footer = () => {
  const client = useApolloClient()

  return (
    <div className="bg-footer p-8 text-white text-center mt-4 relative z-40">
      <div className="flex gap-y-2 justify-between items-start">
        <div className="flex flex-col items-start gap-1">
          <p className="text-base font-semibold opacity-70">Explore</p>
          <a href="https://forms.office.com/r/q46Dn6uVK4" target="_blank" rel="noreferrer" className="text-sm">Change Request</a>
          <Link to={`/feedback`} className="leading-5">
            <span className="text-sm">Feedback</span>
          </Link>

          <a href={getSbUrl("/apps/administration/support.php")} className="text-sm">Support</a>
          <button onClick={() => authService.logout(client, getSbUrl("/apps/shared/signout.php"))} className="text-sm">Logout</button>
        </div>

        <div className="flex flex-col gap-y-2 items-center">
          <p className="text-base font-semibold opacity-70">Powered by</p>
          <SBLogo className="h-16 w-16" />
          <p className="text-sm">&copy; {dayjs().year()} SchoolBooking Ltd</p>
        </div>

        <div className="flex flex-col gap-y-1 items-end">
          <p className="text-base font-semibold opacity-70">Build</p>
          <p className="text-base">{process.env.REACT_APP_SB_BUILD_NO || '1.0.0'}</p>
        </div>
      </div>
    </div>
  )
}

export default memo(Footer)
