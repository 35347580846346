import { ReactComponent as DataSvg } from "@svgs/data.svg"
import PropTypes from "prop-types"

const NoDataTablePlaceholder = ({ 
  children = null,
  className = "", 
  title = "No data found", 
  message = "No Data found! Try clearing any filters." 
}) => (
  <div className={`flex items-center flex-col text-center my-7 ${className}`}>
    <DataSvg className="max-w-[150px] mb-2" />
    <h1 className="text-xl font-semibold mb-0 text-blue-400">{title}</h1>
    <p className="text-base leading-7">{message}</p>
    {children && children}
  </div>
)

NoDataTablePlaceholder.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string
}

export default NoDataTablePlaceholder