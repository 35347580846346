const decodeToken = token => {
  if (token === null) return null

  try {
    return JSON.parse(window.atob(token.split(".")[1]))
  } catch (err) {
    return null
  }
}

export default decodeToken
