const palette = {
  subject: [
    [108, 160, 220],
    [148, 112, 212],
    [200, 135, 205],
    [85, 120, 170],
    [150, 180, 210],
    [130, 130, 130],
    [105, 89, 205],
    [85, 85, 145],
    [220, 120, 150],
    [190, 135, 210],
    [120, 150, 210],
    [145, 112, 175],
    [200, 135, 150],
    [105, 130, 150],
    [190, 160, 210],
    [160, 110, 210],
    [220, 85, 145],
    [140, 120, 180],
    [155, 135, 200],
    [180, 105, 205],
    [190, 85, 160],
    [100, 130, 180],
    [160, 120, 160],
    [180, 180, 230],
    [220, 85, 190],
    [140, 110, 180],
    [210, 130, 145],
    [105, 160, 200],
    [130, 90, 180],
    [210, 180, 220],
    [160, 160, 210],
    [220, 120, 190],
    [85, 100, 170],
    [190, 150, 210],
    [180, 105, 190],
    [140, 90, 200],
    [150, 110, 190],
    [85, 105, 200],
    [180, 135, 180],
    [130, 140, 210],
    [105, 160, 230],
    [200, 120, 210],
    [220, 130, 200],
    [150, 120, 200],
    [180, 150, 230],
    [120, 130, 180],
    [85, 85, 200],
    [190, 110, 230],
    [110, 160, 210],
    [170, 85, 200],
    [210, 140, 210],
    [85, 120, 210],
    [200, 150, 190],
    [130, 120, 220],
    [145, 105, 230],
    [220, 140, 230],
    [190, 135, 230],
    [160, 85, 200],
    [85, 105, 150],
    [120, 110, 190],
    [230, 105, 210],
    [150, 85, 200],
    [105, 130, 200],
    [180, 140, 200],
    [140, 140, 180],
    [210, 130, 200],
    [85, 105, 120],
    [210, 150, 220],
    [100, 160, 210],
    [130, 100, 190],
    [220, 140, 200],
    [170, 135, 180],
    [210, 120, 200],
    [120, 140, 170],
    [85, 100, 140],
    [110, 120, 190],
    [120, 85, 210],
    [200, 135, 210],
    [85, 110, 150],
    [190, 130, 200],
    [160, 120, 180],
    [200, 110, 190],
    [220, 85, 210],
    [180, 120, 190],
    [210, 150, 210],
    [85, 85, 160],
    [220, 85, 150],
    [230, 120, 210],
    [100, 130, 180],
    [140, 120, 200],
    [150, 105, 220],
    [200, 130, 210],
    [85, 120, 170],
    [160, 85, 190],
    [120, 140, 190],
    [190, 85, 200],
    [180, 135, 210],
    [170, 150, 200],
    [200, 110, 210],
    [130, 110, 200],
    [105, 140, 200],
    [160, 120, 220]
  ],

  teacher: [
    [85, 85, 170],
    [170, 85, 85],
    [85, 170, 170],
    [170, 85, 170],
    [170, 170, 85],
    [120, 85, 190],
    [85, 120, 190],
    [190, 85, 120],
    [190, 120, 85],
    [85, 190, 120],
    [120, 190, 85],
    [85, 120, 120],
    [190, 85, 190],
    [190, 190, 85],
    [120, 190, 190],
    [85, 190, 190],
    [85, 85, 120],
    [170, 120, 85],
    [120, 85, 120],
    [85, 170, 85],
    [170, 85, 120],
    [85, 85, 190],
    [85, 170, 120],
    [190, 170, 85],
    [170, 85, 190],
    [120, 120, 85],
    [85, 170, 190],
    [170, 120, 120],
    [190, 85, 85],
    [120, 170, 85],
    [190, 120, 170],
    [170, 190, 85],
    [85, 190, 85],
    [85, 85, 85],
    [120, 120, 170],
    [120, 85, 85],
    [85, 120, 85],
    [85, 85, 190],
    [190, 170, 120],
    [85, 170, 85],
    [170, 190, 120],
    [120, 190, 120],
    [170, 85, 170],
    [85, 120, 190],
    [190, 85, 170],
    [85, 190, 85],
    [170, 170, 170],
    [120, 120, 85],
    [85, 120, 170],
    [190, 190, 120],
    [170, 120, 170],
    [190, 120, 120],
    [170, 170, 120],
    [85, 190, 120],
    [190, 120, 85],
    [170, 190, 85],
    [120, 85, 170],
    [85, 85, 120],
    [85, 85, 170],
    [190, 120, 190],
    [120, 170, 190],
    [85, 170, 85],
    [85, 190, 170],
    [190, 85, 85],
    [85, 170, 120],
    [120, 85, 85],
    [190, 85, 170],
    [120, 190, 85],
    [120, 170, 85],
    [85, 85, 120],
    [85, 190, 120],
    [190, 120, 170],
    [170, 120, 85],
    [85, 85, 170],
    [190, 85, 120],
    [170, 85, 190],
    [120, 85, 190],
    [120, 170, 190],
    [85, 120, 85],
    [170, 85, 120],
    [85, 85, 190],
    [120, 170, 170],
    [190, 120, 85],
    [85, 190, 190],
    [170, 170, 85],
    [190, 170, 120],
    [120, 190, 120],
    [85, 120, 120],
    [120, 120, 170],
    [190, 85, 85],
    [85, 85, 120],
    [85, 190, 85],
    [85, 120, 170],
    [120, 85, 170],
    [85, 190, 170],
    [170, 170, 120],
    [170, 120, 120],
    [120, 190, 170],
    [170, 190, 170],
    [120, 170, 120],
    [190, 170, 85],
    [120, 85, 120],
    [85, 120, 190],
    [170, 120, 85]
  ]
};

const stringToColor = (str, colorPalette = "all", targetLuminance = 50, numColors = 100) => 
{
  const colors = palette[colorPalette];

  if (str === null || str === undefined || str.length === 0) {
    return '#c0bcc2';
  }

  // Simple hash function to convert the string to an integer
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Select color based on hash
  const colorIndex = Math.abs(hash) % numColors;
  const rgb = colors[colorIndex];

  // Convert RGB to hex
  const color = `#${rgb.map(value => ("00" + value.toString(16)).slice(-2)).join('')}`;

  return color;
}

export default stringToColor