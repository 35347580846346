import { gql } from "@apollo/client"

const GET_REPORT_PARENT_ITINERARY = gql`
  query ($event_id: ID!, $user_ids: [ID!], $page: Int!, $perPage: Int!, $searchTerm: String!) {
    reportParentItinerary(
      event_id: $event_id
      user_ids: $user_ids
      page: $page
      first: $perPage
      searchTerm: $searchTerm
    ) {
      data {
        id
        group
        session
        parent
        starts_at
        ends_at
        students
        lesson
        subject
        location
        type
      }

      paginatorInfo {
        total
        currentPage
        hasMorePages
        lastPage
        perPage
      }
    }
  }
`

export { GET_REPORT_PARENT_ITINERARY }
