import {
  CalendarDaysIcon,
  CheckBadgeIcon,
  DocumentCheckIcon,
  ExclamationCircleIcon,
  ReceiptPercentIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline"

import { StatCardCompact } from "@components"
import PropTypes from "prop-types"

const Stats = ({ summary = {} }) => {
  const {
    total_invites = 0,
    total_bookings = 0,
    active_accounts = 0,
    cancelled = 0,
    not_active_accounts = 0,
    response_rate = 0,
  } = summary?.data?.reportParentBookingsSummary || {}

  return (
    <div className="my-5 grid grid-cols-1 gap-5 sm:grid-cols-3 2xl:grid-cols-6">
      <StatCardCompact
        title="Invites"
        stat={total_invites}
        icon={<DocumentCheckIcon className="h-10 w-10 text-white" aria-hidden="true" />}
      />

      <StatCardCompact
        title="Bookings"
        stat={total_bookings}
        icon={<CalendarDaysIcon className="h-10 w-10 text-white" aria-hidden="true" />}
      />

      <StatCardCompact
        title="Active Accounts"
        stat={active_accounts}
        icon={<CheckBadgeIcon className="h-10 w-10 text-white" aria-hidden="true" />}
      />

      <StatCardCompact
        title="Unactive Accounts"
        stat={not_active_accounts}
        icon={<ExclamationCircleIcon className="h-10 w-10 text-white" aria-hidden="true" />}
      />

      <StatCardCompact
        title="Cancelled"
        stat={`${cancelled}`}
        icon={<XCircleIcon className="h-10 w-10 text-white" aria-hidden="true" />}
      />

      <StatCardCompact
        title="Response Rate"
        stat={`${response_rate}%`}
        icon={<ReceiptPercentIcon className="h-10 w-10 text-white" aria-hidden="true" />}
      />
    </div>
  )
}

Stats.propTypes = {
  summary: PropTypes.object,
}

export default Stats
