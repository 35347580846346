import { 
  AcademicCapIcon,
  CalculatorIcon,
  DocumentIcon,
  DocumentCheckIcon,
  InboxArrowDownIcon, 
  InboxIcon,
} from "@heroicons/react/24/outline"

import { StatCardCompact } from "@components"
import PropTypes from "prop-types"

const Stats = ({ 
  total = 0,
  noOfMatched = 0,
  noOfUnmatched = 0,
  noOfInviteEmailsSent = 0,
  noOfInviteEmailsNotSent = 0,
  noOfStudents = 0,
}) => (
  <div className="grid grid-cols-1 gap-5 my-5 sm:grid-cols-3 2xl:grid-cols-6">
    <StatCardCompact
      title="Total"
      stat={total}
      icon={<CalculatorIcon className="w-10 h-10 text-white" aria-hidden="true" />}
    />

    <StatCardCompact
      title="Matched"
      stat={noOfMatched}
      icon={<DocumentCheckIcon className="w-10 h-10 text-white" aria-hidden="true" />}
    />

    <StatCardCompact
      title="Not Matched"
      stat={noOfUnmatched}
      icon={<DocumentIcon className="w-10 h-10 text-white" aria-hidden="true" />}
    />

    <StatCardCompact
      title="Emails Sent"
      stat={noOfInviteEmailsSent}
      icon={<InboxArrowDownIcon className="w-10 h-10 text-white" aria-hidden="true" />}
    />

    <StatCardCompact
      title="Emails Pending"
      stat={noOfInviteEmailsNotSent}
      icon={<InboxIcon className="w-10 h-10 text-white" aria-hidden="true" />}
    />

    <StatCardCompact
      title="Students"
      stat={noOfStudents}
      icon={<AcademicCapIcon className="w-10 h-10 text-white" aria-hidden="true" />}
    />
  </div>
)

Stats.propTypes = {
  total: PropTypes.number,
  noOfMatched: PropTypes.number,
  noOfUnmatched: PropTypes.number,
  noOfInviteEmailsSent: PropTypes.number,
  noOfInviteEmailsNotSent: PropTypes.number,
  noOfStudents: PropTypes.number,
}

export default Stats
