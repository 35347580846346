import { gql } from "@apollo/client"

const GET_REPORT_TEACHER_ITINERARY_PDF_DOWNLOAD = gql`
  query ($event_id: ID!, $group_ids: [ID!]) {
    reportTeacherItineraryPdfs(event_id: $event_id, group_ids: $group_ids) {
      filename
      type
      url
      queued
    }
  }
`

export { GET_REPORT_TEACHER_ITINERARY_PDF_DOWNLOAD }
