import { ContextualSidebar, Footer, Notification, Sidebar, TopNavbar } from "@components"
import PropTypes from "prop-types"

const Page = ({ backgroundImage = null, classNames = "", children }) => (
  <div className={`bg-gray-100 ${classNames}`} style={{ backgroundImage: `url(${backgroundImage})` }}>
    <TopNavbar />

    <div className="pt-16 flex">
      <ContextualSidebar />

      <Sidebar />

      <Notification />

      <div className="flex-grow">
        <main>
          <div className="px-6 min-h-screen">{children}</div>
        </main>
      </div>
    </div>

    <Footer />
  </div >
)

Page.propTypes = {
  backgroundImage: PropTypes.string,
  classNames: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Page
