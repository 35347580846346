import { ReactComponent as Phone } from "@svgs/phone.svg"
import { ReactComponent as Venue } from "@svgs/venue.svg"
import { ReactComponent as VideoConference } from "@svgs/vc.svg"

const meetingTypes = {
  PHONE: { id: 2, image: <Phone className="h-16" />, label: "Telephone", description: "Attend over the phone (we will call you)." },
  VC: { id: 3, image: <VideoConference className="h-16" />, label: "Video Call", description: "Attend with in-app video conferencing." },
  VENUE: { id: 1, image: <Venue className="h-16" />, label: "Venue", description: "Attend in person." },
}

export {
  meetingTypes
}