import React from "react"
import { Progress } from "@nextui-org/react"
import { CardStat, Divider } from "@components"
import { DocumentCheckIcon, UserCircleIcon, UserGroupIcon, XCircleIcon } from "@heroicons/react/24/outline"
import PropTypes from "prop-types"

const HealthStats = ({
  activeAccountsRatio = 0,
  noOfActiveAccounts = 0,
  noOfDisabledSlots = 0,
  noOfOverSubscribedGroups = 0,
  noOfOverSubscribedGroupsRatio = 0,
  noOfFullyBookedGroups = 0,
  noOfFullyBookedGroupsRatio = 0,
  noOfUnactiveAccounts = 0,
  noOfUnassignedGroups = 0,
  noOfUnassignedGroupsRatio = 0,
  totalNoOfAccounts = 0,
  totalNoOfGroups = 0,
  totalNoOfSlots = 0,
}) => {
  const getProgressColor = percentage => (percentage < 50 ? "danger" : percentage <= 75 ? "warning" : "success")
  const disableSlotsRatio = noOfDisabledSlots > 0 ? Math.round((noOfDisabledSlots / totalNoOfSlots) * 100, 0) : 0

  return (
    <React.Fragment>
      <CardStat
        title="Active Accounts"
        icon={UserCircleIcon}
        stat={`${Math.round(activeAccountsRatio, 0)}%`}
        meta={`${noOfActiveAccounts} active | ${noOfUnactiveAccounts} unactive`}
      >
        <Progress
          value={activeAccountsRatio}
          color={getProgressColor(activeAccountsRatio)}
          aria-label="Active Accounts"
        />
      </CardStat>

      <Divider />

      <CardStat
        title="Disabled Slots"
        icon={UserGroupIcon}
        stat={`${disableSlotsRatio}%`}
        meta={`${noOfDisabledSlots} disabled | ${totalNoOfSlots} total`}
      >
        <Progress value={disableSlotsRatio} aria-label="Percentage of disabled slots" />
      </CardStat>

      <Divider />

      <CardStat
        title="Oversubscribed Groups"
        icon={UserGroupIcon}
        stat={`${Math.round(noOfOverSubscribedGroupsRatio, 0)}%`}
        meta={`${noOfOverSubscribedGroups} over | ${totalNoOfGroups - noOfOverSubscribedGroups} free`}
      >
        <Progress value={noOfOverSubscribedGroupsRatio} aria-label="Over Subscribed Groups" />
      </CardStat>

      <Divider />

      <CardStat
        title="Fully Booked Groups"
        icon={DocumentCheckIcon}
        stat={`${Math.round(noOfFullyBookedGroupsRatio, 0)}%`}
        meta={`${noOfFullyBookedGroups} booked | ${totalNoOfGroups - noOfFullyBookedGroups} free`}
      >
        <Progress value={noOfFullyBookedGroupsRatio} aria-label="Fully Booked Groups" />
      </CardStat>

      <Divider />

      <CardStat
        title="Unassigned Groups"
        icon={XCircleIcon}
        stat={`${Math.round(noOfUnassignedGroupsRatio, 0)}%`}
        meta={`${noOfUnassignedGroups} unassigned | ${totalNoOfGroups - noOfUnassignedGroups
          } assigned | total ${totalNoOfGroups}`}
      >
        <Progress value={noOfUnassignedGroupsRatio} aria-label="Unassigned Groups" />
      </CardStat>
    </React.Fragment>
  )
}

HealthStats.propTypes = {
  activeAccountsRatio: PropTypes.number,
  noOfActiveAccounts: PropTypes.number,
  noOfDisabledSlots: PropTypes.number,
  noOfOverSubscribedGroups: PropTypes.number,
  noOfOverSubscribedGroupsRatio: PropTypes.number,
  noOfFullyBookedGroups: PropTypes.number,
  noOfFullyBookedGroupsRatio: PropTypes.number,
  noOfUnactiveAccounts: PropTypes.number,
  noOfUnassignedGroups: PropTypes.number,
  noOfUnassignedGroupsRatio: PropTypes.number,
  totalNoOfGroups: PropTypes.number,
  totalNoOfAccounts: PropTypes.number,
  totalNoOfSlots: PropTypes.number,
}

export default HealthStats
