import { useParams } from "react-router-dom"
import { authService } from "@services"
import { AuthError } from "@components"

const AutoLogin = () => {
  const { details } = useParams()

  try {
    const authObject = JSON.parse(atob(details))
    authService.login(authObject.token)
    return window.location.replace(`${authObject.redirectTo}`)
  } catch (err) {
    return (
      <AuthError />
    )
  }
}

export default AutoLogin
