import { gql } from "@apollo/client"

const GET_MANAGE_BOOKINGS = gql`
  query (
    $eventId: ID!
    $perPage: Int!
    $page: Int!
		$searchTerm: String
		$orderBy: [QueryManageBookingsOrderByOrderByClause!]
		$where: QueryManageBookingsWhereWhereConditions
		$bookingSlotIds: [ID!]
  ) {
    manageBookings(
			first: $perPage, 
			page: $page, 
			event_id: $eventId, 
			searchTerm: $searchTerm, 
			orderBy: $orderBy
			where: $where
			bookingSlotIds: $bookingSlotIds
		) {
		    data {
				  id
				  event_id
				  group
				  lesson
				  student
				  parent
				  booked_by_user
				  booked_by_user_type
				  email
				  starts_at
				  ends_at
				  session
				  attendance_status
				  booked_at
			  }
      
			  paginatorInfo {
				  currentPage
				  lastPage
				  perPage
				  total
				  hasMorePages
			  }
		  }
    }
`

export { GET_MANAGE_BOOKINGS }