import React, { useState } from 'react'
import { Alert, CustomModal } from '@components'

import {
  Button, 
  Checkbox, 
  ModalContent, 
  ModalHeader, 
  ModalBody, 
  ModalFooter, 
  Textarea 
} from '@nextui-org/react';

import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline"
import PropTypes from 'prop-types';

const DeleteLessonsConfirmationModal = ({
  bookingSlotIds = [],
  error = null,
  handleClose, 
  handleDelete, 
  handlePreviewEmail, 
  isDeleting = false, 
  isLoadingPreview = false,
  noOfBookingSlotsToDelete = 0, 
  noOfLessonsToDelete = 0,
  show = false 
}) => {
  const [sendEmail, setSendEmail] = useState(true)
  const [intro, setIntro] = useState("We regret to inform you that some of your booking slots have been cancelled.  We apologize for any inconvenience this may have caused you. Please see the detail of cancellations below.");
  const isValid = sendEmail ? intro.length >= 150 && intro.length <= 1000 : true

  return (
    <CustomModal
      isOpen={show}
      size="2xl"
      handleClose={() => handleClose()}
      scrollBehavior="outside"
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">Delete Lessons</ModalHeader>

            <ModalBody>
              <div>
                <div className="flex items-center gap-2">
                  <QuestionMarkCircleIcon className="shrink-0 w-16 h-16 text-warning" />
                
                  <div>
                    <h1 className="text-xl font-semibold">Are you sure you want to delete {noOfLessonsToDelete} lessons?</h1>
                    <p>This action is irreversible. Are you sure you want to continue!</p>
                  </div>
                </div>


                {error && 
                  <div className='mt-3'>
                    <Alert type="danger" title={error.extensions.details} description={error.message} /> 
                  </div>
                }
                
                {noOfBookingSlotsToDelete > 0 && (
                  <div className="p-4 bg-gray-100 rounded-lg mt-3">
                    <h3 className="text-lg font-semibold">Delete {noOfBookingSlotsToDelete} appointments</h3>
                    <p className="mb-3">Deleting these {noOfLessonsToDelete} lesson(s) will cancel {noOfBookingSlotsToDelete} parent bookings. You can send a cancellation email below if you wish.</p>
                      
                    <div className="flex w-full items-center justify-between">
                      <div>
                        <Checkbox color="secondary" isSelected={sendEmail} onValueChange={() => setSendEmail(!sendEmail)}>Send Cancellation Email</Checkbox>
                      </div>

                      {sendEmail && 
                        <Button size="sm" variant="flat" color="secondary" onClick={() => handlePreviewEmail(bookingSlotIds, intro)} isLoading={isLoadingPreview}>
                          Preview Email
                        </Button>
                      }
                    </div>

                    {sendEmail && (
                      <div className="mt-4">
                        <Textarea
                          classNames={{
                            "inputWrapper": "bg-white border-0",
                            "input": "bg-white",
                          }}
                          label="Introductory text"
                          minRows={8}
                          isInvalid={!isValid}
                          placeholder="Enter your description"
                          value={intro}
                          variant="bordered"
                          onValueChange={setIntro}
                          errorMessage="The message should be between 150 and 1000 characters long."
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </ModalBody>

            <ModalFooter>
              <div className="flex w-full justify-between">
                <Button onClick={() => handleClose()} tabIndex={-1}>Cancel</Button>
                <Button color="danger" onClick={() => handleDelete(sendEmail, intro)} tabIndex={-1} isDisabled={!isValid} isLoading={isDeleting}>Delete</Button>
              </div>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </CustomModal>
  )
}

DeleteLessonsConfirmationModal.propTypes = {
  bookingSlotIds: PropTypes.array,
  error: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handlePreviewEmail: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool,
  isLoadingPreview: PropTypes.bool,
  noOfBookingSlotsToDelete: PropTypes.number.isRequired,
  noOfLessonsToDelete: PropTypes.number.isRequired,
  show: PropTypes.bool,
}

export { DeleteLessonsConfirmationModal }