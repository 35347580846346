import { gql } from "@apollo/client"

const GET_DASHBOARD_TEACHER_EVENT_STATS = gql`
  query ($eventId: ID!) {
    teacherEventStats(event_id: $eventId) {
      communications {
        no_of_comments
        no_of_comments_read
        no_of_comments_unread
        no_of_notifications
        no_of_notifications_read
        no_of_notifications_unread
      }

      summary {
        no_of_bookings
        no_of_groups
        no_of_invitations
        no_of_lessons
        no_of_sessions
        no_of_students
      }

      slots {
        attendance {
          attendance_ratio
          no_of_absent_slots
          no_of_attended_slots
          no_of_unknown_slots
        }

        no_of_total_slots
        no_of_available_slots
        no_of_booked_slots
        no_of_disabled_slots
        response_rate
      }
    }
  }
`

export { GET_DASHBOARD_TEACHER_EVENT_STATS }
