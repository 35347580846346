import { DashboardSkeleton } from "@components"
import { Card, CardBody, CardHeader } from "@nextui-org/react"
import PropTypes from "prop-types"

const DashboardCard = ({ className = "", title, body, loading = false }) => (
  <Card className={`border-0 shadow-lg ${className}`}>
    <CardHeader className="flex gap-3 text-white justify-center bg-indigo-800">
      <h2 className="text-lg font-semibold">{title}</h2>
    </CardHeader>

    <CardBody className="px-4 overflow-hidden justify-center">{loading ? <DashboardSkeleton /> : body}</CardBody>
  </Card>
)

DashboardCard.propTypes = {
  body: PropTypes.node.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
  title: PropTypes.string.isRequired,
}

export default DashboardCard
