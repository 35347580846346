import React, { useState } from 'react'
import { CustomModal } from "@components"

import {
  Button, 
  ModalContent, 
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  Textarea
} from '@nextui-org/react';

import PropTypes from 'prop-types';

const GroupLessonNoteModal = ({ 
  group,
  groupLessonNote = null,
  lesson,
  handleClose, 
  handleSave, 
  isReadOnly = false,
  isSaving, 
  show 
}) => {
  const [note, setNote] = useState(groupLessonNote ? groupLessonNote.note : '')

  const closeModal = () => {
    handleClose()
  }

  const initialNote = groupLessonNote ? groupLessonNote.note : ''
  const isDirty = initialNote !== note
  const isInvalid = note.length > 0 && (note.length < 10 || note.length > 500)
  const errorMessage = isInvalid ? "Note must be between 10 and 500 characters" : null
  
  return (
    <CustomModal
      isOpen={show}
      size="xl"
      handleClose={() => closeModal()}
      scrollBehavior="outside"
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader>Group Lesson Notes</ModalHeader>
          
            <ModalBody>
              <div className=" border-b-1 pb-3">
                <h1 className="text-lg font-semibold mb-0 mt-1">{ group.title } - { lesson.name }</h1>
                <p className="text-md">These notes will be seen by guardians.</p>
              </div>

              <div className="flex justify-between items-center -mb-2 text-sm">
                <p>Max characters 500</p>
                <p>{ note.length } / 500</p>
              </div>

              <Textarea 
                classNames={{ "inputWrapper": "!h-80" }}
                isInvalid={isInvalid}
                defaultValue={note} 
                onChange={(e) => setNote(e.target.value)} 
                placeholder="Enter your notes" 
                errorMessage={errorMessage}
                maxRows={10}
                isDisabled={isReadOnly}
              />
            </ModalBody>

            <ModalFooter>
              <div className="flex w-full justify-between">
                <Button onClick={() => handleClose()} tabIndex={-1}>Cancel</Button>
                <Button color="secondary" onClick={() => handleSave(group, lesson, note)} tabIndex={-1} isDisabled={!isDirty || isReadOnly} isLoading={isSaving}>Save</Button>
              </div>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </CustomModal>
  )
}

GroupLessonNoteModal.propTypes = {
  group: PropTypes.object.isRequired,
  groupLessonNote: PropTypes.object,
  lesson: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
  isSaving: PropTypes.bool,
  show: PropTypes.bool,
}

export default GroupLessonNoteModal