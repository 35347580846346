import React, { useContext, useEffect, useState } from "react"

import { 
  DataTable, 
  EllipsisDropdown,
  EventPageHeader, 
  GuardianStudentDetails,
  MeetingTypeDetails,
  NoDataTablePlaceholder, 
  Page,
  StudentGuardiansSelectorModal,
} from "@components"

import { NotificationContext, UserContext } from "@context"

import { 
  ADD_GUARDIANS_TO_INVITATION,
  GET_INVITATIONS_MATRIX,
  GET_INVITATIONS_MATRIX_SUMMARY,
  GET_INVITATIONS_MATRIX_XLS_DOWNLOAD_LINK,
  GET_INVITATION_STUDENT_GUARDIANS,
} from "@graphql"

import { downloadFile, getEventDateRange, hasExpired, mySqlToLocal } from "@helpers"
import { useSetCurrentEvent } from "@hooks"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { Chip } from "@nextui-org/react"
import { useParams } from "react-router-dom"
import { EnvelopeIcon } from "@heroicons/react/24/outline"
import { columns, defaultColumns, downloadOptions, filters } from "./data"
import { Stats } from "./Stats"

const initialSelectedColumns = JSON.parse(window.localStorage.getItem("event.manage.invites.columns")) ?? defaultColumns

const ManageInvitations = () => {
  const { event: eventId } = useParams()
  const { user, isLoadingCurrentEvent } = useContext(UserContext)
  const { setNotification } = useContext(NotificationContext)
  const [studentGuardiansSelectorModal, setStudentGuardiansSelectorModal] = useState({ show: false, isSaving: false, guardians: [], selectedGuardianIds: [], student: null })
  const [isDownloading, setIsDownloading] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedColumns, setSelectedColumns] = useState(initialSelectedColumns)
  const [selectedFilters, setSelectedFilters] = useState(["all"])
  const [page, setPage] = useState(1)
  const [perPage] = useState(50)
  const [refetching, setRefetching] = useState(false)
  const [where, setWhere] = useState(null)

  useEffect(() => {
    window.localStorage.setItem("event.manage.invites.columns", JSON.stringify(selectedColumns))
  }, [selectedColumns])

  const [getStudentGuardians] = useLazyQuery(GET_INVITATION_STUDENT_GUARDIANS, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      setStudentGuardiansSelectorModal({ show: true, isLoading: false, guardians: data.student.guardians, isSaving: false, selectedGuardianIds: data.invitedGuardiansByStudent.map(guardian => guardian.id), student: data.student })
    }
  })

  const { data, loading, refetch } = useQuery(GET_INVITATIONS_MATRIX, {
    variables: {
      page,
      perPage,
      input: {
        event_id: user.currentEvent.id,
        search_term: searchTerm,
      },
      orderBy: [
        { column: "STUDENT", order: "ASC" },
        { column: "GUARDIAN", order: "ASC" },
      ],
      where
    },
    fetchPolicy: "cache-first",
    pollInterval: 900000,
    skip: isLoadingCurrentEvent,
    onError: errors => {  
      setNotification({ title: "Error", type: "danger", content: errors.graphQLErrors[0].message, open: true })
    }
  })

  const {data: summary, refetch: refetchSummary} = useQuery(GET_INVITATIONS_MATRIX_SUMMARY, {
    variables: {
      event_id: user.currentEvent.id,
    }
  })

  const [addGuardianToEvent] = useMutation(ADD_GUARDIANS_TO_INVITATION, {
    onCompleted: () => {
      refetch().then(() => setStudentGuardiansSelectorModal({ show: false, guardians: [], isSaving: false, selectedGuardianIds: [], student: null }))
      refetchSummary()
    },
    onError: errors => {  
      setNotification({ title: "Error", type: "danger", content: errors.graphQLErrors[0].message, open: true })
      setStudentGuardiansSelectorModal(prevState => ({...prevState, isSaving: false }))
    }
  })

  const [ getInvitationsMatrixXlsDownloadLink ] = useLazyQuery(GET_INVITATIONS_MATRIX_XLS_DOWNLOAD_LINK, {
    variables: { 
      event_id: user.currentEvent.id, 
    },

    fetchPolicy: "network-only",
    
    onCompleted: data => {
      downloadFile(data.invitationsMatrixXlsDownload.filename, data.invitationsMatrixXlsDownload.url)
      setIsDownloading(false)
    },

     onError: errors => {  
      setNotification({ title: "Error", type: "danger", content: errors.graphQLErrors[0].message, open: true })
      setIsDownloading(false)
    }
  })

  useSetCurrentEvent(eventId)

  const handleAddGuardians = (studentId, guardianIds) => {
    setStudentGuardiansSelectorModal(prevState => ({...prevState, isSaving: true }))

    addGuardianToEvent({
      variables: {
        input: {
          event_id: user.currentEvent.id,
          student_id: studentId,
          guardian_ids: guardianIds,
        }
      }
    })
  }

  const handleDownload = type => {
    if (type === "xls") {
      setIsDownloading(true)
      getInvitationsMatrixXlsDownloadLink()
    }
  }

  const handleRefetch = () => {
    setRefetching(true)
    refetchSummary()
    refetch().then(() => setRefetching(false))
  }

  const handleSearchChange = searchTerm => {
    setSearchTerm(searchTerm)
    setPage(1)
  }

  const handleShowStudentGuardianSelectorModal = data => {
    setStudentGuardiansSelectorModal(prevState => ({...prevState, show: true, isLoading: true }))

    getStudentGuardians({ 
      variables: { 
        student_id: data.student_id, 
        invitedGuardiansByStudentInput: { 
          event_id: user.currentEvent.id, 
          student_id: data.student_id 
        } 
      } 
    })
  }

  const handleShowDeleteGuardianModal = data => {
    console.log("Delete Guardian Modal", data)
  }

  const handleToggleColumn = selectedColumns => {
    setSelectedColumns(Object.keys(columns).filter(column => selectedColumns.has(column)))
  }

  const handleToggleFilter = selectedFilter => {
    setSelectedFilters([...selectedFilter])
    setWhere(filters[[...selectedFilter][0]].filter)
    setPage(1)
  }

  const renderCell = (data, column) => {
    const statusClasses = data.status === "Matched" ? "bg-green-400 text-white" : "bg-orange-400 text-white"

    const columnMapping = {
      student: <span className="font-semibold text-gray-700">{data.student}</span>,
      guardian: data.guardian,
      email: data.email,
      status: <Chip className={statusClasses} size="small">{data.status}</Chip>,
      emailed_date: data.last_sent_at ? mySqlToLocal(data.last_sent_at).format("DD/MM/YY HH:mm") : "-",
      contact_details: <GuardianStudentDetails contactSequence={data.contact_sequence} livesWith={data.lives_with} legalGuardian={data.legal_guardian} />,
      meeting_type: <MeetingTypeDetails venue={data.meeting_type === "VENUE"} phone={data.meeting_type === "PHONE"} vc={data.meeting_type === "VC"} />,
      actions: <EllipsisDropdown items={
        [
          { id: 1, handleClick: () => handleShowStudentGuardianSelectorModal(data), caption: "Add Guardian(s)", isDisabled: isReadOnlyMode },
          { id: 2, handleClick: () => handleShowDeleteGuardianModal(data), caption: "Delete Guardian", isDisabled: isReadOnlyMode },
        ]
      } />
    }
      
    return columnMapping[column]
  }

  const invitationsMatrix = loading ? [] : data?.invitationsMatrix?.data
  const { ends } = getEventDateRange(user.currentEvent.sessions)
  const isReadOnlyMode = hasExpired(ends)

  return (
    <Page>
      <EventPageHeader
        title="Manage Invitations"
        icon={<EnvelopeIcon className="page-header-icon" />}
        event={user?.currentEvent}
      />

      <Stats
        total={summary?.invitationsMatrixSummary?.total}
        noOfMatched={summary?.invitationsMatrixSummary?.matched}
        noOfUnmatched={summary?.invitationsMatrixSummary?.unmatched}
        noOfInviteEmailsSent={summary?.invitationsMatrixSummary?.emailed}
        noOfInviteEmailsNotSent={summary?.invitationsMatrixSummary?.not_emailed}
        noOfStudents={summary?.invitationsMatrixSummary?.students}
      />

      <DataTable
        columns={columns}
        currentPage={page}
        data={invitationsMatrix}
        downloadOptions={downloadOptions}
        filters={filters}
        filtersSelectionMode="single"
        handleDownload={type => handleDownload(type)}
        handleRefetch={() => handleRefetch()}
        handlePageChange={page => setPage(page)}
        handleRenderCell={(item, key) => renderCell(item, key)}
        handleSearchChange={searchTerm => handleSearchChange(searchTerm)}
        handleSearchClear={() => setSearchTerm("")}
        handleToggleColumn={selectedColumns => handleToggleColumn(selectedColumns)}
        handleToggleFilter={selectedFilters => handleToggleFilter(selectedFilters)}
        isDownloading={isDownloading}
        loading={loading}
        isRefetching={refetching}
        noDataMessage={<NoDataTablePlaceholder title="No Invitations Found" message="No invitations found! Try clearing any filters." />}
        paginatorInfo={data?.invitationsMatrix?.paginatorInfo}
        searchInputPlaceHolder="Search by student / guardian / email"
        searchTerm={searchTerm}
        selectedColumns={selectedColumns}
        selectedFilters={selectedFilters}
        showColumnsButton={true}
        showDownloadButton={true}
        showRefetchButton={true}
      />

      {studentGuardiansSelectorModal.show && (
        <StudentGuardiansSelectorModal
          guardians={studentGuardiansSelectorModal.guardians}
          handleClose={() => setStudentGuardiansSelectorModal({ show: false, isSaving: false, guardians: [], selectedGuardianIds: [], student: null })}
          handleSave={(studentId, guardianIds) => handleAddGuardians(studentId, guardianIds)}
          isLoading={studentGuardiansSelectorModal.isLoading}
          isSaving={studentGuardiansSelectorModal.isSaving}
          selectedGuardianIds={studentGuardiansSelectorModal.selectedGuardianIds}
          student={studentGuardiansSelectorModal.student}
          show={studentGuardiansSelectorModal.show}
          title="Select the guardians you would like to add to the event."
        />
      )}
    </Page>
  )
}

export default ManageInvitations
