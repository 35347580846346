import { useSetActiveSidebarLink } from "@hooks"
import { ComingSoon } from "@components"

const Imports = () => {
  useSetActiveSidebarLink("SITE_DATA_IMPORTS")

  return <ComingSoon />
}

export default Imports
