import PropTypes from "prop-types"

const StatCardCompact = ({ title, stat, icon, bgColor = "bg-indigo-500" }) => (
  <div className="relative overflow-hidden rounded-lg bg-white shadow-lg">
    <div className={`absolute rounded-start-md ${bgColor} flex items-center p-3 h-full`}>
      {icon}
    </div>

    <div className="ml-2 my-1">
      <div>
        <p className="ml-16 truncate text-sm text-indigo-500">{title}</p>
      </div>

      <div className="ml-16 flex items-baseline">
        <p className="text-2xl font-semibold text-gray-900">{stat}</p>
      </div>
    </div>
  </div>
)

StatCardCompact.propsTypes = {
  bgColor: PropTypes.string,
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  stat: PropTypes.string.isRequired,
}

export default StatCardCompact
