const columns = {
  type: { id: "type", name: "Type", sortable: false, deselectable: false },
  summary: { id: "summary", name: "Summary", sortable: false, deselectable: true },
  user: { id: "user", name: "User", sortable: true, deselectable: false },
  created_at: { id: "created_at", name: "Created", sortable: true, deselectable: false },
  details: { id: "details", name: "Details", sortable: false, deselectable: true, classes: "hidden lg:table-cell"},
  actions: { id: "actions", name: "", sortable: false, deselectable: false },
}

const defaultColumns = [
  "type",
  "summary",
  "user",
  "created_at",
  "details",
  "actions",
]

const downloadOptions = [{ key: "xls", name: "Download All XLS" }]

const typeStyles = {
  BOOKING_AUTO_CANCELLATION: "bg-blue-400",
  BOOKING: "bg-blue-800",
  BOOKING_CANCELLATION: "bg-blue-600",
  GROUP_REMOVED: "bg-red-400",
  LESSON_ADDED: "bg-pink-600",
  LESSON_REMOVED: "bg-pink-400",
  OTHER: "bg-gray-400",
  GROUP_LESSON_STUDENT_ADDED: "bg-purple-400",
  GROUP_LESSON_STUDENT_REMOVED: "bg-orange-400",
  GROUP_ADDED: "bg-green-500",
  GROUP_LESSONS_REASSIGNED: "bg-lime-500",
  GROUP_LESSONS_DUPLICATED: "bg-purple-500",
  INVITE_MANUALLY_CREATED: "bg-lime-500",
}

export { columns, defaultColumns, downloadOptions, typeStyles }
