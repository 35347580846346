import { gql } from "@apollo/client"

const GET_SUBJECTS = gql`
  query (
    $searchTerm: String
    $page: Int!
    $perPage: Int!
    $clientId: ID!
    $orderBy: [ClientSubjectsOrderByOrderByClause!]
    $where: ClientSubjectsWhereWhereConditions
  ) {
    client(id: $clientId) {
      id

      subjects(searchTerm: $searchTerm, page: $page, first: $perPage, orderBy: $orderBy, where: $where) {
        data {
          id
          name
          no_of_lessons
          local_id
          data_ref
          is_sync
        }

        paginatorInfo {
          total
          currentPage
          hasMorePages
          lastPage
          perPage
        }
      }
    }
  }
`

const GET_SUBJECT_LESSONS = gql`
  query ($id: ID!) {
    subject(id: $id) {
      id

      lessons(orderBy: [{ column: NAME, order: ASC }]) {
        id
        name
      }
    }
  }
`

export { GET_SUBJECTS, GET_SUBJECT_LESSONS }
