import { useContext } from "react"
import { Navigate, Outlet } from "react-router-dom"
import { UserContext } from "@context"
import PropTypes from "prop-types"

const ProtectedRoutes = ({ children }) => {
  const { user } = useContext(UserContext)

  if (!user) {
    return <Navigate to="/session-expired" replace />
  }

  return children ? children : <Outlet />
}

ProtectedRoutes.propTypes = {
  children: PropTypes.node,
}

export default ProtectedRoutes
