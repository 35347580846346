import { useContext } from "react"
import { NoData, NoEvents } from "@components"
import { UserContext } from "@context"
import { hasOrganiserRights } from "@helpers"
import { Navigate } from "react-router-dom"

const Home = () => {
  const { user } = useContext(UserContext)

  if (hasOrganiserRights(user) && !user?.client.imported_at) {
    return <NoData />
  }

  if (!user?.me.defaultEvent) {
    return <NoEvents />
  }

  if (user?.me?.defaultEvent) {
    return <Navigate to={`/event/${user.me.defaultEvent.id}/dashboard`} replace />
  }
}

export default Home
