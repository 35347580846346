import {
  CalendarDaysIcon,
  CheckBadgeIcon,
  ExclamationCircleIcon,
  ReceiptPercentIcon,
  QuestionMarkCircleIcon,
  UsersIcon,
} from "@heroicons/react/24/outline"

import { StatCardCompact } from "@components"
import PropTypes from "prop-types"

const Stats = ({ summary = {} }) => {
  const {
    absent = 0,
    attended = 0,
    attendance_ratio = 0,
    no_of_parents = 0,
    unknown,
    total_bookings = 0,
  } = summary?.data?.reportEventAttendanceSummary || {}

  return (
    <div className="my-5 grid grid-cols-1 gap-5 sm:grid-cols-3 2xl:grid-cols-6">
      <StatCardCompact
        title="Absent"
        stat={absent}
        icon={<ExclamationCircleIcon className="h-10 w-10 text-white" aria-hidden="true" />}
      />

      <StatCardCompact
        title="Attended"
        stat={attended}
        icon={<CheckBadgeIcon className="h-10 w-10 text-white" aria-hidden="true" />}
      />

      <StatCardCompact
        title="Unknown"
        stat={unknown}
        icon={<QuestionMarkCircleIcon className="h-10 w-10 text-white" aria-hidden="true" />}
      />

      <StatCardCompact
        title="Bookings"
        stat={total_bookings}
        icon={<CalendarDaysIcon className="h-10 w-10 text-white" aria-hidden="true" />}
      />

      <StatCardCompact
        title="No Of Parents"
        stat={no_of_parents}
        icon={<UsersIcon className="h-10 w-10 text-white" aria-hidden="true" />}
      />

      <StatCardCompact
        title="Attendance Ratio"
        stat={`${attendance_ratio}%`}
        icon={<ReceiptPercentIcon className="h-10 w-10 text-white" aria-hidden="true" />}
      />
    </div>
  )
}

Stats.propTypes = {
  summary: PropTypes.object,
}

export default Stats
