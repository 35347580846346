import { 
  AcademicCapIcon, 
  ArchiveBoxXMarkIcon, 
  CalendarDaysIcon,
  Square3Stack3DIcon, 
  UserGroupIcon, 
  UsersIcon 
} from "@heroicons/react/24/outline"

import { StatCardCompact } from "@components"
import PropTypes from "prop-types"

const Stats = ({ summary = {} }) => {
  const {
    noOfSessions = 0,
    noOfGroups = 0,
    noOfLessons = 0,
    noOfBookings = 0,
    noOfUnassignedLessons = 0,
    noOfMisStudentMismatches = 0,
  } = summary

  return (
    <div className="grid grid-cols-1 gap-5 my-5 sm:grid-cols-3 2xl:grid-cols-6">
      <StatCardCompact
        title="Sessions"
        stat={noOfSessions}
        icon={<Square3Stack3DIcon className="w-10 h-10 text-white" aria-hidden="true" />}
      />

      <StatCardCompact
        title="Groups"
        stat={noOfGroups}
        icon={<UserGroupIcon className="w-10 h-10 text-white" aria-hidden="true" />}
      />

      <StatCardCompact
        title="Lessons"
        stat={noOfLessons}
        icon={<UsersIcon className="w-10 h-10 text-white" aria-hidden="true" />}
      />

      <StatCardCompact
        title="Bookings"
        stat={noOfBookings}
        icon={<CalendarDaysIcon className="w-10 h-10 text-white" aria-hidden="true" />}
      />

      <StatCardCompact
        title="Unassigned"
        stat={noOfUnassignedLessons}
        icon={<ArchiveBoxXMarkIcon className="w-10 h-10 text-white" aria-hidden="true" />}
      />

      <StatCardCompact
        title="Mismatches"
        stat={noOfMisStudentMismatches}
        icon={<AcademicCapIcon className="w-10 h-10 text-white" aria-hidden="true" />}
      />
    </div>
  )
}

Stats.propTypes = {
  summary: PropTypes.object,
}

export default Stats
