import { CheckCircleIcon, ExclamationCircleIcon, InformationCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'
import PropTypes from 'prop-types';

const defaults = {
  info: {
    container: "bg-blue-50",
    heroIcon: InformationCircleIcon,
    icon: 'text-blue-400',
    title: 'text-blue-800',
    description: 'text-blue-700',
  },
  
  success: {
    container: "bg-green-50",
    heroIcon: CheckCircleIcon,
    icon: 'text-green-400',
    title: 'text-green-800',
    description: 'text-green-700',
  },
  
  warning: {
    container: "bg-yellow-50",
    heroIcon: ExclamationCircleIcon,
    icon: 'text-yellow-400',
    title: 'text-yellow-800',
    description: 'text-yellow-700',
  },

  danger: {
    container: "bg-red-50",
    heroIcon: XCircleIcon,
    icon: 'text-red-400',
    title: 'text-red-800',
    description: 'text-red-700',
  },
}

const Alert = ({ type, title, description }) => {
  const { heroIcon: HeroIcon, icon: iconStyles, title: titleStyles, description: descriptionStyles, container: containerStyles } = defaults[type];

  return (
    <div className={`rounded-md ${containerStyles} p-4`}>
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <HeroIcon className={`h-14 w-14 ${iconStyles}`} aria-hidden="true" />
        </div>
  
        <div className="ml-3">
          <h3 className={`text-md font-semibold leading-tight ${titleStyles}`}>{title}</h3>
          <div className={`mt-2 text-sm ${descriptionStyles}`}>
            <p>{description}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

Alert.propTypes = {
  type: PropTypes.oneOf(['info', 'success', 'warning', 'danger']).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default Alert