import React, { useContext, useState } from "react"
import { CustomModal,  DeleteBookingSlotsConfirmationModal } from "@components"
import { NotificationContext } from "@context"
import { GET_GROUP_LESSON_STUDENTS_DIFF, UPDATE_GROUP_LESSON_STUDENTS } from "@graphql"
import { useMutation, useQuery } from "@apollo/client"

import {
  Button, 
  ModalContent, 
  ModalHeader, 
  ModalBody, 
  ModalFooter,
} from '@nextui-org/react';

import { difference } from "lodash"
import ClientStudents from "./ClientStudents"
import GroupLessonStudents from "./GroupLessonStudents"
import PropTypes from 'prop-types';

const GroupLessonStudentsDiffModal = ({ 
  clientId, 
  groupId, 
  handleClose, 
  handlePreviewEmail, 
  isLoadingPreview,
  isPreviewEmailModalVisible,
  isReadOnly = false,
  lessonId, 
  show = false, 
  title 
}) => {
  const { setNotification } = useContext(NotificationContext)
  const [bookingSlotsToDelete, setBookingSlotsToDelete] = useState([])
  const [initialStudents, setInitialStudents] = useState({})
  const [isUpdatingStudents, setIsUpdatingStudents] = useState(false)
  const [selectedStudents, setSelectedStudents] = useState({})
  const [showDeleteBookingSlotsConfirmationModal, setShowDeleteBookingSlotsConfirmationModal] = useState(false)
  const [students, setStudents] = useState({})

  const groupLessonStudents = useQuery(GET_GROUP_LESSON_STUDENTS_DIFF, {
    variables: { groupId: groupId, lessonId: lessonId },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const students = getStudents(data.group.lessons[0].groupStudentsDiff)
      setInitialStudents(students.students)
      setStudents(students.students)
      setSelectedStudents(students.selectedStudents)
    }
  })

  const [updateGroupLessonStudents] = useMutation(UPDATE_GROUP_LESSON_STUDENTS, {
    onError: (errors) => {
      if (errors.graphQLErrors && errors.graphQLErrors[0]?.extensions?.payload?.code === "BOOKING_SLOTS_DELETION_NOT_ALLOWED") {
        setBookingSlotsToDelete(errors.graphQLErrors[0].extensions.payload.bookingSlotIds)
        setShowDeleteBookingSlotsConfirmationModal(true)
      } else {
        setNotification({ title: "Error", type: "danger", content: errors.graphQLErrors[0].message, open: true })
      }

      setIsUpdatingStudents(false)
    },
    
    onCompleted: () => {
      handleClose(true)
    }
  })

  const isStudentSelected = (student) => selectedStudents[student.id] !== undefined ? true : false

  const handleToggleStudent = (student) => {
    isStudentSelected(student) ? removeStudent(student) : addStudent(student)
  }

  const addStudent = (student) => {
    setSelectedStudents({ ...selectedStudents, [student.id]: student })
  }

  const closeModal = () => {
    handleClose()
  }

  const getExcludedStudents = () => {
    return Object.keys(students).reduce((excludedStudents, studentId) => {
      if (students[studentId].diff_status === "Match" || students[studentId].diff_status === "Removed" || selectedStudents[studentId] !== undefined) {
        excludedStudents[studentId] = students[studentId]
      }

      return excludedStudents
    }, {})
  }

  const getStudents = (groupStudentsDiff) => {
    return groupStudentsDiff.reduce((students, student) => {
      students.students[student.id] = {...student}

      if (student.diff_status !== "Removed") {
        students.selectedStudents[student.id] = {...student}
      }

      return students
    }, { students: {}, selectedStudents: {} })
  }

  const handleAddStudent = (student) => {
    const newStudents = { [student.id]: { ...student, diff_status: 'Added' }, ...students}
    setStudents(newStudents)
    setSelectedStudents({ ...selectedStudents, [student.id]: student })
  }

  const handleSave = (sendCancellationEmails, intro, allowBookingDeletions) => {
    setIsUpdatingStudents(true)

    updateGroupLessonStudents({ 
      variables: { 
        input: { 
          allow_booking_deletions: allowBookingDeletions,
          group_id: groupId, 
          student_ids_by_lesson_ids: [{ "lesson_id": lessonId, student_ids: Object.keys(selectedStudents) }],
          send_cancellation_emails: sendCancellationEmails,
          cancellation_email_intro: intro
        } 
      }
    }).then(() => {
      setIsUpdatingStudents(false)
    })
  }

  const hasChanged = () => {
    const currentStudents = Object.keys(initialStudents).reduce((students, studentId) => {
      const student = initialStudents[studentId]

      if (student.diff_status !== "Removed") {
        students[student.id] = student
      }

      return students
    }, {})

    const selectedStudentIds = Object.keys(selectedStudents)
    const currentStudentIds = Object.keys(currentStudents)
    return difference(selectedStudentIds, currentStudentIds).length > 0 || difference(currentStudentIds, selectedStudentIds).length > 0
  }

  const removeStudent = (student) => {
    const { [student.id]: removedStudent, ...newStudents } = students
    const { [student.id]: removedSelectedStudent, ...newSelectedStudents } = selectedStudents
    
    if (student.diff_status === "Added") {
      setStudents(newStudents)
    }

    setSelectedStudents(newSelectedStudents)
  }

  const excludedStudents = getExcludedStudents()
  const isDirty = hasChanged()

  if (isPreviewEmailModalVisible) {
    return null
  }

  if (showDeleteBookingSlotsConfirmationModal) {
    return (
      <DeleteBookingSlotsConfirmationModal
        description={`These student updates will cancel ${bookingSlotsToDelete.length} appointment(s).`}
        handleClose={() => setShowDeleteBookingSlotsConfirmationModal(false)}
        handleConfirm={(sendCancellationEmails, intro) => handleSave(sendCancellationEmails, intro, true)}
        handlePreviewEmail={(intro) => handlePreviewEmail(bookingSlotsToDelete, intro)}
        isProcessing={isUpdatingStudents}
        isLoadingPreview={isLoadingPreview}
        show={showDeleteBookingSlotsConfirmationModal}
        title={`Appointment cancellations`}
      />
    )
  }
  
  return (
    <CustomModal
      isOpen={show}
      size="5xl"
      handleClose={() => closeModal()}
      scrollBehavior="outside"
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">{title}</ModalHeader>
          
            <ModalBody>
              <div className="grid grid-cols-2 pt-1 gap-4">
                <GroupLessonStudents
                  handleToggleStudent={(student) => handleToggleStudent(student)}
                  students={students} 
                  selectedStudents={selectedStudents} 
                  isLoading={groupLessonStudents.loading}
                  isReadOnly={isReadOnly}
                />
                
                <ClientStudents 
                  clientId={clientId} 
                  handleAddStudent={(student) => handleAddStudent(student)} 
                  excludedStudents={excludedStudents}
                  isReadOnly={isReadOnly} 
                />
              </div>
            </ModalBody>

            <ModalFooter>
              <div className="flex w-full justify-between">
                <Button onClick={() => handleClose()} tabIndex={-1}>Cancel</Button>
                <Button color="secondary" onClick={() => handleSave(false, "", false)} tabIndex={-1} isDisabled={!isDirty || isReadOnly} isLoading={isUpdatingStudents}>Save</Button>
              </div>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </CustomModal>
  )
}

GroupLessonStudentsDiffModal.propTypes = {
  clientId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handlePreviewEmail: PropTypes.func.isRequired,
  isLoadingPreview: PropTypes.bool.isRequired,
  isPreviewEmailModalVisible: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool,
  lessonId: PropTypes.string.isRequired,
  show: PropTypes.bool,
  title: PropTypes.string.isRequired,
}

export default GroupLessonStudentsDiffModal