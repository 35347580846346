import { useContext, useEffect } from "react"
import { SidebarContext } from "@context"

const useSetActiveSidebarLink = (link, parent = null) => {
  const { activateLink } = useContext(SidebarContext)

  useEffect(() => {
    activateLink(link, parent)
  }, [link, parent]) // eslint-disable-line react-hooks/exhaustive-deps
}

export default useSetActiveSidebarLink