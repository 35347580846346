import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from "@nextui-org/react"
import { ChevronDownIcon, ListBulletIcon } from "@heroicons/react/20/solid"
import PropTypes from "prop-types"

const ColumnSelector = ({ columns, selectedColumns, toggleColumn }) => {
  const optionalColumns = Object.keys(columns).filter(key => columns[key].deselectable)

  return (
    <Dropdown>
      <DropdownTrigger className="hidden sm:flex flex-shrink-0">
        <Button
          startContent={<ListBulletIcon className="h-5 w-auto" />}
          endContent={<ChevronDownIcon className="h-5 w-auto" />}
          variant="flat"
        >
          Columns
        </Button>
      </DropdownTrigger>

      <DropdownMenu
        aria-label="Table Columns"
        disallowEmptySelection
        closeOnSelect={false}
        selectedKeys={selectedColumns}
        selectionMode="multiple"
        onSelectionChange={toggleColumn}
      >
        {optionalColumns.map(key => (
          <DropdownItem key={key} className="capitalize">
            {columns[key].name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  )
}

ColumnSelector.propTypes = {
  columns: PropTypes.object.isRequired,
  selectedColumns: PropTypes.array.isRequired,
  toggleColumn: PropTypes.func.isRequired,
}

export default ColumnSelector
