const options = {
  legend: {
    position: "bottom",
    show: true,
  },

  colors: [
    "#2E93fA",
    "#66DA26",
    "#F069E3",
    "#4886D9",
    "#F76A4E",
    "#6B69F0",
    "#48D9A4",
    "#FF9800",
    "#4ED3F7",
    "#BB69F0",
  ],

  chart: {
    fontFamily: "Poppins, Helvetica, Arial, sans-serif",
    toolbar: {
      show: false,
    },
  },

  stroke: {
    width: 3,
  },

  dataLabels: {
    enabled: false,
  }
}

export default options
