import { Tooltip } from "@nextui-org/react"
import { DevicePhoneMobileIcon, PaperAirplaneIcon, PhoneIcon } from "@heroicons/react/20/solid"
import PropTypes from "prop-types"

const GuardianContactsList = ({ email = null, mobile = null, misPhone = null }) => (
  <ul className="divide-y divide-gray-100">
    <li key="email" className="flex py-2 items-center">
      <Tooltip showArrow={true} color="secondary" content="Account email">
        <PaperAirplaneIcon className="w-6 h-auto mr-2 text-indigo-400" />
      </Tooltip>
      {email || "N/A"}
    </li>

    <li key="mobile" className="flex py-2 items-center">
      <Tooltip showArrow={true} color="secondary" content="Account phone">
        <DevicePhoneMobileIcon className="w-6 h-auto mr-2 text-indigo-400" />
      </Tooltip>
      {mobile || "N/A"}
    </li>

    <li key="misPhone" className="flex py-2 items-center">
      <Tooltip showArrow={true} color="secondary" content="MIS Phone">
        <PhoneIcon className="w-6 h-auto mr-2 text-indigo-400" />
      </Tooltip>
      {misPhone || "N/A"}
    </li>
  </ul>
)

GuardianContactsList.propTypes = {
  email: PropTypes.string,
  mobile: PropTypes.string,
  misPhone: PropTypes.string,
}

export default GuardianContactsList
