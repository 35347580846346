import { CustomModal } from "@components"
import { Button, ModalBody, ModalContent, ModalFooter, ModalHeader } from "@nextui-org/react"
import { PropTypes } from "prop-types"

const EmailPreviewModal = ({ handleClose, html, show = false, title }) => (
  <CustomModal
    isOpen={show}
    size="3xl"
    handleClose={() => handleClose()}
    scrollBehavior="inside"
  >
    <ModalContent>
      {(onClose) => (
        <>
          <ModalHeader className="flex flex-col gap-1">{ title }</ModalHeader>

          <ModalBody>
            {html && (
              <div
                dangerouslySetInnerHTML={{
                  __html: html.replace(/href="([^'"]+)/g, '')
                }}
              />
              )
            }
          </ModalBody>

          <ModalFooter>
            <div className="flex w-full justify-end">
              <Button onClick={() => handleClose()} tabIndex={-1}>Cancel</Button>
            </div>
          </ModalFooter>
        </>
      )}
    </ModalContent>
  </CustomModal>
)


EmailPreviewModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  html: PropTypes.string,
  show: PropTypes.bool,
  title: PropTypes.string.isRequired,
}

export default EmailPreviewModal