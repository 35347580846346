import { EventSettingsForm } from "@components";
import { PropTypes } from "prop-types"

const Step3 = ({ formData, handlePrevious, handleSubmitStage, venues = [] }) => (
  <EventSettingsForm
    maxBookingsPerStudent={formData.max_bookings_per_student}
    formData={formData}
    handlePrevious={(data) => handlePrevious(data)}
    handleSubmit={(data) => handleSubmitStage(data)}
    isVcEvent={formData.selected_meeting_type_ids.includes(3)}
    isVenueEvent={formData.selected_meeting_type_ids.includes(1)}
    showWizardFooter={true}
    vcAutoDisconnect={formData.vc_auto_disconnect}
    venueId={formData.venue_id}
    venues={venues}
  />
)

Step3.propTypes = {
  formData: PropTypes.object.isRequired,
  handlePrevious: PropTypes.func.isRequired,
  handleSubmitStage: PropTypes.func.isRequired,
  venues: PropTypes.array
}

export default Step3