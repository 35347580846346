import React, { useEffect, useState } from 'react'
import { CustomModal, StaffAutoComplete } from "@components"
import { Checkbox, Link } from '@nextui-org/react'

import {
  Button, 
  ModalContent, 
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  Spinner,
} from '@nextui-org/react';

import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';

const ReassignModal = ({ 
  client, 
  group, 
  handleClose, 
  handleDownload, 
  handleSave, 
  isDownloading, 
  isSaving, 
  lessons, 
  selectedLessons, 
  show 
}) => {
  const [currentTeacher, setCurrentTeacher] = useState(null)
  const [selectedLessonIds, setSelectedLessonIds] = useState([])

  const closeModal = () => {
    handleClose()
  }

  useEffect(() => {
    setSelectedLessonIds(selectedLessons.map(lesson => lesson.id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessons])

  const handleToggleLesson = (lessonId, isSelected) => {
    isSelected ? setSelectedLessonIds([...selectedLessonIds, lessonId]) : setSelectedLessonIds(selectedLessonIds.filter(id => id !== lessonId))
  }

  const isDisabled = selectedLessonIds.length === 0 || !currentTeacher

  return (
    <CustomModal
      isOpen={show}
      size="xl"
      handleClose={() => closeModal()}
      scrollBehavior="outside"
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader>Reassign Lessons</ModalHeader>
          
            <ModalBody>
              <h1 className="-mb-3 text-lg font-semibold">Reassign lessons from: { group.title }</h1>
              <p className="-mb-1">Any bookings against the selected lessons will be deleted. If you wish to rebook these click the link to generate a download of bookings for the relevant group lessons.</p>
                
              <div className="flex items-center">
                {!isDownloading && <ArrowDownTrayIcon className="h-6 mr-2" />}
                {isDownloading && <Spinner className="h-6 mr-2" size="sm" />}
                <Link href="#" onClick={() => handleDownload(group.id, selectedLessonIds)}> Download Bookings</Link>
              </div>

              <div className="py-3 border-t border-b border-slate-200">
                <h2 className="mb-1 font-semibold text-md">Select the lesson to reassign</h2>

                <div className="flex flex-col gap-2">
                  {lessons.map(lesson => (
                    <Checkbox key={lesson.id} isSelected={selectedLessonIds.includes(lesson.id)} onValueChange={(isSelected) => handleToggleLesson(lesson.id, isSelected)}>
                      {lesson.name}
                    </Checkbox>
                  ))}
                </div>
              </div>

              <div className="py-3 -mt-3 border-b border-slate-200">
                <h2 className="mb-1 font-semibold text-md">Select the teacher to reassign to</h2>
                <StaffAutoComplete clientId={client.id} onChange={(teacher) => setCurrentTeacher(teacher)} value={currentTeacher} />
              </div>
            </ModalBody>

            <ModalFooter>
              <div className="flex justify-between w-full">
                <Button onClick={() => handleClose()} tabIndex={-1}>Cancel</Button>
                <Button color="secondary" onClick={() => handleSave(group, selectedLessonIds, currentTeacher.user_id)} tabIndex={-1} isDisabled={isDisabled} isLoading={isSaving}>Reassign</Button>
              </div>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </CustomModal>
  )
}

ReassignModal.propTypes = {
  client: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
  lessons: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDownload: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  isDownloading: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool,
  selectedLessons: PropTypes.array.isRequired,
  show: PropTypes.bool,
}

export default ReassignModal