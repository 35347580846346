import React, { useRef, useState } from 'react'
import { NoDataPlaceholder } from '@components'
import { Button, Spinner } from '@nextui-org/react'
import { MagnifyingGlassCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import InfiniteScroll from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';

const AutoComplete = ({
  handleChange, 
  handleClear, 
  handleLoadMore,
  handleOptionSelected, 
  hasMore = false,
  id,
  inputRef,
  isLoading = false,
  options = [], 
  noData = { title: "No data found", message: "Sorry no data could be found that matches your search term. Please try adjusting or clearing your filter." },
  placeholder = "Start typing to search...", 
  searchTerm = "", 
  isSelected = ""
}) => {  
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0)
  const [hasFocus, setHasFocus] = useState(false)
  const itemRefs = useRef([]);
  const listRef = useRef(null);

  const getStatus = () => {
    if (isLoading) return <Spinner color="secondary" size="sm" className="absolute right-3 top-1.5 h-8 w-8 text-success-400" />
    if (!isLoading && searchTerm.length > 0 && !isSelected) return <XMarkIcon className="absolute right-3 top-3.5 text-gray-700 hover:cursor-pointer h-4 w-4" onClick={handleSearchClear} />
    if (!isLoading && isSelected) return <CheckCircleIcon className="absolute right-3 top-1.5 h-8 w-8 text-success-400" />
  }

  const handleSearchChange = (searchTerm) => {
    listRef.current.scrollTo({ top: 0 })
    setSelectedOptionIndex(0)
    handleChange(searchTerm)
  }
    
  const handleSearchClear = () => {
    listRef.current.scrollTo({ top: 0, behavior: "smooth" })
    setSelectedOptionIndex(0)
    handleClear()
  }
  
  const handleSelectListItem = (e) => {
    if (showMenu) {
      if(e.key === "ArrowDown") {
        e.preventDefault()
        setSelectedOptionIndex(selectedOptionIndex === options.length - 1 ? selectedOptionIndex : selectedOptionIndex + 1)

        if (options.length > 0) {
          itemRefs.current[selectedOptionIndex].scrollIntoView({  block: 'start', behavior: 'smooth' });
        }
      }
      
      if(e.key === "ArrowUp") {
        e.preventDefault()
        setSelectedOptionIndex(selectedOptionIndex === 0 ? 0 : selectedOptionIndex - 1)
        if (options.length > 0) {
          itemRefs.current[selectedOptionIndex].scrollIntoView({ block: 'end', behavior: 'smooth' });
        }
      } 
      
      if(e.key === "Enter") {
        handleOptionSelected(options[selectedOptionIndex])
      }
        
      if(e.key === "Escape") {
        if (!isSelected) {
          e.preventDefault()
          handleSearchClear()
        }
      }
    }

    if(e.key === "Backspace" || e.key === "Delete") {
      if (isSelected) {
        handleSearchClear()
      } else {
        listRef.current.scrollTo({ top: 0, behavior: "smooth" })
        setSelectedOptionIndex(0)
      }
    }
  }

  const handleSelectOption = (index, option) => {
    setSelectedOptionIndex(index)
    handleOptionSelected(option)
  }
  
  const showMenu = !isSelected && searchTerm.length > 0 && hasFocus
  const showNoDataMessage = searchTerm.length > 0 && options.length === 0
  const inputStatus = getStatus()

  return (
    <div className="relative rounded-t-lg">
      <div className="relative rounded-2xl z-20">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassCircleIcon className="absolute left-2 top-1.5 h-8 w-8 text-gray-400" />
        </div>

        <input 
          type="text" 
          className={`${showMenu ? "rounded-b-none" : ""} block z-10 bg-gray-100 focus:bg-gray-200 hover:bg-gray-200 outline-none w-full rounded-2xl border-0 py-2.5 pl-12 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6`} 
          placeholder={placeholder}
          spellCheck={false}
          onChange={(e) => handleSearchChange(e.target.value)} 
          onKeyDown={(e) => handleSelectListItem(e, showMenu)}
          value={searchTerm}
          aria-label={placeholder}
          ref={inputRef}
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
        />
        
        {inputStatus}
      </div>

      <div id={id} className={`${showMenu ? 'block' : 'hidden'} absolute z-20 shadow-lg rounded-b-xl pb-0 bg-gray-50 max-h-96 w-full overflow-y-auto`} ref={listRef}>
        <InfiniteScroll dataLength={options.length} next={() => handleLoadMore()} hasMore={hasMore} scrollableTarget={id}>
          <ul 
            id="options" 
            className={`transform-gpu scroll-py-3`}
            aria-label="Search results"
          >
            {options.map((option, index) => (
              <li className={`group flex items-center border-b select-none p-3 hover:bg-gray-100 cursor-pointer ${index === selectedOptionIndex ? "bg-gray-100" : ""}`}
                key={option.id}
                tabIndex="-1"
                ref={(el) => (itemRefs.current[index] = el)} 
                onClick={() => handleSelectOption(index, option)}
              >
                {option.icon && option.icon}

                <div className="ml-4 flex-auto">
                  <p className="text-sm font-semibold text-gray-700">{option.title}</p>
                  <p className="text-sm text-gray-600">{option.description}</p>
                </div>
              </li>
            ))}
          </ul>
        </InfiniteScroll>
        
        {isLoading && options.length === 0 && (
          <div className="m-4 h-80 flex items-center justify-center rounded-lg border-2 border-dashed border-gray-300 p-8 text-center overflow-hidden">
            <div className="flex flex-col">
              <Spinner color="secondary" size="lg" className="block mx-auto mb-2" />
              <p>Please wait loading...</p>
            </div>
          </div>
        )}

        {showNoDataMessage && !isLoading && (
          <NoDataPlaceholder
            className="!m-4"
            title={noData.title} 
            message={noData.message}
            actions={<Button className="mt-3" onClick={handleSearchClear} color="secondary" radius="full">Clear search</Button>} 
          />
        )}
      </div>
    </div>
  )}

AutoComplete.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired,
  handleLoadMore: PropTypes.func.isRequired,
  handleOptionSelected: PropTypes.func.isRequired,
  hasMore: PropTypes.bool,
  id: PropTypes.string,
  inputRef: PropTypes.object,
  isLoading: PropTypes.bool,
  isSelected: PropTypes.bool,
  noData: PropTypes.object,
  options: PropTypes.array.isRequired, 
  placeholder: PropTypes.string, 
  searchTerm: PropTypes.string, 
}

export default AutoComplete