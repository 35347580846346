import { EventMeetingTypesForm } from "@components"
import { PropTypes } from "prop-types"

const Step2 = ({ handlePrevious, handleSubmitStage, formData }) => (
  <EventMeetingTypesForm
    handlePrevious={(data) => handlePrevious(data)}
    handleSubmit={(data) => handleSubmitStage(data)}
    selectedMeetingTypeIds={formData.selected_meeting_type_ids}
    showWizardFooter={true}
  />
)

Step2.propTypes = {
  handlePrevious: PropTypes.func.isRequired,
  handleSubmitStage: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
}

export default Step2