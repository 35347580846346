import { useQuery } from "@apollo/client"
import { Chip } from "@nextui-org/react"
import { LineLoader, SimplePlaceholder } from "@components"
import { CircleStackIcon } from "@heroicons/react/24/outline"
import { GET_GUARDIAN_STUDENTS } from "@graphql"
import { studentFullname } from "@helpers"
import PropTypes from "prop-types"

const GuardianStudentsList = ({ guardian }) => {
  const { loading, error, data } = useQuery(GET_GUARDIAN_STUDENTS, {
    variables: {
      fetchPolicy: "cache-first",
      id: guardian.id,
    },
  })

  if (loading) return <LineLoader message="Loading students..." classNames="mt-8" />

  if (error) return "error"

  if (data.guardian.students.length === 0) {
    return (
      <SimplePlaceholder icon={<CircleStackIcon className="h-20 w-auto" />} title="No students found for this guardian">
        <p className="mt-1 text-sm text-gray-500">
          If you believe this guardian should have associated students then please check your MIS and Xporter
          integration.
        </p>
      </SimplePlaceholder>
    )
  }

  return (
    <ul className="divide-y divide-gray-100">
      {data.guardian.students.map(student => (
        <li key={student.id} className="flex justify-between py-2">
          {studentFullname(student)} {student.intake && <Chip color="primary">Year {student.intake}</Chip>}
        </li>
      ))}
    </ul>
  )
}

GuardianStudentsList.propTypes = {
  guardian: PropTypes.object.isRequired,
}

export default GuardianStudentsList
