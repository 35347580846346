import React from "react"
import { CardStat, Divider, EventStatusBadge } from "@components"
import { formattedDateRange, hasMeetingType } from "@helpers"
import { Cog8ToothIcon, UsersIcon } from "@heroicons/react/24/outline"
import PropTypes from "prop-types"

const EventSettings = ({
  maxBookingsPerStudent = 0,
  meetingTypes = [],
  vcAutoDisconnect = false,
  noOfSessions = 0,
  openToParents = null,
  startsAt,
  endsAt,
  slotDuration = null,
  slotGap = null,
}) => {
  const eventDate = startsAt === null ? "No Date Set" : formattedDateRange(startsAt, endsAt, true, "D", "MMM", "YYYY")

  return (
    <React.Fragment>
      <CardStat title="Date of event" stat={eventDate} />

      <Divider />

      <div className="flex flex-wrap gap-y-2 items-center justify-between text-blue-700 px-4">
        <h3 className="text-md text-blue-700">Event Status</h3>
        <EventStatusBadge size="md" startDate={startsAt} endDate={endsAt} />
      </div>

      <Divider />

      <CardStat icon={Cog8ToothIcon} meta="Number of sessions" title="No Of Sessions" stat={noOfSessions} />

      <Divider />

      {meetingTypes.length > 0 && (
        <React.Fragment>
          <CardStat
            icon={Cog8ToothIcon}
            meta={
              meetingTypes.length === 1
                ? meetingTypes[0].name + " only"
                : meetingTypes.map(meetingType => meetingType.name).join(" | ")
            }
            title="Meeting Types"
            stat={meetingTypes.length}
          />

          <Divider />
        </React.Fragment>
      )}

      {meetingTypes.length > 0 && hasMeetingType("VC", meetingTypes) && (
        <React.Fragment>
          <CardStat
            icon={Cog8ToothIcon}
            meta="Automatically disconnect VC"
            title="VC Auto Disconnect"
            stat={vcAutoDisconnect ? "Yes" : "No"}
          />

          <Divider />
        </React.Fragment>
      )}

      {slotDuration && (
        <React.Fragment>
          <CardStat icon={Cog8ToothIcon} meta="Slot duration meta" title="Slot Duration" stat={slotDuration} />
          <Divider />
        </React.Fragment>
      )}

      {slotGap && (
        <React.Fragment>
          <CardStat icon={Cog8ToothIcon} meta="Slot gap meta" title="Slot Gap" stat={slotGap} />
          <Divider />
        </React.Fragment>
      )}

      {openToParents !== null && (
        <React.Fragment>
          <CardStat
            icon={UsersIcon}
            meta="Is this event open to parents"
            title="Open to Parents"
            stat={openToParents ? "Yes" : "No"}
          />

          <Divider />
        </React.Fragment>
      )}

      <CardStat
        icon={Cog8ToothIcon}
        meta="Total slots per student"
        title="Max Bookings Per Student"
        stat={maxBookingsPerStudent === 0 ? "N/A" : maxBookingsPerStudent}
      />
    </React.Fragment>
  )
}

EventSettings.propTypes = {
  maxBookingsPerStudent: PropTypes.number,
  meetingTypes: PropTypes.arrayOf(PropTypes.object),
  noOfSessions: PropTypes.number,
  openToParents: PropTypes.bool,
  sessions: PropTypes.arrayOf(PropTypes.object),
  slotDuration: PropTypes.number,
  slotGap: PropTypes.number,
  vcAutoDisconnect: PropTypes.bool,
}

export default EventSettings
