import { Button, Dropdown, DropdownMenu, DropdownItem, DropdownTrigger } from "@nextui-org/react"
import { ArrowDownTrayIcon } from "@heroicons/react/20/solid"
import PropTypes from "prop-types"

const DownloadButton = ({ handleClick, isLoading = false, options }) => (
  <Dropdown>
    <DropdownTrigger className="hidden sm:flex">
      <Button variant="flat" isIconOnly isLoading={isLoading}>
        {!isLoading && <ArrowDownTrayIcon className="h-6 w-6" />}
      </Button>
    </DropdownTrigger>

    <DropdownMenu aria-label="Options">
      {options.map(option => (
        <DropdownItem key={option.key} className="capitalize" onClick={() => handleClick(option.key)}>
          {option.name}
        </DropdownItem>
      ))}
    </DropdownMenu>
  </Dropdown>
)

DownloadButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  options: PropTypes.array.isRequired,
}

export default DownloadButton
