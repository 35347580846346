import React, { useState } from "react"
import { NoDataTablePlaceholder, SearchInput } from "@components"
import { GET_STUDENTS } from "@graphql"
import { useQuery } from "@apollo/client"
import { Checkbox, Spinner } from '@nextui-org/react';
import InfiniteScroll from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';

const ClientStudents = ({ clientId, excludedStudents, handleAddStudent, isReadOnly = false }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [paginatorInfo, setPaginatorInfo] = useState({})
  const [perPage] = useState(50)
  const [searchTerm, setSearchTerm] = useState("")
  const [students, setStudents] = useState([])

  useQuery(GET_STUDENTS, {
    variables: {
      searchTerm: searchTerm,
      page: page,
      perPage: perPage,
      clientId: clientId,
      orderBy: [
        { column: "FORENAME", order: "ASC" },
        { column: "SURNAME", order: "ASC"}
      ],
    },
    fetchPolicy: "cache-first",
    pollInterval: 900000,
    onCompleted: (data) => {
      page === 1 
        ? setStudents(data.client.students.data) 
        : setStudents((prevStudents) => ([...prevStudents, ...data.client.students.data]))
    
      setPaginatorInfo(data.client.students.paginatorInfo)
      setIsLoading(false)
    }
  })

  const getFilteredStudents = (clientStudents) => clientStudents.filter((student) => excludedStudents[student.id] === undefined)
  
  const handleLoadMore = () => setPage(page + 1)

  const handleSearchChange = (searchTerm) => {
    setSearchTerm(searchTerm)
    setPage(1)
  }

  const filteredStudents = getFilteredStudents(students)

  return (
    <div className="grow">
      <h3 className="text-md font-semibold mb-2">All Students</h3>
      
      <SearchInput
        classNames="mb-3"
        debounceMilliSeconds={300}
        placeholder="Search for a student"
        handleChange={(searchTerm) => handleSearchChange(searchTerm)}
        handleClear={() => setSearchTerm("")}
      />

      <div className="bg-gray-50 rounded-lg h-[400px]">
        {isLoading && (
          <div className="flex items-center justify-center h-full">
            <Spinner /><p className="ml-2">Please wait fetching students...</p>
          </div>
        )}

        {!isLoading && filteredStudents.length === 0 && (
          <NoDataTablePlaceholder className="flex items-center justify-center h-full !my-0" />
        )}

        {!isLoading && (
          <InfiniteScroll
          dataLength={students.length}
          next={() => handleLoadMore()}
          hasMore={paginatorInfo.hasMorePages}
          loader={<div className="flex items-center justify-center gap-2 px-3 py-1 bg-gray-100 border-b-1 border-gray-300 border-dashed"><Spinner size="sm" /><p className="ml-2">Loading more students...</p></div>}
          height={400}
          >
            {filteredStudents.map((student) => (
              <div key={student.id} className="flex items-center gap-2 px-3 py-1 border-b-1 border-gray-300 border-dashed">
                <Checkbox onChange={() => handleAddStudent(student)} isDisabled={isReadOnly} />
                <p className="text-md">{student.forename} {student.surname}</p>
              </div>
            ))}
          </InfiniteScroll>
        )}
      </div>
    </div>
  )
}

ClientStudents.propTypes = {
  clientId: PropTypes.string.isRequired,
  excludedStudents: PropTypes.object.isRequired,
  handleAddStudent: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
}

export default ClientStudents