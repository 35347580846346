import { gql } from "@apollo/client"

const GET_SITE_DATA_SUBJECTS_SUMMARY = gql`
  query ($clientId: ID!) {
    dataSubjectsSummary(client_id: $clientId) {
      data_age
      imported_at
      total
      sync_count
      custom_count
      missing_lesson_count
    }
  }
`

export { GET_SITE_DATA_SUBJECTS_SUMMARY }
