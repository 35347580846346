import { gql } from "@apollo/client"

const GET_EVENTS_LIST = gql`
  query (
    $clientId: ID!
    $searchTerm: String
    $page: Int!
    $perPage: Int!
    $orderBy: [ClientEventsOrderByRelationOrderByClause!]
  ) {
    client(id: $clientId) {
      id

      events(searchTerm: $searchTerm, page: $page, first: $perPage, orderBy: $orderBy) {
        data {
          id
          title
          description
          start_date
          end_date
          vc_auto_disconnect
          open_parents
          open_staff

          meetingTypes {
            id
            type
          }
        }

        paginatorInfo {
          total
          currentPage
          hasMorePages
          lastPage
          perPage
        }
      }
    }
  }
`
export { GET_EVENTS_LIST }
