import { gql } from "@apollo/client"

const GET_STUDENTS = gql`
  query (
    $searchTerm: String
    $page: Int!
    $perPage: Int!
    $clientId: ID!
    $orderBy: [ClientStudentsOrderByOrderByClause!]
    $where: ClientStudentsWhereWhereConditions
  ) {
    client(id: $clientId) {
      id

      students(searchTerm: $searchTerm, page: $page, first: $perPage, orderBy: $orderBy, where: $where) {
        data {
          id
          forename
          surname
          is_sync
          local_id
          data_ref
          no_of_guardians
          no_of_lessons
          created_at
          updated_at
        }

        paginatorInfo {
          total
          currentPage
          hasMorePages
          lastPage
          perPage
        }
      }
    }
  }
`

const GET_STUDENT_GUARDIANS = gql`
  query ($id: ID!) {
    student(id: $id) {
      id
      forename
      surname

      guardians {
        id
        salutation
        forename
        surname

        pivot {
          id
          contact_sequence
          lives_with
          legal_guardian
        }

        user {
          id
          email
          mobile
          activated_at
          last_active
        }
      }
    }
  }
`

const GET_STUDENT_LESSONS = gql`
  query ($id: ID!) {
    student(id: $id) {
      id
      forename
      surname

      lessons {
        id
        name

        teacher {
          id
          salutation
          forename
          surname
        }
      }
    }
  }
`

export { GET_STUDENTS, GET_STUDENT_GUARDIANS, GET_STUDENT_LESSONS }
