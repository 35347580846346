import { useState, useRef } from "react"
import { useQuery } from "@apollo/client"
import { Button, Chip, Pagination } from "@nextui-org/react"
import { LineLoader, PaginatorInfo, SimplePlaceholder, SearchInput } from "@components"
import { PresentationChartLineIcon } from "@heroicons/react/24/outline"
import { ComputerDesktopIcon, DeviceTabletIcon, WindowIcon } from "@heroicons/react/24/solid"
import { GET_DASHBOARD_EVENT_VIEWS } from "@graphql"
import { humanReadableDuration } from "@helpers"
import PropTypes from "prop-types"

const EventViewsList = ({ eventId, filter = null, teacherId = null }) => {
  const [searchTerm, setSearchTerm] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const searchInputRef = useRef(null)

  const { loading, error, data } = useQuery(GET_DASHBOARD_EVENT_VIEWS, {
    variables: {
      fetchPolicy: "network-first",
      input: {
        event_id: eventId,
        teacher_id: teacherId,
        filter,
        search_term: searchTerm,
      },
      page: currentPage,
      perPage: 50,
    },
  })

  const handleClearSearch = () => {
    searchInputRef.current.value = ""
    setSearchTerm("")
  }

  if (error) return "error"

  return (
    <div>
      <SearchInput
        classNames="mb-3"
        debounceMilliSeconds={300}
        placeholder="Search by name / email"
        handleChange={searchTerm => setSearchTerm(searchTerm)}
        handleClear={() => setSearchTerm("")}
        innerRef={searchInputRef}
        value={searchTerm}
      />

      {loading && <LineLoader message="Loading event views..." classNames="mt-8" />}

      {!loading && data.eventUserViews.data.length === 0 && (
        <SimplePlaceholder icon={<PresentationChartLineIcon className="h-20 w-auto" />} title="No event views found">
          <p className="mt-1 text-sm text-gray-500">
            No event views found.{" "}
            {searchTerm.length > 0 && (
              <span>
                Try a different search term.{" "}
                <Button className="mt-4" color="primary" fullWidth onClick={() => handleClearSearch()}>
                  Clear Search
                </Button>
              </span>
            )}
          </p>
        </SimplePlaceholder>
      )}

      <ul className="clear-right divide-y divide-gray-100">
        {!loading &&
          data.eventUserViews.data.map(view => (
            <li key={view.user_id} className="py-2">
              <div className="flex items-center justify-between">
                <span className="text-md font-semibold">{view.name}</span>

                <div>
                  <Chip
                    color="warning"
                    classNames={{
                      base: "mr-2",
                      content: "text-white text-xs font-semibold",
                    }}
                  >
                    {humanReadableDuration(view.last_viewed_at)} ago
                  </Chip>

                  <Chip
                    color="secondary"
                    classNames={{
                      content: "text-white text-xs font-semibold",
                    }}
                  >
                    {view.type}
                  </Chip>
                </div>
              </div>

              {view.email && <span className="text-blue-700 text-sm">{view.email}</span>}

              <div className="text-sm text-gray-500 mt-1 flex items-center">
                <ComputerDesktopIcon className="h4 w-4 mr-2 text-indigo-400" />
                <span className="mr-4">{view.platform_family}</span>
                <DeviceTabletIcon className="h4 w-4 mr-2 text-green-400" />
                <span className="mr-4">{view.device_type}</span>
                <WindowIcon className="h4 w-4 mr-2 text-blue-400" />
                {view.browser_family}
              </div>
            </li>
          ))}
      </ul>

      {data?.eventUserViews?.paginatorInfo?.lastPage > 0 && data?.eventUserViews?.paginatorInfo?.hasMorePages && (
        <div className="flex w-full justify-between items-center mt-3">
          <PaginatorInfo paginatorInfo={data?.eventUserViews?.paginatorInfo}></PaginatorInfo>

          <Pagination
            isCompact
            showControls
            showShadow
            color="secondary"
            page={currentPage}
            total={data?.eventUserViews.paginatorInfo?.lastPage}
            onChange={page => setCurrentPage(page)}
          />
        </div>
      )}
    </div>
  )
}

EventViewsList.propTypes = {
  eventId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  teacherId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  filter: PropTypes.string,
}

export default EventViewsList
