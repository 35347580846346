import { Page } from "@components"
import RocketLaunchSvg from "@svgs/rocket-launch.svg"

const ComingSoon = () => (
  <Page backgroundImage={RocketLaunchSvg} classNames="bg-cover [background-position-x:65%] [background-position-y:20%] lg:bg-center">
    <div className="h-full max-w-lg pl-10 pt-20">
      <h1 className="text-6xl font-semibold mb-4 text-blue-400">Coming Soon!</h1>
      <p className="text-white text-xl mt-4 leading-9">This area of the site is currently under construction. We are working hard to bring you a new and improved experience.</p>
    </div>
  </Page>
)

export default ComingSoon