import React from "react"
import { CheckListItem, Divider } from "@components"

import {
  BookmarkSlashIcon,
  Cog8ToothIcon,
  PaperAirplaneIcon,
  UserGroupIcon,
  UsersIcon,
} from "@heroicons/react/24/outline"

import PropTypes from "prop-types"

const EventCheckList = ({
  bookingsDisabled,
  eventCreated,
  noOfInvitationsPending = 0,
  noOfInvitationsSent = 0,
  noOfBookingConfirmationsPending = 0,
  noOfBookingConfirmationsSent = 0,
  noOfSessions = 0,
  noOfStudents = 0,
  openToParents,
  openToStaff,
}) => {
  const getEmailStatus = (sent, pending) => {
    if (sent === 0) return "warning"
    if (pending === 0) return "success"
    if (pending > 0) return "warning"
  }

  return (
    <React.Fragment>
      <CheckListItem
        icon={<Cog8ToothIcon className="h-10 w-10 mr-2 text-blue-500" />}
        status={eventCreated ? "success" : "danger"}
        title="Event Created"
        meta={`${noOfSessions} sessions | ${noOfStudents} students.`}
        required={true}
      />

      <Divider />

      <CheckListItem
        icon={<UserGroupIcon className="h-10 w-10 mr-2 text-blue-500" />}
        status={openToStaff ? "success" : "danger"}
        title="Open to Staff"
        meta="Is this event open to staff."
        required={true}
      />

      <Divider />

      <CheckListItem
        icon={<PaperAirplaneIcon className="h-10 w-10 mr-2 text-blue-500" />}
        status={getEmailStatus(noOfInvitationsSent, noOfInvitationsPending)}
        title="Invitation Emails Sent"
        meta={`${noOfInvitationsPending} Pending | ${noOfInvitationsSent} Sent`}
        required={false}
      />

      <Divider />

      <CheckListItem
        icon={<UsersIcon className="h-10 w-10 mr-2 text-blue-500" />}
        status={openToParents ? "success" : "danger"}
        title="Open to Parents"
        meta="Is this event open to parents."
        required={true}
      />

      <Divider />

      <CheckListItem
        icon={<PaperAirplaneIcon className="h-10 w-10 mr-2 text-blue-500" />}
        status={getEmailStatus(noOfBookingConfirmationsSent, noOfBookingConfirmationsPending)}
        title="Booking Confirmation Emails Sent"
        meta={`${noOfBookingConfirmationsPending} Pending | ${noOfBookingConfirmationsSent} Sent`}
        required={false}
      />

      <Divider />

      <CheckListItem
        icon={<BookmarkSlashIcon className="h-10 w-10 mr-2 text-blue-500" />}
        status={bookingsDisabled ? "success" : "warning"}
        title="Bookings Disabled"
        meta="You may wish to disable parent bookings just prior to the event."
        required={false}
      />
    </React.Fragment>
  )
}

EventCheckList.propTypes = {
  bookingsDisabled: PropTypes.bool.isRequired,
  eventCreated: PropTypes.bool.isRequired,
  noOfSessions: PropTypes.number,
  noOfStudents: PropTypes.number,
  noOfInvitationsPending: PropTypes.number,
  noOfInvitationsSent: PropTypes.number,
  noOfBookingConfirmationsPending: PropTypes.number,
  noOfBookingConfirmationsSent: PropTypes.number,
  openToParents: PropTypes.bool.isRequired,
  openToStaff: PropTypes.bool.isRequired,
}

export default EventCheckList
