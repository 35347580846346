import React, { useContext } from "react"
import { UserContext } from "@context"
import { Page, EventPageHeader } from "@components"
import { useSetActiveSidebarLink, useSetCurrentEvent } from "@hooks"
import { NewspaperIcon } from "@heroicons/react/20/solid"
import { Link, useParams } from "react-router-dom"

const ManageEvent = () => {
  const { event: eventId } = useParams()
  const { user, isLoadingCurrentEvent } = useContext(UserContext)
  useSetCurrentEvent(eventId)
  useSetActiveSidebarLink("EVENT_DASHBOARD")

  // tidy this up
  if (isLoadingCurrentEvent) return <p>ewrwerew</p>

  return (
    <Page>
      <EventPageHeader
        title="Manage Event"
        icon={<NewspaperIcon className="page-header-icon" />}
        event={user?.currentEvent}
      />
      <Link to={`/event/${eventId}/groups`}>Manage Groups</Link>
      <Link to={`/event/${eventId}/audit-log`}>View Audit Log</Link>
      <Link to={`/event/${eventId}/bookings`}>Manage Bookings</Link>

      {/* <ManageSessionGroups 
        session={sessionQuery.data.session} 
      /> */}
    </Page>
  )
}

export default ManageEvent
