import { gql } from "@apollo/client"
import { MANAGE_GROUPS_SESSION_FRAGMENT } from "@graphql"

const GET_SESSION = gql`
  ${MANAGE_GROUPS_SESSION_FRAGMENT}
  
  query ($sessionId: ID!) {
    session(id: $sessionId) {
      ...SessionFragment
    }
  }
`

export { GET_SESSION }