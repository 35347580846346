import { Fragment, useContext } from "react"
import { NotificationContext } from "@context"
import { Transition } from "@headlessui/react"
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline"

const types = {
  danger: {
    color: "border-red-500",
    icon: <XCircleIcon className="h-8 w-8 text-red-500" aria-hidden="true" />,
  },

  info: {
    color: "border-blue-500",
    icon: <InformationCircleIcon className="h-8 w-8 text-blue-500" aria-hidden="true" />,
  },

  success: {
    color: "border-green-500",
    icon: <CheckCircleIcon className="h-8 w-8 text-green-500" aria-hidden="true" />,
  },

  warning: {
    color: "border-orange-500",
    icon: <ExclamationCircleIcon className="h-8 w-8 text-orange-500" aria-hidden="true" />,
  },
}

const Notification = () => {
  const { notification, setOpen } = useContext(NotificationContext)
  const type = types[notification.type] || types["info"]

  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-[100]"
    >
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        <Transition
          show={notification.open}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={`pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 border-l-8 border-solid ${type.color}`}
          >
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">{type.icon}</div>

                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-lg font-medium text-gray-900">{notification.title}</p>
                  <p className="mt-1 text-sm text-gray-500">{notification.content}</p>
                </div>

                <div className="ml-4 flex flex-shrink-0">
                  <button
                    type="button"
                    className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  )
}

export { Notification }
