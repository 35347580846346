import { gql } from "@apollo/client"

const GET_REPORT_COMMENTS = gql`
  query (
    $event_id: ID!
    $searchTerm: String
    $page: Int!
    $perPage: Int!
    $orderBy: [QueryReportCommentsOrderByOrderByClause!]
    $sessions: [ID!]
    $filters: [String!]
  ) {
    reportComments(
      event_id: $event_id
      page: $page
      first: $perPage
      searchTerm: $searchTerm
      orderBy: $orderBy
      sessions: $sessions
      filters: $filters
    ) {
      data {
        id
        comment
        commenter
        student
        teacher
        lesson
        session
        read
        type
        starts_at
        ends_at
        created_at
      }

      paginatorInfo {
        total
        currentPage
        hasMorePages
        lastPage
        perPage
      }
    }
  }
`

export { GET_REPORT_COMMENTS }
