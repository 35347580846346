import React, { useContext, useEffect, useState } from "react"
import { DataTable, EllipsisDropdown, EventPageHeader, NoDataTablePlaceholder, Page, TeacherItineraryList } from "@components"
import { Progress } from "@nextui-org/react"
import { useLazyQuery, useQuery } from "@apollo/client"

import {
  GET_REPORT_TEACHER_BOOKINGS,
  GET_REPORT_TEACHER_BOOKINGS_SUMMARY,
  GET_REPORT_TEACHER_ITINERARY_PDF_DOWNLOAD,
  GET_REPORT_TEACHER_BOOKINGS_XLS_DOWNLOAD_LINK,
} from "@graphql"

import { clearCache, downloadFile, formattedDateRange, getSessionFilters } from "@helpers"
import { useSetActiveSidebarLink, useSetCurrentEvent } from "@hooks"
import { BuildingOfficeIcon, QueueListIcon } from "@heroicons/react/24/outline"
import { ContextualSidebarContext, NotificationContext, UserContext } from "@context"
import { useParams } from "react-router-dom"
import { columns, defaultColumns, downloadOptions, filters } from "./data"
import { Stats } from "./Stats"

const initialSelectedColumns =
  JSON.parse(window.localStorage.getItem("reports.event.teacherBookings.columns")) ?? defaultColumns

const TeacherBookingsReport = () => {
  const { event: eventId } = useParams()
  const { user } = useContext(UserContext)
  const { setContextualSidebar } = useContext(ContextualSidebarContext)
  const { setNotification } = useContext(NotificationContext)
  const [isDownloading, setIsDownloading] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedColumns, setSelectedColumns] = useState(initialSelectedColumns)
  const [selectedFilters, setSelectedFilters] = useState(["all"])
  const [selectedSessionIds, setSelectedSessionIds] = useState(null)
  const [sortDescriptor, setSortDescriptor] = useState({ column: "group", direction: "ascending" })
  const [refetching, setRefetching] = useState(false)
  const [page, setPage] = useState(1)
  const [perPage] = useState(50)

  useSetActiveSidebarLink("EVENT_REPORTS_TEACHER_BOOKINGS", "EVENT_REPORTS")
  useSetCurrentEvent(eventId)

  useEffect(() => {
    window.localStorage.setItem("reports.event.teacherBookings.columns", JSON.stringify(selectedColumns))
  }, [selectedColumns])

  const summaryData = useQuery(GET_REPORT_TEACHER_BOOKINGS_SUMMARY, {
    variables: {
      event_id: eventId,
    },
    fetchPolicy: "cache-first",
    pollInterval: 900000,
  })

  const [getTeacherItineraryPdfsDownload] = useLazyQuery(GET_REPORT_TEACHER_ITINERARY_PDF_DOWNLOAD, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data.reportTeacherItineraryPdfs.queued) {
        setNotification({
          title: "PDF Download Queued",
          content:
            "Your download has been queued. We'll send an email to the address you logged in with containing your download. This should take no longer than 5 minutes.",
          type: "success",
          open: true,
        })
      }

      if (!data.reportTeacherItineraryPdfs.queued) {
        downloadFile(data.reportTeacherItineraryPdfs.filename, data.reportTeacherItineraryPdfs.url)
      }

      setIsDownloading(false)
    },

    onError: ({ graphQLErrors }) => {
      if (graphQLErrors) {
        setNotification({ title: "Error", type: "danger", content: graphQLErrors[0].message, open: true })
        setIsDownloading(false)
      }
    },
  })

  const [getTeacherBookingsXlsDownloadLink] = useLazyQuery(GET_REPORT_TEACHER_BOOKINGS_XLS_DOWNLOAD_LINK, {
    variables: { event_id: eventId },
    fetchPolicy: "network-only",
    onCompleted: data => {
      downloadFile(data.reportTeacherBookingsXls.filename, data.reportTeacherBookingsXls.url)
      setIsDownloading(false)
    },
  })

  const { error, data, loading, refetch } = useQuery(GET_REPORT_TEACHER_BOOKINGS, {
    variables: {
      event_id: eventId,
      searchTerm,
      filters: selectedFilters,
      sessions: selectedSessionIds,
      page,
      perPage,
      orderBy: [
        {
          column: sortDescriptor.column.toUpperCase(),
          order: sortDescriptor.direction === "ascending" ? "ASC" : "DESC",
        },
      ],
    },
    fetchPolicy: "cache-first",
    pollInterval: 900000,
  })

  const handleDownload = type => {
    if (type === "xls") {
      setIsDownloading(true)
      getTeacherBookingsXlsDownloadLink()
    }

    if (type === "pdf") {
      setIsDownloading(true)
      getTeacherItineraryPdfsDownload({ variables: { event_id: eventId, group_ids: [] } })
    }
  }

  const handleShowItinerary = item => {
    const session = user.currentEvent.sessions.find(session => parseInt(session.id) === item.session_id)

    setContextualSidebar(prevState => ({
      ...prevState,
      open: true,
      title: `${item.group}`,
      subTitle: `${session.title} - ${formattedDateRange(session.starts_at, session.ends_at)}`,
      additionalHeaderContent: item.location ? (
        <div className="flex items-center mt-2">
          <BuildingOfficeIcon className="h-5 w-5 text-indigo-300 mr-1" />
          <p className="text-md text-indigo-300">{item.location}</p>
        </div>
      ) : null,
      content: <TeacherItineraryList eventId={eventId} groupId={item.id} />,
      size: "2xl",
    }))
  }

  const refetchData = () => {
    setRefetching(true)
    summaryData.refetch()
    refetch().then(() => setRefetching(false))
  }

  const handleDownloadItinerary = item => {
    setIsDownloading(true)
    getTeacherItineraryPdfsDownload({ variables: { event_id: eventId, group_ids: [item.id] } })
  }

  const handleSearchClear = () => {
    setSearchTerm("")
    setPage(1)
  }

  const handleSearchChange = searchTerm => {
    setSearchTerm(searchTerm)
    setPage(1)
  }

  const handleSortChange = sortDescriptor => {
    setSortDescriptor(sortDescriptor)
    setPage(1)
  }

  const handleToggleColumn = selectedColumns => {
    setSelectedColumns(Object.keys(columns).filter(column => selectedColumns.has(column)))
  }

  const handleToggleSessionFilter = selectedSessionIds => setSelectedSessionIds([...selectedSessionIds])

  const handleToggleFilter = selectedFilters => {
    setSelectedFilters([...selectedFilters])
  }

  const handleRenderCell = (item, column) => {
    if (column === "actions") {
      const items = [
        { id: 1, handleClick: () => handleShowItinerary(item), caption: "View Itinerary" },
        { id: 2, handleClick: () => handleDownloadItinerary(item), caption: "Download Itinerary" },
      ]

      return <EllipsisDropdown items={items} />
    }

    if (column === "capacity") {
      const color = item.capacity <= 70 ? "success" : item.capacity <= 90 ? "warning" : "danger"

      return <Progress value={item.capacity} color={color} className="max-w-md" aria-label="Capacity" />
    }

    if (column === "booked_percentage") {
      return item.booked_percentage + "%"
    }

    return item[column]
  }

  const handleRefetchData = () => {
    clearCache("reportTeacherBookings", { $event_id: eventId })
    refetchData()
  }

  if (error) return `Error! ${error.message}`

  const sessionFilters = user?.currentEvent?.sessions ? getSessionFilters(user?.currentEvent.sessions) : null
  const defaultSelectedSessionIds = sessionFilters !== null ? Object.keys(sessionFilters) : null

  return (
    <Page>
      <EventPageHeader
        title="Teacher Bookings Report"
        icon={<QueueListIcon className="page-header-icon" />}
        event={user?.currentEvent}
      />

      <Stats summary={summaryData} />

      <DataTable
        columns={columns}
        currentPage={page}
        currentSortOrder={sortDescriptor}
        data={data?.reportTeacherBookings?.data}
        downloadOptions={downloadOptions}
        filters={filters}
        filtersSelectionMode="single"
        handleDownload={type => handleDownload(type)}
        handlePageChange={page => setPage(page)}
        handleRenderCell={(item, key) => handleRenderCell(item, key)}
        handleRefetch={() => handleRefetchData()}
        handleSearchChange={searchTerm => handleSearchChange(searchTerm)}
        handleSearchClear={handleSearchClear}
        handleSortChange={sortDescriptor => handleSortChange(sortDescriptor)}
        handleToggleColumn={selectedColumns => handleToggleColumn(selectedColumns)}
        handleToggleFilter={selectedFilters => handleToggleFilter(selectedFilters)}
        handleToggleSessionFilter={selectedSession => handleToggleSessionFilter(selectedSession)}
        loading={loading || refetching}
        isDownloading={isDownloading}
        isRefetching={refetching}
        noDataMessage={<NoDataTablePlaceholder title="No Data Found" message="No Data found! Try clearing any filters." />}
        paginatorInfo={data?.reportTeacherBookings?.paginatorInfo}
        searchInputPlaceHolder="Search by teacher"
        searchTerm={searchTerm}
        selectedColumns={selectedColumns}
        selectedFilters={selectedFilters}
        sessionFilters={sessionFilters}
        selectedSessions={selectedSessionIds ?? defaultSelectedSessionIds}
        showDownloadButton={true}
        showRefetchButton={true}
      />
    </Page>
  )
}

export default TeacherBookingsReport
