import { gql } from "@apollo/client"
import { MANAGE_GROUPS_SESSION_FRAGMENT } from "@graphql"

const UPDATE_SESSION_LESSONS_MUTATION = gql`
  ${MANAGE_GROUPS_SESSION_FRAGMENT}

  mutation($input: UpdateSessionLessonsInput!) {
    updateSessionLessons(input: $input) {
      ...SessionFragment
    }
  }
`

export default UPDATE_SESSION_LESSONS_MUTATION