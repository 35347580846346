import React, { useContext, useEffect, useState } from "react"

import { 
  DataTable, 
  DeleteLessonsConfirmationModal,
  DuplicateModal,
  EllipsisDropdown,  
  EmailPreviewModal,
  EventPageHeader, 
  GroupLessonNoteModal,
  GroupLessonStudentsDiffModal,
  LessonSelectorModal, 
  NoDataTablePlaceholder, 
  Page,
  ReassignModal,
  SessionList,
} from "@components"

import { ContextualSidebarContext, NotificationContext, UserContext } from "@context"

import { 
  DELETE_GROUP_LESSON_NOTE,
  DUPLICATE_GROUP_LESSONS_MUTATION,
  GET_DELETE_BOOKING_SLOTS_EMAIL_PREVIEW, 
  GET_GROUP_BOOKINGS_XLS_DOWNLOAD_LINK,
  GET_SESSION, 
  GET_SESSION_GROUP_LESSON_BOOKING_COUNTS,
  GET_SESSION_GROUPS_XLS_DOWNLOAD_LINK,
  REASSIGN_GROUP_LESSONS_MUTATION,
  UPDATE_GROUP_LESSON_NOTE,
  UPDATE_SESSION_LESSONS_MUTATION,
} from "@graphql"

import { downloadFile, hasExpired, mySqlToLocal, stringToColor } from "@helpers"
import { useSetCurrentEvent } from "@hooks"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { Button, Checkbox, Chip } from "@nextui-org/react"
import { useParams } from "react-router-dom"
import { columns, defaultColumns, downloadOptions, filters } from "./data"
import { SessionHeader } from "./SessionHeader"
import { Stats } from "./Stats"
import { RectangleGroupIcon } from "@heroicons/react/24/outline"
import { groupBy, isEmpty, map, orderBy } from "lodash"

const initialSelectedColumns = JSON.parse(window.localStorage.getItem("event.manage.groups.columns")) ?? defaultColumns

const ManageGroups = () => {
  const { event: eventId } = useParams()
  const { setContextualSidebar, setOpen: setShowSessionSidebar } = useContext(ContextualSidebarContext)
  const { user, isLoadingCurrentEvent } = useContext(UserContext)
  const { setNotification } = useContext(NotificationContext)
  
  const [addingMisLessons, setAddingMisLessons] = useState(false)
  const [currentSessionId, setCurrentSessionId] = useState(null)
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState({ bookingSlotIds:[], totalBookingSlots: 0, show: false, error: null })
  const [duplicateModal, setDuplicateModal] = useState({ group: null, lessons: null, selectedLessons: null, show: false })
  const [groupLessonNoteModal, setGroupLessonNoteModal] = useState({ group: null, lesson: null, groupLessonNote: null, show: false })
  const [groupLessonStudentsDiffModal, setGroupLessonStudentsDiffModal] = useState({ data: null, show: false })
  const [isDeletingLessons, setIsDeletingLessons] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [isDownloadingGroupBookingsXls, setIsDownloadingGroupBookingsXls] = useState(false)
  const [isDuplicating, setIsDuplicating] = useState(null)
  const [isReassigning, setIsReassigning] = useState(null)
  const [isUpdatingGroupLessonNote, setIsUpdatingGroupLessonNote] = useState(null)
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedColumns, setSelectedColumns] = useState(initialSelectedColumns)
  const [selectedFilters, setSelectedFilters] = useState(["all"])
  const [selectedIds, setSelectedIds] = useState(new Set([]))
  const [showEmailPreviewModal, setShowEmailPreviewModal] = useState(false)
  const [showLessonSelectorModal, setShowLessonSelectorModal] = useState(false)
  const [sortDescriptor, setSortDescriptor] = useState({ column: "group", direction: "ascending" })
  const [reassignModal, setReassignModal] = useState({ group: null, lessons: null, selectedLessons: null, show: false })
  const [refetching, setRefetching] = useState(false)
  
  useSetCurrentEvent(eventId)

  useEffect(() => {
    window.localStorage.setItem("event.manage.groups.columns", JSON.stringify(selectedColumns))
  }, [selectedColumns])

  useEffect(() => {
    if (!isLoadingCurrentEvent) {
      setCurrentSessionId(user.currentEvent.sessions[0].id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingCurrentEvent]);

  /**
   * Mutations
   */
  const [deleteGroupLessonNote] = useMutation(DELETE_GROUP_LESSON_NOTE, {
    onCompleted: () => {
      sessionQuery.refetch().then(() => {
        setGroupLessonNoteModal(prevState => ({ ...prevState, show: false }))
        setIsUpdatingGroupLessonNote(false)
      })
    }
  })

  const [duplicateGroupLessons] = useMutation(DUPLICATE_GROUP_LESSONS_MUTATION, {
    onCompleted: () => {
      sessionQuery.refetch().then((response) => {
        setDuplicateModal({ group: null, lessons: null, selectedLessons: null, show: false })
        setIsDuplicating(false)
      })
    },

    onError: (errors) => {
      setIsDuplicating(false)
      setNotification({ title: "Error", type: "danger", content: errors.graphQLErrors[0].message, open: true })
    }
  })

  const [reassignGroupLessons] = useMutation(REASSIGN_GROUP_LESSONS_MUTATION, {
    onCompleted: () => {
      sessionQuery.refetch().then((response) => {
        setReassignModal({ group: null, lessons: null, selectedLessons: null, show: false })
        setIsReassigning(false)
      })
    },
    
    onError: (errors) => {
      setIsReassigning(false)
      setNotification({ title: "Error", type: "danger", content: errors.graphQLErrors[0].message, open: true })
    }
  })
  
  const [updateGroupLessonNote] = useMutation(UPDATE_GROUP_LESSON_NOTE, {
    onCompleted: (response) => {
      sessionQuery.refetch().then(() => {
        setGroupLessonNoteModal(prevState => ({ ...prevState, show: false }))
        setIsUpdatingGroupLessonNote(false)
      })
    },
    onError: (errors) => {
      setIsUpdatingGroupLessonNote(false)
      setNotification({ title: "Error", type: "danger", content: errors.graphQLErrors[0].message, open: true })
      setGroupLessonNoteModal(prevState => ({ ...prevState, show: false }))
    }
  })
  
  const [updateSessionLessons] = useMutation(UPDATE_SESSION_LESSONS_MUTATION)
  
  
  /**
   * Queries
  */
  const [ getEmailPreview, { data: cancellationEmailPreview, loading: isLoadingCancellationEmailPreview }] = useLazyQuery(GET_DELETE_BOOKING_SLOTS_EMAIL_PREVIEW)
  
  const [getGroupBookingsXlsDownloadLink] = useLazyQuery(GET_GROUP_BOOKINGS_XLS_DOWNLOAD_LINK, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      downloadFile(data.groupBookingsXlsDownload.filename, data.groupBookingsXlsDownload.url)
      setIsDownloadingGroupBookingsXls(false)
    },
  })

  const [ getSessionGroupsXlsDownloadLink ] = useLazyQuery(GET_SESSION_GROUPS_XLS_DOWNLOAD_LINK, {
    variables: { 
      input: {
        session_id: currentSessionId 
      }
    },

    fetchPolicy: "network-only",
    
    onCompleted: data => {
      downloadFile(data.sessionGroupsXlsDownload.filename, data.sessionGroupsXlsDownload.url)
      setIsDownloading(false)
    },
  })

  const [sessionGroupLessonBookingCounts] = useLazyQuery(GET_SESSION_GROUP_LESSON_BOOKING_COUNTS, {
    variables: { sessionId: currentSessionId },
    fetchPolicy: "network-first",
    skip: currentSessionId === null,
  })

  const sessionQuery = useQuery(GET_SESSION, {
    variables: {
      sessionId: currentSessionId,
    },
    skip: currentSessionId === null,
    fetchPolicy: "cache-first",
  })

  /**
   * Functions
   */
  const getBookingCountsByGroupLesson = (session) => {
    return session.groupLessonBookingCounts.reduce((bookingCountsByLesson, count) => {
      bookingCountsByLesson[`${count.group_id}-${count.lesson_id}`] = count.no_of_bookings
      return bookingCountsByLesson
    }, {})
  }

  const getFilteredOrderedTableData = (tableData) => {
    const orderedTableData = orderBy(tableData, [sortDescriptor.column], [sortDescriptor.direction === "ascending" ? "asc" : "desc"])
    if (selectedFilters.includes("has_bookings")) return orderedTableData.filter(item => item.groupLessonBookingCount > 0)
    if (selectedFilters.includes("no_bookings")) return orderedTableData.filter(item => item.groupLessonBookingCount === 0)
    if (selectedFilters.includes("student_mismatch")) return orderedTableData.filter(item => item.groupLessonStudentCount !== item.studentCount)
    if (selectedFilters.includes("unassigned")) return orderedTableData.filter(item => item.isUnassigned)
    
    return orderedTableData
  }

  const getGroupsById = (sessionGroups) => {
    return sessionGroups.reduce((groups, group) => {
      groups[group.id] = group
      return groups
    }, {})
  }

  const getLessonsToDeletePayload = () => {
    return Array.from(selectedIds).reduce((lessonIdsByGroup, id) => {
      const [groupId, lessonId] = id.split("-")
      const groupIndex = lessonIdsByGroup.findIndex((group) => group.group_id === groupId)

      groupIndex === -1 
        ? lessonIdsByGroup.push({ group_id: groupId, lesson_ids: [lessonId] })
        : lessonIdsByGroup[groupIndex].lesson_ids.push(lessonId)
      
      return lessonIdsByGroup
    }, [])
  }

  const getStudentCountsByGroupLesson = (session) => {
    return session.groupLessonStudentCounts.reduce((studentCountsByLesson, count) => {
      studentCountsByLesson[`${count.group_id}-${count.lesson_id}`] = count.no_of_students
      return studentCountsByLesson
    }, {})
  }

  const getSubjectStyles = subject => {
    if (subject === "N/A") return { backgroundColor: "#c0bcc2", color: "white" }

    return {
      backgroundColor: stringToColor(subject, "subject"),
      color: "white",
    }
  }

  const getTableData = (session, filter = true) => {
    const bookingCountsByGroupLesson = getBookingCountsByGroupLesson(session)
    const studentCountsByGroupLesson = getStudentCountsByGroupLesson(session)

    const data = session?.groups.reduce((data, group) => {
      if (group.lessons.length > 0) {
        group?.lessons.forEach(lesson => {
          data.push({ 
            id: `${group.id}-${lesson.id}`,
            lessonId: lesson.id,
            groupId: group.id, 
            lesson: lesson.name, 
            group: group.title, 
            studentCount: lesson.studentCount,
            isUnassigned: group.user_id === user?.client?.unassignedStaffMember?.user_id,
            groupLessonStudentCount: studentCountsByGroupLesson[`${group.id}-${lesson.id}`] || 0,
            subject: lesson?.subject?.name || "N/A",
            groupLessonBookingCount: bookingCountsByGroupLesson[`${group.id}-${lesson.id}`] || 0,
          })
        })
      }

      return data
    }, [])

    if (filter) {
      return data.filter(item => isInFilter(item))
    }

    return data
  }

  const getLessonIdsByUserId = (lessons) => {
    const groupedLessons = groupBy(lessons, (lesson) => lesson.teacher.user_id);

    return map(groupedLessons, (lessons, userId) => ({
      user_id: userId,
      lesson_ids: map(lessons, (lesson) => lesson.id)
    }));
  }

  const getSelectedLessonsByUser = () => {
    return session.groups.reduce((lessonsByUser, group) => {
      group.lessons.forEach(lesson => {
        lessonsByUser.push({ lesson_id: lesson.id, user_id: group.user_id })
      })

      return lessonsByUser
    }, [])
  }

  const getSummaryData = (tableData) => ({
    noOfLessons: tableData.length,
    noOfGroups: session.groups.length,
    noOfSessions: user.currentEvent.sessions.length,
    noOfBookings: tableData.reduce((total, item) => total + item.groupLessonBookingCount, 0),
    noOfMisStudentMismatches: tableData.reduce((total, item) => total + (item.groupLessonStudentCount !== item.studentCount ? 1 : 0), 0),
    noOfUnassignedLessons: tableData.reduce((total, item) => total + (item.isUnassigned ? 1 : 0), 0),
  })
  

  const handleAddLessons = (lessons) => {
    setAddingMisLessons(true)

    updateSessionLessons({ 
      variables: { 
        input: { 
          session_id: currentSessionId, 
          lessons_to_add: getLessonIdsByUserId(lessons), 
          lessons_to_delete: [] 
        } 
      },
      
      onCompleted: () => {
        setShowLessonSelectorModal(false)
        setAddingMisLessons(false)
      },

      onError: (e) => {
        setAddingMisLessons(false)
        setNotification({ title: "Error", type: "danger", content: e.graphQLErrors[0].message, open: true })
      }
    })
  }

  const handleChangeSession = (session) => {
    setCurrentSessionId(session.id)
    setShowSessionSidebar(false)
  }

  const handleCloseGroupLessonStudentsDiffModal = (refresh = false) => {
    setGroupLessonStudentsDiffModal(prevState => ({ ...prevState, show: false }))

    if (refresh) {
      sessionQuery.refetch()
    }
  }

  const handleDeleteLessons = (sendCancellationEmails, intro) => {
    setIsDeletingLessons(true)
  
    updateSessionLessons({ 
      variables: { 
        input: { 
          session_id: currentSessionId,
          lessons_to_add: [],
          lessons_to_delete: getLessonsToDeletePayload(), 
          send_cancellation_emails: sendCancellationEmails, 
          cancellation_email_intro: intro 
        }
      },

      onCompleted: () => {
        setIsDeletingLessons(false)
        setDeleteConfirmationModal({ bookingSlotIds: [], totalBookingSlots: 0, show: false, error: null })
        setSelectedIds(new Set([]))
      },

      onError: ({ graphQLErrors }) => {
        setDeleteConfirmationModal(prevState => ({ ...prevState, error: graphQLErrors[0] }))
        setIsDeletingLessons(false)
      }
    })
  }

  const handleDownload = type => {
    if (type === "xls") {
      setIsDownloading(true)
      getSessionGroupsXlsDownloadLink()
    }
  }
  
  const handleDownloadGroupBookingsXls = (groupId, lessonIds) => {
    setIsDownloadingGroupBookingsXls(true)
    getGroupBookingsXlsDownloadLink({ variables: { input: { event_id: user.currentEvent.id, group_id: groupId, lesson_ids: lessonIds } } })
  }

  const handleDuplicateGroupLessons = (group, selectedLessonIds, userId) => {
    setIsDuplicating(true)

    duplicateGroupLessons({ 
      variables: { 
        input: { 
          client_id: user.client.id,
          event_id: user.currentEvent.id,
          session_id: currentSessionId,
          source_group_id: group.id, 
          source_lesson_ids: selectedLessonIds, 
          user_id: userId 
        } 
      }
    })
  }

  const handlePreviewEmail = (bookingSlotIds, intro) => {
    getEmailPreview({
      variables: {
        input: {
          event_id: eventId,
          booking_slot_ids: bookingSlotIds,
          intro: intro
        }
      }
    }).then(() => {
      setShowEmailPreviewModal(true)
    })
  }

  const handleReassignGroupLessons = (group, selectedLessonIds, userId) => {
    setIsReassigning(true)

    reassignGroupLessons({ 
      variables: { 
        input: { 
          client_id: user.client.id,
          event_id: user.currentEvent.id,
          session_id: currentSessionId,
          source_group_id: group.id, 
          source_lesson_ids: selectedLessonIds, 
          user_id: userId 
        } 
      }
    })
  }

  const handleRefetchData = () => {
    setRefetching(true)
    sessionQuery.refetch().then(() => setRefetching(false))
  }

  const handleRowSelection = id => {
    selectedIds.has(id)
      ? setSelectedIds(previousIds => new Set([...previousIds].filter(_id => _id !== id)))
      : setSelectedIds(previousIds => new Set([...previousIds, id]))
  }

  const handleSaveGroupLessonNote = (group, lesson, note) => {
    setIsUpdatingGroupLessonNote(true)

    note.trim() === ""
      ? deleteGroupLessonNote({ variables: { input: { group_id: group.id, lesson_id: lesson.id } } })
      : updateGroupLessonNote({ variables: { input: { group_id: group.id, lesson_id: lesson.id, note } } })
  }

  const handleShowDeleteLessonsConfirmation = () => {
    sessionGroupLessonBookingCounts().then(({ data }) => { 
      const bookingSlotIds = data.session.groupLessonBookingCounts
        .filter(groupLesson => selectedIds.has(`${groupLesson.group_id}-${groupLesson.lesson_id}`))
        .flatMap(item => item.booking_slot_ids)
      
      setDeleteConfirmationModal({ totalBookingSlots: bookingSlotIds.length, bookingSlotIds, show: true, error: null })
    })    
  }

  const handleShowDuplicateModal = (data, group) => {
    const selectedLesson = group.lessons.find(lesson => lesson.id === data.lessonId)
    setDuplicateModal({ group, lessons: group.lessons, selectedLessons: [selectedLesson], show: true })
  }

  const handleShowGroupLessonNoteModal = (data, group) => {
    const selectedLesson = group.lessons.find(lesson => lesson.id === data.lessonId)
    setGroupLessonNoteModal({ group, lesson: selectedLesson, groupLessonNote: group.groupLessonNotes.find(note => note.lesson_id === data.lessonId), show: true })
  }
  
  const handleShowGroupLessonStudentsDiffModal = (data) => {
    setGroupLessonStudentsDiffModal({ data, show: true })
  }

  const handleShowReassignModal = (data, group) => {
    const selectedLesson = group.lessons.find(lesson => lesson.id === data.lessonId)
    setReassignModal({ group, lessons: group.lessons, selectedLessons: [selectedLesson], show: true })
  }

  const handleToggleColumn = selectedColumns => {
    setSelectedColumns(Object.keys(columns).filter(column => selectedColumns.has(column)))
  }

  const handleShowSessionsSidebar = () => {
    setContextualSidebar(prevState => ({
      ...prevState,
      open: true,
      title: "Session Selector",
      subTitle: `Select a session below.`,
      content: <SessionList sessions={user.currentEvent.sessions} handleChangeSession={(session) => handleChangeSession(session)} />,
      size: "lg",
    }))
  }

  const handleToggleFilter = selectedFilter => {
    setSelectedFilters([...selectedFilter])
  }

  const isInFilter = (item) => {
    const search = searchTerm.toLowerCase()
    return item.group.toLowerCase().includes(search) || item.lesson.toLowerCase().includes(search) || item?.subject?.toLowerCase().includes(search)
  }

  const renderCell = (data, column, group) => {
    if (column === "select") {
      return <Checkbox color="secondary" isSelected={selectedIds.has(data.id)} onChange={() => handleRowSelection(data.id)} />
    }

    const studentsColor = data.groupLessonStudentCount === data.studentCount ? "success" : "warning"

    const columnMapping = {
      group: data.group,
      lesson: data.lesson,
      subject: <Chip size="md" radius="sm" style={getSubjectStyles(data.subject)}>{data.subject}</Chip>,
      studentCounts: <Chip className="text-center text-white min-w-16" size="md" color={studentsColor}>{`${data?.groupLessonStudentCount || 0} / ${data?.studentCount || 0}`}</Chip>,
      bookingCounts: `${data?.groupLessonBookingCount || 0}`,
      actions: <EllipsisDropdown items={
        [
          { id: 1, handleClick: () => handleShowDuplicateModal(data, group), caption: "Duplicate Lesson(s)", isDisabled: isReadOnlyMode },
          { id: 2, handleClick: () => handleShowGroupLessonNoteModal(data, group), caption: "Lesson Notes" },
          { id: 3, handleClick: () => handleShowReassignModal(data, group), caption: "Reassign Lesson", isDisabled: isReadOnlyMode },
          { id: 4, handleClick: () => handleShowGroupLessonStudentsDiffModal(data), caption: "View Students" },
        ]
      } />
    }
      
    return columnMapping[column]
  }
  
  const session = sessionQuery?.data?.session || {}
  const isLoading = !currentSessionId || sessionQuery.loading
  const deleteButtonCaption = selectedIds.size === 0 ? "Delete Lessons" : `Delete ${selectedIds.size} Lessons`
  const hasSelection = selectedIds.size > 0
  const groupsById = !isLoading ? getGroupsById(session.groups) : {}
  const tableData = !isLoading ? getTableData(session) : []
  const filteredOrderedTableData = !isLoading ? getFilteredOrderedTableData(tableData) : []
  const summary = !isLoading ? getSummaryData(tableData) : {}
  const isReadOnlyMode = !isEmpty(session) ? hasExpired(mySqlToLocal(session.ends_at)) : true

  return (
    <Page>
      <EventPageHeader
        title="Manage Groups"
        icon={<RectangleGroupIcon className="page-header-icon" />}
        event={user?.currentEvent}
      />
        
      <Stats summary={summary} />

      <DataTable
        actions={
          <div className="flex gap-2">
            <Button color="primary" onClick={() => setShowLessonSelectorModal(true)} isDisabled={isReadOnlyMode}>Add Lessons</Button>
            <Button color="danger" className="shrink-0" isDisabled={!hasSelection || isReadOnlyMode} onClick={() => handleShowDeleteLessonsConfirmation()}>{deleteButtonCaption}</Button>
          </div>
        }
        columns={columns}
        currentSortOrder={sortDescriptor}  
        data={filteredOrderedTableData}
        downloadOptions={downloadOptions}
        filters={filters}
        filtersSelectionMode="single"
        handleDownload={type => handleDownload(type)}
        handleRefetch={() => handleRefetchData()}
        handleRenderCell={(item, key) => renderCell(item, key, groupsById[item.groupId])}
        handleSearchChange={searchTerm => setSearchTerm(searchTerm)}
        handleSearchClear={() => setSearchTerm("")}
        handleSortChange={sortDescriptor => setSortDescriptor(sortDescriptor)}
        handleToggleColumn={selectedColumns => handleToggleColumn(selectedColumns)}
        handleToggleFilter={selectedFilters => handleToggleFilter(selectedFilters)}
        header={<SessionHeader session={session} handleShowSessionsSidebar={handleShowSessionsSidebar} showChangeSessionButton={user.currentEvent.sessions.length > 1} />}
        isDownloading={isDownloading}
        loading={isLoading}
        isRefetching={refetching}
        noDataMessage={<NoDataTablePlaceholder title="No Lessons Found" message="No Lessons found! Try clearing any filters." />}
        searchInputPlaceHolder="Search by group / lesson / subject"
        searchTerm={searchTerm}
        selectedColumns={selectedColumns}
        selectedFilters={selectedFilters}
        showColumnsButton={true}
        showDownloadButton={true}
        showRefetchButton={true}
      />

      {deleteConfirmationModal.show && 
        <DeleteLessonsConfirmationModal
          error={deleteConfirmationModal.error}
          handleClose={() => setDeleteConfirmationModal(prevState => ({ ...prevState, show: false, error: null }))}
          handleDelete={(sendCancellationEmails, intro) => handleDeleteLessons(sendCancellationEmails, intro)}
          handlePreviewEmail={(bookingSlotIds, intro) => handlePreviewEmail(bookingSlotIds, intro)}
          isDeleting={isDeletingLessons}
          isLoadingPreview={isLoadingCancellationEmailPreview}
          noOfBookingSlotsToDelete={deleteConfirmationModal.totalBookingSlots}
          noOfLessonsToDelete={selectedIds.size}
          show={deleteConfirmationModal.show} 
          bookingSlotIds={deleteConfirmationModal.bookingSlotIds}
        />
      }

      {duplicateModal.show && 
        <DuplicateModal 
          client={user.client}
          group={duplicateModal.group} 
          lessons={duplicateModal.lessons} 
          selectedLessons={duplicateModal.selectedLessons}
          handleClose={() => setDuplicateModal(prevState => ({ ...prevState, show: false }))}
          show={duplicateModal.show}
          handleSave={(group, selectedLessonIds, userId) => handleDuplicateGroupLessons(group, selectedLessonIds, userId)}
          isSaving={isDuplicating}
        />
      }

      {showEmailPreviewModal && 
        <EmailPreviewModal 
          handleClose={() => setShowEmailPreviewModal(false)}
          html={cancellationEmailPreview?.deleteBookingSlotsPreview?.html} 
          isLoading={isLoadingCancellationEmailPreview} 
          show={showEmailPreviewModal}
          title="Delete Appointments Email Preview"
        />
      }

      {groupLessonNoteModal.show && 
        <GroupLessonNoteModal
          group={groupLessonNoteModal.group} 
          groupLessonNote={groupLessonNoteModal.groupLessonNote} 
          lesson={groupLessonNoteModal.lesson} 
          handleClose={() => setGroupLessonNoteModal(prevState => ({ ...prevState, show: false }))}
          show={groupLessonNoteModal.show}
          handleSave={(group, lesson, note) => handleSaveGroupLessonNote(group, lesson, note)}
          isReadOnly={isReadOnlyMode}
          isSaving={isUpdatingGroupLessonNote}
        />
      }

      {groupLessonStudentsDiffModal.show && (
        <GroupLessonStudentsDiffModal
          clientId={user.client.id}
          groupId={groupLessonStudentsDiffModal.data.groupId} 
          handleClose={(refresh) => handleCloseGroupLessonStudentsDiffModal(refresh)}
          handlePreviewEmail={(bookingSlotIds, intro) => handlePreviewEmail(bookingSlotIds, intro)}
          isPreviewEmailModalVisible={showEmailPreviewModal}
          isLoadingPreview={isLoadingCancellationEmailPreview}
          isReadOnly={isReadOnlyMode}
          lessonId={groupLessonStudentsDiffModal.data.lessonId}
          sessionId={currentSessionId}
          show={groupLessonStudentsDiffModal.show}
          title={`Students in ${groupLessonStudentsDiffModal.data.group} - ${groupLessonStudentsDiffModal.data.lesson}`}
        />
      )}

      {showLessonSelectorModal && 
        <LessonSelectorModal 
          clientId={user.client.id} 
          show={showLessonSelectorModal} 
          handleClose={() => setShowLessonSelectorModal(false)} 
          handleSubmit={(selectedLessons) => handleAddLessons(selectedLessons)}
          isSubmitting={addingMisLessons}
          lessons={session.lessons}
          selectedLessonsByUser={getSelectedLessonsByUser()}
        />
      }

      {reassignModal.show && 
        <ReassignModal 
          client={user.client}
          group={reassignModal.group} 
          lessons={reassignModal.lessons} 
          selectedLessons={reassignModal.selectedLessons}
          handleClose={() => setReassignModal(prevState => ({ ...prevState, show: false }))}
          show={reassignModal.show}
          handleSave={(group, selectedLessonIds, userId) => handleReassignGroupLessons(group, selectedLessonIds, userId)}
          handleDownload={(groupId, lessonIds) => handleDownloadGroupBookingsXls(groupId, lessonIds)}
          isDownloading={isDownloadingGroupBookingsXls}
          isSaving={isReassigning}
        />
      }
    </Page>
  )
}

export default ManageGroups
