const formatDuration = (time, includeSeconds = false) => {
  const timeParts = time.split(":")
  const days = timeParts[0] > 23 ? Math.floor(timeParts[0] / 24) : 0
  const hours = timeParts[0] > 23 ? timeParts[0] % 24 : parseInt(timeParts[0], 10)
  const minutes = parseInt(timeParts[1], 10)

  return `
    ${days > 0 ? `${days} day${days > 1 ? "s" : ""} ` : ""}
    ${hours > 0 ? `${hours} hr${hours > 1 ? "s" : ""} ` : ""}
    ${minutes} min${minutes > 1 ? "s" : ""}
    ${includeSeconds ? ` and ${timeParts[2]} second${timeParts[2] > 1 ? "s" : ""}` : ""}
  `
}

export default formatDuration
