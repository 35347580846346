import {
  EventDateRangeBadge,
  EventSessionCountBadge,
  EventStatusBadge,
  PageHeader,
} from "@components"

import PropTypes from "prop-types"

const Header = ({ actions = null, title, icon, event = null }) => (
  <PageHeader
    actions={actions}
    subTitle={event?.title}
    title={title}
    icon={icon}
    bgColor="bg-indigo-900"
    contentBottom={
      <div className="flex gap-2 flex-wrap">
        <EventDateRangeBadge startDate={event?.start_date} endDate={event?.end_date} />
        <EventStatusBadge size="md" startDate={event?.start_date} endDate={event?.end_date} />
        <EventSessionCountBadge size="md" count={event?.sessions?.length ?? 0} />
      </div>
    }
  />
)

Header.propTypes = {
  actions: PropTypes.node,
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  event: PropTypes.object,
}

export default Header
