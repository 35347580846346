import { gql } from "@apollo/client"

const GET_LESSON_SELECTOR = gql`
  query client(
    $clientId: ID!,
		$filterByIds: [ID!],
		$page: Int!,
		$perPage: Int!,
    $searchTerm: String!, 
    $orderBy: [ClientLessonsOrderByOrderByClause!],
		$where: ClientLessonsWhereWhereConditions
  ) {
    client(id: $clientId) {
      id
		
			lessons(searchTerm: $searchTerm, orderBy: $orderBy, page: $page, first: $perPage, filterByIds: $filterByIds, where: $where) {
				data {
					id
					name
					is_sync
					studentCount

					subject {
					  id
						name
						is_sync
					}
					
					teacher {
						id
						salutation
						forename
						surname
						is_sync
						user_id
						unassigned
					}
				}

				paginatorInfo {
					currentPage
					lastPage
					perPage
					total
				}
			}
		}
	}
`

export { GET_LESSON_SELECTOR }
