import React, { useContext, useState } from "react"
import { DataTable, EllipsisDropdown, EventPageHeader, EventViewsTracker, NoDataTablePlaceholder, Page } from "@components"
import { UserContext } from "@context"
import { GET_AUDIT_LOG, GET_AUDIT_LOG_DOWNLOAD, GET_AUDIT_LOG_SUMMARY_AND_TYPES } from "@graphql"
import { downloadFile, mySqlToLocal } from "@helpers"
import { useSetCurrentEvent } from "@hooks"
import { useLazyQuery, useQuery } from "@apollo/client"
import { ClipboardDocumentCheckIcon } from "@heroicons/react/24/outline"
import { Chip } from "@nextui-org/react"
import { cache } from "@services/graphql/client"
import { truncate } from "lodash"
import { useParams } from "react-router-dom"
import { columns, defaultColumns, downloadOptions, typeStyles } from "./data"
import { Stats } from "./Stats"
import { DetailsModal } from "./DetailsModal"

const initialSelectedColumns = JSON.parse(window.localStorage.getItem("event.auditlog.columns")) ?? defaultColumns

const AuditLog = () => {
  const { event: eventId } = useParams()
  const { user } = useContext(UserContext)
  const [currentRow, setCurrentRow] = useState({})
  const [isDownloading, setIsDownloading] = useState(false)
  const [refetching, setRefetching] = useState(false)
  const [selectedColumns, setSelectedColumns] = useState(initialSelectedColumns)
  const [searchTerm, setSearchTerm] = useState("")
  const [showDetailsModal, setShowDetailsModal] = useState(false)
  const [sortDescriptor, setSortDescriptor] = useState({ column: "created_at", direction: "descending" })
  const [selectedTypes, setSelectedTypes] = useState([])
  const [summary, setSummary] = useState({})
  const [page, setPage] = useState(1)
  const [perPage] = useState(50)
  
  useSetCurrentEvent(eventId)

  const { data: typesData, loading: typesLoading } = useQuery(GET_AUDIT_LOG_SUMMARY_AND_TYPES, { 
    fetchPolicy: "cache-first",
    variables: { eventId },
    onCompleted: data => {
      setSelectedTypes(data.auditLogTypes.map(type => type.id))
      setSummary(data.event.auditLogSummary)
    }
  })

  const {data, loading, refetch} = useQuery(GET_AUDIT_LOG, {
    variables: {
      eventId,
      page,
      perPage,
      orderBy: [
        {
          column: sortDescriptor.column.toUpperCase(),
          order: sortDescriptor.direction === "ascending" ? "ASC" : "DESC",
        },
      ],
      searchTerm,
      types: selectedTypes
    },
    skip: selectedTypes.length === 0,
    fetchPolicy: "cache-first",
    pollInterval: 1500000,
  })

  const [getAuditLogXlsDownloadLink] = useLazyQuery(GET_AUDIT_LOG_DOWNLOAD, {
    fetchPolicy: "network-only",
    variables: { eventId },
    onCompleted: data => {
      downloadFile(data.event.auditLogXlsDownload.filename, data.event.auditLogXlsDownload.url)
      setIsDownloading(false)
    },
  })

  const getFilters = () => {
    return typesData?.auditLogTypes?.reduce((filters, type) => {
      filters["o" + type.id] = { id: type.id, name: type.description }
      return filters
    }, {})
  }

  const handleDownload = type => {
    if (type === "xls") {
      setIsDownloading(true)
      getAuditLogXlsDownloadLink()
    }
  }

  const handleRefetchData = () => {
    const cacheData = cache.extract();

    Object.keys(cacheData).forEach(key => {
      const entry = cacheData[key];

      if (entry.__typename === "AuditLog" && entry.event_id === eventId) {
        cache.evict({ id: key });
      }
    });

    cache.gc();

    refetchData()
  }

  const handleSearchChange = searchTerm => {
    setPage(1)
    setSearchTerm(searchTerm)
  }

  const handleSearchClear = () => {
    setSearchTerm("")
    setPage(1)
  }

  const handleShowDetails = logItem => {
    setCurrentRow(logItem)
    setShowDetailsModal(true)
  }

  const handleSortChange = sortDescriptor => {
    setSortDescriptor(sortDescriptor)
    setPage(1)
  }

  const handleToggleColumn = selectedColumns => {
    setSelectedColumns(Object.keys(columns).filter(column => selectedColumns.has(column)))
  }

  const handleToggleFilter = selectedFilters => setSelectedTypes([...selectedFilters].map(filter => parseInt(filter.substring(1))))
  
  const refetchData = () => {
    setRefetching(true)
    refetch().then(() => setRefetching(false))
  }

  const renderCell = (logItem, column) => {
    if (column === "actions") {
      const items = [{ id: 1, handleClick: () => handleShowDetails(logItem), caption: "View Details" }]

      return <EllipsisDropdown items={items} />
    }

    if (["created_at"].includes(column)) {
      return mySqlToLocal(logItem[column]).format("YYYY-MM-DD HH:mm:ss")
    }

    if (column === "type") {
      return (
        <Chip classNames={{ base: `${typeStyles[logItem.type.code]}`, content: "text-white font-semibold px-3" }} size="sm" variant="flat">
          {logItem[column].description}
        </Chip>
      )
    }

    if (column === "user") {
      return logItem[column]?.name
    } 

    if (column === "details") {
      return (
        <p className="font-semibold text-green-600">
          { truncate(logItem[column], { length: 150 }) }
        </p>
      ) 
    } 

    return logItem[column]
  }

  const filters = getFilters(typesData)
  
  return (
    <EventViewsTracker eventId={eventId}>

      <Page>
        <EventPageHeader
          title="Event Audit Log"
          icon={<ClipboardDocumentCheckIcon className="page-header-icon" />}
          event={user?.currentEvent}
        />

        <Stats summary={summary} typeStyles={typeStyles} />

        <DetailsModal details={currentRow} show={showDetailsModal} handleClose={() => setShowDetailsModal(false)} typeStyles={typeStyles} />

        <DataTable
          columns={columns}
          currentPage={page}
          currentSortOrder={sortDescriptor}
          data={data?.event?.auditLog?.data}
          downloadOptions={downloadOptions}
          filters={filters}
          handleDownload={type => handleDownload(type)}
          handlePageChange={page => setPage(page)}
          handleRefetch={() => handleRefetchData()}
          handleRenderCell={(item, key) => renderCell(item, key)}
          handleSearchChange={searchTerm => handleSearchChange(searchTerm)}
          handleSearchClear={handleSearchClear}
          handleSortChange={sortDescriptor => handleSortChange(sortDescriptor)}
          handleToggleColumn={selectedColumns => handleToggleColumn(selectedColumns)}
          handleToggleFilter={selectedFilters => handleToggleFilter(selectedFilters)}
          loading={loading || typesLoading}
          isDownloading={isDownloading}
          isRefetching={refetching}
          noDataMessage={<NoDataTablePlaceholder title="No audit records" message="No audit records found! Try clearing any filters." />}
          paginatorInfo={data?.event?.auditLog?.paginatorInfo}
          searchInputPlaceHolder="Search by summary or user"
          searchTerm={searchTerm}
          selectedColumns={selectedColumns}
          selectedFilters={selectedTypes.map(type => "o" + type)}
          showDownloadButton={true}
          showRefetchButton={true}
        />
      </Page>
    </EventViewsTracker>
  )
}

export default AuditLog