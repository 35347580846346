import { useContext } from "react"
import { UserContext } from "@context"
import { hasOrganiserRights } from "@helpers"
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

const Tawk = () => {
  const { user } = useContext(UserContext)
  const propertyId = process.env.REACT_APP_TAWK_PROPERTY_ID
  const widgetId = hasOrganiserRights(user) ? process.env.REACT_APP_TAWK_WIDGET_ID_ADMIN : process.env.REACT_APP_TAWK_WIDGET_ID_STAFF

  return (
    <TawkMessengerReact
      propertyId={propertyId}
      widgetId={widgetId}
    />
  )
}

export default Tawk