import { useContext } from "react"
import { EventSetupWizard, Page } from "@components"
import { ModalContext, UserContext } from "@context"
import { hasFullLiveAccess, hasOrganiserRights } from "@helpers"
import { useSetActiveSidebarLink } from "@hooks"
import { Button } from "@nextui-org/react"
import { useNavigate } from 'react-router-dom';
import { ReactComponent as UsersSvg } from "@svgs/users.svg"

const NoEvents = () => {
  const { user } = useContext(UserContext)
  const { setModal } = useContext(ModalContext)
  const isOrganiser = hasOrganiserRights(user)
  const navigate = useNavigate();

  useSetActiveSidebarLink("CREATE_EVENT")

  const handleCreateEvent = () => {
    if (hasFullLiveAccess()) {
      setModal(prevState => ({ ...prevState, isOpen: true, title: "Event Setup Wizard", content: <EventSetupWizard previousLink="CREATE_EVENT" /> }))
    } else {
      navigate('/coming-soon')
    }
  }

  return (
    <Page>
      <div className="-mt-16 min-h-screen flex items-center flex-col justify-center">
        {isOrganiser && (
          <div className="flex items-center flex-col max-w-xl text-center">
            <UsersSvg className="max-h-[500px]" />
            <h1 className="text-3xl font-semibold mb-2 text-blue-400">Create your first event</h1>
            <p className="text-lg leading-7 mb-3">You currently have no events. Click the button below to create your first.</p>
            <Button radius="full" size="lg" color="secondary" onClick={() => handleCreateEvent()}>Create Event</Button>
          </div>
        )}

        {!isOrganiser && (
          <div className="flex items-center flex-col max-w-xl text-center">
            <UsersSvg className="max-h-[500px]" />
            <h1 className="text-3xl font-semibold mb-2 text-blue-400">You have no events</h1>
            <p className="text-lg leading-7 mb-3">You currently have no events. Events will appear here once opened to staff by your administrator.</p>
          </div>
        )}
      </div>
    </Page>
  )
}

export default NoEvents