import { SimplePlaceholder, WizardFooter } from "@components"
import { Button, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "@nextui-org/react"
import { PencilSquareIcon, Square3Stack3DIcon, TrashIcon } from "@heroicons/react/24/outline"
import PropTypes from "prop-types"
import dayjs from "dayjs"
import React from "react"

const advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

const ViewSessions = ({ handleDeleteSession, handleNext, handlePrevious, handleSetCurrentSession, handleSetMode, sessions, showWizardFooter }) => {
  const handleValidateForm = () => {
    if (sessions.length > 0) {
      handleNext()
    }

    return false
  }

  return (
    <div>
      {sessions.length === 0 && (
        <SimplePlaceholder icon={<Square3Stack3DIcon className="h-36 w-auto text-indigo-500" />} title="No Sessions">
          <p className="mt-1 mb-4 text-base text-gray-500">
            No sessions have been added to this event. You must have at least one session. Click the button below to add a session.
          </p>

          <Button onClick={() => handleSetMode("new")} color="primary" radius="full" size="lg">Add Session</Button>
        </SimplePlaceholder>
      )}

      {sessions.length > 0 && (
        <div>
          <Button onClick={() => handleSetMode("new")} className="mb-3 float-end" color="primary">Add Session</Button>

          <Table isStriped isCompact removeWrapper aria-label="Event sessions">
            <TableHeader>
              <TableColumn>Name</TableColumn>
              <TableColumn>Date</TableColumn>
              <TableColumn>Starts</TableColumn>
              <TableColumn>Ends</TableColumn>
              <TableColumn align="end">Duration</TableColumn>
              <TableColumn align="end">Break</TableColumn>
              <TableColumn align="end">Edit</TableColumn>
              <TableColumn align="end">Delete</TableColumn>
            </TableHeader>

            <TableBody>
              {sessions.map((session) => (
                <TableRow key={session.id}>
                  <TableCell>{session.title}</TableCell>
                  <TableCell>{dayjs(session.start_date).format("ddd Do MMM")}</TableCell>
                  <TableCell>{dayjs(session.start_time).format("HH:mm")}</TableCell>
                  <TableCell>{dayjs(session.end_time).format("HH:mm")}</TableCell>
                  <TableCell>{session.slot_duration}</TableCell>
                  <TableCell>{session.slot_gap}</TableCell>

                  <TableCell>
                    <Button size="sm" isIconOnly color="secondary" onClick={() => { handleSetMode("edit"); handleSetCurrentSession(session) }}>
                      <PencilSquareIcon className="h-6 w-6" />
                    </Button>
                  </TableCell>

                  <TableCell>
                    <Button size="sm" isIconOnly color="danger" onClick={() => handleDeleteSession(session.id)}>
                      <TrashIcon className="h-6 w-6" />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}

      {showWizardFooter && (
        <WizardFooter currentStep={4} totalSteps={5} previousCaption="Settings" nextCaption="Review" handlePrevious={() => handlePrevious()} handleNext={() => handleValidateForm()} />
      )}
    </div>
  )
}

ViewSessions.propTypes = {
  handleDeleteSession: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
  handleSetMode: PropTypes.func.isRequired,
  sessions: PropTypes.array.isRequired,
}

export default ViewSessions