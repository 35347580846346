import dayjs from "dayjs"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import { humanReadableDuration, mySqlToLocal } from "@helpers"
dayjs.extend(isSameOrAfter)

const getTimeToNextAppointment = schedule => {
  const now = dayjs()
  const nextAppointment = schedule.find(item => mySqlToLocal(item.starts_at).isSameOrAfter(now))
  return nextAppointment ? humanReadableDuration(nextAppointment.starts_at) : null
}

export default getTimeToNextAppointment
