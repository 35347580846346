import { Tooltip } from "@nextui-org/react"

import {
  BuildingOffice2Icon,
  PhoneArrowDownLeftIcon,
  VideoCameraIcon,
  ClockIcon,
  UserGroupIcon,
  UsersIcon,
} from "@heroicons/react/24/outline"

import PropTypes from "prop-types"

const EventIconSummary = ({ 
  classNames = "", 
  isPhone, 
  isVc, 
  isVcTimer, 
  isVenue, 
  isOpenToParents, 
  isOpenToStaff, 
  size = "md" 
}) => {
  const iconSize = size === "sm" ? "h-5" : size === "md" ? "h-7" : "h-9"

  return (
    <div className={`flex ${classNames}`}>
      <Tooltip showArrow={true} color="secondary" content="Venue event" className="capitalize">
        <BuildingOffice2Icon className={`mr-3 ${iconSize} w-auto text-blue-500 ${!isVenue && "opacity-40"}`} />
      </Tooltip>

      <Tooltip showArrow={true} color="secondary" content="Phone event" className="capitalize">
        <PhoneArrowDownLeftIcon className={`mr-3 ${iconSize} w-auto text-blue-500 ${!isPhone && "opacity-40"}`} />
      </Tooltip>

      <Tooltip showArrow={true} color="secondary" content="Video conference event" className="capitalize">
        <VideoCameraIcon className={`mr-3 ${iconSize} w-auto text-blue-500 ${!isVc && "opacity-40"}`} />
      </Tooltip>

      <Tooltip showArrow={true} color="secondary" content="VC Timer" className="capitalize">
        <ClockIcon className={`mr-3 ${iconSize} w-auto text-blue-500 ${!isVcTimer && "opacity-40"}`} />
      </Tooltip>

      <Tooltip showArrow={true} color="secondary" content="Open to parents" className="capitalize">
        <UserGroupIcon className={`mr-3 ${iconSize} w-auto text-blue-500 ${!isOpenToParents && "opacity-40"}`} />
      </Tooltip>

      <Tooltip showArrow={true} color="secondary" content="Open to staff" className="capitalize">
        <UsersIcon className={`mr-3 ${iconSize} w-auto text-blue-500 ${!isOpenToStaff && "opacity-40"}`} />
      </Tooltip>
    </div>
  )
}

EventIconSummary.propTypes = {
  classNames: PropTypes.string,
  isPhone: PropTypes.bool.isRequired,
  isVc: PropTypes.bool.isRequired,
  isVenue: PropTypes.bool.isRequired,
  isVcTimer: PropTypes.bool.isRequired,
  isOpenToParents: PropTypes.bool.isRequired,
  isOpenToStaff: PropTypes.bool.isRequired,
  size: PropTypes.string,
}

export default EventIconSummary
