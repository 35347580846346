import { createContext, useState } from "react"

const ContextualSidebarContext = createContext()

const ContextualSidebarContextProvider = ({ children }) => {
  const [contextualSidebar, setContextualSidebar] = useState({
    title: null,
    subTitle: null,
    content: null,
    open: false,
    size: "lg",
  })

  const setOpen = open => setContextualSidebar(prevState => ({ ...prevState, open }))

  return (
    <ContextualSidebarContext.Provider
      value={{ contextualSidebar, setContextualSidebar, open: contextualSidebar.open, setOpen }}
    >
      {children}
    </ContextualSidebarContext.Provider>
  )
}

export { ContextualSidebarContext, ContextualSidebarContextProvider }
