import { CheckIcon } from "@heroicons/react/24/solid"
import PropTypes from "prop-types"

const Step = ({ currentStep, step, totalSteps }) => {
  const isLastStep = step.stepNo === totalSteps
  const isCompleted = step.stepNo < currentStep
  const isCurrent = step.stepNo === currentStep
  const isFuture = step.stepNo > currentStep

  return (
    <li className={`${!isLastStep ? "w-full" : ""}`}>
      <div className={`flex items-center ${!isLastStep ? "w-full after:content-[''] after:w-full after:h-1 after:border-b after:border-4 after:inline-block dark:after:border-blue-800" : ""} ${isFuture ? "text-gray-500" : ""} ${isCurrent ? "text-blue-600" : ""} ${isCompleted ? "after:border-blue-100 text-blue-600 dark:text-blue-500 dark:after:border-blue-800" : ""}`}>
        <span className={`flex items-center justify-center w-14 h-14 rounded-full dark:bg-blue-800 shrink-0 ${isCurrent ? "bg-blue-100" : ""} ${isCompleted ? "bg-blue-100 dark:bg-blue-800" : "bg-gray-100 dark:bg-gray-700"}`}>
          {isCompleted ? <CheckIcon className="w-8 h-8 text-blue-600 dark:text-blue-300" /> : step.icon}
        </span>
      </div>
    </li>
  )
}

const Steps = ({ currentStep, steps }) => (
  <nav aria-label="Progress">
    <ol className="flex items-center w-full">
      {steps.map((step) => (
        <Step key={step.stepNo} currentStep={currentStep} step={step} totalSteps={steps.length} />
      ))}
    </ol>
  </nav>
)

Steps.propTypes = {
  currentStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      stepNo: PropTypes.number.isRequired,
      icon: PropTypes.element.isRequired,
    })
  ).isRequired
}

export default Steps