import { useContext, useState } from "react"
import { Steps } from "@components"
import { useMutation, useQuery } from "@apollo/client"
import { Cog8ToothIcon, DocumentCheckIcon, Square2StackIcon, Square3Stack3DIcon, VideoCameraIcon } from "@heroicons/react/24/outline";
import { ModalContext, SidebarContext, UserContext } from "@context"
import { CREATE_EVENT_MUTATION, GET_EVENT_CREATION_WIZARD, GET_ME } from "@graphql";
import { getValidationErrors } from "@helpers";
import dayjs from "dayjs"
import { Step1, Step2, Step3, Step4, Step5 } from "./Steps";

const steps = [
  { stepNo: 1, title: "Basic Info", "description": "Add a title and descriptions for your event. This will be visible to staff and parents.", icon: <Square2StackIcon className="w-8 h-8" /> },
  { stepNo: 2, title: "Meeting Types", "description": "Select all the meeting types you wish to make available to parents.", icon: <VideoCameraIcon className="w-8 h-8" /> },
  { stepNo: 3, title: "Settings", "description": "General meeting settings.", icon: <Cog8ToothIcon className="w-8 h-8" /> },
  { stepNo: 4, title: "Sessions", "description": "A standard event will likely run across a single session i.e. on Wednesday from 5:00pm to 7:00pm.  However you can specify multiple sessions if you wish to run an event across several days.", icon: <Square3Stack3DIcon className="w-8 h-8" /> },
  { stepNo: 5, title: "Review", "description": "Review the details of your event before creation.", icon: <DocumentCheckIcon className="w-8 h-8" /> }
]

const EventSetupWizard = ({ previousLink }) => {
  const { user } = useContext(UserContext)
  const { setModal } = useContext(ModalContext)
  const { activateLink } = useContext(SidebarContext)
  const [activeStep, setActiveStep] = useState(1)

  const [form, setForm] = useState({
    title: "",
    description: "",
    selected_meeting_type_ids: [],
    venue_id: "",
    max_bookings_per_student: 0,
    vc_auto_disconnect: false,
    sessions: []
  })

  const [errors, setErrors] = useState(null)
  const [event, setEvent] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [status, setStatus] = useState(null)
  const [showSteps, setShowSteps] = useState(true)

  const wizardData = useQuery(GET_EVENT_CREATION_WIZARD, {
    variables: { id: user.client.id },
    fetchPolicy: "cache-first",
  })

  const [createEvent] = useMutation(CREATE_EVENT_MUTATION, {
    refetchQueries: [
      {
        query: GET_ME, // refresh default event
        variables: { clientId: user.client.id }
      }
    ],

    onCompleted: (data) => {
      setIsSubmitting(false)
      setEvent(data.createEvent)
      setShowSteps(false)
      setStatus("Success")
    },

    onError: (errors) => {
      setIsSubmitting(false)
      setShowSteps(false)
      setStatus("Errors")
      setErrors(getValidationErrors(errors))
    }
  })

  const handlePrevious = (data = null) => {
    if (activeStep === 1) {
      activateLink(previousLink)
      setModal(prevState => ({ ...prevState, isOpen: false }))
    }

    if (activeStep === 2) {
      setForm((prevState) => ({ ...prevState, selected_meeting_type_ids: [...data] }))
    }

    if (activeStep === 3) {
      setForm((prevState) => ({ ...prevState, venue_id: data.venue_id, max_bookings_per_student: data.max_bookings_per_student, vc_auto_disconnect: data.vc_auto_disconnect }))
    }

    if (activeStep === 4) {
      setForm((prevState) => ({ ...prevState, sessions: data }))
    }

    if (activeStep === 5) {
      setStatus(null)
      setErrors(null)
      setShowSteps(true)
    }

    if (activeStep > 1) {
      setActiveStep(activeStep - 1)
    }
  }

  const handleSubmit = (data, step) => {
    if (step === 1) {
      setForm((prevState) => ({ ...prevState, title: data.title, description: data.description }))
      setActiveStep(2)
    }

    if (step === 2) {
      setForm((prevState) => ({ ...prevState, selected_meeting_type_ids: data }))
      setActiveStep(3)
    }

    if (step === 3) {
      setForm((prevState) => ({ ...prevState, venue_id: data.venue_id, max_bookings_per_student: data.max_bookings_per_student, vc_auto_disconnect: data.vc_auto_disconnect }))
      setActiveStep(4)
    }

    if (step === 4) {
      setForm((prevState) => ({ ...prevState, sessions: data }))
      setActiveStep(5)
    }

    if (step === 5) {
      setIsSubmitting(true)

      const input = {
        title: form.title,
        description: form.description,
        max_bookings_per_student: form.max_bookings_per_student,
        meeting_type_ids: form.selected_meeting_type_ids,
        vc_auto_disconnect: form.vc_auto_disconnect,
        venue_id: parseInt(form.venue_id, 10),
        sessions: form.sessions.map(session => ({
          title: session.title,
          starts_at: dayjs(session.start_date).format("YYYY-MM-DD") + " " + session.start_time.format("HH:mm:ss"),
          ends_at: dayjs(session.end_date).format("YYYY-MM-DD") + " " + session.end_time.format("HH:mm:ss"),
          slot_duration: parseInt(session.slot_duration, 10),
          slot_gap: parseInt(session.slot_gap, 10)
        }))
      }

      createEvent({ variables: { input: input } })
    }
  }

  const currentStep = steps.find(step => step.stepNo === activeStep)

  const venues = wizardData?.data?.client?.venues || []

  return (
    <div className="mt-4">
      {showSteps && (
        <div>
          <Steps steps={steps} currentStep={activeStep} />

          <div className="mt-4">
            <div className="my-6">
              <h1 className="text-lg mb-1 font-semibold">Step {currentStep.stepNo} of {steps.length} - {currentStep.title}</h1>
              <p className="text-sm text-gray-500">{currentStep.description}</p>
            </div>
          </div>
        </div>
      )}

      {currentStep.stepNo === 1 && <Step1 formData={form} handlePrevious={() => handlePrevious()} handleSubmitStage={(data) => handleSubmit(data, 1)} />}
      {currentStep.stepNo === 2 && <Step2 formData={form} handlePrevious={(data) => handlePrevious(data)} handleSubmitStage={(data) => handleSubmit(data, 2)} />}
      {currentStep.stepNo === 3 && <Step3 formData={form} handlePrevious={(data) => handlePrevious(data)} venues={venues} handleSubmitStage={(data) => handleSubmit(data, 3)} />}
      {currentStep.stepNo === 4 && <Step4 formData={form} handlePrevious={(data) => handlePrevious(data)} handleSubmitStage={(data) => handleSubmit(data, 4)} handleShowSteps={(isVisible) => setShowSteps(isVisible)} />}
      {currentStep.stepNo === 5 && <Step5 formData={form} handlePrevious={() => handlePrevious()} handleSubmit={() => handleSubmit(null, 5)} venues={venues} status={status} errors={errors} event={event} isSubmitting={isSubmitting} handleCloseModal={() => setModal(prevState => ({ ...prevState, isOpen: false }))} />}
    </div >
  )
}

export default EventSetupWizard