import React, { useContext, useEffect, useState } from "react"
import { DataTable, EventPageHeader, NoDataTablePlaceholder, Page } from "@components"
import { useLazyQuery, useQuery } from "@apollo/client"

import {
  GET_REPORT_EVENT_ATTENDANCE,
  GET_REPORT_EVENT_ATTENDANCE_SUMMARY,
  GET_REPORT_EVENT_ATTENDANCE_PDF_DOWNLOAD,
  GET_REPORT_EVENT_ATTENDANCE_XLS_DOWNLOAD_LINK,
} from "@graphql"

import { clearCache, downloadFile, getSessionFilters, mySqlToLocal } from "@helpers"
import { useSetActiveSidebarLink, useSetCurrentEvent } from "@hooks"
import { DocumentCheckIcon } from "@heroicons/react/24/outline"
import { CheckCircleIcon, QuestionMarkCircleIcon, XCircleIcon } from "@heroicons/react/20/solid"
import { NotificationContext, UserContext } from "@context"
import { useParams } from "react-router-dom"
import { columns, defaultColumns, downloadOptions, filters } from "./data"
import { Stats } from "./Stats"

const initialSelectedColumns =
  JSON.parse(window.localStorage.getItem("reports.event.attendance.columns")) ?? defaultColumns

const AttendanceReport = () => {
  const { event: eventId } = useParams()
  const { user } = useContext(UserContext)
  const { setNotification } = useContext(NotificationContext)
  const [isDownloading, setIsDownloading] = useState(false)
  const [refetching, setRefetching] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedColumns, setSelectedColumns] = useState(initialSelectedColumns)
  const [selectedFilters, setSelectedFilters] = useState(Object.keys(filters))
  const [selectedSessionIds, setSelectedSessionIds] = useState(null)
  const [sortDescriptor, setSortDescriptor] = useState({ column: "starts_at", direction: "ascending" })
  const [page, setPage] = useState(1)
  const [perPage] = useState(50)

  useSetActiveSidebarLink("EVENT_REPORTS_ATTENDANCE", "EVENT_REPORTS")
  useSetCurrentEvent(eventId)

  useEffect(() => {
    window.localStorage.setItem("reports.event.attendance.columns", JSON.stringify(selectedColumns))
  }, [selectedColumns])

  const summaryData = useQuery(GET_REPORT_EVENT_ATTENDANCE_SUMMARY, {
    variables: {
      event_id: eventId,
    },
    pollInterval: 900000,
    fetchPolicy: "cache-first",
  })

  const [getAttendancePdfsDownload] = useLazyQuery(GET_REPORT_EVENT_ATTENDANCE_PDF_DOWNLOAD, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data.reportAttendancePdfs.queued) {
        setNotification({
          title: "PDF Download Queued",
          content:
            "Your download has been queued. We'll send an email to the address you logged in with containing your download. This should take no longer than 5 minutes.",
          type: "success",
          open: true,
        })
      }

      if (!data.reportAttendancePdfs.queued) {
        downloadFile(data.reportAttendancePdfs.filename, data.reportAttendancePdfs.url)
      }

      setIsDownloading(false)
    },

    onError: ({ graphQLErrors }) => {
      if (graphQLErrors) {
        setNotification({ title: "Error", type: "danger", content: graphQLErrors[0].message, open: true })
        setIsDownloading(false)
      }
    },
  })

  const [getAttendanceXlsDownloadLink] = useLazyQuery(GET_REPORT_EVENT_ATTENDANCE_XLS_DOWNLOAD_LINK, {
    variables: { event_id: eventId },
    fetchPolicy: "network-only",
    onCompleted: data => {
      downloadFile(data.reportEventAttendanceXls.filename, data.reportEventAttendanceXls.url)
      setIsDownloading(false)
    },
  })

  const { data, loading, error, refetch } = useQuery(GET_REPORT_EVENT_ATTENDANCE, {
    variables: {
      event_id: eventId,
      searchTerm,
      page,
      perPage,
      filters: selectedFilters,
      sessions: selectedSessionIds,
      orderBy: [
        {
          column: sortDescriptor.column.toUpperCase(),
          order: sortDescriptor.direction === "ascending" ? "ASC" : "DESC",
        },
      ],
    },
    pollInterval: 900000,
    fetchPolicy: "cache-first",
  })

  const refetchData = () => {
    setRefetching(true)
    summaryData.refetch()
    refetch().then(() => setRefetching(false))
  }

  const handleDownload = type => {
    if (type === "pdf") {
      setIsDownloading(true)
      getAttendancePdfsDownload({ variables: { event_id: eventId } })
    }

    if (type === "xls") {
      setIsDownloading(true)
      getAttendanceXlsDownloadLink()
    }
  }

  const handleRefetchData = () => {
    clearCache("reportEventAttendance", { event_id: eventId })
    refetchData()
  }

  const handleSearchClear = () => {
    setSearchTerm("")
    setPage(1)
  }

  const handleSearchChange = searchTerm => {
    setSearchTerm(searchTerm)
    setPage(1)
  }

  const handleSortChange = sortDescriptor => {
    setSortDescriptor(sortDescriptor)
    setPage(1)
  }

  const handleToggleColumn = selectedColumns => {
    setSelectedColumns(Object.keys(columns).filter(column => selectedColumns.has(column)))
  }

  const handleToggleFilter = selectedFilters => setSelectedFilters([...selectedFilters])

  const handleToggleSessionFilter = selectedSessionIds => setSelectedSessionIds([...selectedSessionIds])

  const handleRenderCell = (item, column) => {
    if (column === "attendance_icon" && item.attendance === "ABSENT") {
      return <XCircleIcon className="h-6 w-auto text-danger" />
    }

    if (column === "attendance_icon" && item.attendance === "ATTENDED") {
      return <CheckCircleIcon className="h-6 w-auto text-success" />
    }

    if (column === "attendance_icon" && item.attendance === "UNKNOWN") {
      return <QuestionMarkCircleIcon className="h-6 w-auto text-warning" />
    }

    if (["starts_at", "ends_at"].includes(column)) {
      return mySqlToLocal(item[column]).format("DD/MM/YY HH:mm")
    }

    return item[column]
  }

  if (error) return `Error! ${error.message}`

  const sessionFilters = user?.currentEvent?.sessions ? getSessionFilters(user?.currentEvent.sessions) : null
  const defaultSelectedSessionIds = sessionFilters !== null ? Object.keys(sessionFilters) : null

  return (
    <Page>
      <EventPageHeader
        title="Attendance Report"
        icon={<DocumentCheckIcon className="page-header-icon" />}
        event={user?.currentEvent}
      />

      <Stats summary={summaryData} />

      <DataTable
        columns={columns}
        currentPage={page}
        currentSortOrder={sortDescriptor}
        data={data?.reportEventAttendance?.data}
        downloadOptions={downloadOptions}
        filters={filters}
        handleDownload={type => handleDownload(type)}
        handlePageChange={page => setPage(page)}
        handleRefetch={() => handleRefetchData()}
        handleRenderCell={(item, key) => handleRenderCell(item, key)}
        handleSearchChange={searchTerm => handleSearchChange(searchTerm)}
        handleSearchClear={handleSearchClear}
        handleSortChange={sortDescriptor => handleSortChange(sortDescriptor)}
        handleToggleColumn={selectedColumns => handleToggleColumn(selectedColumns)}
        handleToggleFilter={selectedFilters => handleToggleFilter(selectedFilters)}
        handleToggleSessionFilter={selectedSession => handleToggleSessionFilter(selectedSession)}
        isDownloading={isDownloading}
        isRefetching={refetching}
        loading={loading || refetching}
        noDataMessage={<NoDataTablePlaceholder title="No Data Found" message="No Data found! Try clearing any filters." />}
        paginatorInfo={data?.reportEventAttendance?.paginatorInfo}
        searchInputPlaceHolder="Search by parent / group / lesson / student / subject / attendance"
        searchTerm={searchTerm}
        selectedColumns={selectedColumns}
        selectedFilters={selectedFilters}
        sessionFilters={sessionFilters}
        selectedSessions={selectedSessionIds ?? defaultSelectedSessionIds}
        showDownloadButton={true}
        showRefetchButton={true}
      />
    </Page>
  )
}

export default AttendanceReport
