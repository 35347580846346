import { gql } from "@apollo/client"

const GET_INVITATIONS_MATRIX_SUMMARY = gql`  
  query invitationsMatrixSummary($event_id: ID!) {
	  invitationsMatrixSummary(event_id: $event_id) {
      total
      matched
      unmatched
      emailed
      not_emailed
      students
	  }
  }
`

export { GET_INVITATIONS_MATRIX_SUMMARY }