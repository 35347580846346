import { gql } from "@apollo/client"

const GET_GUARDIANS = gql`
  query (
    $searchTerm: String
    $page: Int!
    $perPage: Int!
    $clientId: ID!
    $orderBy: [ClientGuardiansOrderByOrderByClause!]
    $where: ClientGuardiansWhereWhereConditions
  ) {
    client(id: $clientId) {
      id

      guardians(searchTerm: $searchTerm, page: $page, first: $perPage, orderBy: $orderBy, where: $where) {
        data {
          id
          salutation
          forename
          surname
          local_id
          data_ref
          is_sync
          no_of_students
          created_at
          updated_at
        }

        paginatorInfo {
          total
          currentPage
          hasMorePages
          lastPage
          perPage
        }
      }
    }
  }
`

const GET_GUARDIAN_STUDENTS = gql`
  query ($id: ID!) {
    guardian(id: $id) {
      id
      salutation
      forename
      surname

      students {
        id
        forename
        surname
        intake
      }
    }
  }
`
export { GET_GUARDIANS, GET_GUARDIAN_STUDENTS }
