import { gql } from "@apollo/client"

const UPDATE_GROUP_LESSON_STUDENTS = gql`
  mutation($input: UpdateGroupLessonStudentsInput!) {
    updateGroupLessonStudents(input: $input) {
      bookingSlotsUnbooked {
        id
        slot_id
      }
    }
  }
`

export { UPDATE_GROUP_LESSON_STUDENTS }
