import { createContext, useEffect, useState } from "react"
import { useLazyQuery, useQuery } from "@apollo/client"
import { LoadingPage } from "@components"
import { GET_EVENT, GET_ME } from "@graphql"
import { decodeToken } from "@helpers"
import { authService } from "@services"

const UserContext = createContext()
const decodedToken = decodeToken(authService.getToken())
const clientId = decodedToken === null ? null : decodedToken?.client_id

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingCurrentEvent, setIsLoadingCurrentEvent] = useState(true)

  const [getCurrentEvent] = useLazyQuery(GET_EVENT, {
    onCompleted: data => {
      if (data.event === null) {
        return window.location.replace(`/404`)
      }
      
      setUser({ ...user, currentEvent: data.event })
      setIsLoadingCurrentEvent(false)
    },

    onError: error => setIsLoadingCurrentEvent(false)
  })

  const { loading, error, data } = useQuery(GET_ME, {
    variables: { clientId },
    fetchPolicy: "cache-first",
    skip: clientId === null,
  })

  const setCurrentEvent = event => {
    if (typeof event === "object") {
      setUser({ ...user, currentEvent: event })
    } else {
      setIsLoadingCurrentEvent(true)
      getCurrentEvent({ variables: { event_id: event } })
    }
  }

  useEffect(() => {
    if (!loading) {
      setIsLoading(false)

      if (data) {
        setUser({ ...data, currentEvent: data.me.defaultEvent })
      }
    }
  }, [data, loading])

  if (isLoading) {
    return <LoadingPage message="Fetching user data" />
  }

  if (error) {
    return <UserContext.Provider value={{ user: null }}>{children}</UserContext.Provider>
  }

  return <UserContext.Provider value={{ user, setCurrentEvent, isLoadingCurrentEvent }}>{children}</UserContext.Provider>
}

export { UserContext, UserContextProvider }
