import {
  CalculatorIcon,
  ChatBubbleBottomCenterIcon,
  ChatBubbleBottomCenterTextIcon,
  RectangleGroupIcon,
  UserGroupIcon,
  UsersIcon,
} from "@heroicons/react/24/outline"

import { StatCardCompact } from "@components"
import PropTypes from "prop-types"

const Stats = ({ summary = {} }) => {
  const {
    parent_total = 0,
    read_total = 0,
    no_of_groups = 0,
    staff_total = 0,
    total = 0,
    unread_total = 0,
  } = summary?.data?.reportCommentsSummary || {}

  return (
    <div className="my-5 grid grid-cols-1 gap-5 sm:grid-cols-3 2xl:grid-cols-6">
      <StatCardCompact
        title="Total"
        stat={total}
        icon={<CalculatorIcon className="h-10 w-10 text-white" aria-hidden="true" />}
      />

      <StatCardCompact
        title="Staff"
        stat={staff_total}
        icon={<UsersIcon className="h-10 w-10 text-white" aria-hidden="true" />}
      />

      <StatCardCompact
        title="Parent"
        stat={parent_total}
        icon={<UserGroupIcon className="h-10 w-10 text-white" aria-hidden="true" />}
      />

      <StatCardCompact
        title="Read"
        stat={read_total}
        icon={<ChatBubbleBottomCenterTextIcon className="h-10 w-10 text-white" aria-hidden="true" />}
      />

      <StatCardCompact
        title="Unread"
        stat={unread_total}
        icon={<ChatBubbleBottomCenterIcon className="h-10 w-10 text-white" aria-hidden="true" />}
      />

      <StatCardCompact
        title="No of Groups"
        stat={no_of_groups}
        icon={<RectangleGroupIcon className="h-10 w-10 text-white" aria-hidden="true" />}
      />
    </div>
  )
}

Stats.propTypes = {
  summary: PropTypes.object,
}

export default Stats
