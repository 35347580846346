import { gql } from "@apollo/client"

const GET_SITE_DATA_STUDENTS_SUMMARY = gql`
  query ($clientId: ID!) {
    dataStudentsSummary(client_id: $clientId) {
      data_age
      imported_at
      total
      sync_count
      custom_count
      missing_lesson_count
      missing_guardian_count
    }
  }
`

export { GET_SITE_DATA_STUDENTS_SUMMARY }
