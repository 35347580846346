import { gql } from "@apollo/client"

const GET_REPORT_COMMENTS_SUMMARY = gql`
  query ($event_id: ID!) {
    reportCommentsSummary(event_id: $event_id) {
      total
      no_of_groups
      staff_total
      parent_total
      read_total
      unread_total
    }
  }
`

export { GET_REPORT_COMMENTS_SUMMARY }
