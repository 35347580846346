import { formattedDateRange } from "@helpers"
import { ArrowPathIcon, Square3Stack3DIcon } from "@heroicons/react/24/outline"
import { Button } from "@nextui-org/react"
import { isEmpty } from "lodash"
import PropTypes from 'prop-types'

const SessionHeader = ({ handleShowSessionsSidebar, session, showChangeSessionButton }) => (
  <div className="flex items-center justify-between w-full">
    <div className="flex gap-2">
      <Square3Stack3DIcon className="h-8 w-8" />
      <h1 className="text-md font-semibold leading-8">
        {!isEmpty(session) ? `${session.title} (${formattedDateRange(session.starts_at, session.ends_at)})` : <span>Loading...</span>}
      </h1>
    </div>
    
    {showChangeSessionButton && (
      <Button 
        className="bg-indigo-700 hover:bg-indigo-900 text-white" 
        onClick={() => handleShowSessionsSidebar()} 
        startContent={<ArrowPathIcon className="text-white h-6 w-6"/>}
      >
        Switch Session
      </Button>
    )}
  </div>
)

SessionHeader.propTypes = {
  handleShowSessionsSidebar: PropTypes.func.isRequired,
  session: PropTypes.object.isRequired,
  showChangeSessionButton: PropTypes.bool.isRequired,
}

export default SessionHeader