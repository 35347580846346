import React, { useContext, useEffect, useState } from "react"
import { cache } from "@services/graphql/client"
import { Chip } from "@nextui-org/react"
import { CircleStackIcon } from "@heroicons/react/24/outline"
import { useLazyQuery, useQuery } from "@apollo/client"
import { DataTable, EllipsisDropdown, GuardianStudentsList, IntegrationTypeBadge, MisBadge, MisCountBadge, NoDataTablePlaceholder, Page, PageHeader } from "@components"
import { ContextualSidebarContext, UserContext } from "@context"
import { GET_GUARDIANS, GET_SITE_DATA_GUARDIANS_SUMMARY, GET_SITE_DATA_GUARDIANS_XLS_DOWNLOAD_LINK } from "@graphql"
import { clearCacheWithRegex, downloadFile, guardianFullname, lastImportDuration, mySqlToLocal } from "@helpers"
import { useSetActiveSidebarLink } from "@hooks"
import { columns, defaultColumns, downloadOptions, filters } from "./data"
import { Stats } from "./Stats"

const initialSelectedColumns = JSON.parse(window.localStorage.getItem("data.guardians.columns")) ?? defaultColumns

const Guardians = () => {
  const { user } = useContext(UserContext)
  const { setContextualSidebar } = useContext(ContextualSidebarContext)
  const [isDownloading, setIsDownloading] = useState(false)
  const [refetching, setRefetching] = useState(false)
  const [selectedColumns, setSelectedColumns] = useState(initialSelectedColumns)
  const [selectedFilters, setSelectedFilters] = useState(Object.keys(filters))
  const [searchTerm, setSearchTerm] = useState("")
  const [sortDescriptor, setSortDescriptor] = useState({ column: "surname", direction: "ascending" })
  const [page, setPage] = useState(1)
  const [perPage] = useState(50)

  useSetActiveSidebarLink("SITE_DATA_GUARDIANS", "SITE_DATA")

  useEffect(() => {
    window.localStorage.setItem("data.guardians.columns", JSON.stringify(selectedColumns))
  }, [selectedColumns])

  const summaryData = useQuery(GET_SITE_DATA_GUARDIANS_SUMMARY, {
    variables: {
      clientId: user.client.id,
    },
    fetchPolicy: "cache-first",
    pollInterval: 900000,
  })

  const { data, loading, error, refetch } = useQuery(GET_GUARDIANS, {
    variables: {
      searchTerm,
      page,
      perPage,
      clientId: user.client.id,
      orderBy: [
        {
          column: sortDescriptor.column.toUpperCase(),
          order: sortDescriptor.direction === "ascending" ? "ASC" : "DESC",
        },
      ],
      where: selectedFilters.length < 2 ? { column: "IS_SYNC", value: selectedFilters.includes("sync") } : null,
    },
    fetchPolicy: "cache-first",
    pollInterval: 900000,
  })

  const [getSiteDataGuardiansXlsDownloadLink] = useLazyQuery(GET_SITE_DATA_GUARDIANS_XLS_DOWNLOAD_LINK, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      downloadFile(data.dataGuardiansXlsDownload.filename, data.dataGuardiansXlsDownload.url)
      setIsDownloading(false)
    },
  })

  const refetchData = () => {
    setRefetching(true)
    refetch().then(() => setRefetching(false))
  }

  const handleDownload = type => {
    if (type === "xls") {
      setIsDownloading(true)
      getSiteDataGuardiansXlsDownloadLink()
    }
  }

  const handleRefetchData = () => {
    const cacheId = cache.identify({ __typename: "Client", id: user.client.id })
    clearCacheWithRegex(cacheId, /^guardians\(.*\)$/g)
    refetchData()
  }

  const handleSearchClear = () => {
    setSearchTerm("")
    setPage(1)
  }

  const handleSearchChange = searchTerm => {
    setPage(1)
    setSearchTerm(searchTerm)
  }

  const handleShowStudents = guardian =>
    setContextualSidebar(prevState => ({
      ...prevState,
      open: true,
      title: guardianFullname(guardian),
      subTitle: `${guardianFullname(guardian)} students are listed below.`,
      content: <GuardianStudentsList guardian={guardian} />,
      size: "lg",
    }))

  const handleSortChange = sortDescriptor => {
    setSortDescriptor(sortDescriptor)
    setPage(1)
  }

  const handleToggleColumn = selectedColumns => {
    setSelectedColumns(Object.keys(columns).filter(column => selectedColumns.has(column)))
  }

  const handleToggleFilter = selectedFilters => {
    setSelectedFilters(Object.keys(filters).filter(filter => selectedFilters.has(filter)))
  }

  const renderCell = (guardian, column) => {
    if (column === "actions") {
      const items = [{ id: 1, handleClick: () => handleShowStudents(guardian), caption: "View Students" }]

      return <EllipsisDropdown items={items} />
    }

    if (["created_at", "updated_at"].includes(column)) {
      return mySqlToLocal(guardian[column]).format("YYYY-MM-DD HH:mm")
    }

    if (column === "is_sync" && guardian.is_sync) {
      return (
        <Chip classNames={{ base: "bg-sb-sync", content: "text-white" }} size="sm" variant="flat">
          Sync
        </Chip>
      )
    }

    if (column === "is_sync" && !guardian.is_sync) {
      return (
        <Chip classNames={{ base: "bg-sb-sync-custom", content: "text-white" }} size="sm" variant="flat">
          Custom
        </Chip>
      )
    }

    return guardian[column]
  }

  if (error) return `Error! ${error.message}`

  return (
    <Page>
      <PageHeader
        title="Guardian Data"
        subTitle="Imported guardian data from your MIS / CSV file."
        icon={<CircleStackIcon className="page-header-icon" />}
        contentBottom={
          <div className="flex gap-2 flex-wrap">
            <IntegrationTypeBadge name={user?.client?.importType?.name || "unknown"} />
            <MisBadge name={user?.client?.mis || "n/a"} />
            <MisCountBadge name={`${summaryData?.data?.dataGuardiansSummary?.total || 0} Guardians`} />
          </div>
        }
      />

      <Stats
        summary={{
          dataAge: summaryData?.data?.dataGuardiansSummary?.data_age,
          lastImport: lastImportDuration(summaryData?.data?.dataGuardiansSummary?.imported_at),
          total: summaryData?.data?.dataGuardiansSummary?.total,
          syncCount: summaryData?.data?.dataGuardiansSummary?.sync_count,
          customCount: summaryData?.data?.dataGuardiansSummary?.custom_count,
          missingStudentCount: summaryData?.data?.dataGuardiansSummary?.missing_student_count,
        }}
      />

      <DataTable
        columns={columns}
        currentPage={page}
        currentSortOrder={sortDescriptor}
        data={data?.client?.guardians?.data}
        downloadOptions={downloadOptions}
        filters={filters}
        handleDownload={type => handleDownload(type)}
        handlePageChange={page => setPage(page)}
        handleRefetch={() => handleRefetchData()}
        handleRenderCell={(item, key) => renderCell(item, key)}
        handleSearchChange={searchTerm => handleSearchChange(searchTerm)}
        handleSearchClear={handleSearchClear}
        handleSortChange={sortDescriptor => handleSortChange(sortDescriptor)}
        handleToggleColumn={selectedColumns => handleToggleColumn(selectedColumns)}
        handleToggleFilter={selectedFilters => handleToggleFilter(selectedFilters)}
        isDownloading={isDownloading}
        loading={loading || refetching}
        isRefetching={refetching}
        noDataMessage={<NoDataTablePlaceholder title="No Guardians Found" message="No Guardians found! Try clearing any filters." />}
        paginatorInfo={data?.client?.guardians?.paginatorInfo}
        searchInputPlaceHolder="Search by forename / surname"
        searchTerm={searchTerm}
        selectedColumns={selectedColumns}
        selectedFilters={selectedFilters}
        showDownloadButton={true}
        showRefetchButton={true}
      />
    </Page>
  )
}

export default Guardians
