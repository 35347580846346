import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client"
import * as Sentry from '@sentry/react'
import { onError } from "@apollo/client/link/error"
import { setContext } from "@apollo/client/link/context"
import { authService } from "@services"

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
})

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      switch (err.message) {
        case "Unauthenticated.":
          authService.logout(true)
          window.location.href = "/session-expired"
          break
        default:
          Sentry.withScope(function (scope) {
            scope.setLevel('info')
            scope.setExtra('GraphQL response', JSON.stringify(err))
            Sentry.captureException(new Error(err.message))
          })
      }
    }
  }
})

const authLink = setContext((_, { headers }) => {
  const token = authService.getToken()

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      "X-Referrer": window.location.href,
    },
  }
})

export const cache = new InMemoryCache({
  typePolicies: {
    Session: {
      fields: {
        groupLessonStudentCounts: {
          merge(existing, incoming) {
            return incoming;  // Always use incoming data
          },
        },

        groupLessonBookingCounts: {
          merge(existing, incoming) {
            return incoming; // Always use incoming data
          },
        },

        groups: {
          merge(existing, incoming) {
            return incoming; // Always use incoming data 
          },
        },

      },
    },
    Group: {
      fields: {
        lessons: {
          merge(existing, incoming) {
            return incoming; // Always use incoming data
          },
        },
      }
    }
  },
})

const apolloClient = new ApolloClient({
  link: authLink.concat(errorLink).concat(httpLink),
  cache,
})

export default apolloClient
