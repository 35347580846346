import { gql } from "@apollo/client"

const GET_SITE_DATA_STAFF_XLS_DOWNLOAD_LINK = gql`
  {
    dataStaffXlsDownload {
      filename
      type
      url
      queued
    }
  }
`

export { GET_SITE_DATA_STAFF_XLS_DOWNLOAD_LINK }
