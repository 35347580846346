import React from "react"
import { ChevronRightIcon } from "@heroicons/react/24/outline"
import classNames from "classnames"
import PropTypes from "prop-types"

const CardStat = ({ children = null, title, stat = "N/A", meta = null, icon = null, handleClick = null }) => {
  const DynamicIcon = icon || null

  return (
    <div onClick={handleClick} className={classNames({ "cursor-pointer group": handleClick, "px-4": true })}>
      <h3 className="text-md mb-1 text-blue-700">{title}</h3>

      <div className="flex items-center">
        <div className="flex mr-4 items-center">
          {DynamicIcon && <DynamicIcon className="h-8 mr-2 hidden sm:block text-blue-500" />}
          <h2 className="text-xl font-semibold">{stat}</h2>
        </div>

        <div className="flex">
          <div>{meta && <p className="text-sm text-gray-500">{meta}</p>}</div>
        </div>

        {handleClick && (
          <div className="ml-auto">
            <ChevronRightIcon className="h-5 w-auto text-gray-300 group-hover:text-blue-500" />
          </div>
        )}
      </div>

      {children && <div className="mt-2">{children}</div>}
    </div>
  )
}

CardStat.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  stat: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.node]),
  meta: PropTypes.string,
  icon: PropTypes.object,
  handleClick: PropTypes.func,
}

export default CardStat
